import React from "react"
import { connect } from "react-redux"

import Icones from "../../../../assets/icones/Icones"
import { Button } from "../../../UI/Button/Button"

import "./DrawerToggle.css";

const DrawerToggle = (props) => {
  React.useEffect(() => {
    if (props.sideDrawerShow) {
      const menuItemElement = document.querySelector("#menuItemHome")

      menuItemElement.focus()
    }
  }, [props.sideDrawerShow])

  const MdMenu = Icones.md.MdMenu

  return <>
    <Button
      id="toggleSideDrawer"
      tabIndex={props.tabIndex}
      className={`clickable DrawerToggle ${(props.sideDrawerShow ? "DrawerOpen" : "")}`}
      onClick={props.clicked}
    >
      <MdMenu style={{ fontSize: "32px" }} />
    </Button>
  </>
}

const mapStateToProps = state => ({
  tabIndex: state.appReducer.getTabIndex()
  , sideDrawerShow: state.appReducer.sideDrawerShow
})

export default connect(mapStateToProps)(DrawerToggle)