import React from "react"
import { connect } from "react-redux"
import { AccordionClick } from "../../UI/Accordion/Accordion"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { formatNumber, roundNumber } from "../../../utils/NumberUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { cadastroBuildOptionsFromCadastros, cadastroBuildOptionsFromEnum } from "../../../utils/SelectUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import {
    DISCOUNT_TYPE_PERCENTAGE
    , DISCOUNT_TYPE_VALUE
} from "../../../store/reducers/controleVenda/pagamentoVendasReducer"
import * as cadastroAction from "../../../store/actions/cadastroAction"
import * as pagamentoVendasActions from "../../../store/actions/controleVenda/pagamentoVendasAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import OrigemVendaTable from "./OrigemVendaTable"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

import "./TelaPagamentoVendasContent.css";

/**
 * Conteúdo da tela de pagamento de venda(s).
 */
class TelaPagamentoVendasContent extends React.Component {
    static state = {
        mobile: true,
        totalCupom: 0,
        totalTeleEntrega: 0,
    }

    ismounted = false;

    /**
     * Monta o *check box* correspondente a todos os produtos.
     */
    buildCheckBoxHeader = () => <InputCustomizado
        tabIndex={this.props.tabIndex}
        ref={checkbox => this.checkBoxHeader = getReduxWrappedComponent(checkbox)}
        id='HeaderCheckbox'
        type='checkbox'
        name='HeaderCheckbox'
        handleInputValidado={() => this.props.dispatch(pagamentoVendasActions.setNewSalePaymentItemAmount(
            Object.keys(this.props.newSalePaymentItemMap || {}),
            this.props.hasCheckedProduct ? -1 : +1,
            true))}
    />;

    /**
     * Função que cria o Header dos itens
     */
    buildHeader = checkboxHeader => {

        log('TelaPagamentoVendasContent buildHeader', checkboxHeader);

        if (!isAncientChromeMobile) {
            return <div className='table-pagamento table-columns-header'>
                <div className='table-cell check ButtonAlign'> {checkboxHeader} </div>
                <div className='table-cell product TextAlign'> {getStrings().product} </div>
                <div className='table-cell amount NumberAlign'> {getStrings().remainingAmmount}</div>
                <div className='table-cell price NumberAlign'> {getStrings().price} </div>
                <div className='table-cell pay ButtonAlign'> {getStrings().payAmount} </div>
                <div className='table-cell total NumberAlign'> {getStrings().payTotalAndComission} </div>
            </div>;
        }

        return <div className='table-pagamento table-columns-header ancient-chrome-mobile'>
            <div className='row'>
                <div className='table-cell check ButtonAlign'> {checkboxHeader} </div>
                <div className='table-cell product TextAlign'> {getStrings().product} </div>
                <div className={`table-cell amount NumberAlign`}> {getStrings().remainingAmmount}</div>
                <div className={`table-cell price NumberAlign`}> {getStrings().price} </div>
                <div className={`table-cell pay ButtonAlign`}> {getStrings().payAmount} </div>
                <div className={`table-cell total NumberAlign`}> {getStrings().payTotalAndComission} </div>
            </div>
        </div>;
    };

    /**
     * Monta as tabelas de totais, de itens ou de pagamentos.
     */
    buildTable = (className, title, header, itemList, totalLabel, productTotal, commissionTotal, discountTotal, total) => {
        log('TelaPagamentoVendasContent buildTable', { className, title, header, itemList, totalLabel, productTotal, commissionTotal, discountTotal, total });

        const totalCupom = productTotal ? (this.state?.totalCupom || 0) * -1 : 0;
        const totalTeleEntrega = productTotal ? this.state?.totalTeleEntrega || 0 : 0;
        total = total + totalTeleEntrega + totalCupom;

        return <div className={`table${className ? ` ${className}` : ''}${isAncientChromeMobile ? ' ancient-chrome-mobile' : ''}`}>
            <div name='accordion' className={`accordion table-header Bold${className === 'itens-venda-pagar' ? ' active' : ''}`} onClick={AccordionClick}>{title}</div>
            <div className='accordion-content'>
                {header}
                {itemList}
                {this.valueTableCell(getStrings().products, productTotal)}
                {this.valueTableCell(getStrings().commission, commissionTotal)}
                {this.valueTableCell(getStrings().coupon, totalCupom)}
                {this.valueTableCell(getStrings().deliveryLabel, totalTeleEntrega)}
                {this.valueTableCell(getStrings().discount, discountTotal)}
                {this.valueTableCell(totalLabel, total)}
            </div>
        </div>;
    }

    /**
     * Método que define o valor inicial do select.
     */
    setFormaPagamentoVinculado = () => {
        log('TelaPagamentoVendasContent setFormaPagamentoVinculado');

        this.paymentMethod.inputComponent.updateValue(this.props.paymentMethod ? cadastroBuildOptionsFromCadastros(false, this.props.paymentMethod) : null);
    }

    /**
     * Cria os totais de fora das tabelas.
     */
    valueTableCell = (label, value) => <div className='pure-u-xl-1-2 pure-u-lg-1-2 pure-u-md-1-2 pure-u-sm-1-2 pure-u-1-1' >
        <div className='table-row table-total' >
            <label className='TextAlign' >{label}</label>
            <label className='NumberAlign' >{formatNumber(value, 2)}</label>
        </div>
    </div>;

    inputDesconto = (props) => {
        return <div className='TPVContent row accordion-content'>
            <div className='TPVContent column'>
                <label className='commissionLabel'>{getStrings().commissionPercent}</label>
                <label className='commissionInput'>
                    <InputCustomizado
                        id='comissao'
                        inputType='masked'
                        type='text'
                        validacaoDados='numeroDecimal'
                        name='comissao'
                        ref={input => {
                            if (input && !this.comissaoInput) {
                                this.comissaoInput = getReduxWrappedComponent(input)
                                this.comissaoInput.setMaskValue(this.props.commissionTotal)
                            }
                        }}
                        placeholder={getStrings().commissionPercentShort}
                        // Atualiza a comissão no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                        onInput={() => {
                            if (this.comissaoInput.getMaskValue() || (this.comissaoInput.getMaskValue() === 0)) {
                                this.props.dispatch(pagamentoVendasActions.updatePercentualComissao(this.comissaoInput.getMaskValue()));
                            }
                        }}
                        // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera a comissão para zero.
                        // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                        onBlur={() => {
                            if ((!this.comissaoInput.getMaskValue()) && (this.comissaoInput.getMaskValue() !== 0)) {
                                this.props.dispatch(pagamentoVendasActions.updatePercentualComissao(0));
                            }
                        }}
                    />
                </label>
            </div>

            <div className='TPVContent column'>
                <label className='discountTypeLabel'>{getStrings().discountType}</label>
                <label className='discountTypeValue'>
                    <InputCustomizado
                        id='discountType'
                        inputType='singleSelect'
                        name='discountType'
                        ref={input => this.discountType = getReduxWrappedComponent(input)}
                        placeholder={getStrings().discountTypePlaceholder}
                        onChange={selected => { selected && this.props.dispatch(pagamentoVendasActions.setDiscountType(selected.labelKey)); }}
                        clearable={false}
                        searchable={false}
                        sort
                    />
                </label>
            </div>

            <div className='TPVContent column'>
                <label className='discountFieldLabel'>{getStrings().discountFieldLabelEnumToString(this.props.discountType)}</label>
                <label className='discountFieldValue'>
                    <InputCustomizado
                        id='discountField'
                        inputType='masked'
                        type='text'
                        validacaoDados='numeroDecimal'
                        name='discountField'
                        ref={input => this.discountField = getReduxWrappedComponent(input)}
                        placeholder={getStrings().discountFieldValuePlaceholderEnumToString(this.props.discountType)}
                        // Atualiza o desconto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                        onInput={() => {
                            if (this.discountField.getMaskValue() || (this.discountField.getMaskValue() === 0)) {
                                this.props.dispatch(pagamentoVendasActions.updateDiscount(this.discountField.getMaskValue()));
                            }
                        }}
                        // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera o desconto para zero.
                        // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                        onBlur={() => {
                            if ((!this.discountField.getMaskValue()) && (this.discountField.getMaskValue() !== 0)) {
                                this.props.dispatch(pagamentoVendasActions.updateDiscount(0));
                            }
                        }}
                    />
                </label>
            </div>
        </div>;
    };

    atualizaTotaisCupomTeleEntrega = () => {
        let totalCupom = 0;
        let totalTeleEntrega = 0;
        const mapVendasNaoSelecionadas = {};

        Object.values(this.props.newSalePaymentItemMap).forEach(item => {
            if (!item.checked) {
                mapVendasNaoSelecionadas[item.vendaURI] = true;
            }
        })

        this.props.origemVendaList.forEach(origemVenda => {
            (origemVenda.vendaList || []).forEach(venda => {
                if (!mapVendasNaoSelecionadas[getURIFromEntity(venda)]) {
                    totalCupom = venda.entregaVenda?.valorCupom ? totalCupom + venda.entregaVenda.valorCupom : totalCupom;
                    totalTeleEntrega = venda.entregaVenda?.valorTeleEntrega ? totalTeleEntrega + venda.entregaVenda.valorTeleEntrega : totalTeleEntrega;
                }
            })
        });

        if (this.state) {
            if (totalCupom !== this.state.totalCupom || totalTeleEntrega !== this.state.totalTeleEntrega) {
                this.setState({ totalCupom, totalTeleEntrega });
            }
        }
        else {
            this.setState({ totalCupom, totalTeleEntrega });
        }
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Carrega os tipos disponíveis e também define os valores iniciais das opções conforme cadastro carregado.
     */
    componentDidMount() {
        log('TelaPagamentoVendasContent componentDidMount');
        this.ismounted = true;
        // Define as opções disponíveis no campo "Tipo" e seleciona o valor atual do cadastro.
        this.discountType.inputComponent.updateOptions(cadastroBuildOptionsFromEnum(true,
            [DISCOUNT_TYPE_PERCENTAGE, DISCOUNT_TYPE_VALUE], string => getStrings().discountTypeEnumToString(string)));

        this.discountType.inputComponent.updateValue(cadastroBuildOptionsFromEnum(false,
            this.props.discountType, string => getStrings().discountTypeEnumToString(string)));

        this.props.dispatch(cadastroAction.cadastroLoadFormasPagamento(cadastros => {
            if (!this.ismounted)
                return;

            this.paymentMethod.inputComponent.updateOptions(cadastroBuildOptionsFromCadastros(true, cadastros));
        }, () => { }));

        // Atualiza o percentual de comissão
        let value = this.props.percentualComissaoEmpresa;
        this.props.dispatch(pagamentoVendasActions.updatePercentualComissao(value || 0));

        // Atualiza o valor de desconto
        this.props.dispatch(pagamentoVendasActions.updateDiscount(0));

        // Verifica se deve exibir o dialog para encerrar vendas ao pagar todos os produtos
        this.props.dispatch(pagamentoVendasActions.showProductsPaidCloseSalesDialog());

        this.atualizaTotaisCupomTeleEntrega();

        const formasPagamento = [];

        this.props.origemVendaList.forEach(origemVenda => {
            (origemVenda.vendaList || []).forEach(venda => {
                venda.entregaVenda?.formaPagamento && formasPagamento.push(venda.entregaVenda.formaPagamento);
            })
        });

        if (formasPagamento.length && formasPagamento.every(formaPag => formaPag === formasPagamento[0])) {
            this.props.dispatch(pagamentoVendasActions.setFormaPagamento(formasPagamento[0]));
        }
        else {
            this.props.dispatch(pagamentoVendasActions.setFormaPagamento(null));
        }
    }

    componentWillUnmount() {
        this.props.dispatch(pagamentoVendasActions.updatePayedValueAction(0));
    }

    /**
     * Método executado APÓS a atualização do componente.
     * Carrega os tipos disponíveis e também define os valores iniciais das opções conforme cadastro carregado.
     */
    componentDidUpdate() {
        log('TelaPagamentoVendasContent componentDidUpdate');
        this.setFormaPagamentoVinculado();

        // Define as opções disponíveis no campo "Tipo" e seleciona o valor atual do cadastro.
        this.discountType.inputComponent.updateOptions(cadastroBuildOptionsFromEnum(true,
            [DISCOUNT_TYPE_PERCENTAGE, DISCOUNT_TYPE_VALUE], string => getStrings().discountTypeEnumToString(string)));

        this.discountType.inputComponent.updateValue(cadastroBuildOptionsFromEnum(false,
            this.props.discountType, string => getStrings().discountTypeEnumToString(string)));

        // Atualiza o campo de desconto se o valor foi alterado no reducer e é diferente do valor atual.
        // Primeiro testa se um é truthy e o outro é falsy. Se sim, precisa atualizar. Se não, ou os dois são truthy, ou os dois são falsy.
        // Se os dois são falsy, não atualiza, pois os dois podem ser diferentes mas ambos serem falsy, o que significa que um equivale ao outro.
        // Se os dois forem truthy, significa que ambos são valores válidos, então testa a diferença entre os valores.
        if (((!!this.discountField.getMaskValue()) !== (!!this.props.discountValue)) || (this.discountField.getMaskValue() && this.props.discountValue && (this.discountField.getMaskValue() !== this.props.discountValue))) {
            this.discountField.setMaskValue(this.props.discountValue);
        }
        this.checkBoxHeader.inputComponent.checked = this.props.hasCheckedProduct;

        // Verifica se deve exibir o dialog para encerrar vendas ao pagar todos os produtos
        this.props.dispatch(pagamentoVendasActions.showProductsPaidCloseSalesDialog());

        // Mostra troco
        if (this.moneyChange) {
            if (this.props.payedValue > this.props.newTotal) {
                this.moneyChange.setMaskValue(roundNumber(this.props.payedValue - this.props.newTotal, 2));
            }
            else {
                this.moneyChange.setMaskValue(0);
            }
        }

        this.atualizaTotaisCupomTeleEntrega();
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('TelaPagamentoVendasContent render');

        return <WidthAwareDiv style={this.props.style} pureStyle={this.props.pureStyle} >

            {/* Comissão e desconto */}
            <div className='table-inline' style={{marginTop: '15px'}} >
                <div className='TPVContent row'>
                    <div className='TPVContent column'>
                        <label className='paymentMethodLabel'>{getStrings().paymentMethod}</label>
                        <label className='paymentMethodInput'>
                            <InputCustomizado
                                id='formaPagamento'
                                inputType='singleSelect'
                                name='formaPagamento'
                                ref={input => this.paymentMethod = getReduxWrappedComponent(input)}
                                placeholder={getStrings().paymentMethodPlaceholder}
                                onChange={selected => this.props.dispatch(pagamentoVendasActions.setFormaPagamento((selected || {}).value))}
                                required={false}
                                sort
                            />
                        </label>
                    </div>
                    {(this.props.paymentMethod || { nome: '' }).nome.toLowerCase() === 'dinheiro'
                        ? <>
                            <div className='TPVContent column'>
                                <label className='payedValue'>{getStrings().payedValue}</label>
                                <label className='payedValue'>
                                    <label className='payedValue'>
                                        <InputCustomizado
                                            id='valorPago'
                                            inputType='masked'
                                            type='text'
                                            validacaoDados='numeroDecimal'
                                            name='valorPago'
                                            placeholder={getStrings().payedValue}
                                            ref={input => this.payedValue = getReduxWrappedComponent(input)}
                                            // Atualiza o desconto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                                            onInput={() => {
                                                if (this.payedValue.getMaskValue() || (this.payedValue.getMaskValue() === 0)) {
                                                    this.props.dispatch(pagamentoVendasActions.updatePayedValueAction(roundNumber(this.payedValue.getMaskValue(), 2)));
                                                }
                                            }}
                                            // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera o desconto para zero.
                                            // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                                            onBlur={() => {
                                                if ((!this.payedValue.getMaskValue()) && (this.payedValue.getMaskValue() !== 0)) {
                                                    this.props.dispatch(pagamentoVendasActions.updatePayedValueAction(0));
                                                }
                                            }}
                                        />
                                    </label>
                                </label>
                            </div>

                            <div className='TPVContent column'>
                                <label className='moneyChange'>{getStrings().moneyChange}</label>
                                <label className='moneyChange'>
                                    <label className='moneyChange'>
                                        <InputCustomizado
                                            id='troco'
                                            inputType='masked'
                                            type='text'
                                            validacaoDados='numeroDecimal'
                                            name='troco'
                                            ref={input => this.moneyChange = getReduxWrappedComponent(input)}
                                            // Atualiza o desconto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                                            placeholder={getStrings().moneyChange}
                                            disabled
                                        />
                                    </label>
                                </label>
                            </div>
                        </>
                        : null
                    }
                </div>

                <button
                    className='TPVContent gray-button accordion'
                    name='accordion'
                    children={`${getStrings().commission}${this.props.percentualComissaoAtual ? `: ${formatNumber(this.props.percentualComissaoAtual, 2)}%` : ''} / ${getStrings().discount}${this.props.discountValue ? `: ${this.props.discountType === DISCOUNT_TYPE_VALUE ? '$' : ''}${formatNumber(this.props.discountValue, 2)}${this.props.discountType === DISCOUNT_TYPE_PERCENTAGE ? '%' : ''}` : ''}`}
                    onClick={AccordionClick}
                />
                <this.inputDesconto />
            </div>

            {/* Tabela de totais */}
            {this.buildTable(
                'total-pagar-pagos',
                getStrings().saleItemsGeneral(),
                null,
                null,
                getStrings().grandTotal,
                this.props.productTotal,
                this.props.commissionTotal,
                this.props.discountTotal,
                this.props.total
            )}
            {/* Tabela de produtos a pagar */}
            {this.buildTable(
                'itens-venda-pagar',
                getStrings().saleItems(),
                this.buildHeader(this.buildCheckBoxHeader()),
                (this.props.origemVendaList || []).map(origemVenda => <OrigemVendaTable
                    key={getURIFromEntity(origemVenda)}
                    new={true}
                    origemVenda={origemVenda}
                />),
                getStrings().totalToPayTemplate(''),
                this.props.newProductTotal,
                this.props.newCommissionTotal,
                this.props.newDiscountTotal,
                this.props.newTotal
            )}
            {/* Tabela de produtos pagos */}
            {this.buildTable(
                'itens-venda-pagos',
                getStrings().soldItems(),
                this.buildHeader(null),
                (this.props.origemVendaList || []).map(origemVenda => <OrigemVendaTable
                    key={getURIFromEntity(origemVenda)}
                    new={false}
                    origemVenda={origemVenda}
                />),
                getStrings().totalPaidTemplate(''),
                this.props.sentProductTotal,
                this.props.sentCommissionTotal,
                this.props.sentDiscountTotal,
                this.props.sentTotal
            )}
        </WidthAwareDiv >;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    updateLocale: state.idiomaReducer,

    hasCheckedProduct: state.controleVendaReducer.hasCheckedProduct,

    discountType: state.pagamentoVendasReducer.discountType,
    discountValue: state.pagamentoVendasReducer.discountValue,
    paymentMethod: state.pagamentoVendasReducer.paymentMethod,
    payedValue: state.pagamentoVendasReducer.payedValue,
    percentualComissaoAtual: state.pagamentoVendasReducer.percentualComissaoAtual,
    percentualComissaoEmpresa: state.pagamentoVendasReducer.percentualComissaoEmpresa,

    origemVendaList: state.controleVendaReducer.origemVendaList,
    newCommissionTotal: state.controleVendaReducer.newCommissionTotal,
    newDiscountTotal: state.controleVendaReducer.newDiscountTotal,
    newProductTotal: state.controleVendaReducer.newProductTotal,
    newSalePaymentItemMap: state.controleVendaReducer.newSalePaymentItemMap,
    newTotal: state.controleVendaReducer.newTotal,

    sentCommissionTotal: state.controleVendaReducer.sentCommissionTotal,
    sentDiscountTotal: state.controleVendaReducer.sentDiscountTotal,
    sentProductTotal: state.controleVendaReducer.sentProductTotal,
    sentTotal: state.controleVendaReducer.sentTotal,

    commissionTotal: state.controleVendaReducer.commissionTotal,
    discountTotal: state.controleVendaReducer.discountTotal,
    productTotal: state.controleVendaReducer.productTotal,
    total: state.controleVendaReducer.total,

    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,

    tabIndex: state.appReducer.getTabIndex()
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    dispatch,
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TelaPagamentoVendasContent);


export const TelaPagamentoVendasContentWithoutReducers = TelaPagamentoVendasContent;
