import "./BuildCellFromProducedItem.css"

import React from "react"
import { getStrings } from "../../utils/LocaleUtils"
import { getNomeProdutoFromItemVenda } from "../../utils/MiscUtils"

import { connect } from "react-redux"
import * as producaoActions from "../../store/actions/producaoAction"
import { ClienteHttp } from "../../apps/promo_app_frontend/nucleo/utils/utils"

let moment = require("moment")

class BuildCellFromProducedItem extends React.Component {

    render() {

        let idVenda = this.props.itemVenda.venda._links.self.href.replace(ClienteHttp.getApi('vendas/'), '');
        idVenda = typeof idVenda === 'string' ? idVenda.toString().padStart(9, '0') : idVenda;

        return <div className={'card from-produced-item'} onClick={() => this.props.setReadItemVendaList(this.props.itemVenda)} >

            <div className='linha'>
                <h2 className='coluna CenterAlign' style={{ marginBottom: '0.3em'}}>
                    {`${moment(this.props.itemVenda.dataHora).format('HH:mm')} - 
                    ${moment(this.props.itemVenda.dataHora).format(getStrings().dateFormat)} - 
                    ${this.props.itemVenda.venda.cliente
                            ? getStrings().clientTemplate(this.props.itemVenda.venda.cliente.codigo)
                            : getStrings().saleSourceTypeEnumToString(this.props.itemVenda.venda.origemVenda.nome)} - 
                    ${idVenda}`}
                </h2>
                {/* <h2 className='coluna CenterAlign'>
                    {moment(this.props.itemVenda.dataHora).format('HH:mm')}
                </h2>
                <h2 className='coluna LeftAlign'>
                    {moment(this.props.itemVenda.dataHora).format(getStrings().dateFormat)}
                </h2>
                <h2 className='coluna RightAlign'>
                    {this.props.itemVenda.venda.cliente
                        ? getStrings().clientTemplate(this.props.itemVenda.venda.cliente.codigo)
                        : getStrings().saleSourceTypeEnumToString(this.props.itemVenda.venda.origemVenda.nome)}
                </h2>
                <h2 className='coluna CenterAlign'>
                    {this.props.itemVenda.venda._links.self.href.replace(ClienteHttp.getApi('vendas/'), '')}
                </h2> */}
            </div>
            <div className='linha items'>
                <h2 className='coluna LeftAlign' style={{maxWidth: '10em', marginLeft: '0.5em'}}>
                    {`${getStrings().amount}: ${this.props.itemVenda.quantidade}`}
                </h2>
                <h2 className='coluna LeftAlign'>
                    {getNomeProdutoFromItemVenda(this.props.itemVenda)}
                </h2>
            </div>
            <div className='linha'>
                <h2 className='coluna CenterAlign nome-venda'>
                    {getStrings().saleTemplate(this.props.itemVenda.venda.nome)}
                </h2>
            </div>
        </div>;
    }
}

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    setReadItemVendaList: itemVenda => dispatch(producaoActions.setReadItemVendaList(itemVenda))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(null, mapDispatchToProps, null)(BuildCellFromProducedItem);
