import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"

import "./ItensVendaTable.css";

/**
 * Exibe uma tabela ou com os itens de venda para enviar ou com os itens de venda enviados.
 */
const ItensVendaTable = (props) => {
    log("ItensVendaTable render")

    if (!isAncientChromeMobile) {
        return <div className="table itens-venda-table">
            <div className="table-header Lighter" >{props.title}</div>
            {/* Header */}
            <div className="table-items table-columns-header">
                <div> </div>
                <div className="TextAlign product"> {getStrings().product} </div>
                <div className="NumberAlign price"> {getStrings().price} </div>
                <div className="NumberAlign amount"> {getStrings().amountShort} </div>
                <div className="NumberAlign total"> {getStrings().total} </div>
                <div className="ButtonAlign time"> {getStrings().timeHourMinute} </div>
                <div className="ButtonAlign obs"> {getStrings().observationShort} </div>
            </div>
            {props.itemList}
        </div>
    }
    
    return <div className="table itens-venda-table ancient-chrome-mobile">
        <div className="table-header Lighter" >{props.title}</div>
        {/* Header */}
        <div className="table-items table-columns-header hide-when-small">
            <div className="column left">
                <div className="row">
                    <div className="blank-space" />
                    <div className="TextAlign product"> {getStrings().product} </div>
                </div>
            </div>
            <div className="column right">
                <div className="row">
                    <div className="NumberAlign price"> {getStrings().price} </div>
                    <div className="NumberAlign amount"> {getStrings().amountShort} </div>
                    <div className="NumberAlign total"> {getStrings().total} </div>
                    <div className="ButtonAlign time"> {getStrings().timeHourMinute} </div>
                    <div className="ButtonAlign obs header"> {getStrings().observationShort} </div>
                </div>
            </div>
        </div>
        <div className="table-items table-columns-header display-when-small column">
            <div className="row">
                <div className="blank-space" />
                <div className="TextAlign product"> {getStrings().product} </div>
                <div className="ButtonAlign time"> {getStrings().timeHourMinute} </div>
                <div className="ButtonAlign obs header"> {getStrings().observationShort} </div>
            </div>
            <div className="row">
                <div className="blank-space" />
                <div className="NumberAlign price"> {getStrings().price} </div>
                <div className="NumberAlign amount"> {getStrings().amountShort} </div>
                <div className="NumberAlign total"> {getStrings().total} </div>
            </div>
        </div>
        {props.itemList}
    </div>
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.idiomaReducer
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps)(ItensVendaTable)
