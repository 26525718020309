import React from 'react';

import "./WidthAwareDiv.css";

/**
 * Classe para ajustar a largura do conteúdo dependendo da largura da tela do dispositivo.
 */
export default class WidthAwareDiv extends React.Component {

    render() {
        return <div className={'pure-g ' + (this.props.className || '')} style={this.props.style}>
            <div className='pure-u-1-1 pure-u-lg-3-4 pure-u-xl-5-8 pure-u-xxl-1-2 centerContent' style={this.props.pureStyle}>
                {this.props.children}
            </div>
        </div>;
    }
}
