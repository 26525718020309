import React from "react"
import { connect } from "react-redux"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/getReduxWrappedComponent"
import { reduxStateType } from "../../../utils/reduxUtils/reduxStateType"
import { getFrontUrl } from "../../../utils/SecureConnectionUtils"
import { getPageNames } from "../../../utils/siteUtils/siteUtils"

import { colorHandler, colorHandlerType, multiColorHandler, onHandleColorType } from "../../../store/actions/colorActions"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import AtivoInputDefault from "../cadastroInputDefault/AtivoInputDefault"
import CadastroForm, { CadastroFormModularType } from "./CadastroForm"
import CadastroValues, { CadastroValuesParentReduxStateType, CadastroValuesParentType } from "./CadastroValues"
import Icones from "../../../apps/promo_app_frontend/assets/icones/Icones"
import { PreviewMobiApp } from "../../UI/Preview/PreviewMobiApp"
import { mobiAppCoresEstadoInicial, mobiAppReducerTipo } from "../../../apps/promo_app_frontend/nucleo/redux/mobiApp/mobiAppReducer"
import { equalsCoerced } from "../../../utils/ComparatorsUtils"

export type MobiAppFormType = CadastroFormModularType;

type MobiAppValuesType = CadastroValuesParentType;
type MobiAppValuesReduxStateType = CadastroValuesParentReduxStateType;
type MobiAppValuesReduxDispatchType = {
  colorHandler: colorHandlerType,
  multiColorHandler: colorHandlerType,
};
type MobiAppValuesTypeWithReducer = MobiAppValuesType & MobiAppValuesReduxStateType & MobiAppValuesReduxDispatchType;

type MobiAppColorButtonType = {
  handleChange: () => void,
  colorHandler: colorHandlerType,
  label: string,
  onHandleColor: onHandleColorType,
  required: boolean,

  color?: string,
}

function MobiAppForm_(props: MobiAppFormType & MobiAppValuesTypeWithReducer) {
  log("BairroForm render");
  return <CadastroForm {...props} formModel={MobiAppValues}
    registerSaveHelp={getStrings().neighborhoodHelp} registerUpdateHelp={getStrings().neighborhoodHelp} />;
}

export const MobiAppForm = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MobiAppForm_);
export default MobiAppForm;

class MobiAppValues_ extends React.Component<MobiAppValuesTypeWithReducer> {
  enabled: any = null;
  nomeExibicao: any = null;
  valorLinkParametro: any = null;
  habilitarMensagemLojaFechada: any = null;
  obrigaDataEntrega: any = null;
  vendaMobiAppEncerrada: any = null;
  mostrarMensagemContatoEmpresa: any = null;
  corDeFundo: string | undefined;
  corDeFundoTexto: string | undefined;
  corPrincipal: string | undefined;
  corPrincipalTexto: string | undefined;
  corBarraTopo: string | undefined;
  corBarraTopoTexto: string | undefined;
  corDoDialog: string | undefined;
  corDoDialogTexto: string | undefined;
  corDoCard: string | undefined;
  corDoCardTexto: string | undefined;
  corDaEntrada: string | undefined;
  corDaEntradaTexto: string | undefined;
  corDaBarraNavegacao: string | undefined;
  corDaBarraNavegacaoTexto: string | undefined;
  corBotaoDeAcao: string | undefined;
  corBotaoDeAcaoTexto: string | undefined;
  corBotaoVoltar: string | undefined;
  corBotaoVoltarTexto: string | undefined;
  corLinksDoCard: string | undefined;
  logoEmpresa: string = "";

  MobiAppColorButton = (props: MobiAppColorButtonType) => {
    const marginNumber = 4;
    const margin = marginNumber + "px";

    return <div className="pure-u-1 pure-u-sm-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-3 pure-u-xxl-1-3" >
      <div className="flex flex-column flex-nowrap justify-content-end align-items-center" style={{ width: `calc(100% - ${marginNumber * 2}px)`, padding: `${margin}`, height: "5em" }} >
        <label>{props.label + (props.required ? "*" : "")}</label>
        <button
          onClick={(ev) => {
            props.colorHandler({
              onHandleColor: props.onHandleColor,
              color: props.color,
              handleChange: props.handleChange,
            }, ev);
          }}
          style={{

            border: "1px solid var(--color-mid)",
            borderRadius: "var(--default-border-radius-input)",
            height: "2em",
            minWidth: "1px",
            background: props.color
          }}
        ></button>
      </div>
    </div>;
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados presentes ao abrir a criação/edição do cadastro.
   */
  wasChangedFromInitial = (newData: Record<string, any>) => {
      log("MobiAppValues wasChangedFromInitial", { newData });
      const wasChanged = this.wasChanged(newData, this.props.cadastroDadosIniciais);
      return wasChanged;
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro
   */
  wasChanged = (newData: Record<string, any>, oldData: Record<string, any>) => {
    log("MobiAppValues wasChanged", { newData, oldData });

    const result = !equalsCoerced(oldData, newData, (oldValue: any, newValue: any) => {
      return (newValue.nomeExibicao === oldValue.nomeExibicao)
      && (newValue.valorLinkParametro === oldValue.valorLinkParametro)
      && (newValue.habilitarMensagemLojaFechada === oldValue.habilitarMensagemLojaFechada)
      && (newValue.obrigaDataEntrega === oldValue.obrigaDataEntrega)
      && (newValue.vendaMobiAppEncerrada === oldValue.vendaMobiAppEncerrada)
      && (newValue.mostrarMensagemContatoEmpresa === oldValue.mostrarMensagemContatoEmpresa)
      && (newValue.corDeFundo === oldValue.corDeFundo)
      && (newValue.corDeFundoTexto === oldValue.corDeFundoTexto)
      && (newValue.corPrincipal === oldValue.corPrincipal)
      && (newValue.corPrincipalTexto === oldValue.corPrincipalTexto)
      && (newValue.corBarraTopo === oldValue.corBarraTopo)
      && (newValue.corBarraTopoTexto === oldValue.corBarraTopoTexto)
      && (newValue.corDoDialog === oldValue.corDoDialog)
      && (newValue.corDoDialogTexto === oldValue.corDoDialogTexto)
      && (newValue.corDoCard === oldValue.corDoCard)
      && (newValue.corDoCardTexto === oldValue.corDoCardTexto)
      && (newValue.corDaEntrada === oldValue.corDaEntrada)
      && (newValue.corDaEntradaTexto === oldValue.corDaEntradaTexto)
      && (newValue.corDaBarraNavegacao === oldValue.corDaBarraNavegacao)
      && (newValue.corDaBarraNavegacaoTexto === oldValue.corDaBarraNavegacaoTexto)
      && (newValue.corBotaoDeAcao === oldValue.corBotaoDeAcao)
      && (newValue.corBotaoDeAcaoTexto === oldValue.corBotaoDeAcaoTexto)
      && (newValue.corBotaoVoltar === oldValue.corBotaoVoltar)
      && (newValue.corBotaoVoltarTexto === oldValue.corBotaoVoltarTexto)
      && (newValue.corLinksDoCard === oldValue.corLinksDoCard)
    });

    return result;
  }

  /**
   * Retorna se houve alteração em algum dado do cadastro, comparando com os dados sendo editados.
   */
  wasChangedFromCurrent = (newData: Record<string, any>) => {
      log("MobiAppValues wasChangedFromCurrent", newData);
      const wasChanged = this.wasChanged(newData, this.props.cadastroDados);

      return wasChanged;
  }

  checkRequired = () => {
    log("MobiAppForm checkRequired");

    return (typeof this.enabled.inputField.inputComponent.checked) === "boolean"
      && this.nomeExibicao.inputComponent.value
      && this.valorLinkParametro.inputComponent.value
      && (typeof this.habilitarMensagemLojaFechada.inputComponent.checked) === "boolean"
      && (typeof this.mostrarMensagemContatoEmpresa.inputComponent.checked) === "boolean"
      && (typeof this.obrigaDataEntrega.inputComponent.checked) === "boolean"
      && (typeof this.vendaMobiAppEncerrada.inputComponent.checked) === "boolean"
      && this.corDeFundo
      && this.corDeFundoTexto
      && this.corPrincipal
      && this.corPrincipalTexto
      && this.corBarraTopo
      && this.corBarraTopoTexto
      && this.corDoDialog
      && this.corDoDialogTexto
      && this.corDoCard
      && this.corDoCardTexto
      && this.corDaEntrada
      && this.corDaEntradaTexto
      && this.corDaBarraNavegacao
      && this.corDaBarraNavegacaoTexto
      && this.corBotaoDeAcao
      && this.corBotaoDeAcaoTexto
      && this.corBotaoVoltar
      && this.corBotaoVoltarTexto
      && this.corLinksDoCard
      ;
  }

  getFormData = () => {
    log("MobiAppForm getFormData");

    const formData = {
      enabled: this.enabled.inputField.inputComponent.checked,
      nomeExibicao: this.nomeExibicao.inputComponent.value,
      valorLinkParametro: this.valorLinkParametro.inputComponent.value,
      habilitarMensagemLojaFechada: this.habilitarMensagemLojaFechada.inputComponent.checked,
      mostrarMensagemContatoEmpresa: this.mostrarMensagemContatoEmpresa.inputComponent.checked,
      obrigaDataEntrega: this.obrigaDataEntrega.inputComponent.checked,
      vendaMobiAppEncerrada: this.vendaMobiAppEncerrada.inputComponent.checked,
      corDeFundo: this.corDeFundo,
      corDeFundoTexto: this.corDeFundoTexto,
      corPrincipal: this.corPrincipal,
      corPrincipalTexto: this.corPrincipalTexto,
      corBarraTopo: this.corBarraTopo,
      corBarraTopoTexto: this.corBarraTopoTexto,
      corDoDialog: this.corDoDialog,
      corDoDialogTexto: this.corDoDialogTexto,
      corDoCard: this.corDoCard,
      corDoCardTexto: this.corDoCardTexto,
      corDaEntrada: this.corDaEntrada,
      corDaEntradaTexto: this.corDaEntradaTexto,
      corDaBarraNavegacao: this.corDaBarraNavegacao,
      corDaBarraNavegacaoTexto: this.corDaBarraNavegacaoTexto,
      corBotaoDeAcao: this.corBotaoDeAcao,
      corBotaoDeAcaoTexto: this.corBotaoDeAcaoTexto,
      corBotaoVoltar: this.corBotaoVoltar,
      corBotaoVoltarTexto: this.corBotaoVoltarTexto,
      corLinksDoCard: this.corLinksDoCard,
      logoEmpresa : this.logoEmpresa,
      _links: this.props.cadastroDados._links,
    };

    return formData;
  }

  handleGravar = () => {
    const formData = this.getFormData();
    log("MobiAppForm handleGravar", { formData });

    this.props.onSave(formData);
  }

  componentDidUpdate(prevProps: MobiAppValuesTypeWithReducer) {
    log("MobiAppForm componentDidUpdate");

    // @ts-ignore
    if (((typeof this.props.loading) !== "boolean" && !this.props.loading.form)
      || (prevProps.cadastroDados.corDeFundo === undefined && this.props.cadastroDados.corDeFundo !== undefined)) {
      this.enabled.inputField.inputComponent.checked = this.props.cadastroDados.enabled !== false;

      this.nomeExibicao.inputComponent.value = this.props.cadastroDados.nomeExibicao ? this.props.cadastroDados.nomeExibicao : null;
      this.valorLinkParametro.inputComponent.value = this.props.cadastroDados.valorLinkParametro ? this.props.cadastroDados.valorLinkParametro : null;

      this.habilitarMensagemLojaFechada.inputComponent.checked = this.props.cadastroDados.habilitarMensagemLojaFechada !== false;
      this.mostrarMensagemContatoEmpresa.inputComponent.checked = this.props.cadastroDados.mostrarMensagemContatoEmpresa === true;

      this.obrigaDataEntrega.inputComponent.checked = this.props.cadastroDados.obrigaDataEntrega === true;
      this.vendaMobiAppEncerrada.inputComponent.checked = this.props.cadastroDados.vendaMobiAppEncerrada !== false;

      this.corDeFundo = this.props.cadastroDados.corDeFundo || mobiAppCoresEstadoInicial.corDeFundo;
      this.corDeFundoTexto = this.props.cadastroDados.corDeFundoTexto || mobiAppCoresEstadoInicial.corDeFundoTexto;

      this.corPrincipal = this.props.cadastroDados.corPrincipal || mobiAppCoresEstadoInicial.corPrincipal;
      this.corPrincipalTexto = this.props.cadastroDados.corPrincipalTexto || mobiAppCoresEstadoInicial.corPrincipalTexto;

      this.corBarraTopo = this.props.cadastroDados.corBarraTopo || mobiAppCoresEstadoInicial.corBarraTopo;
      this.corBarraTopoTexto = this.props.cadastroDados.corBarraTopoTexto || mobiAppCoresEstadoInicial.corBarraTopoTexto;

      this.corDoDialog = this.props.cadastroDados.corDoDialog || mobiAppCoresEstadoInicial.corDoDialog;
      this.corDoDialogTexto = this.props.cadastroDados.corDoDialogTexto || mobiAppCoresEstadoInicial.corDoDialogTexto;

      this.corDoCard = this.props.cadastroDados.corDoCard || mobiAppCoresEstadoInicial.corDoCard;
      this.corDoCardTexto = this.props.cadastroDados.corDoCardTexto || mobiAppCoresEstadoInicial.corDoCardTexto;

      this.corDaEntrada = this.props.cadastroDados.corDaEntrada || mobiAppCoresEstadoInicial.corDaEntrada;
      this.corDaEntradaTexto = this.props.cadastroDados.corDaEntradaTexto || mobiAppCoresEstadoInicial.corDaEntradaTexto;

      this.corDaBarraNavegacao = this.props.cadastroDados.corDaBarraNavegacao || mobiAppCoresEstadoInicial.corDaBarraNavegacao;
      this.corDaBarraNavegacaoTexto = this.props.cadastroDados.corDaBarraNavegacaoTexto || mobiAppCoresEstadoInicial.corDaBarraNavegacaoTexto;

      this.corBotaoDeAcao = this.props.cadastroDados.corBotaoDeAcao || mobiAppCoresEstadoInicial.corBotaoDeAcao;
      this.corBotaoDeAcaoTexto = this.props.cadastroDados.corBotaoDeAcaoTexto || mobiAppCoresEstadoInicial.corBotaoDeAcaoTexto;

      this.corBotaoVoltar = this.props.cadastroDados.corBotaoVoltar || mobiAppCoresEstadoInicial.corBotaoVoltar;
      this.corBotaoVoltarTexto = this.props.cadastroDados.corBotaoVoltarTexto || mobiAppCoresEstadoInicial.corBotaoVoltarTexto;

      this.corLinksDoCard = this.props.cadastroDados.corLinksDoCard || mobiAppCoresEstadoInicial.corLinksDoCard;
    }
  }

  render() {
    const paddingHorizontal = "0.2em";
    const MobiAppColorButton = this.MobiAppColorButton;
    const linkParaMobiApp = this.props.cadastroDados
      ? getFrontUrl(getPageNames().especial.MOBI_APP.slice(1)) + "?empresa="
      + (this.valorLinkParametro ? this.valorLinkParametro.inputComponent.value : this.props.cadastroDados.valorLinkParametro)
      : "";

    const MobiAppIcone = Icones.mobi.LogoEmpresa;
    const nomeEmpresa: string = this.props.cadastroDados.valorLinkParametro;
    const mobiApp: mobiAppReducerTipo = {
      corBarraTopo: this.corBarraTopo || "",
      corBarraTopoTexto: this.corBarraTopoTexto || "",

      corBotaoDeAcao: this.corBotaoDeAcao || "",
      corBotaoDeAcaoTexto: this.corBotaoDeAcaoTexto || "",

      corBotaoVoltar: this.corBotaoVoltar || "",
      corBotaoVoltarTexto: this.corBotaoVoltarTexto || "",

      corDaBarraNavegacao: this.corDaBarraNavegacao || "",
      corDaBarraNavegacaoTexto: this.corDaBarraNavegacaoTexto || "",

      corDaEntrada: this.corDaEntrada || "",
      corDaEntradaTexto: this.corDaEntradaTexto || "",

      corDeFundo: this.corDeFundo || "",
      corDeFundoTexto: this.corDeFundoTexto || "",

      corDoCard: this.corDoCard || "",
      corDoCardTexto: this.corDoCardTexto || "",

      corDoDialog: this.corDoDialog || "",
      corDoDialogTexto: this.corDoDialogTexto || "",

      corLinksDoCard: this.corLinksDoCard || "",

      corPrincipal: this.corPrincipal || "",
      corPrincipalTexto: this.corPrincipalTexto || "",


      habilitarMensagemLojaFechada: this.habilitarMensagemLojaFechada?.inputComponent?.checked === true,
      mostrarMensagemContatoEmpresa: this.mostrarMensagemContatoEmpresa?.inputComponent?.checked === true,
      obrigaDataEntrega: this.obrigaDataEntrega?.inputComponent?.checked === true,
      vendaMobiAppEncerrada: this.vendaMobiAppEncerrada?.inputComponent?.checked === true,

      logoEmpresa: this.logoEmpresa,


      nomeExibicao: this.nomeExibicao?.inputComponent?.value || "",
      valorLinkParametro: this.valorLinkParametro?.inputComponent?.value || "",

      versaoLogoEmpresa: "0",
    }

    const maxLogoIconSize = "10em";

    return <CadastroValues>

      <AtivoInputDefault
        ref={input => this.enabled = getReduxWrappedComponent(input)}
        tabIndex={this.props.tabIndex}
        handleInputValidado={this.props.handleChange}
      />

      <label>{linkParaMobiApp}</label>

      <div className="flex flex-row flex-nowrap justify-content-center" style={{ width: "100%" }} >
        <div className="upload-wrapper" style={{ maxWidth: "90%" }}>
          <label htmlFor="file-selection" className="cursor-pointer flex flex-column flex-nowrap justify-content-start align-items-center">
            <MobiAppIcone src={this.logoEmpresa} style={{ maxWidth: maxLogoIconSize, maxHeight: maxLogoIconSize }} nomeEmpresa={nomeEmpresa} />
            {getStrings().loadAnotherImage}
          </label>
        </div>
        <div id="image-container" style={{ display: "none" }}>
          <input id="file-selection" type="file" onChange={(event: any) => {
            // verifica se há algum arquivo
            if (event.target.files.length) {
              // Necessário para gerar base64
              const reader = new FileReader();

              reader.onload = (event: any) => {
                // resultado da leitura
                this.logoEmpresa = event.target.result;
                this.forceUpdate();

                // this.props.dispatch(atualizarMobiApp({ logoEmpresa }));
              };

              // lê arquivo
              reader.readAsDataURL(event.target.files[0]);
            }
          }} />
        </div>
      </div>

      <div className="pure-g" >

        <div className="pure-u-1 pure-u-sm-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-2 pure-u-xxl-1-2" >
          <InputCustomizado
            topClassStyle={{ paddingRight: paddingHorizontal }}
            ref={(input: any) => {
              if (input) {
                this.nomeExibicao = getReduxWrappedComponent(input);
              }
            }}
            tabIndex={this.props.tabIndex}
            required={true}
            type="text"
            id="nomeExibicao"
            name="nomeExibicao"
            label={getStrings().mobiAppLabels.exihibitionName + "*"}
            placeholder={getStrings().mobiAppLabels.exihibitionName}
            handleInputValidado={this.props.handleChange}
          />
        </div>

        <div className="pure-u-1 pure-u-sm-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-2 pure-u-xxl-1-2" >
          <InputCustomizado
            topClassStyle={{ paddingLeft: paddingHorizontal }}
            ref={(input: any) => {
              if (input) {
                this.valorLinkParametro = getReduxWrappedComponent(input);
              }
            }}
            tabIndex={this.props.tabIndex}
            required={true}
            type="text"
            id="valorLinkParametro"
            name="valorLinkParametro"
            label={getStrings().mobiAppLabels.link + "*"}
            placeholder={getStrings().mobiAppLabels.link}
            handleInputValidado={this.props.handleChange}
          />
        </div>

      </div>

      <InputCustomizado
        ref={(input: any) => {
          if (input) {
            this.habilitarMensagemLojaFechada = getReduxWrappedComponent(input);
          }
        }}
        tabIndex={this.props.tabIndex}
        type="checkbox"
        id="habilitarMensagemLojaFechada"
        name="habilitarMensagemLojaFechada"
        label={getStrings().enableClosedStoreMessage}
        handleInputValidado={this.props.handleChange}
      />

      <InputCustomizado
        ref={(input: any) => {
          if (input) {
            this.mostrarMensagemContatoEmpresa = getReduxWrappedComponent(input);
          }
        }}
        tabIndex={this.props.tabIndex}
        type="checkbox"
        id="mostrarMensagemContatoEmpresa"
        name="mostrarMensagemContatoEmpresa"
        label={getStrings().showCompanyContactMessage}
        handleInputValidado={this.props.handleChange}
      />

      <InputCustomizado
        ref={(input: any) => {
          if (input) {
            this.obrigaDataEntrega = getReduxWrappedComponent(input);
          }
        }}
        tabIndex={this.props.tabIndex}
        type="checkbox"
        id="obrigaDataEntrega"
        name="obrigaDataEntrega"
        label={getStrings().requiresDeliveryDate}
        handleInputValidado={this.props.handleChange}
      />

      <InputCustomizado
        ref={(input: any) => {
          if (input) {
            this.vendaMobiAppEncerrada = getReduxWrappedComponent(input);
          }
        }}
        tabIndex={this.props.tabIndex}
        type="checkbox"
        id="vendaMobiAppEncerrada"
        name="vendaMobiAppEncerrada"
        label={getStrings().requestsFinishByDefault}
        handleInputValidado={this.props.handleChange}
      />

      <div className="flex flex-column flex-nowrap justify-content-center align-items-center" style={{ width: "100%" }} >
        <div className="pure-g" >
          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.colors.background}
            color={this.corDeFundo}
            onHandleColor={(cor) => { this.corDeFundo = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.textColors.background}
            color={this.corDeFundoTexto}
            onHandleColor={(cor) => { this.corDeFundoTexto = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.colors.main}
            color={this.corPrincipal}
            onHandleColor={(cor) => { this.corPrincipal = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.textColors.main}
            color={this.corPrincipalTexto}
            onHandleColor={(cor) => { this.corPrincipalTexto = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.colors.topBar}
            color={this.corBarraTopo}
            onHandleColor={(cor) => {
              this.corBarraTopo = cor;
            }}
            colorHandler={this.props.multiColorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.textColors.topBar}
            color={this.corBarraTopoTexto}
            onHandleColor={(cor) => { this.corBarraTopoTexto = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.colors.dialog}
            color={this.corDoDialog}
            onHandleColor={(cor) => { this.corDoDialog = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.textColors.dialog}
            color={this.corDoDialogTexto}
            onHandleColor={(cor) => { this.corDoDialogTexto = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.colors.input}
            color={this.corDaEntrada}
            onHandleColor={(cor) => { this.corDaEntrada = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.textColors.input}
            color={this.corDaEntradaTexto}
            onHandleColor={(cor) => { this.corDaEntradaTexto = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.colors.navigationBar}
            color={this.corDaBarraNavegacao}
            onHandleColor={(cor) => { this.corDaBarraNavegacao = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.textColors.navigationBar}
            color={this.corDaBarraNavegacaoTexto}
            onHandleColor={(cor) => { this.corDaBarraNavegacaoTexto = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.colors.actionButton}
            color={this.corBotaoDeAcao}
            onHandleColor={(cor) => { this.corBotaoDeAcao = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.textColors.actionButton}
            color={this.corBotaoDeAcaoTexto}
            onHandleColor={(cor) => { this.corBotaoDeAcaoTexto = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.colors.backButton}
            color={this.corBotaoVoltar}
            onHandleColor={(cor) => { this.corBotaoVoltar = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.textColors.backButton}
            color={this.corBotaoVoltarTexto}
            onHandleColor={(cor) => { this.corBotaoVoltarTexto = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

          <MobiAppColorButton
            required={true}
            label={getStrings().mobiAppLabels.textColors.cardLink}
            color={this.corLinksDoCard}
            onHandleColor={(cor) => { this.corLinksDoCard = cor; }}
            colorHandler={this.props.colorHandler}
            handleChange={this.props.handleChange}
          />

        </div>
      </div>

      <div className="flex flex-nowrap justify-content-center align-items-center" style={{ width: "100%", flex: "1 1 auto" }} >
        <div style={{ maxWidth: "35em" }} >
          <PreviewMobiApp mobiApp={mobiApp} nomeEmpresa={nomeEmpresa} />
        </div>
      </div>

    </CadastroValues>;
  }
}

function mapStateToProps(state: reduxStateType) {
  const props: MobiAppValuesReduxStateType = {
    tabIndex: state.appReducer.getTabIndex(),
    ...state.idiomaReducer,
  };

  return props;
}

function mapDispatchToProps(dispatch: dispatchTipo) {
  const props: MobiAppValuesReduxDispatchType = {
    colorHandler: (props, event) => dispatch(colorHandler(props, event)),
    multiColorHandler: (props, event) => dispatch(multiColorHandler(props, event)),
  };

  return props;
}

const MobiAppValues = MobiAppValues_;