//@ts-check
import "./BarraLogoEmpresa.css"

import React from "react"
import Icones from "../../../assets/icones/Icones"

class BarraLogoEmpresa extends React.Component {

  state = {
    erroCarregarLogo: false,
  }

  render() {
    const LogoEmpresaIcone = Icones.mobi.LogoEmpresa;
    const style = this.state.erroCarregarLogo ? {display: 'none'} : undefined;

    return <div key='__barra_logo_empresa__' id='barra-logo-empresa' style={style}>
      {
      this.props.versaoLogo && !this.state.erroCarregarLogo
      ? <LogoEmpresaIcone nomeEmpresa={this.props.nomeEmpresa} versaoLogo={this.props.versaoLogo} onError={() => this.setState({erroCarregarLogo: true})}/>
      : null
      }
    </div>;
  }
}

export default BarraLogoEmpresa;
