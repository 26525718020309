import React from "react";
import { connect } from "react-redux";

import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils";
import { getStrings } from "../../../utils/LocaleUtils";
import { log } from "../../../utils/LogUtils";

import * as cadastroActions from "../../../store/actions/cadastroAction";
import { appDialogShow } from "../../../store/actions/appAction";

import AddIcon from "@material-ui/icons/Add";
import CargoUsuarioRow from "./CargoUsuarioRow";
import InputCustomizado from "../../UI/Input/InputCustomizado";
import { Button } from "../../UI/Button/Button";
import DialogCargoNovoUsuario from "../dialog/DialogCargoNovoUsuario";

import "./CargoUsuarioForm.css";

import { updateFormData } from "../../../store/actions/signUpAction";
import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils";
import { Fieldset } from "../../UI/Fields/Fieldset";

/**
 * Componente para gerência de usuários vinculados a um cargo, que é feito pelo email do usuário.
 * 
 * Se ocorrer algum erro ao realizar esse vínculo, os outros dados do cargo não são persistidos. O usuário não pode estar vinculado a outro cargo da mesma empresa. Se isso ocorrer, o usuário aparecerá como "Duplicado".
 * 
 * Se o usuário não existir, isso não é considerado um erro. Será criado um usuário e enviado um email para o mesmo para verificação. O usuário já é considerado vinculado ao cargo mesmo antes de ser verificado. Assim, também pode ser desvinculado de um cargo e vinculado a outro. Ele aparecerá como "Criado" enquanto não for verificado.
 */
class CargoUsuarioForm extends React.Component {

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("CargoUsuarioForm render", this.state, this.props);

    return <>
      {/* Tabela em volta de tudo para prover uma borda */}
      <Fieldset legend={getStrings().linkedUsers}>
      <table id="cargoUsuarioDiv" >
        <tbody>
          {/* Linha para inserção de emails */}
          <tr>
            <td className="Lighter" >
              <form className="sub-form" method="post" onSubmit={e => e.preventDefault()} style={{paddingTop: '0'}}>
                <div className="tela-integration">
                  <div className="tokenInputBox">
                    <InputCustomizado
                      ref={input => this.emailInput = getReduxWrappedComponent(input)}
                      label={getStrings().userEmail}
                      placeholder={getStrings().addUserEmail}
                      maxLength="250"
                      type="email"
                      name="emailInput"
                    />
                    <Button
                      type="submit"
                      className="gray-button"
                      title={getStrings().add}
                      onClick={() => {
                        let emailInput = this.emailInput.inputComponent;

                        ClienteHttp.requisicaoServidor("usuarios/isEmailRegistrado", "get", true, {
                          params: { email: emailInput.value }
                        })
                          .then((response) => {
                            if (response.data) {
                              // Verifica se há um email digitado e se ele é válido
                              if (this.emailInput.inputComponent.value && this.emailInput.inputComponent.checkValidity()) {
                                // Verifica se o email já não está na lista
                                if (this.props.cadastroDados.usuarioList.findIndex(usuario => 
                                  usuario.login.email === this.emailInput.inputComponent.value) < 0) {
                                  // Atualiza a lista de usuários do colaborador
                                  this.props.cadastroUpdateFormData(
                                    Object.assign(
                                      {},
                                      this.props.cadastroDados,
                                      {
                                        usuarioList: this.props.cadastroDados.usuarioList.concat({
                                          login: {
                                            email: this.emailInput.inputComponent.value
                                          }
                                        })
                                      }
                                    )
                                  );
                                  // Limpa o campo de email
                                  this.emailInput.inputComponent.value = "";
                                  // Agenda o método que ajusta o brilho do botão de confirmação e dialog de alterações pendentes.
                                  // Provavelmente não seria necessário se lógica de cadastro e do InputCustomizado fosse refeita.
                                  setTimeout(this.props.handleChange, 0);
                                }
                                // Se email já está na lista, age como se tivesse inserido.
                                else {
                                  this.emailInput.inputComponent.value = "";
                                }
                              }
                            }
                            else {
                              this.props.setSignUpValue(["login", "email"], emailInput.value);
                              this.props.appDialogShow(
                                <DialogCargoNovoUsuario
                                  limpaEmailUsuario={() => this.emailInput.inputComponent.value = ""}
                                />,
                                getStrings().userRegistration,
                                { overflow: 'unset' },
                                undefined,
                                () => { },
                                () => { },
                                { overflow: 'unset', width: '95%'  }
                              );
                            }
                          });
                      }}>
                      <AddIcon />
                    </Button>
                  </div>
                </div>
              </form>
            </td>
          </tr>
          {/* Tabela de emails inseridos */}
          <tr>
            <td>
              <div className="pure-g">
                <table className="pure-table cargoUserList" id="cargoUserList" width="100%" >
                  {/* Cabeçalho */}
                  <thead>
                    <tr>
                      {/* Botão de remover */}
                      <th className="Bold ButtonAlign fix" width="50px">{getStrings().options}</th>
                      {/* Email do usuário */}
                      <th className="Bold TextAlign fix">{getStrings().userEmail}</th>
                      {/* Estado do vínculo */}
                      <th className="Bold TextAlign fix">{getStrings().status}</th>
                    </tr>
                  </thead>
                  {/* Linhas da tabela */}
                  <tbody>{
                    (this.props.cadastroDados.usuarioList || []).map(usuario => 
                      <CargoUsuarioRow
                        key={usuario.login.email}
                        email={usuario.login.email}
                        status={usuario.estadoUsuarioCargo || "OK"}
                        handleChange={this.props.handleChange}
                      />)
                  }</tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </Fieldset>
    </>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state) {
  const props = {
    ...state.idiomaReducer,
    cadastroDados: state.cadastroReducer.cadastroDados || { usuarioList: [] },
    operation: state.cadastroReducer.operation,
  };

  return props;
}

/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  const props = {
    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles, onCloseClicked, onOverlayClicked, dialogContentStyles) => dispatch(appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles, onCloseClicked, onOverlayClicked, dialogContentStyles)),
    cadastroUpdateFormData: cadastroDados => dispatch(cadastroActions.cadastroUpdateFormData(cadastroDados)),
    handleRoleUserListChange: (email, isValid, onAdded) => dispatch(cadastroActions.handleRoleUserListChange(email, isValid, onAdded)),
    setSignUpValue: (keyArray, value) => dispatch(updateFormData(keyArray, value)),
  };

  return props;
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CargoUsuarioForm);