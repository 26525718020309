import React from 'react';
import { connect } from 'react-redux';

import { log } from "../../utils/LogUtils"
import { isIE } from "../../utils/NavigatorUtils"

import * as producaoActions from '../../store/actions/producaoAction';

import "./Alert.css";
import "./ItemStateAlert.css";
import Icones from '../../assets/icones/Icones';
import SoundManager from '../../assets/sound/SoundManager';

/**
 * Mostra um sino animado no *header* para sinalizar que há itens de venda produzidos não visualizados.
 */
class ItemStateAlert extends React.Component {
    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('ItemStateAlert render');

        const MdNotificationsNone = Icones.md.MdNotificationsNone;

        // Somente exibe o aviso se há itens de venda produzidos não visualizados
        if (this.props.itemVendaProduzidoList.length > 0 || localStorage.getItem('forceAlert') === 'true') {
            SoundManager('bellRing');

            return <div className={`clickable ItemStateAlert${isIE ? ' ie' : ''}`} onClick={() => {
                this.props.showItemProduzidoDialog();
            }}>
                <MdNotificationsNone />
            </div>;
        }
        // Se não, esconde o aviso.
        else {
            return null;
        }
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    itemVendaProduzidoList: state.producaoReducer.itemVendaProduzidoList
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    showItemProduzidoDialog: () => dispatch(producaoActions.showItemProduzidoDialog())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ItemStateAlert);
