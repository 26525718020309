//@ts-check
import "./GrupoProdutoTela.css"

import React from "react"
import ProdutoTela from "../ProdutoTela/ProdutoTela"
import { connect } from "react-redux"
import { carregarProdutos, formatarProduto } from "../../../nucleo/redux/carregamentos/carregamentoActions"
import TamanhosTela from "../TamanhosTela/TamanhosTela"
import CarregamentoOverlay from "../../overlay/CarregamentoOverlay/CarregamentoOverlay"
import { esconderOverlay, mostrarOverlay } from "../../../nucleo/redux/overlay/overlayActions"
import BarraDeBusca from "../../barras/BarraDeBusca/BarraDeBusca"
import { mostrarDialog } from "../../../nucleo/redux/dialog/dialogActions"
import InstalacaoDialog from "../../dialogs/InstalacaoDialog/InstalacaoDialog"

class GrupoProdutoTela extends React.Component {
    /**
     * 
     * @type {any}
     */
    beforeInstallEvent;

    carregarProdutos = () => {
        this.props.dispatch(mostrarOverlay({componente: 
            /**
             * 
             * @param {any} props 
             * @returns 
             */
            (props) => <CarregamentoOverlay {...props} />}));
  
        carregarProdutos(this.props.parametrosEmpresa, this.props.dispatch)
            .then(({grupoProdutoList, listaProdutos}) => {
                formatarProduto(listaProdutos, grupoProdutoList, this.props.parametrosEmpresa, this.props.dispatch);
            })
            .finally(() => {
                this.props.dispatch(esconderOverlay());

                this.forceUpdate();

                setTimeout(() => {
                    if (this.beforeInstallEvent) {
                        const installEvent = this.beforeInstallEvent;
                        this.props.dispatch(mostrarDialog({
                            conteudo: () => <InstalacaoDialog instalarAction={() => installEvent.prompt()} />
                        }));
                        this.beforeInstallEvent = null;
                    }
                }, 800);

            });
    }

    mensagemInstalacao = () => {
        const beforeInstallPrompt = sessionStorage.getItem('beforeInstallPrompt');
        if (!beforeInstallPrompt) {
            window.addEventListener('beforeinstallprompt', (event) => {

                // Prevent Chrome 67 and earlier from automatically showing the prompt
                event.preventDefault();

                // Salva o evento
                this.beforeInstallEvent = event;
            });
            sessionStorage.setItem('beforeInstallPrompt', 'true');
        }
    }

    componentDidMount() {
        this.mensagemInstalacao();

        if (!this.props.webAppGrupoProdutosReducer.grupoCarregado) {
            this.carregarProdutos();
        }
    }

    render() {
        const gruposProdutos = Object.keys(this.props.webAppGrupoProdutosReducer.grupos)
            .map((codigo) => this.props.webAppGrupoProdutosReducer.grupos[codigo]);

        return <div key='tela' className='tela' id='grupo-produto-tela'>
            <BarraDeBusca telaTamanhos={false} />
            {gruposProdutos
                .map(
                    /**
                     * 
                     * @param {import('../../../nucleo/redux/produtos/grupoProdutos/grupoProdutosReducer').grupoProdutoReducerTipo} grupoProduto 
                     * @returns 
                     */
                    (grupoProduto) => {
                    const busca = this.props.webAppGrupoProdutosReducer.busca.toLocaleLowerCase();
                    let incluiBusca = true;

                    if (busca) {
                        incluiBusca = grupoProduto.nome.toLowerCase().includes(busca)
                            || Object.values(grupoProduto.produtos).some((produto) => produto.nome.toLowerCase().includes(busca))
                    }

                    if (!incluiBusca) {
                        return null;
                    }

                    const grupoProdutoTamanhoList = grupoProduto?.grupoProdutoTamanhoList || [];

                    if (grupoProdutoTamanhoList.length) {  
                        return <TamanhosTela
                            key={`__produto_lista__${grupoProduto.codigo}__`}
                            grupoProduto={grupoProduto}
                        />;
                    }

                    return <ProdutoTela
                        key={`__produto_lista__${grupoProduto.codigo}__`}
                        grupoProduto={grupoProduto}
                    />;
                })
            }
        </div>;
    }
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
    parametrosEmpresa: state.webAppParametrosEmpresaReducer,
    webAppGrupoProdutosReducer: state.webAppGrupoProdutosReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(GrupoProdutoTela);
