//@ts-check
var conversor = require('xml-js');

/**
 * 
 * @param {string} xml
 */
export default (xml) => {
  return conversor.xml2js(xml);
}
