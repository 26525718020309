//@ts-check
/**
 * 
 * @param {number} numero 
 * @param {number} numeroDecasas 
 * @returns {string}
 */
export const numeroReal = (numero, numeroDecasas) => {
    return numero.toFixed(numeroDecasas).replace(".", ",");
}

/**
 * 
 * @param {number} numero 
 * @param {number} numeroDecasas 
 * @returns {string}
 */
export function stringNumericaComCasas(numero, numeroDecasas) {
    const multiploDez = 10 * (numeroDecasas - 1);
    let zeros = ""

    for(let i = 1; i < numeroDecasas; i += 1) {
        zeros += "0";
    }

    return `${(numero) >= multiploDez ? "" : zeros}${(numero)}`
}