import "./CadastroEnderecoTela.css"

import React from "react"
import { Entrada, Form } from "../../../../nucleo/nucleo"
import { LocalidadeUtils, ClienteHttp, EnderecoUtils } from "../../../../nucleo/utils/utils"
import { connect } from "react-redux"
import { atualizarCadastro } from "../../../../nucleo/redux/cadastro/cadastroEndereco/cadastroEnderecoActions"
import { polularPaises } from "../../../../nucleo/redux/paisesReducer/paisesActions"


class CadastroEnderecoTela extends React.Component {
  /**
   * 
   * @type {{paises: Array<{defaultChecked?: boolean,value: string,label: string}>}}
   */
  state = {
    paises: [],
  }
  
  componentDidMount() {
    window.scrollTo({top: 0});

    if (this.props.editar) {
      this.props.atualizarCadastro(this.props.usuario.endereco);
    }

    ClienteHttp.obterClienteHttp().get(ClienteHttp.getApi('paises'), ClienteHttp.getHeaders())
      .then((resposta) => {
        const dadosPaises = ((resposta || {}).data || {}).content || {};
        let paises = [];

        for(const pais of dadosPaises) {

          const label = LocalidadeUtils.obterTraducao().country.countryName(pais.iso);
          const value = pais._links.self.href.replace(ClienteHttp.getApi('paises/'), '');
          const defaultChecked = pais.iso === LocalidadeUtils.obterTraducao().country.iso;

          if (!this.props.cadastro.endereco.pais.codigo && defaultChecked) {
            this.props.atualizarCadastro({
              ...this.props.cadastro.endereco,
              pais: {
                codigo: pais._links.self.href.replace(ClienteHttp.getApi('paises/'), ''),
                iso: pais.iso,
                nome: pais.nome,
              },
            });
          }

          paises.push({
            defaultChecked,
            label,
            value,
          });
        }

        /**
         * 
         * @type {import('../../../../nucleo/redux/paisesReducer/paisesReducer').paisesReducerTipo}
         */
        const paisesReducer = {};

        for (const pais of dadosPaises) {
          paisesReducer[pais._links.self.href.replace(ClienteHttp.getApi('paises/'), '')] = {
            label: `${pais.iso} - ${pais.nome}`,
            labelKey: pais.iso,
            value: pais,
          };
        }

        this.setState({paises});
        this.props.dispatch(polularPaises(paisesReducer));
      });
  }

  /**
   * 
   * @param {{target: {value: string, name: string}}} evt 
   */
  changeHandler = ({target}) => {
    const { value, name } = target;

    if (name === 'pais') {
      const pais = this.props.paises[value];
      if (pais) {
        this.props.atualizarCadastro({
          ...this.props.cadastro.endereco,
          [name]: {
            codigo: pais.value._links.self.href.replace(ClienteHttp.getApi('paises/'), ''),
            iso: pais.value.iso,
            nome: pais.value.nome,
          },
        });
      }
    }
    else if (name === 'cep') {
      EnderecoUtils.lerCEP(value, (dados) => {
        this.props.atualizarCadastro({...this.props.cadastro.endereco, ...dados});
      });
      this.props.atualizarCadastro({...this.props.cadastro.endereco, cep: value});
    }
    else {
      this.props.atualizarCadastro({
        ...this.props.cadastro.endereco,
        [name]: value,
      });
    }
  }

  render() {
    return <div id='cadastro-endereco-tela' className='tela'>
      <div className='barra'>
        {LocalidadeUtils.obterTraducao().register.label.adress}
      </div>
      <Form id='conteudo'>
        <Entrada
          obrigatorio
          name='cep'
          value={this.props.cadastro.endereco.cep}
          onChange={this.changeHandler}
          type='digits'
          maxLength={20}
          label={LocalidadeUtils.obterTraducao().register.label.zipCode}
          placeholder={LocalidadeUtils.obterTraducao().register.placeholder.zipCode}
        />
        <div className='campo-duplo'>
          <div className='left maior'>
            <Entrada obrigatorio
              name='rua'
              value={this.props.cadastro.endereco.rua}
              onChange={this.changeHandler}
              type='text'
              maxLength={50}
              label={LocalidadeUtils.obterTraducao().register.label.street}
              placeholder={LocalidadeUtils.obterTraducao().register.placeholder.street}
            />
          </div>
          <div className='right menor'>
            <Entrada obrigatorio
              name='numero'
              value={this.props.cadastro.endereco.numero}
              onChange={this.changeHandler}
              type='digits'
              maxLength={20}
              label={LocalidadeUtils.obterTraducao().register.label.number}
              placeholder={LocalidadeUtils.obterTraducao().register.placeholder.number}
            />
          </div>
        </div>
        <Entrada
          name='complemento'
          value={this.props.cadastro.endereco.complemento}
          onChange={this.changeHandler}
          type='text'
          maxLength={20}
          label={LocalidadeUtils.obterTraducao().register.label.complement}
          placeholder={LocalidadeUtils.obterTraducao().register.placeholder.complement}
        />
        <div className='campo-duplo'>
          <div className='left igual'>
            <Entrada
              obrigatorio
              name='bairro'
              value={this.props.cadastro.endereco.bairro}
              onChange={this.changeHandler}
              type='text'
              maxLength={50}
              label={LocalidadeUtils.obterTraducao().register.label.hood}
              placeholder={LocalidadeUtils.obterTraducao().register.placeholder.hood}
            />
          </div>
          <div className='right igual'>
            <Entrada
              obrigatorio
              name='cidade'
              value={this.props.cadastro.endereco.cidade}
              onChange={this.changeHandler}
              type='text'
              maxLength={50}
              label={LocalidadeUtils.obterTraducao().register.label.city}
              placeholder={LocalidadeUtils.obterTraducao().register.placeholder.city}
            />
          </div>
        </div>
        <div className='campo-duplo'>
          <div className='left menor'>
            <Entrada
              obrigatorio
              name='estado'
              value={this.props.cadastro.endereco.estado}
              onChange={this.changeHandler}
              type='text'
              maxLength={50}
              label={LocalidadeUtils.obterTraducao().register.label.stateName}
              placeholder={LocalidadeUtils.obterTraducao().register.placeholder.stateName}
            />
          </div>
          <div className='right maior'>
            <Entrada
              obrigatorio
              name='pais'
              value={this.props.cadastro.endereco.pais.codigo}
              onChange={this.changeHandler}
              type='seletor-unico'
              label={LocalidadeUtils.obterTraducao().register.label.country}
              placeholder={LocalidadeUtils.obterTraducao().register.placeholder.country}
              seletores={this.state.paises}
            />
          </div>
        </div>
      </Form>
    </div>;
  }
}

/**
 * 
 * @param {import('../../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  usuario: state.webAppUsuarioReducer,
  cadastro: state.webAppCadastroEnderecoReducer,
  paises: state.webAppPaisesReducer,
});

/**
 * 
 * @param {import('../../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  atualizarCadastro: (endereco) => dispatch(atualizarCadastro({endereco})),
  dispatch: (acao) => dispatch(acao),
});

export default connect(mapStateToProps, mapDispatchToProps)(CadastroEnderecoTela);
