//@ts-check

import SeletorMultiplo from "./SeletorMultiplo"
import SeletorUnico from "./SeletorUnico"

const TipoSeletores = {
  'seletor-unico': SeletorUnico,
  'seletor-multiplo': SeletorMultiplo,
}

export default TipoSeletores;
