//@ts-check
import React from 'react';
import { Entrada } from '../../../nucleo/nucleo';
import { LocalidadeUtils } from '../../../nucleo/utils/utils';
import { connect } from 'react-redux';
import DialogTemplate from '../DialogTemplate';
import { atualizarCarrinho } from '../../../nucleo/redux/carrinho/carrinhoActions';

import "./ObservacaoDialog.css";

/**
 * 
 * @param {import('./types').ObservacaoDialogPropsType} props 
 * @returns {JSX.Element}
 */
function ObservacaoDialog({id, dispatch, carrinhoReducer}) {
    const produto = carrinhoReducer.produtos[id];

    /**
     * 
     * @param {{target: {value: string}}} evt 
     */
    function changeHandler({target: {value}}) {
        dispatch(atualizarCarrinho({...produto, observacao: value, add: false}));
    }

    return <DialogTemplate fecharHabilitado titulo={LocalidadeUtils.obterTraducao().words.observation}>
        <div className='observacao-dialog'>
            <div>
                <Entrada value={produto.observacao || ''} onChange={changeHandler} maxLength={300}/>
            </div>
        </div>
    </DialogTemplate>
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => {
    const props = {
        carrinhoReducer: state.webAppCarrinhoReducer,
    }

    return props;
}

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    const actions = {
        dispatch,
    };

    return actions;
}

export default connect(mapStateToProps, mapDispatchToProps)(ObservacaoDialog);