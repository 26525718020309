//@ts-check
import criarReducer from "../factory/criarReducer"
import tiposDeAcoes from "./pedidosActions";
import moment from 'moment';

/**
 * 
 * @type {import("./types").pedidosReducerTipo}
 */
const estadoInicial = {};

/**
 * 
 * @type {import("../../../../../store/actions/acaoTemplate").acoesNomeadasTipo<import("./types").pedidosReducerTipo>}
 */
const acoesNomeadas = {
  [tiposDeAcoes.ADICIONAR_PEDIDO]: ({id, produtos, endereco, formaPagamento, numeroDeParcelas, motivoCancelamento, observacao}, estadoAtual) => {
    if (Object.keys(produtos).length === 0) {
      return estadoAtual || estadoInicial;
    }

    /**
     * 
     * @type {import("./types").pedidosReducerTipo}
     */
    const novoEstado = {
      ...(estadoAtual || estadoInicial)
      , [id]: {
        key: `__pedido_item_key_${id}__`
        , dataDoPedido: moment().toISOString().slice(0, 10)
        , estado: 'analise'
        , produtos
        , endereco
        , formaPagamento
        , numeroDeParcelas
        , motivoCancelamento
        , observacao
      }
    };

    return novoEstado;
  }
  , [tiposDeAcoes.ATUALIZAR_PEDIDO]: ({id, estado, dataDoPedido, entregaVenda, valorTotal, produtos, endereco, formaPagamento, motivoCancelamento, observacao}, estadoAtual) => {
    /**
     * 
     * @type {import("./types").pedidosReducerTipo}
     */
    const novoEstado = {
      ...(estadoAtual || estadoInicial)
      , [id]: {
        key: (estadoAtual || {key: ''}).key
        , dataDoPedido
        , entregaVenda
        , valorTotal
        , estado
        , produtos
        , endereco
        , formaPagamento
        , numeroDeParcelas: (estadoAtual || {numeroDeParcelas: 1}).numeroDeParcelas
        , motivoCancelamento
        , observacao
      }
    };

    return novoEstado;
  }
  , [tiposDeAcoes.LIMPAR_PEDIDOS]: () => {
    const novoEstado = estadoInicial;

    return novoEstado;
  }
};

/**
 * 
 * @param {import("./types").pedidosReducerTipo} estado 
 * @param {import("../factory/criarReducer").acaoTipo} acao 
 * @returns 
 */
export default function(estado = estadoInicial, acao) {
  return criarReducer(estado, acao, acoesNomeadas);
}
