import "./BotaoChecado.css"

import React from "react"

export type BotaoChecadoTipo = {
  desativado: boolean,
};

function BotaoChecado({desativado}: BotaoChecadoTipo) {
  return <div className={"botao-checado" + (desativado ? " desativado" : "")}>
    {desativado
      ? "×"
      : "✓"
    }
  </div>
}

export default BotaoChecado;