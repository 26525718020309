import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"

import { getStrings } from "../../../utils/LocaleUtils"
import { updateActiveMenuItem } from "../../../utils/ScreenUtils"
import { getSaleSourceNoCompany } from "../../../utils/StorageUtils/LocalStorageUtils"
import { getSaleSourceCode } from "../../../utils/StorageUtils/SessionStorageUtils"
import { formatI18nString } from "../../../utils/StringUtils"

import * as appActions from "../../../store/actions/appAction"
import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"

import { getTour } from "../../UI/ModalSystem/TourSystem"
import CustomButton from "../../UI/Button/CustomButton"
import Dashboard from "../Dashboard/Dashboard"
import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"

import "./Home.css";

/**
 * Tela inicial da aplicação.
 */
class Home extends React.Component {

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount = () => {
    updateActiveMenuItem('menuItemHome');

    // Se possui no local storage dados sobre uma venda realizada por um usuário sem cadastro,
    // abre esta venda.
    if ((!this.props.isCargo) && (getSaleSourceNoCompany() || getSaleSourceCode())) {
      this.props.goTo('/controleVendas');
    }
    else {
      this.props.openSideDrawer();
    }
  }

  /**
   * 
   * @param {Object} tourObject 
   */
  removeDeactivedMenusFromTour(tourObject) {
    tourObject['/'] = tourObject['/'].filter(value => document.querySelector(`${value.selector}`));

    return tourObject;
  }

  tourButtons() {
    let buttons = [
      <button onClick={() => this.props.startTour(getTour('website'))}>
        <span>{formatI18nString(getStrings().tourIcon)}</span><span>{getStrings().tourWebsite}</span></button>,
      <button id="startUsuarioLogadoTour" onClick={() => this.props.startTour(getTour('usuarioLogado', this.removeDeactivedMenusFromTour))}>
        <span>{formatI18nString(getStrings().tourIcon)}</span><span>{getStrings().tourUser}</span></button>,
    ]

    if (document.querySelector('#menuItemCadastro')) {
      buttons.push(
        <button id="startMenuCadadastroTour" onClick={() => this.props.startTour(getTour('menuCadastro', this.removeDeactivedMenusFromTour))}>
          <span>{formatI18nString(getStrings().tourIcon)}</span><span>{getStrings().tourRegisterMenu}</span></button>
      );
    }

    if (document.querySelector('#accordionMenuItemVenda')) {
      buttons.push(
        <button id="startMenuControleTour" onClick={() => this.props.startTour(getTour('menuControle', this.removeDeactivedMenusFromTour))}>
          <span>{formatI18nString(getStrings().tourIcon)}</span><span>{getStrings().tourControlMenu}</span></button>
      );
    }


    if (document.querySelector('#accordionMenuRelatorios')) {
      buttons.push(
        <button id="startMenuRelatorios" onClick={() => this.props.startTour(getTour('menuRelatorios', this.removeDeactivedMenusFromTour))}>
          <span>{formatI18nString(getStrings().tourIcon)}</span><span>{getStrings().tourReportMenu}</span></button>
      );
    }

    if (document.querySelector('#accordionMenuItemUsuario')) {
      buttons.push(
        <button id="startMenuConfiguracoes" onClick={() => this.props.startTour(getTour('menuConfiguracoes', this.removeDeactivedMenusFromTour))}>
          <span>{formatI18nString(getStrings().tourIcon)}</span><span>{getStrings().tourSettingsMenu}</span></button>
      );
    }

    return buttons;
  }

  render() {
    return <>
      <div className='header breadCrumbs'>
        <h1>{getStrings().homePage}</h1>
        <h2>{'\u00A0'}</h2>
      </div>
      <WidthAwareDiv>

        <HelpParagraph divClass='homeHelp' children={getStrings().homeHelp.map(string => formatI18nString(string)).concat(this.tourButtons())} />
        {/* Dependendo se um cargo foi selecionado ou não */}
        {this.props.isCargo
          // Exibe mensagem sobre painel de instrumentos que será implementado no futuro
          ? <Dashboard />
          // Ou exibe botão para permitir fazer uma compra em uma empresa
          : <div className='tela-start tela-login'>
            <div className='sub-form'>
              <div className='button-grid'>
                <CustomButton
                  tabIndex={this.props.tabIndex}
                  className='gray-button login-submit'
                  btnType='Success'
                  id='codeReaderButton'
                  onClick={() => this.props.goTo('/controleVendas')}
                >{getStrings().codeReader}</CustomButton>
              </div>
            </div>
          </div>}

      </WidthAwareDiv>
    </>;
  }
};

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  tabIndex: state.appReducer.getTabIndex(),
  isCargo: state.empresaSelectorReducer.cargo ? true : false,
  saleControlState: state.controleVendaReducer.state
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  openSideDrawer: () => dispatch(appActions.setSideDrawerState(true)),
  dispatch: app => dispatch(app),
  goTo: path => dispatch(push(path)),
  resetState: () => dispatch(controleVendaActions.resetState()),
  startTour: tour => dispatch(appActions.appStartTour(tour)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(Home);
