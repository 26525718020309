//@ts-check
import React from "react"

import "./Texto.css"

/**
 * 
 * @param {import('./types').textoPropsType} props 
 * @returns {JSX.Element}
 */
function Texto(props) {
    return <div {...props} className={'ui-texto ' + (props.className || '')} />;
}

export default Texto;