//@ts-check
import "./BarraTopo.css"

import React from "react"
import Icones from "../../../assets/icones/Icones"
import { connect } from "react-redux"
import apelidos from "../../../rotas/apelidos"
import { inverterEstadoMenuModal } from "../../../nucleo/redux/menus/menuModal/menuModalActions"
import { Texto } from "../../../nucleo/nucleo"
import { NavegacaoUtils } from "../../../nucleo/utils/utils"
import { getEmpresaApp } from "../../../nucleo/utils/StorageUtils/StorageUtils"

class BarraTopo extends React.Component {
  componentDidMount() {
    document.addEventListener("scroll", () => {
      this.forceUpdate();
    });
  }

  render() {
    const IconeLogo = Icones.mobi.Logo;
    const IconeMenu = Icones.mobi.Menu;
    const IconePerfil = Icones.mobi.Perfil;

    return <div id='barra-topo'>
        <div className='menu' onClick={
          /**
           * 
           * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event 
           */
          (event) => { event.stopPropagation(); this.props.inverterEstadoMenuModal(); }}>
          {this.props.webAppUsuarioReducer.usuario.mudouEstadoVenda
            ? <div className='notification'></div>
            : null
          }
          {this.props.webAppUsuarioReducer.verificaUsuarioLogado()
            ? <IconePerfil style={{width: '2.2em', height: '2.2em'}} />
            : <IconeMenu style={{width: '2.2em', height: '2.2em'}} />
          }
        </div>
        <div className='nome-exibicao'>
          <Texto onClick={() => { this.props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`)); }}>{this.props.parametrosEmpresa.nomeExibicao}</Texto>
        </div>
        <div className='logo' onClick={() => { this.props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`)); }}>
          <IconeLogo />
        </div>
      </div>;
  }
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  estadoMenu: state.webAppMenuModalReducer.estadoMenu,
  webAppUsuarioReducer: state.webAppUsuarioReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  inverterEstadoMenuModal: () => dispatch(inverterEstadoMenuModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarraTopo);
