//@ts-check
import React from 'react';
import { connect } from 'react-redux';

import { Entrada } from '../../../nucleo/nucleo';
import { atualizarCampoBuscaEmGrupoAction } from '../../../nucleo/redux/produtos/grupoProdutos/grupoProdutosActions';
import { atualizarMultiplosProduto } from '../../../nucleo/redux/produtos/produtos/produtosActions';
import { LocalidadeUtils } from '../../../nucleo/utils/utils';

import './BarraDeBusca.css';

/**
 * 
 * @param {import('./types').barraDeBuscaPropsTipo} props 
 * @returns {JSX.Element}
 */
function BarraDeBusca(props) {
    const [pesquisando, updatePesquisando] = React.useState(false);

    /**
     * 
     * @param {string} valor 
     */
    function pesquisarProduto(valor) {
        if (!pesquisando) {
            updatePesquisando(true);
            valor = valor.toLowerCase();

            new Promise(
                /**
                 * 
                 * @param {(valor?: any) => any} resolve 
                 * @param {(error?: any) => any} _reject 
                 */
                (resolve, _reject) => {
                /**
                 * 
                 * @type {import('../../../nucleo/redux/produtos/produtos/produtosReducer').produtosTipo}
                 */
                let produtos = {};

                Object.keys(props.grupoProdutos.grupos).forEach((key) => {
                    const grupoProduto = props.grupoProdutos.grupos[key];

                    Object.values(grupoProduto.produtos).forEach(({id}) => {
                        const produto = props.produtosReducer.produtos[id];
                        
                        const nome = produto.nome.toLowerCase();
                        const codigo = produto.codigo.toLowerCase();
                        const codigoImpresso = (produto.codigoImpresso || '').toLowerCase();

                        if (valor && (produto.produtoTamanhoList?.length || (!nome.includes(valor) && !codigo.includes(valor) && !codigoImpresso.includes(valor)))) {
                            if (produto.mostrar) {
                                produtos[produto.id] = {...produto, mostrar: false};
                            }
                        }
                        else {
                            if (!produto.mostrar) {
                                produtos[produto.id] = {...produto, mostrar: true};
                            }
                        }
                    });
                });

                props.dispatch(atualizarMultiplosProduto({produtos}));
                resolve();
            })
            .then(() => {
                updatePesquisando(false);
            });
        }
    };

    /**
     * 
     * @param {any} evt 
     */
    function pesquisaHandler(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        pesquisarProduto(props.grupoProdutos.busca);
    };

    /**
     * 
     * @param {{target: {value: string, name: string, obrigatorio?: boolean}}} evt 
     */
    function changeValueHandler(evt) {
        const valor = evt.target.value;

        props.dispatch(atualizarCampoBuscaEmGrupoAction({busca: valor || ''}));

        window.setTimeout(() => {
            if (props.grupoProdutos.busca === valor) {
                pesquisaHandler(evt);
            }
        }, 750);
    };

    return <div id='barra-de-busca'>
        <Entrada value={props.grupoProdutos.busca} type={'search'} onChange={changeValueHandler} placeholder={LocalidadeUtils.obterTraducao().words.search} />
    </div>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => {
    const props = {
        grupoProdutos: state.webAppGrupoProdutosReducer,
        produtosReducer: state.webAppProdutosReducer,
    };

    return props;
};

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    const actions = {
        dispatch,
    };

    return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(BarraDeBusca);
