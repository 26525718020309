import React from "react"

import { getStrings } from "../../../utils/LocaleUtils"
import { getFrontUrl } from "../../../utils/SecureConnectionUtils"
import { getPageNames } from "../../../utils/siteUtils/siteUtils"

import TableModel, { TableHeader, TableModelModularType } from "./TableModel"
import { TableRow, TableData, TableRowType } from "./TableRow"
import CopyToClipboardButton from "../../../components/UI/Button/CopyToClipboardButton"
import { getLinkToQrCodeAndShowOnCanvasRef } from "../../../utils/qrCodeUtils/qrCodeUtils"

type MobiAppTableRowType = Omit<TableRowType, "children">;

function MobiAppTableRow(props: MobiAppTableRowType) {
  const linkParaMobiApp = getFrontUrl(getPageNames().especial.MOBI_APP.slice(1)) + "?empresa=" + props.cadastro.valorLinkParametro;
  

  return <TableRow {...props}>
    <TableData align="Text" widthInPx={50} >{props.cadastro.nomeExibicao}</TableData>
    <TableData align="Text" widthInPx={50} >{<CopyToClipboardButton link notPropagate valueToCopy={linkParaMobiApp} />}</TableData>
    {/* @ts-ignore */}
    <TableData align="Text" widthInPx={20} ><canvas ref={getLinkToQrCodeAndShowOnCanvasRef(linkParaMobiApp)} /></TableData>
  </TableRow>;
}

export type MobiAppTableModelType = TableModelModularType

class MobiAppTableModel_ extends React.Component<MobiAppTableModelType> {
  render() {
    const cadastroRowList = (this.props.cadastroList || []).map((cadastro) => {
      return <MobiAppTableRow key={`__cadastro_id_${cadastro.id}__`} onUpdate={this.props.onUpdate} onDelete={this.props.onDelete} cadastro={cadastro} />;
    });

    // @ts-ignore
    return <TableModel cadastroRowList={cadastroRowList} >
      <TableHeader align="Text" >{getStrings().mobiAppLabels.exihibitionName}</TableHeader>
      <TableHeader align="Text" >{getStrings().mobiAppLabels.link}</TableHeader>
      <TableHeader align="Text" >{getStrings().qrCode}</TableHeader>
    </TableModel>;
  }
}

export const MobiAppTableModel = MobiAppTableModel_;
export default MobiAppTableModel;