//@ts-check
import config from "../../../configuracoes/config";

/**
 * 
 * @param {string} nome 
 * @param  {...any} dados 
 */
export default function log(nome, ...dados) {
  if ((sessionStorage.getItem('logEnabled') === 'true') && (config.filtroLog.length === 0 || config.filtroLog.includes(nome))) {
    console.debug(...dados);
  }
}
