import React from 'react';
import { connect } from 'react-redux';
//import jsQR from 'jsqr';
//import Quagga from 'quagga';

import * as codeReaderAction from '../../store/actions/codeReaderAction';
import * as controleVendaActions from '../../store/actions/controleVenda/controleVendaAction';

import { getAppOrdemFormatoCodigoBarras } from "../../utils/AppUtils"
import CameraPhoto, { FACING_MODES } from "../../utils/camera_utils_api"
import * as errorUtils from "../../utils/ErrorUtils"
import { detectFlash } from "../../utils/flash_utils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { isIE } from "../../utils/NavigatorUtils"
import { randomNumberBetween } from "../../utils/NumberUtils"
import { formatI18nString } from "../../utils/StringUtils"

import BarraAcoesForm, { SUBMIT_ROLE_SWITCH_CAMERA } from '../cadastros/barraAcao/BarraAcoesForm';
import HelpParagraph from '../UI/HelpParagraph/HelpParagraph';
import WidthAwareDiv from '../UI/WidthAwareDiv/WidthAwareDiv';

import "./CodeReader.css";

/**
 * Exibe imagem da câmera do usuário e permite ler códigos de barra e QR.
 */
class CodeReader extends React.Component {

    constructor() {
        super();
        log('CodeReader constructor');
        /**
         * Elemento necessário para extrair quadros do fluxo de vídeo. Usado somente para a leitura de código de barras.
         */
        this.canvasBarCode = document.createElement('canvas');
        /**
         * Elemento necessário para extrair quadros do fluxo de vídeo. Usado somente para a leitura de código QR.
         */
        this.canvasQR = document.createElement('canvas');
        /**
         * Auxilia para realizar o controle de somente fazer uma leitura de código de barras por vez.
         */
        this.notDecodingBarCode = true;
        /**
         * Configuração da leitura do código de barras.
         */
        this.config = {
            decoder: {
                readers: getAppOrdemFormatoCodigoBarras()
            }
        };
    }

    /**
     * Busca um quadro do vídeo e tenta identificar um código de barras nele.
     */
    readBarCode = () => {
        log('CodeReader readBarCode', this.notDecodingBarCode, this.ismounted, this.cameraStarted);
        // Testa se deve continuar lendo códigos
        if (this.props.readEnabled && this.notDecodingBarCode && this.ismounted && this.cameraStarted) {
            // Testa se o elemento que exibe o vídeo já está montado e já possui as dimensões do vídeo
            if (this.videoElement && this.videoElement.videoWidth && this.videoElement.videoHeight) {
                // Define que não será feita uma leitura de código de barras enquanto esta não terminar
                this.notDecodingBarCode = false;
                // Lê um quadro do vídeo
                this.videoToCanvas(this.canvasBarCode);
                // Converte um quadro do vídeo para o formato necessário pelo leitor de código de barras
                this.config.src = this.canvasBarCode.toDataURL();

                import('quagga').then(modulo => {

                    const Quagga = modulo.default;

                    // Lê o código de barras
                    Quagga.decodeSingle(this.config, result => {
                        log('CodeReader readBarCode result:', result);
                        // Se encontrou dados, coloca na fila para ser verificado.
                        if (result && result.codeResult && result.codeResult.code) {
                            this.props.verifyCode(result.codeResult.code);
                            log('CodeReader readBarCode success');
                        } else {
                            log('CodeReader readBarCode failure');
                        }
                        // Agenda uma nova leitura enquanto um dos dados a serem verificados não retornar positivo.
                        this.notDecodingBarCode = true;
                        setTimeout(this.readBarCode, 0);
                    });
                });
            }
            // Agenda uma nova tentativa de ler um código de barras
            setTimeout(this.readBarCode, 0);
        }
    }

    /**
     * Busca um quadro do vídeo e tenta identificar um código QR nele.
     */
    readQRCode = () => {
        log('CodeReader readQRCode');
        // Testa se deve continuar lendo códigos
        if (this.props.readEnabled && this.ismounted && this.cameraStarted) {
            // Testa se o elemento que exibe o vídeo já está montado e já possui as dimensões do vídeo
            if (this.videoElement && this.videoElement.videoWidth && this.videoElement.videoHeight) {
                /**
                 * Guarda o resultado da leitura do código QR. Se não encontrou código, é `null`.
                 * Se encontrou, é um objeto com várias informações. Dentre elas, `data` são os dados codificados pelo QR.
                 */
                let result = null;
                // Lê um quadro do vídeo
                this.videoToCanvas(this.canvasQR, (canvas) => {
                    const imageData = canvas.getContext('2d').getImageData(0, 0, this.videoElement.videoWidth, this.videoElement.videoHeight);

                    if (!isIE || imageData.data[randomNumberBetween(0, imageData.data.length - 1)] !== 255) {
                        import('jsqr').then(modulo => {

                            const jsQR = modulo.default;

                            // Lê o código QR
                            result = jsQR(
                                // Converte o quadro do fluxo de vídeo para o formato apropriado para a leitura do código QR
                                imageData.data,
                                this.videoElement.videoWidth, this.videoElement.videoHeight
                            );

                            log('CodeReader readQRCode result:', result);
                            // Se encontrou dados, coloca na fila para ser verificado.
                            if (result) {
                                this.props.verifyCode(result.data);
                                log('CodeReader readQRCode success');
                            } else {
                                log('CodeReader readQRCode failure');
                            }
                        });
                    } else {
                        log("Can't read camera, permission denied?");
                    }
                });
            }
            // Agenda uma nova tentativa de ler um código QR
            setTimeout(this.readQRCode, 0);
        }
    }

    /**
     * Inicia a câmera e as leituras de código de barras e QR.
     */
    startCamera = () => {
        log('CodeReader startCamera', { cameraFacingMode: localStorage.getItem('cameraFacingMode') });
        // Marca a câmera como não iniciada
        this.cameraStarted = false;
        // Inicia a câmera escolhendo entre a câmera traseira e a câmera frontal de acordo com a última escolha do usuário.
        // Se o usuário nunca trocou a câmera, o padrão é a traseira.
        this.cameraPhoto.startCamera(localStorage.getItem('cameraFacingMode') || FACING_MODES.ENVIRONMENT, {})
            // Se iniciou a câmera com sucesso
            .then(stream => {
                log('CodeReader startCamera camera started');
                // Busca as faixas de vídeo. Provavelmente será só uma.
                let tracks = stream.getVideoTracks();
                // Se não houver pelo menos uma.
                if ((!tracks) || (tracks.length < 1)) {
                    log('CodeReader startCamera no tracks found in stream', tracks);
                    // Dispara um erro para ser tratado logo abaixo
                    throw new Error();
                }
                // Busca a primeira faixa de vídeo
                let track = tracks[0];
                // Se estiver inválida
                if (!track) {
                    log('CodeReader startCamera error reading stream track');
                    // Dispara um erro para ser tratado logo abaixo
                    throw new Error();
                }

                // Marca a câmera como iniciada
                this.cameraStarted = true;

                // Agenda a execução dos métodos para ler códigos de barra e QR a partir da câmera
                setTimeout(() => {
                    // Verifica se foram configurados formatos de código de barras para leitura
                    if (this.config.decoder.readers.length > 0) {
                        // Se sim, inicia a leitura de código de barras. Se não, não.
                        this.readBarCode();
                    }
                    this.readQRCode();
                }, 0);
            })
            // Se não iniciou a câmera, dispara uma notificação.
            .catch(error => {
                log('CodeReader startCamera error starting camera', error);
                this.props.requestErrorNotificationShow({ response: { status: 400, data: 'cameraError' } });
            });
    }

    /**
     * Encerra a câmera e as leituras de código de barras e QR.
     */
    stopCamera = () => {
        log('CodeReader stopCamera');
        this.cameraPhoto.stopCamera().catch(error => log('CodeReader stopCamera error stopping camera', error));
        // Marca a câmera como não iniciada
        this.cameraStarted = false;
    }

    /**
     * Captura um quadro do vídeo para ser manipulado pelo canvas.
     */
    videoToCanvas = (canvas, callback) => {
        // Ajusta o tamanho do canvas
        canvas.width = this.videoElement.videoWidth;
        canvas.height = this.videoElement.videoHeight;
        const ctx = canvas.getContext('2d');
        // Busca um quadro do fluxo de vídeo
        let flashElement = document.querySelector('#webcam_movie_obj');

        if (flashElement) {
            if (flashElement._snap) {
                this.cameraPhoto.snap((data_uri) => {
                    let img = new Image();
                    img.src = data_uri;
                    ctx.drawImage(img, 0, 0, this.videoElement.videoWidth, this.videoElement.videoHeight);

                    if ((typeof callback) === 'function') {
                        callback(canvas);
                    } else {
                        log('CodeReader videoToCanvas', 'error', 'callback not setted 0');
                    }
                }, undefined, flashElement);
            }
        } else {
            canvas.getContext('2d').drawImage(this.videoElement, 0, 0, this.videoElement.videoWidth, this.videoElement.videoHeight);

            if ((typeof callback) === 'function') {
                callback(canvas);
            } else {
                log('CodeReader videoToCanvas', 'error', 'callback not setted 1');
            }
        }
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Inicializa o objeto que acessa a câmera e a inicia.
     */
    componentDidMount() {
        log('CodeReader componentDidMount', getAppOrdemFormatoCodigoBarras());
        this.ismounted = true;
        this.cameraPhoto = new CameraPhoto(this.videoElement);
        this.startCamera();
    }

	/**
	 * Método executado ANTES de "DESMONTAR" o componente.
	 * Encerra a câmera e marca a tela para não ser exibida.
	 */
    componentWillUnmount() {
        log('CodeReader componentWillUnmount');
        this.ismounted = false;
        this.stopCamera();
        this.props.stopReader();
    }

    /**
     * Método que monta o componente.
     */
    render() {
        log('CodeReader render', this.props);
        return <>
            <div className='header breadCrumbs'>
                <h1>{getStrings()[this.props.header]}</h1>
                <h2>{this.props.breadCrumbs}</h2>
            </div>

            <WidthAwareDiv>
                <HelpParagraph children={getStrings().codeReaderHelp.map(string => formatI18nString(string))} />
            </WidthAwareDiv>

            <BarraAcoesForm
                submitRole={SUBMIT_ROLE_SWITCH_CAMERA}
                handleListar={this.props.backAction}
                // Ao pressionar o botão de trocar a câmera
                handleSubmit={() => {

                    // Busca o "lado" da câmera que o usuário usou pela última vez
                    let cameraFacingMode = localStorage.getItem('cameraFacingMode');
                    // Troca para o "outro lado". Ou seja, se era a câmera traseira, agora será a frontal, e vice versa.
                    // Se não havia um "lado" em memória, como o padrão é a câmera traseira, seleciona a frontal.
                    cameraFacingMode = (cameraFacingMode === FACING_MODES.USER) ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER;
                    // Guarda este "lado" na memória
                    localStorage.setItem('cameraFacingMode', cameraFacingMode);
                    // Encerra a câmera atual
                    this.stopCamera();
                    // Inicia a outra câmera
                    this.startCamera();
                }}
            />
            {/* Elemento para exibir o fluxo de vídeo e de onde é possível capturar quadros do vídeo. */}
            {
                (isIE && detectFlash)
                    ? (<div className='videoElement' ref={ref => {
                        if (ref) {
                            this.videoElement = ref;
                        }
                    }}>
                    </div>)
                    : (<video className='videoElement' ref={ref => {
                        if (ref) {
                            this.videoElement = ref;
                        }
                    }} autoPlay playsInline></video>)
            }
        </>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        ...state.idiomaReducer,
        backAction: state.codeReaderReducer.backAction,
        breadCrumbs: state.codeReaderReducer.breadCrumbs,
        header: state.codeReaderReducer.header,
        readEnabled: state.codeReaderReducer.readEnabled
    };
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        exibeGridOrigemVendaEmUso: () => dispatch(controleVendaActions.exibeGridOrigemVendaEmUso()),
        requestErrorNotificationShow: error => dispatch(errorUtils.requestErrorNotificationShow(error)),
        stopReader: () => dispatch(codeReaderAction.stopReader()),
        treatCodeData: codeData => dispatch(controleVendaActions.treatCodeData(codeData)),
        verifyCode: code => dispatch(codeReaderAction.verifyCode(code))
    };
};

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CodeReader);
