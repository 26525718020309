//@ts-check
import React from 'react';

import DialogTemplate from '../DialogTemplate';
import { connect } from 'react-redux';
import { LocalidadeUtils } from '../../../nucleo/utils/utils';

/**
 * 
 * @param {import('./types').avisoDialogPropsType} props 
 * @returns 
 */
function AvisoDialog(props) {
  const { mensagem, ...sanitizedProps } = props;

  return <DialogTemplate {...sanitizedProps}
    fecharHabilitado={true}
    titulo={LocalidadeUtils.obterTraducao().dialog.warning}
    style={{maxWidth: '20em'}}
  >
    <label className='alinhar-ao-centro' style={{ textAlign: 'center' }}>{props.mensagem}</label>
  </DialogTemplate>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state
 * @returns 
 */
const mapStateToProps = (state) => ({
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AvisoDialog);
