import React from "react"
import { connect } from "react-redux"
import { AccordionClick } from "../../UI/Accordion/Accordion"

import * as AuthUtils from "../../../utils/AuthUtils"
import { PERMISSAO_DEV } from "../../../utils/AuthUtils"
import { isEmpresaColetor } from "../../../utils/CompanyUtils"
import { generalConfig } from "../../../utils/Config"
import { getStrings } from "../../../utils/LocaleUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"

import * as appActions from "../../../store/actions/appAction"

import apelidos from "../../../apps/promo_app_frontend/rotas/apelidos"

import Link from "../../UI/LinkHide/LinkHide"
import { Button } from "../../UI/Button/Button"

import "./SideDrawer.css";
import { getPageNames } from "../../../utils/siteUtils/siteUtils"

const mapIdToTabIndexItemsReducer = {
  "accordionMenuItemCadastro": "registersItem"
  , "accordionMenuItemVenda": "controlItem"
  , "accordionMenuRelatorios": "reportsItem"
  , "accordionMenuItemUsuario": "settingsItem"
}

/**
 * Componente SideDrawer (Menu da aplicação). 
 */
class SideDrawer extends React.Component {
  componentDidUpdate = () => {
    const newTabIndexSuperItemValue = this.props.sideDrawerShow
      ? 0
      : -1

    if (newTabIndexSuperItemValue !== this.props.tabIndexReducer.superItem) {
      this.props.updateTabIndexAction({
        superItem: newTabIndexSuperItemValue
      })
    }

    if (newTabIndexSuperItemValue === -1 && (
      newTabIndexSuperItemValue !== this.props.tabIndexReducer.registersItem ||
      newTabIndexSuperItemValue !== this.props.tabIndexReducer.controlItem ||
      newTabIndexSuperItemValue !== this.props.tabIndexReducer.reportsItem ||
      newTabIndexSuperItemValue !== this.props.tabIndexReducer.settingsItem
    )) {
      this.props.updateTabIndexAction({
        registersItem: -1
        , controlItem: -1
        , reportsItem: -1
        , settingsItem: -1
      });
    } else if (newTabIndexSuperItemValue === 0) {
      if (this.registersMenu) {
        const classNameArray = this.registersMenu.className.split(" ")

        if (this.props.tabIndexReducer.registersItem !== 0 && classNameArray.includes("active")) {
          this.props.updateTabIndexAction({ registersItem: 0 })
        }
      }
      if (this.controlMenu) {
        const classNameArray = this.controlMenu.className.split(" ")

        if (this.props.tabIndexReducer.controlItem !== 0 && classNameArray.includes("active")) {
          this.props.updateTabIndexAction({ controlItem: 0 })
        }
      }
      if (this.reportsMenu) {
        const classNameArray = this.reportsMenu.className.split(" ")

        if (this.props.tabIndexReducer.reportsItem !== 0 && classNameArray.includes("active")) {
          this.props.updateTabIndexAction({ reportsItem: 0 })
        }
      }
      if (this.settingsMenu) {
        const classNameArray = this.settingsMenu.className.split(" ")

        if (this.props.tabIndexReducer.settingsItem !== 0 && classNameArray.includes("active")) {
          this.props.updateTabIndexAction({ settingsItem: 0 })
        }
      }
    }
  }

  /**
   * Método que faz a verificação se há um Usuário logado. 
   */
  verificaUsuarioLogado = () => {
    if (this.props.isAuthenticated)
      return true

    return false
  }

  /**
   * Método que faz a verificação se há um Usuário logado e se o mesmo é Cargo. 
   */
  verificaUsuarioCargo = () => {
    if (!this.verificaUsuarioLogado())
      return false

    if (this.props.cargo)
      return true

    return false
  }

  /**
   * Método que carrega o Item de Menu para Seleção de Empresa, caso o Usuário esteja logado
   * e possua vínculo com empresa.
   */
  loadSelecaoEmpresaItem = () => {
    // Método que irá preencher a lista de cargos do props para verificar quantidade        
    if (this.verificaUsuarioLogado()) {
      // Se usuário possui vínculo com empresa(s), permite selecionar empresas
      if (this.props.quantidadeCargos > 0) {
        // Se possuir apenas uma empresa, não aparece item de selecionar empresa
        return <>
          <li className="pure-menu-item">
            <Link id="menuItemEmpresaSelector" to={getPageNames().logado.SELECAO_EMPRESA} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}>{getStrings().companySelection}</Link></li>
        </>
      }
      // Se usuário não possui vínculo com empresa(s), permite criar uma empresa
      // else if (this.props.quantidadeCargos === 0) {
      //   return <>
      //     <li className="pure-menu-item">
      //       <Link id="menuItemSignUpCompanyAsUser" to={getPageNames().logado.CADASTRO_EMPRESA} className="pure-menu-link menuItem" onClick={this.props.closeSideDrawerHandler}
      //         tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}>{getStrings().companyRegister}</Link></li>
      //   </>
      // }
      // Se usuário não está logado, oculta ambas as opções
      else {
        return null
      }
    }
    return null
  }

  superItemHandler = (evt) => {
    let superItem = {}
    superItem[mapIdToTabIndexItemsReducer[evt.target.id]] = evt.target.className.split(" ").includes("active")
      ? 0 : -1

    this.props.updateTabIndexAction(superItem)
  }

  /**
   * Método que carrega o Item de Menu e Subitens para Cadastros, caso o Usuário for Cargo. 
   */
  loadCadastrosItem = () => {
    if (!this.verificaUsuarioCargo()) {
      return null
    }

    // Verifica se o Cargo possui pelo menos uma das Permissões para exibir o menu de Cadastro
    if (!AuthUtils.verificaPermissaoAcesso(
      [
        AuthUtils.PERMISSAO_CADASTRO_GERAL
        , AuthUtils.PERMISSAO_CADASTRO_CARGO
        , AuthUtils.PERMISSAO_CADASTRO_EMPRESA
      ])) {
      return null
    }

    return (
      <>
        <Button
          id="accordionMenuItemCadastro"
          name="accordion"
          className="accordion accordion-menu pure-menu-item superMenuItem"
          tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}
          ref={ref => this.registersMenu = ref} onClick={(evt) => {
            AccordionClick(evt)
            this.superItemHandler(evt)
          }}
        >
          {getStrings().registers}
        </Button>
        <div id="menuItemCadastro" className="accordion-content submenu">
          {/* Empresa */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_EMPRESA]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemEmpresa" to={getPageNames().cargoSelecionado.EMPRESAS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().company}</Link></li>
          ) : ""}

          {/* MobiApp */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_EMPRESA]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemMobiApp" to={getPageNames().cargoSelecionado.MOBI_APPS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().mobiApp}</Link></li>
          ) : ""}

          {/* Cargo */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_CARGO]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemCargo" to={getPageNames().cargoSelecionado.CARGOS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().role}</Link></li>
          ) : ""}

          {/* Quadro de Horários */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_CARGO]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemQuadroHorarios" to={getPageNames().cargoSelecionado.QUADRO_HORARIOS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().timesheet}</Link>
            </li>
          ) : ""}

          {/* Impressora */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_GERAL]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemImpressora" to={getPageNames().cargoSelecionado.IMPRESSORAS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().printer}</Link></li>
          ) : ""}

          {/* Origem Venda */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_GERAL]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemFormaPagamento" to={getPageNames().cargoSelecionado.FORMA_PAGAMENTOS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().paymentMethodSideDrawer}</Link></li>
          ) : ""}

          {/* Tipo de Produto */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_GERAL]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemTipoProduto" to={getPageNames().cargoSelecionado.TIPO_PRODUTOS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().productType}</Link></li>
          ) : ""}

          {/* Tamanho */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_GERAL]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemTamanho" to={getPageNames().cargoSelecionado.TAMANHOS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().size}</Link></li>
          ) : ""}

          {/* Grupo de Produto */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_GERAL]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemGrupoProduto" to={getPageNames().cargoSelecionado.GRUPO_PRODUTOS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().productGroup}</Link></li>
          ) : ""}

          {/* Produto */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_GERAL]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemProduto" to={getPageNames().cargoSelecionado.PRODUTOS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().product}</Link></li>
          ) : ""}

          {/* Origem Venda */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_GERAL]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemOrigemVenda" to={getPageNames().cargoSelecionado.ORIGEM_VENDAS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().saleSource()}</Link></li>
          ) : ""}

          {/* Bairros */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_GERAL]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemBairro" to={getPageNames().cargoSelecionado.BAIRROS} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().neighborhood}</Link></li>
          ) : ""}

          {/* Clientes */}
          {(AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_CADASTRO_GERAL]) && isEmpresaColetor(this.props.ramoEmpresa)) ? (
            <li className="pure-menu-item">
              <Link id="menuItemCliente" to={getPageNames().cargoSelecionado.CLIENTES} className="pure-menu-link menuItem submenuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().client}</Link></li>
          ) : ""}
        </div>
      </>
    )
  }

  /**
   * Método que carrega os itens de menu relacionados à venda. 
   */
  loadControleVendasItem = () => {
    if (!this.verificaUsuarioLogado())
      return null

    // Se for um usuário sem vínculo com empresa, permite o acompanhamento do estado de seus pedidos.
    if (!this.props.isCargo) {
      return <li className="pure-menu-item">
        <Link id="menuItemLeitorCodigo" to={getPageNames().logado.LEITOR_CODIGO} className="pure-menu-link menuItem"
          onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}
        >{getStrings().codeReader}</Link>
      </li>
    }

    if (!this.verificaUsuarioCargo())
      return null

    // Verifica se o Cargo possui pelo menos uma das Permissões para exibir o menu de Vendas
    if (!AuthUtils.verificaPermissaoAcesso([
      AuthUtils.PERMISSAO_VENDA_CONTROLE
      , AuthUtils.PERMISSAO_VENDA_ENTREGA
      , AuthUtils.PERMISSAO_VENDA_ENVIO
      , AuthUtils.PERMISSAO_VENDA_EXCLUSAO
      , AuthUtils.PERMISSAO_VENDA_PAGAMENTO
      , AuthUtils.PERMISSAO_PRODUCAO_ALTERACAO
      , AuthUtils.PERMISSAO_PRODUCAO_CONTROLE
    ])) {
      return null
    }

    return <>
      <Button
        id="accordionMenuItemVenda"
        name="accordion"
        className="accordion accordion-menu pure-menu-item superMenuItem"
        tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}
        ref={ref => this.controlMenu = ref} onClick={(evt) => {
          AccordionClick(evt)
          this.superItemHandler(evt)
        }}>
        {getStrings().control}
      </Button>
      <div id="menuItemVenda" className="accordion-content submenu">
        {/* Pedidos */}
        {AuthUtils.verificaPermissaoAcesso([
          AuthUtils.PERMISSAO_VENDA_CONTROLE
          , AuthUtils.PERMISSAO_VENDA_ENVIO
          , AuthUtils.PERMISSAO_VENDA_EXCLUSAO
          , isEmpresaColetor(this.props.ramoEmpresa) ? "" : AuthUtils.PERMISSAO_VENDA_PAGAMENTO
        ]) ? (
          <li className="pure-menu-item">
            <Link id="menuItemPedidos" to={getPageNames().cargoSelecionado.PEDIDOS} className="pure-menu-link menuItem submenuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.registersItem}>{getStrings().orders}</Link></li>
        ) : ""}

        {/* Controle */}
        {AuthUtils.verificaPermissaoAcesso([
          AuthUtils.PERMISSAO_VENDA_CONTROLE,
          AuthUtils.PERMISSAO_VENDA_ENVIO,
          AuthUtils.PERMISSAO_VENDA_EXCLUSAO,
          isEmpresaColetor(this.props.ramoEmpresa) ? "" : AuthUtils.PERMISSAO_VENDA_PAGAMENTO]) ? (
          <li className="pure-menu-item">
            <Link id="menuItemControleVenda" to={getPageNames().cargoSelecionado.CONTROLE_VENDAS} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.controlItem}>{getStrings().movement}</Link></li>
        ) : ""}

        {/* Produção */}
        {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_PRODUCAO_ALTERACAO, AuthUtils.PERMISSAO_PRODUCAO_CONTROLE, AuthUtils.PERMISSAO_VENDA_ENTREGA]) ? (
          <li className="pure-menu-item">
            <Link id="menuItemProducao" to={getPageNames().cargoSelecionado.PRODUCAO} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.controlItem}>{getStrings().production}</Link></li>
        ) : ""}

        {/* Cancelamento */}
        {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_VENDA_EXCLUSAO]) ? (
          <li className="pure-menu-item">
            <Link id="menuItemCancelamento" to={getPageNames().cargoSelecionado.CANCEL} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.controlItem}>{getStrings().saleCancellationMenu}</Link></li>
        ) : ""}
      </div>
    </>
  }

  /**
   * Método que carrega o Item de Menu para Seleção de Relatório, caso o Usuário esteja logado. 
   */
  loadRelatoriosItem = () => {
    if (!this.verificaUsuarioCargo())
      return null

    // Verifica se o Cargo possui pelo menos uma das Permissões para exibir o menu de Relatórios
    if (!AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_RELATORIOS])) {
      return null
    }

    return (
      <>
        <Button
          id="accordionMenuRelatorios"
          name="accordion"
          className="accordion accordion-menu pure-menu-item superMenuItem"
          tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}
          ref={ref => this.reportsMenu = ref} onClick={(evt) => {
            AccordionClick(evt)
            this.superItemHandler(evt)
          }}
        >
          {getStrings().reports}
        </Button>
        <div id="menuRelatorios" className="accordion-content submenu">
          {/* Movimento */}
          <li className="pure-menu-item">
            <Link id="menuRelatorioMovimento" to={getPageNames().cargoSelecionado.RELATORIOS_MOVIMENTO} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.reportsItem}>{getStrings().movementReport}</Link></li>

          {/* Fechamento de caixa */}
          <li className="pure-menu-item">
            <Link id="menuRelatorioFechamento" to={getPageNames().cargoSelecionado.RELATORIOS_FECHAMENTO} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.reportsItem}>{getStrings().closingReport}</Link></li>

          {/* Lucratividade */}
          <li className="pure-menu-item">
            <Link id="menuRelatorioLucratividade" to={getPageNames().cargoSelecionado.RELATORIOS_LUCRATIVIDADE} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.reportsItem}>{getStrings().profitabilityReport}</Link></li>

          {/* Lucratividade por dia */}
          <li className="pure-menu-item">
            <Link id="menuRelatorioLucratividadeData" to={getPageNames().cargoSelecionado.RELATORIOS_LUCRATIVIDADE_DATA} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.reportsItem}>{getStrings().profitabilityDayReportShort}</Link></li>

          {/* Lucratividade por hora */}
          <li className="pure-menu-item">
            <Link id="menuRelatorioLucratividadeHora" to={getPageNames().cargoSelecionado.RELATORIOS_LUCRATIVIDADE_HORA} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.reportsItem}>{getStrings().profitabilityHourReportShort}</Link></li>

          {/* Entregas */}
          <li className="pure-menu-item">
            <Link id="menuRelatorioEntregas" to={getPageNames().cargoSelecionado.RELATORIOS_ENTREGAS} className="pure-menu-link menuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.reportsItem}>{getStrings().deliveryReport}</Link></li>
        </div>
      </>
    )
  }

  /**
   * Método que carrega o Item de Menu para Seleção de Empresa, caso o Usuário esteja logado. 
   */
  loadControleUsuarioItem = () => {
    if (!this.verificaUsuarioLogado()) {
      return this.loadLoginItem()
    }

    return this.loadUsuarioItem()
  }

  /**
   * Método que carrega o Item de Menu para Login/Cadastro do Usuário. 
   */
  loadLoginItem = () => {
    return (
      <>
        <li className="pure-menu-item">
          <Link id="menuItemLogin" to={getPageNames().naoLogado.LOGIN} className="pure-menu-link menuItem"
            onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}>{getStrings().login}</Link></li>
      </>
    )
  }

  /**
   * Método que carrega o Item de Menu para Login/Cadastro do Usuário. 
   */
  loadUsuarioItem = () => {
    return (
      <>
        <Button
          id="accordionMenuItemUsuario"
          name="accordion"
          className="accordion accordion-menu pure-menu-item superMenuItem"
          tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}
          ref={ref => this.settingsMenu = ref} onClick={(evt) => {
            AccordionClick(evt)
            this.superItemHandler(evt)
          }}
        >
          {getStrings().settings}
        </Button>
        <div id="menuItemUsuario" className="accordion-content submenu">

          {/* Alterar dados do Usuario */}
          <li className="pure-menu-item">
            <Link id="menuItemUsuarioForm" to={getPageNames().logado.USUARIO} className="pure-menu-link menuItem submenuItem"
              onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.settingsItem}>{getStrings().user}</Link></li>

          {/* Integração */}
          {AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_INTEGRACAO_CONFIGURACAO]) ? (
            <li className="pure-menu-item">
              <Link id="menuItemIntegracao" to={getPageNames().cargoSelecionado.INTEGRACAO} className="pure-menu-link menuItem"
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.settingsItem}>{getStrings().integration}</Link></li>
          ) : ""}
        </div>

        <li className="pure-menu-item">
          <Link id="menuItemLogout" to={getPageNames().logado.LOGOUT} className="pure-menu-link menuItem" onClick={this.props.closeSideDrawerHandler}
            tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}>{getStrings().logout}</Link></li>
      </>
    );
  }

  // Carrega página Dev do modo de desenvolvedor
  loadDevPageItem = () => {
    if (!PERMISSAO_DEV && JSON.parse(sessionStorage.getItem("a") || "{}").u !== "1") {
      return <></>
    }

    return (
      <>
        <li className="pure-menu-item">
          <Link id="menuItemDevPage" to={getPageNames().especial.DEV} className="pure-menu-link menuItem"
            onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}>dev</Link>
        </li>
        <li className="pure-menu-item">
          <Link id="menuItemApp" to={getPageNames().especial.MOBI_APP + "?empresa=padrao"} className="pure-menu-link menuItem"
            onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}>App</Link>
        </li>
      </>
    )
  }

  SideDrawerCompanyName = (exhibit) => {
    return exhibit
      ? <>
        <div className="SideDrawerCompanyName">
          {this.props.nomeEmpresa}
        </div>
      </>
      : null
  }

  render() {
    const mobiVersion = generalConfig.FRONT_VERSION

    const pageDomain = this.verificaUsuarioLogado()
      ? this.verificaUsuarioCargo()
        ? "cargoSelecionado"
        : "logado"
      : "naoLogado";

    return <>
      <nav id="menu" className={`SideDrawer${this.props.sideDrawerShow && !((new RegExp(apelidos.app + "[(/|?).*]*")).test(this.props.location.pathname)) ? " Open" : " Close"}`}>
        <div className="pure-menu">
          <ul className="pure-menu-list">
            <li className="pure-menu-item">
              <Link id="menuItemHome" to={getPageNames()[pageDomain].HOME} className="pure-menu-link menuItem" style={{ fontSize: "1.25em" }}
                onClick={this.props.closeSideDrawerHandler} tabIndex={this.props.tabIndex === -1 ? -1 : this.props.tabIndexReducer.superItem}>{getStrings().homePage}</Link></li>

            {(this.props.quantidadeCargos === 1) ? null : this.loadSelecaoEmpresaItem()}
            {this.loadCadastrosItem()}
            {this.loadControleVendasItem()}
            {this.loadRelatoriosItem()}
            {this.loadControleUsuarioItem()}
            {this.loadDevPageItem()}
          </ul>
          <div className="userNameVersionNumberDiv">
            <div className="userName">{getStrings().userNameTemplate(this.props.nomeUsuario.nome)}</div>
            <div className="versionNumber">{mobiVersion}</div>
          </div>
        </div>
        <div className="SideDrawerCompanyNameWrapper">
          {this.SideDrawerCompanyName(!isAncientChromeMobile)}
        </div>
      </nav>
      {/* Necessário caso chrome mobile seja antigo */}
      {isAncientChromeMobile
        ? <div className={`SideDrawerCompanyNameWrapper ancient-chrome-mobile${this.props.sideDrawerShow ? " Open" : " Close"}`}>
          {this.SideDrawerCompanyName(isAncientChromeMobile)}
        </div>
        : null
      }
    </>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  tabIndexReducer: state.tabIndexReducer
  , ...state.idiomaReducer
  , isCargo: !!state.empresaSelectorReducer.cargo
  , nomeEmpresa: state.empresaSelectorReducer.nomeEmpresa
  , nomeUsuario: state.usuarioReducer.nomeUsuario
  , permissoesCargo: state.empresaSelectorReducer.permissoesCargo
  , quantidadeCargos: state.empresaSelectorReducer.quantidadeCargos
  , ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa
  , sideDrawerShow: state.appReducer.sideDrawerShow
  , tabIndex: state.appReducer.getTabIndex()
  , webAppRedutor: state.webAppRedutor
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  updateTabIndexAction: (payload) => dispatch(appActions.updateTabIndexAction(payload))
  , closeSideDrawerHandler: () => dispatch(appActions.closeSideDrawerHandler())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer)
