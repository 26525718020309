import React from "react"
import { connect } from "react-redux"
import { Redirect, Link } from "react-router-dom"

import { getStrings } from "../../../utils/LocaleUtils"
import { setSaleSourceNoCompany } from "../../../utils/StorageUtils/LocalStorageUtils"
import { blurAll, updateActiveMenuItem } from "../../../utils/ScreenUtils"

import { appDialogShow } from "../../../store/actions/appAction"
import * as authActions from "../../../store/actions/authAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"
import CustomButton from "../../UI/Button/CustomButton"
import F3ButtonComponent from "../../Auth/F3SDK/F3ButtonComponent"
import F3DialogContent from "./F3DialogContent"

import "./Login.css";

/* 
 * Necessário para o Edge, pelo menos até sair o próximo update
 * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8993198/
 */
var URLSearchParams = require('url-search-params');

/**
 * Componente de Login de Usuário. 
 */
class Login extends React.Component {
    state = {
        codigo: '',
        email: '',
        senha: '',
    };

    noCompany = false;

    componentDidMount() {
        updateActiveMenuItem('menuItemLogin');

        // Busca o token na querystring
        const query = new URLSearchParams(this.props.location.search);
        const confirmationToken = query.get('token');
        this.noCompany = query.get('noCompany');

        if (confirmationToken)
            this.emailConfirmationHandler(confirmationToken);

        let origemVendaURI = query.get('origemVenda');
        let numeroPessoas = query.get('numeroPessoas');

        if (origemVendaURI) {
            setSaleSourceNoCompany({ origemVendaURI, numeroPessoas });
        }
    }

    /**
     * Método chamado ao clicar no botão Login. 
     */
    submitHandler = event => {
        // Impede que o form redirecione para outra URL
        event.preventDefault();

        blurAll();

        const login = {
            email: this.state.email,
            senha: this.state.senha,
        }

        this.props.authenticationSubmit(login);
    }

    /**
     * Método chamado quando for necessária a confirmação do email do usuário. 
     */
    emailConfirmationHandler = token => {
        this.props.emailConfirmationSubmit(token);
    }

    /**
     * Método que retorna o botão de Envio dos dados. 
     */
    submitButton = () => {
        return <CustomButton tabIndex={this.props.tabIndex} className='gray-button login-submit' btnType='Success' id='loginButton'>{getStrings().loginButton}</CustomButton>;
    }

    render() {
        // Verifica se o usuário deve ser redirecionado e o redireciona
        // Implementado em App porque aqui estava apresentando condição de corrida
        let authRedirect = null;
        if (this.props.isAuthenticated) {
            authRedirect = <Redirect to={this.props.authRedirectPath} />
        }

        let button = this.submitButton();

        return (
            <>
                {authRedirect}
                {this.props.inputDoCodigoDeConfirmacao
                ? <div  key='__login_tela__' className='tela-start tela-login coluna'>
                    <h2 style={{color: 'var(--color-text-dark)'}}>
                        {getStrings().signUpConfirmation(this.state.email)[1]}
                    </h2>
                    <InputCustomizado id='codigo' placeholder={getStrings().code} onInput={(event) => {
                        if (event.target.value.length > 4) {
                            const login = {
                                email: this.state.email,
                                senha: this.state.senha,
                                token: event.target.value,
                            };

                            this.props.emailConfirmationSubmitByCode(login, () => {
                                this.props.desativarInputDoCodigoDeConfirmacao();
                            });
                            
                        }
                    }}
                        
                    />
                </div>
                : <div key='__login_tela__' className='tela-start tela-login'>

                    <form className='sub-form' onSubmit={this.submitHandler}>
                        <InputCustomizado id='email' onInput={(event) => { this.setState({email: event.target.value}); }}
                            placeholder={getStrings().email} maxLength={250} type='email' name='email' required={true} label={getStrings().emailRequired} />

                        <InputCustomizado id='senha' onInput={(event) => { this.setState({senha: event.target.value}); }}
                            placeholder={getStrings().password} maxLength={100} type='password' name='senha' required={true} label={getStrings().passwordRequired} />
                        <div className='button-grid'>
                            {button}
                            <Link className='Lighter change-pass' tabIndex={this.props.tabIndex} to='/redefinirSenha' >{getStrings().forgotPassword}</Link>
                            <Link className='Lighter change-pass' tabIndex={this.props.tabIndex} to={`${this.noCompany ? '/signUpUser' : '/signUp'}`} >{getStrings().signUp}</Link>
                        </div>
                    </form>

                    <F3ButtonComponent
                        children={getStrings().f3Login}
                        onClick={() => {
                            this.props.appDialogShow(<F3DialogContent />, getStrings().f3Login);
                        }}
                    />
                </div>}
            </>);
    }
}

/**
 * Mapeia as propriedades do estado global para utilizar localmente. 
 * @param {*} state 
 */
const mapStateToProps = state => ({
    authRedirectPath: state.authReducer.authRedirectPath,
    inputDoCodigoDeConfirmacao: state.authReducer.inputDoCodigoDeConfirmacao,
    isAuthenticated: state.authReducer.token !== null,
    ...state.idiomaReducer,
    tabIndex: state.appReducer.getTabIndex(),
});


/**
 * Mapeia as ações para utilizar localmente. 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles, onCloseClicked, onOverlayClicked) =>
        dispatch(
            appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles, onCloseClicked, onOverlayClicked)
        ),
    authenticationWithF3: (login) => dispatch(authActions.authenticationWithF3(login)),
    authenticationSubmit: (login) => dispatch(authActions.authenticationSubmit(login)),
    emailConfirmationSubmit: (token) => dispatch(authActions.emailConfirmationSubmit(token)),
    desativarInputDoCodigoDeConfirmacao: () => dispatch(authActions.desativarInputDoCodigoDeConfirmacao()),
    emailConfirmationSubmitByCode: (login, success) => dispatch(authActions.emailConfirmationSubmitByCode(login, success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
