//@ts-check
import React from 'react';

import './BarraNavegacao.css';

import BotaoVoltar from '../../botoes/BotaoVoltar/BotaoVoltar';
import BotaoPrincipal from '../../botoes/BotaoPrincipal/BotaoPrincipal';
import { connect } from 'react-redux';

/**
 * 
 * @param {import('./types').barraNavegacaoPropsType} props 
 * @returns 
 */
function BarraNavegacao(props) {
    return <div key='__dev_barra_navegacao__' id='barra-navegacao'>
        <div className='column-left'>
            <BotaoVoltar key='__dev_botao_voltar__' botaoVoltar={props.botaoVoltar} />
        </div>
        <div className={`column-center ${props.soldOut
            ? ` sold-out`
            : ''}`}>
            {props.shortInfo}
        </div>
        <div className='column-right'>
            <BotaoPrincipal key='__dev_botao_principal__' caminhoAtual={props.caminhoAtual} quantidadeDeProdutos={0} />
        </div>
    </div>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
    ...state.webAppInfoReducer,
    webAppBarraNavegacaoReducer: state.webAppBarraNavegacaoReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BarraNavegacao);
