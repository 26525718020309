//@ts-check
import React from 'react';
import { LocalidadeUtils, NavegacaoUtils } from '../../../nucleo/utils/utils';
import { Botao, Texto } from '../../../nucleo/nucleo';

import { connect } from 'react-redux';
import apelidos from '../../../rotas/apelidos';
import { definirGrupoProdutoId } from '../../../nucleo/redux/produtos/grupoProdutos/grupoProdutosActions';
import { atualizarUnidadeCarrinho } from '../../../nucleo/redux/carrinho/carrinhoActions';

import "./TamanhoCard.css";

/**
 * 
 * @param {import('./types').TamanhoCardTipo} props 
 * @returns 
 */
function TamanhoCard(props) {
  const {tamanho, quantidadeMaximaSabores} = props.grupoProdutoTamanho;
  return <div tabIndex={props.tabIndex} className={'app-card tamanho-card'}>
    <div>
      <div className='identificacao-tamanho'>
        <div className='info'>
          <div className='column nome'>
            <Texto className='nome'>{`${tamanho.nome} (${quantidadeMaximaSabores} ${LocalidadeUtils.obterTraducao().words.flavor(quantidadeMaximaSabores > 1)})`}</Texto>
          </div>
        </div>
        <div className='painel-e-preco'>
          <div className='row controle-quantidade'>
            <Botao className='botao-add' onClick={() => {
              props.dispatch(definirGrupoProdutoId(props.grupoProduto));
              Object.keys(props.grupoProduto.produtos)
                .map((id) => props.grupoProduto.produtos[id]).forEach((produto) => {
                  props.dispatch(atualizarUnidadeCarrinho({
                    nomeUnidade: props.grupoProduto.nome + ' ' + props.grupoProdutoTamanho.tamanho.nome,
                    idSabor: produto.id,
                    quantidade: 0,
                    grupoProdutoTamanhoId: props.grupoProdutoTamanho.id,
                    quantidadeMaximaSabores: props.grupoProdutoTamanho.quantidadeMaximaSabores,
                    quantidadeMinimaSabores: props.grupoProdutoTamanho.quantidadeMinimaSabores,
                  }));
                });
              props.dispatch(NavegacaoUtils.goTo(apelidos.produtosComTamanho));
            }}>
              +
            </Botao>
          </div>
      </div>
      </div>
      {props.ultimoCardDoGrupo
        ? null
        : <div className={`linha-separadora`} />
      }
    </div>
  </div>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  carrinho: state.webAppCarrinhoReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TamanhoCard);
