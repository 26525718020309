import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import $ from 'jquery';

import Header from '../../components/Navigation/Header/Header';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import DialogSystem from '../../components/UI/ModalSystem/DialogSystem';
import NotificationSystem from '../../components/UI/ModalSystem/NotificationSystem';
import SpinnerSystem from '../../components/UI/ModalSystem/SpinnerSystem';
import SnackbarSystem from '../../components/UI/ModalSystem/SnackbarSystem';
import TourSystem from '../../components/UI/ModalSystem/TourSystem';

import * as actions from '../../store/actions/appAction';
import apelidos from '../../apps/promo_app_frontend/rotas/apelidos';

import './Layout.css';

class Layout extends React.Component {
    constructor() {
        super()

        this.disableScrollIf = (condition) => {
            document.body.style.overflowY = condition ? 'hidden' : 'auto';
        }
    }

    state = {};

    /**
     * Método executado APÓS de montar/renderizar o componente.
     */
    componentDidMount() {

        // Verifica a largura da scrollbar do navegador
        const scriptScrollbarWidth = document.createElement('script');

        scriptScrollbarWidth.src = './js/getScrollbarWidth.js';
        scriptScrollbarWidth.async = true;
        scriptScrollbarWidth.document = document;
        document.body.appendChild(scriptScrollbarWidth);

        // Disponibiliza a fonte
        const fontLink = document.createElement('link');

        fontLink.href = 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&amp;subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese';
        // Opções de tamanho e itálico: 300,300i,400,400i,600,600i,700,700i,800,800i
        fontLink.rel = 'stylesheet';
        fontLink.type = 'text/css';

        document.body.appendChild(fontLink);

        // reCAPTCHA
        const reCAPTCHA = document.createElement('script');
        reCAPTCHA.src = 'https://www.google.com/recaptcha/api.js';
        reCAPTCHA.async = true;
        reCAPTCHA.document = document;

        document.head.appendChild(reCAPTCHA);

        // https://www.jqueryscript.net/other/Simple-jQuery-Based-Barcode-Generator-Barcode.html
        // https://github.com/artdomg/barcode-jquery
        window.jQuery = $; // Armazena o jQuery como variável global para que o script inserido manualmente possa ter acesso
        const jQueryBarcode = document.createElement('script');
        jQueryBarcode.src = './js/jquery-barcode.min.js';
        jQueryBarcode.async = true;
        jQueryBarcode.document = document;

        document.head.appendChild(jQueryBarcode);

        // Guarda no estado a visibilidade do side drawer
        this.props.closeSideDrawerHandler();

        this.controlTimeout = 0;

        // Suporte teclado para mostrar/esconder menu lateral
        // document.addEventListener('keydown', this.controlActiveListener);
        // document.addEventListener('keydown', this.invertedBarActiveListener);
    }

    // controlActiveListener = (event) => {
    //     if (event.key === 'Control') {
    //         this.controlTimeout = 250;

    //         setTimeout(() => {
    //             this.controlTimeout = 0;
    //         }, this.controlTimeout);
    //     }
    // };

    // invertedBarActiveListener = (event) => {
    //     if (event.key === "\\" && this.controlTimeout) {
    //         this.props.dispatch(actions.toggleSideDrawer());
    //         this.controlTimeout = 0;
    //     }
    // };

    render() {
        if (this.props.noLayout) {
            return this.props.children;
        }

        if ((new RegExp(`${apelidos.app}[(/|?).*]*`)).test(this.props.location.pathname)) {
            return <>
                {this.props.children}            
            </>;
        }

        // UI Components
        require('../../components/UI/Table/Table.css');
        require('../../components/UI/Card/Card.css');
        require('../../assets/css/components/Button.css');
        require('../../components/UI/Input/Input.css');
        require('../../components/UI/HelpParagraph/HelpParagraph.css');
        require('../../components/UI/BreadCrumbs/BreadCrumbs.css');
        require('../../components/UI/Text/Text.css');
        require('../../components/UI/ModalSystem/ModalSystem.css');
        require('../../components/UI/Toolbar/Toolbar.css');
        require('../../components/UI/Accordion/Accordion.css');
        require('../../components/Telas/Telas.css');
        require('../../assets/css/components.css');
        require('../../assets/css/components/Integration.css');

        return <>

            <div id='dialogs'>
                <TourSystem />
                <SnackbarSystem />
                <DialogSystem />
                <SpinnerSystem />
                <NotificationSystem />
            </div>

            <Header
                history={this.props.history}
                style={this.props.fullScreenStyle.hide}
            />

            {/* <div className='conteudo'> */}

                <div id='left' className='column' style={this.props.fullScreenStyle.hide}>
                    <SideDrawer
                        location={this.props.location}
                        className='bottom'
                        isAuthenticated={this.props.isAuthenticated}
                        cargo={this.props.cargo} />
                    <div id='SideDrawer-placeholder' />
                </div>

                <div id='right' className='column' style={this.props.fullScreenStyle.padding}>
                    <main className={'Content bottom ' + (this.props.sideDrawerShow ? 'menu-opened' : 'menu-closed')} style={{...this.props.fullScreenStyle.margin, ...this.props.fullScreenStyle.content}}>
                        {this.disableScrollIf(
                            // Desativa scroll se pathname é "readerHome"
                            (this.props.location.pathname.indexOf('/readerHome') > -1)
                            // E se está exibindo a câmera
                            && !this.props.companyInformationName)}
                        {this.props.children}
                    </main>
                </div>

            {/* </div> */}
        </ >;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    sideDrawerShow: state.appReducer.sideDrawerShow,
    isAuthenticated: state.authReducer.token !== null,
    cargo: state.empresaSelectorReducer.cargo,
    companyInformationName: state.companyInformationReducer.name,
    fullScreenStyle: state.appReducer.fullScreenStyle,
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    dispatch,
    appNotificationHide: () => dispatch(actions.appNotificationHide()),
    closeSideDrawerHandler: () => dispatch(actions.closeSideDrawerHandler())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
