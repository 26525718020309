import React from "react"
import { connect } from "react-redux"

import { isEmpresaPainel } from "../../utils/CompanyUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../utils/reduxUtils/reduxUtils"
import { formatHourMinuteFromOriginalZone } from "../../utils/TimeUtils"
import { getURIFromEntity } from "../../utils/URIUtils"

import * as pagamentoVendasActions from "../../store/actions/controleVenda/pagamentoVendasAction"

import InputCustomizado from "../UI/Input/InputCustomizado"

import "../vendas/pagamento/BuildCellFromQuebraVendaOrigem.css";

/**
 * Item inserido entre os pagamentos de venda para identificar e separar os mesmos
 * e exibir totais de cada pagamento.
 */
class BuildCellFromPagamentoVendaOrigem extends React.Component {

  isChecked = () => {
    let pagamentoVenda = this.props.pagamentoVenda || {}
    let id = getURIFromEntity(pagamentoVenda)
    let checked = (this.props.salePaymentToCancelList || []).find(salePaymentToCancel => getURIFromEntity(salePaymentToCancel) === id)
    log("BuildCellFromPagamentoVendaOrigem isChecked", { pagamentoVenda: this.props.pagamentoVenda, checked })
    return checked
  }

  /**
   * Metodo executado após a atualização do componente.
   */
  componentDidUpdate() {
    log("BuildCellFromPagamentoVendaOrigem componentDidUpdate")
    this.checkboxInput.inputComponent.checked = this.isChecked()
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    let pagamentoVenda = this.props.pagamentoVenda || {}

    let id = getURIFromEntity(pagamentoVenda)

    let venda = pagamentoVenda.venda || {}

    let vendaContainer = venda.origemVenda || venda.cliente || {}

    let checked = this.isChecked()

    log("BuildCellFromPagamentoVendaOrigem render", { id, checked })

    return <div className="table-footer">
      <div className="table-cancel table-row">
        <InputCustomizado
          tabIndex={this.props.tabIndex}
          ref={checkbox => this.checkboxInput = getReduxWrappedComponent(checkbox)}
          id={id}
          type="checkbox"
          name={id}
          handleInputValidado={() => checked ? this.props.removeSalePaymentToCancel([pagamentoVenda]) : this.props.addSalePaymentToCancel([pagamentoVenda])}
        />
        <div className="table-cell day TextAlign" >
          {getStrings().formatDate(new Date(pagamentoVenda.dataHora))}
        </div>
        <div className="table-cell hour TextAlign" >
          {formatHourMinuteFromOriginalZone(pagamentoVenda.dataHora)}
        </div>
        <div className="table-cell saleSourceSale TextAlign" >{isEmpresaPainel(this.props.ramoEmpresa)
          ? getStrings().saleTemplate(venda.nome || "")
          : getStrings().saleSourceAndSaleTemplate(vendaContainer.nome || "", venda.nome || "")
        }</div>
      </div>
    </div>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  salePaymentToCancelList: state.pagamentoVendasReducer.salePaymentToCancelList,
  tabIndex: state.appReducer.getTabIndex()
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  addSalePaymentToCancel: salePaymentToCancelList => dispatch(pagamentoVendasActions.addSalePaymentToCancel(salePaymentToCancelList)),

  removeSalePaymentToCancel: salePaymentToCancelList => dispatch(pagamentoVendasActions.removeSalePaymentToCancel(salePaymentToCancelList))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BuildCellFromPagamentoVendaOrigem)

