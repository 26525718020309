//@ts-check
import { obterEnderecoPorCep } from "../LocalidadeUtils/obterTraducao";

/**
 * 
 * @param {string} cep 
 * @param {((resposta: any) => void | undefined)?} callback 
 * @returns 
 */
export default function(cep, callback = null) {
  return obterEnderecoPorCep(cep, (resposta) => callback && callback({
      bairro: resposta.data.bairro, 
      cidade: resposta.data.localidade,
      codigoCidade: resposta.data.ibge,
      rua: resposta.data.logradouro, 
      estado: resposta.data.uf, 
    })
  );
}
