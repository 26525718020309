import React from 'react';
import { connect } from 'react-redux';

import { getURIFromEntity } from '../../../utils/URIUtils';

import BuildCellFromItemVenda from './BuildCellFromItemVenda';
import { updateDeliveryAction, removeDeliveryAction, clearDeliveryAction } from '../../../store/actions/deliveryAction';

import "./GridProducao.css";

import {
    ESTADO_ITEM_VENDA_ENVIADO,
    ESTADO_ITEM_VENDA_AGUARDANDO,
    ESTADO_ITEM_VENDA_EM_PRODUCAO,
    ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA,
    ESTADO_ITEM_VENDA_ENTREGUE,
    ESTADO_ITEM_VENDA_CANCELADO, ESTADO_ITEM_VENDA_ENCERRADO, ESTADO_ITEM_VENDA_PRODUZIDO
} from '../../../store/reducers/controleVenda/controleVendaReducer';

import { updateSimpleTapSelectionAction } from '../../../store/actions/simpleTapSelectionAction';
import { appDialogShow } from '../../../store/actions/appAction';
import { getIdFromEntity } from '../../../utils/URIUtils';

/**
 * Retorna um nome de classe CSS para cada estado do item de venda para alterar a cor de fundo do seu indicador.
 */
export const stateToColor = (key) => {
    const obj = {
        [ESTADO_ITEM_VENDA_ENVIADO]: ' productionItemStateSent',
        [ESTADO_ITEM_VENDA_AGUARDANDO]: ' productionItemStateWaiting',
        [ESTADO_ITEM_VENDA_EM_PRODUCAO]: ' productionItemStateProducing',
        [ESTADO_ITEM_VENDA_PRODUZIDO]: ' productionItemStateProduced',
        [ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA]: ' productionItemStateLeftForDelivery',
        [ESTADO_ITEM_VENDA_ENTREGUE]: ' productionItemStateDelivered',
        [ESTADO_ITEM_VENDA_ENCERRADO]: ' productionItemStateClosed',
        [ESTADO_ITEM_VENDA_CANCELADO]: ' productionItemStateCancelled',
    }

    if (!Object.keys(obj).includes(key)) {
        return ' productionItemStateDefault';
    }

    return obj[key];
}

class GridProducao extends React.Component {
    state = {
        selecionados: [],
    };

    // Para apagar eventos
    componentWillUnmount() {
    }

    // Modifica rapidamente estilo de um array de elementos
    changeColor = (elements, style) => {
        for (const element of elements) {
            for (const key in style) {
                element.style[key] = style[key];
            }
        }
    }

    buildCellFromItemVenda = ({keyIds, itemVenda, itemProducao, orelhinhaDePapel = false}) => {
        if (itemProducao) {
            itemProducao = itemVenda.itemProducaoList.filter(item => getIdFromEntity(item) === getIdFromEntity(itemProducao))[0];
        }

        const piscar = itemProducao
            ? itemProducao.itemNaoVisto
            : [false , ...itemVenda.itemProducaoList.map((item) => item.itemNaoVisto)].reduce((piscar, itemNaoVisto) => piscar || itemNaoVisto);

        return <BuildCellFromItemVenda
            key={'__item_venda_' + getIdFromEntity(itemVenda) + '_' + (getIdFromEntity(itemProducao) || '') + '_'}
            keyIds={keyIds}

            itemVendaId={getURIFromEntity(itemVenda)}
            itemVenda={itemVenda}

            itemProducaoId={getURIFromEntity(itemProducao)}
            itemProducao={itemProducao}

            allowDrop={this.allowDrop}
            addingToDelivery={this.state.addingToDelivery}
            stateToColor={stateToColor}
            ctrlPressed={this.state.ctrlPressed}
            // touchVersion={this.touchVersion}

            simpleTapSelection={this.props.simpleTapSelection}
            updateSimpleTapSelection={this.props.updateSimpleTapSelection}

            delivery={this.props.delivery}
            updateDelivery={this.props.updateDelivery}
            removeDelivery={this.props.removeDelivery}

            modoSelecao={this.props.modoSelecao}
            atualizarSelecao={this.props.atualizarSelecao}
            selecionados={this.props.selecionados}

            piscar={piscar}

            orelhinhaDePapel={orelhinhaDePapel}
        />
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        let itemVendaList = [];
        let itemProducaoList = [];

        itemVendaList.push(...this.props.itemVendaList);

        this.props.itemProducaoList.forEach(itemProducao => {
            if (itemProducao.itemVenda) {
                itemProducaoList.push({ itemVenda: itemProducao.itemVenda, itemProducao: itemProducao });
            }
        });

        const keyIds = this.props.itemProducao
            ? getIdFromEntity(this.props.itemProducao) + '_'
            : [...itemProducaoList.map((item) => getIdFromEntity(item)), '_'].reduce((ids, id) => `${ids}_${id}_`);

        return <>
            <div className={`pure-g`}>
                {this.props.agruparProdutosVenda
                    ? itemVendaList.map((itemVenda) => {
                        return this.buildCellFromItemVenda({key: `__item_venda_item_producao_${keyIds}`, keyIds, itemVenda});
                    })
                    : itemProducaoList.map((item) => {
                        // const id = getIdFromEntity(item);
                        // if ([3,4,13].includes[id]) {
                        //     debugger;
                        // }

                        return this.buildCellFromItemVenda({key:`__item_venda_item_producao_${keyIds}`, keyIds, itemVenda: item.itemVenda, itemProducao: item.itemProducao, orelhinhaDePapel: (itemProducaoList.filter((itemProd) => getIdFromEntity(itemProd.itemVenda.venda) === getIdFromEntity(item.itemVenda.venda)).length > 1)});
                    })
                }
            </div>
        </>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * 
 * @param {Object} state 
 */
const mapStateToProps = state => ({
    simpleTapSelection: state.simpleTapSelectionReducer,
    ...state.idiomaReducer,
    delivery: state.deliveryReducer,
    ...state.producaoReducer,
});

const mapDispatchToProps = dispatch => ({
    appDialogShow:  (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
        dispatch(appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
    clearDelivery: (id) => dispatch(clearDeliveryAction(id)),
    dispatch,
    updateDelivery: (id) => dispatch(updateDeliveryAction(id)),
    removeDelivery: (id) => dispatch(removeDeliveryAction(id)),

    updateSimpleTapSelection: (value) => dispatch(updateSimpleTapSelectionAction(value)),
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(GridProducao);
