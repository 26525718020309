import criarReducer from "../factory/criarReducer";

export const bairroReducerActionTypes = {
  POPULAR_BAIRROS: "POPULAR_BAIRROS"
};

/**
 * 
 * @type {import("./types").bairroReducerTipo}
 */
const estadoInicial = {};

/**
 * 
 * @type {import("../../../../../store/actions/acaoTemplate").acoesNomeadasTipo<import("./types").bairroReducerTipo>}
 */
const acoesNomeadas = {
    /**
     * 
     * @param {import("./types").bairroReducerTipo} bairros 
     * @returns {import("./types").bairroReducerTipo}
     */
    [bairroReducerActionTypes.POPULAR_BAIRROS]: (bairros) => {
        /**
         * 
         * @type {import("./types").bairroReducerTipo}
         */
        const newState = {
            ...bairros
        }

        return newState;
    }
};

/**
 * 
 * @param {import("./types").bairroReducerTipo} estado 
 * @param {import("../factory/criarReducer").acaoTipo} acao 
 * @returns 
 */
export default function(estado = estadoInicial, acao) {
  return criarReducer(estado, acao, acoesNomeadas);
}
