import React from 'react';
import { connect } from 'react-redux';

import { isEmpresaPainel } from "../../utils/CompanyUtils"
import { isNonEmptyArray } from "../../utils/ComparatorsUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../utils/reduxUtils/reduxUtils"
import { getURIFromEntity } from "../../utils/URIUtils"

import * as pagamentoVendasActions from '../../store/actions/controleVenda/pagamentoVendasAction';

import InputCustomizado from '../UI/Input/InputCustomizado.js';
import PagamentoVendaTable from './PagamentoVendaTable';
import WidthAwareDiv from '../UI/WidthAwareDiv/WidthAwareDiv';

import "../vendas/pagamento/TelaPagamentoVendasContent.css";

/**
 * Conteúdo da tela de cancelamento de pagamento de venda(s).
 */
class TelaCancelPagamentoVendasContent extends React.Component {

    constructor() {
        super();
        log('TelaCancelPagamentoVendasContent constructor');
        this.ismounted = false;

        this.state = {
            mobile: window.innerWidth <= 720,
        };

        window.addEventListener('resize', () => {
            if (window.innerWidth > 720) {
                this.setState({ mobile: false });
            } else {
                this.setState({ mobile: true });
            }
        });
    }

    /**
     * Monta o *check box* correspondente a todos os produtos.
     */
    buildCheckBoxHeader = () => <InputCustomizado
        tabIndex={this.props.tabIndex}
        ref={checkbox => this.checkBoxHeader = getReduxWrappedComponent(checkbox)}
        id='HeaderCheckbox'
        type='checkbox'
        name='HeaderCheckbox'
        handleInputValidado={() => {
            let checked = isNonEmptyArray(this.props.salePaymentToCancelList || []);
            if (checked) {
                this.props.clearSalePaymentToCancel();
            } else {
                this.props.addAllSalePaymentToCancel();
            }
        }}
    />;

    /**
     * Função que cria o Header dos itens
     */
    buildHeader = checkboxHeader => {

        log('TelaCancelPagamentoVendasContent buildHeader', checkboxHeader);

        return <div className='table-cancel table-columns-header'>
            <div className='table-cell check ButtonAlign'> {checkboxHeader} </div>
            <div className='table-cell day TextAlign'> {getStrings().date} </div>
            <div className='table-cell hour TextAlign'> {getStrings().timeHourMinute} </div>
            <div className='table-cell saleSourceSale TextAlign'> {
                isEmpresaPainel(this.props.ramoEmpresa)
                    ? getStrings().sale()
                    : getStrings().saleSourceAndSaleTemplate(getStrings().saleContainer(), getStrings().sale())
            }</div>
            <div className='table-cell product TextAlign'> {getStrings().product} </div>
            <div className='table-cell amount NumberAlign'> {getStrings().payAmount}</div>
            <div className='table-cell total NumberAlign'> {getStrings().payTotalAndComission} </div>
            <div className='table-cell amount NumberAlign'> {getStrings().discount}</div>
            <div className='table-cell total NumberAlign'> {getStrings().total} </div>
        </div>;
    };

    /**
     * Monta as tabelas de totais, de itens ou de pagamentos.
     */
    buildTable = (className, title, header, itemList) => {
        return <div className={`table ${className}`}>
            <div className={'table-header Bold'} >{title}</div>
            {header}
            {itemList}
        </div>;
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Carrega os tipos disponíveis e também define os valores iniciais das opções conforme cadastro carregado.
     */
    componentDidMount() {
        log('TelaCancelPagamentoVendasContent componentDidMount');
        this.ismounted = true;
    }

    /**
     * Metodo executado após a atualização do componente.
     * Marca o check box geral se o de algum pagamento estiver marcado.
     */
    componentDidUpdate() {
        log('BuildCellFromPagamentoVendaOrigem componentDidUpdate');
        this.checkBoxHeader.inputComponent.checked = isNonEmptyArray(this.props.salePaymentToCancelList || []);
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('TelaCancelPagamentoVendasContent render');

        return <WidthAwareDiv>
            {/* Tabela de produtos a pagar */}
            {this.buildTable(
                'itens-venda-pagar',
                getStrings().payments,
                this.buildHeader(this.buildCheckBoxHeader()),
                (this.props.pagamentoVendaList || []).map(pagamentoVenda => <PagamentoVendaTable
                    key={getURIFromEntity(pagamentoVenda)}
                    pagamentoVenda={pagamentoVenda}
                />)
            )}
        </WidthAwareDiv >;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.idiomaReducer,

    pagamentoVendaList: state.cadastroReducer.cadastroList,

    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,

    salePaymentToCancelList: state.pagamentoVendasReducer.salePaymentToCancelList,

    tabIndex: state.appReducer.getTabIndex()
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    addAllSalePaymentToCancel: () => dispatch(pagamentoVendasActions.addAllSalePaymentToCancel()),

    clearSalePaymentToCancel: () => dispatch(pagamentoVendasActions.clearSalePaymentToCancel())
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TelaCancelPagamentoVendasContent);
