//@ts-check
const raiz = `/`;
const app = `/apps`;
const cadastro = `${app}/cadastro`;
const carrinho = `${app}/carrinho`;
const compra = `${app}/compra`;
const editar = `${app}/editar`;
const pedidos = `${app}/pedidos`;
const redefinirSenha = `${app}/redefinirSenha`;
const produtosComTamanho = `${app}/produtosComTamanho`;

export default {
    app,
    cadastro,
    carrinho,
    compra,
    editar,
    pedidos,
    raiz,
    redefinirSenha,
    produtosComTamanho,
}
