//@ts-check
import "./PedidosTela.css";

import React from 'react';
import { Botao } from '../../../../nucleo/nucleo';
import { connect } from 'react-redux';
import PedidoCard from '../../../cards/PedidoCard/PedidoCard';
import { ClienteHttp, EntidadeUtils, LocalidadeUtils, NavegacaoUtils, ProdutoUtils } from '../../../../nucleo/utils/utils';
import { atualizarPedido } from '../../../../nucleo/redux/pedidos/pedidosActions';
import { mostrarOverlay, esconderOverlay } from '../../../../nucleo/redux/overlay/overlayActions';
import CarregamentoOverlay from '../../../overlay/CarregamentoOverlay/CarregamentoOverlay';
import tiposDeAcoes from '../../../../nucleo/redux/usuario/usuarioActions';
import criarAction from '../../../../nucleo/redux/factory/criarAction';
import { getEmpresaApp } from '../../../../../../utils/StorageUtils/SessionStorageUtils';
import apelidos from '../../../../rotas/apelidos';
import MostrarMensagemContatoEmpresaDialog from '../../../dialogs/MostrarMensagemContatoEmpresaDialog/MostrarMensagemContatoEmpresaDialog';
import { mostrarDialog } from '../../../../nucleo/redux/dialog/dialogActions';
import { definirBotaoVoltar } from '../../../../nucleo/redux/botoes/botaoVoltar/botaoVoltarActions';

class PedidosTela extends React.Component {

  componentDidMount() {
    this.props.dispatch(definirBotaoVoltar({
      botaoVoltarCustomizado: () => { this.props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`)) }
    }));

    this.props.dispatch(mostrarOverlay({componente: 
      /**
       * 
       * @param {any} props 
       * @returns 
       */
      (props) => <CarregamentoOverlay {...props} />}));

    ClienteHttp.obterClienteHttp().get(ClienteHttp.getApi(`vendas/findToApp`), ClienteHttp.getHeaders())
    .then((resposta) => {

        // Apaga notificação de novo estado venda
        if (this.props.webAppUsuarioReducer.usuario.mudouEstadoVenda) {
          this.props.dispatch(criarAction({...this.props.webAppUsuarioReducer, usuario: {...this.props.webAppUsuarioReducer.usuario, mudouEstadoVenda: false}}, tiposDeAcoes.ATUALIZAR_USUARIO));
        }

        const listaPedidos = ((resposta.data || {}).content || []);

        for (const pedido of listaPedidos) {
          
          const id = pedido._links.self.href.replace(ClienteHttp.getApi('vendas/'), '');
          const listaItens = pedido.itemVendaList;
          /**
           * 
           * @type {import("../../../../nucleo/redux/carrinho/types").carrinhoProdutosTipo}
           */
          let produtos = {};

          for (const item of listaItens) {
            /**
             * 
             * @type {any}
             */
            const id = `${EntidadeUtils.getIdFromEntity(item.produto)}` || '-1';

            produtos = {
              ...produtos,
              [id]: {
                key: item.produto.codigo,
                nome: item.nomeProduto || item.produto.nome,
                fonteImagem: '',
                fonteImagemTipo: item.produto.imagemFile,
                preco: item.precoUnitario,
                quantidadeEmEstoque: ProdutoUtils.obterQuantidadeEmEstoque(),
                quantidade: item.quantidade,
                valorTotal: item.valorTotal,
                observacao: item.observacao,
              },
            }
          }

          /**
           * 
           * @type {import("../../../../nucleo/utils/PedidoUtils/types").estadoDosPedidosNomeTipo}
           */
          const nomeEstado = pedido.estadoItensVenda;

          this.props.dispatch(atualizarPedido({
            id: id,
            key: id,
            dataDoPedido: pedido.dataHoraInicial,
            entregaVenda: pedido.entregaVenda,
            valorTotal: pedido.valorTotal,
            estado: nomeEstado,
            motivoCancelamento: pedido.motivoCancelamento,
            endereco: pedido.enderecoUsuario ? {
              pais: {
                codigo: pedido.enderecoUsuario.pais._links.self.href.replace(ClienteHttp.getApi('paises/'), ''),
                iso: pedido.enderecoUsuario.pais.iso,
                nome: pedido.enderecoUsuario.pais.nome,
              },
              estado: pedido.enderecoUsuario.uf,
              cep: pedido.enderecoUsuario.cep,
              cidade: pedido.enderecoUsuario.municipio,
              codigoCidade: pedido.enderecoUsuario.codigoMunicipioIbge,
              bairro: pedido.enderecoUsuario.bairro,
              rua: pedido.enderecoUsuario.endereco,
              numero: pedido.enderecoUsuario.numero,
              complemento: pedido.enderecoUsuario.complemento,
            } : {
              pais: {
                codigo: pedido.usuarioCliente.dadosAdicionais.pais._links.self.href.replace(ClienteHttp.getApi('paises/'), ''),
                iso: pedido.usuarioCliente.dadosAdicionais.pais.iso,
                nome: pedido.usuarioCliente.dadosAdicionais.pais.nome,
              },
              estado: pedido.usuarioCliente.dadosAdicionais.uf,
              cep: pedido.usuarioCliente.dadosAdicionais.cep,
              cidade: pedido.usuarioCliente.dadosAdicionais.municipio,
              codigoCidade: pedido.usuarioCliente.dadosAdicionais.codigoMunicipioIbge,
              bairro: pedido.usuarioCliente.dadosAdicionais.bairro,
              rua: pedido.usuarioCliente.dadosAdicionais.endereco,
              numero: pedido.usuarioCliente.dadosAdicionais.numero,
              complemento: pedido.usuarioCliente.dadosAdicionais.complemento,
            },
            formaPagamento: (this.props.webAppPedidosReducer[id] || {formaPagamento: ''}).formaPagamento,
            numeroDeParcelas: 1,
            produtos: produtos,
            observacao: pedido.observacao,
          }))
        }
      })
      .catch(() => {})
      .finally(() => {
        const location = this.props.location;
        
        this.props.dispatch(esconderOverlay());
        
        if (location?.state?.mostrarMensagemContatoEmpresa) {
            this.props.dispatch(mostrarDialog({conteudo: () => <MostrarMensagemContatoEmpresaDialog />}));
        }
      });
  }

  render() {
    const webAppPedidosReducer = this.props.webAppPedidosReducer;

    return <div key='tela' id='pedidos-tela' className='tela'>
      <Botao className='voltar-tela-produtos' onClick={() => { this.props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`)) }}>
        {LocalidadeUtils.obterTraducao().phrases.goBackToProductsScreen}
      </Botao>
      {Object.keys(webAppPedidosReducer)
        .reverse()
        .map((id) =>
          <PedidoCard key={`__pedido_card_${id}__`} pedido={{id, ...webAppPedidosReducer[id]}} webAppFormasPagamentoReducer={this.props.webAppFormasPagamentoReducer} />
        )}
    </div>;
  }
}

/**
 * 
 * @param {import("../../../../nucleo/redux/types").webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  webAppFormasPagamentoReducer: state.webAppFormasPagamentoReducer,
  webAppPedidosReducer: state.webAppPedidosReducer,
  webAppUsuarioReducer: state.webAppUsuarioReducer,
});

/**
 * 
 * @param {import("../../../../../../store/actions/acaoTemplate").dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PedidosTela);
