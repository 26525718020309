import 'core-js';

// era usado para funcionar as câmeras no ie
//import 'video.js'; 

import './utils/getusermedia_polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'

import './index.css';
import 'typeface-roboto';

import App from './App';

import store, { history } from './store/store.js';

import cssVars from 'css-vars-ponyfill';

cssVars();

/**
 * Render inicial da aplicação. A partir desse toda a aplicação é carregada. 
 */
const app = (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));