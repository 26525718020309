import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getStrings } from "../../../utils/LocaleUtils";
import { log } from "../../../utils/LogUtils";
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils";

import * as signUpActions from "../../../store/actions/signUpAction";

import InputCustomizado from "../../UI/Input/InputCustomizado";
import SignUpUserFormStepPersonalInfo from "./SignUpUserFormStepPersonalInfo";
import SignUpUserFormStepAddressInfo from "./SignUpUserFormStepAddressInfo";
// eslint-disable-next-line
import dispatchTipo from "../../../store/actions/acaoTemplate";

import "./SignUp.css";
import { updateFromZipCode } from "../../../store/actions/cadastroEmpresaAction";
import { SignUpUserFormType } from "./types.d"

/**
 * Campos do cadastro de usuário.
 */
class SignUpUserForm extends SignUpUserFormType {
  state = { step: 0 };
  inputIsSetted = false;

  /**
   * Método executado ao atualizar campo de data de nascimento.
   */
  updateBirthday = value => {
    if (this.props.updateReducer) {
      // Se for cadastro de empresa
      if (this.props.companyForm) {
        // Envia método para atualizar valor usando o valor recebido pelo callback ao invés do valor lido do campo,
        // que pode demorar um tempo para estar atualizado.
        this.props.updateReducer(dados => dados.dadosAdicionais.dataNascimento = this.dataNascimento.inputField.formatValue(value));
      }
      // Se não, faz igual como era antes.
      else if (this.props.updateReducer) {
        this.props.updateReducer();
      }
    }
    if (this.props.handleBirthdayInput) {
      this.props.handleBirthdayInput(["dadosAdicionais", "dataNascimento"], value, this.dataNascimento.inputField.formatValue(value));
    }
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidMount() {
    log("SignUpUser componentDidMount");

    if (this.aceitarNotificacoes?.inputComponent) {
      this.aceitarNotificacoes.inputComponent.checked = true;
    }
  }

  componentDidUpdate() {

    if (this.aceitarNotificacoes?.inputComponent
      && this.aceitarNotificacoes.inputComponent.checked !== this.props.aceitarNotificacoes) {
      this.aceitarNotificacoes.inputComponent.checked = this.props.aceitarNotificacoes;
    }
  }

  render() {
    log("SignUpUser render");

    return <div id="campos-usuario">

      <SignUpUserFormStepPersonalInfo
        companyForm={this.props.companyForm}
        f3={this.props.f3}
        novoUsuarioCargo={this.props.novoUsuarioCargo}
      />

      <SignUpUserFormStepAddressInfo
        companyForm={this.props.companyForm}
        novoUsuarioCargo={this.props.novoUsuarioCargo}
      />

      {this.props.step === 0
        ? <InputCustomizado
          id="aceitarNotificacoes"
          name="aceitarNotificacoes"
          type="checkbox"
          placeholder={""}
          label={getStrings().acceptNotifications}
          tabIndex={this.props.tabIndex}
          ref={input => { if (input) { this.aceitarNotificacoes = getReduxWrappedComponent(input) } }}
          handleInputValidado={() => this.props.handleTextInput(["parametrosUsuario", "aceitarNotificacoes"], this.aceitarNotificacoes.inputComponent.checked ? true : false)}
        />
        : null
      }
    </div>;
  }
}

SignUpUserForm.propTypes = {

  /**
   * Função executada quando algum dado é atualizado em um dos campos de texto do formulário.
   */
  handleTextInput: PropTypes.func,

  /**
   * Função executada quando algum dado é alterado em algum campo do formulário.
   */
  updateReducer: PropTypes.func,
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state
 */
function mapStateToProps(state) {
  const props = {
    ...state.idiomaReducer
    , step: state.signUpReducer.step
    , aceitarNotificacoes: state.signUpReducer.formData.parametrosUsuario.aceitarNotificacoes
    , tabIndex: state.appReducer.getTabIndex()
  };

  return props;
}

/**
 * Mapeia as ações para utilizar localmente.
* @param {dispatchTipo} dispatch
*/
function mapDispatchToProps(dispatch) {
  const props = {
    /**
     * 
     * @param {string[]} keyArray 
     * @param {any} value 
     * @returns {void}
     */
    handleTextInput: (keyArray, value) =>
      dispatch(signUpActions.updateFormData(keyArray, value))
    , setSuggestions: (suggestions) => 
      dispatch(signUpActions.setSuggestions(suggestions))
    //, setToken: reCaptchaToken => dispatch(signUpActions.setToken(reCaptchaToken))
    , signUpSubmit: usuario => 
      dispatch(signUpActions.signUpSubmit(usuario))
    , setWarning: (warning) => 
      dispatch(signUpActions.setWarning(warning))
    //, validaConfirmacaoSenhaHandler: (senha, confirmacaoSenha) => dispatch(signUpActions.validaConfirmacaoSenhaHandler(senha, confirmacaoSenha))
  , updateFromZipCode: (zipCode, state, city, neighborhood, street) =>
    dispatch(updateFromZipCode(zipCode, state, city, neighborhood, street))
  }

  return props;
}

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(SignUpUserForm);