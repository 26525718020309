import React from 'react';
import { connect } from 'react-redux';

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { naturalSort } from "../../../utils/SortUtils"

import { cadastroBuildOptionsFromTipoOrigemVenda } from '../../../utils/SelectUtils';

import * as cadastroActions from '../../../store/actions/cadastroAction';
import * as cadastroSetupActions from '../../../store/actions/cadastroSetupAction';

import { STATE_CADASTRO_FORM } from '../../../store/reducers/cadastroReducer';

import BarraAcoesForm from '../barraAcao/BarraAcoesForm';
import HelpParagraph from '../../UI/HelpParagraph/HelpParagraph';
import InputCustomizado from '../../UI/Input/InputCustomizado';
import WidthAwareDiv from '../../UI/WidthAwareDiv/WidthAwareDiv';

import "./CadastroSetup.css";

class CadastroSetup extends React.Component {

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Atualiza o campo de quantidade.
     */
    componentDidMount() {
        log('CadastroSetup componentDidMount');

        // Define as opções disponíveis no campo "Tipo"
        this.tipoOrigemVendaList.inputComponent.updateOptions(naturalSort([
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: 'Cartão' }),
            cadastroBuildOptionsFromTipoOrigemVenda(false, { codigo: 'Mesa' }),
        ], 'label'));

        this.componentDidUpdate();
    }

    /**
     * Método executado APÓS a atualização do componente.
     * Atualiza os campos.
     */
    componentDidUpdate() {
        log('CadastroSetup componentDidUpdate');
        ['numberInitial', 'numberFinal'].forEach(input => this[input].setMaskValue(this.props[input]));
        ['codePrefix', 'codePreview', 'codeSuffix', 'namePrefix', 'namePreview', 'nameSuffix', 'numberPadding'].forEach(input => this[input].inputComponent.value = this.props[input]);
    }

	/**
	 * Método executado ANTES de "DESMONTAR" o componente.
	 * Limpa o estado.
	 */
    componentWillUnmount() {
        log('CadastroSetup componentWillUnmount');
        this.props.clearState();
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        log('CadastroSetup render');

        return <div style={{ height: '100%' }}>

            <WidthAwareDiv>
                <HelpParagraph children={getStrings().saleSourceBatchHelp} />
            </WidthAwareDiv>
            {/* <form onSubmit={this.handleGravar} method='post' style={{ height: '100%' }}> */}

            <BarraAcoesForm
                // Não permite enviar se código ou nome resultante possuírem mais caracteres que o permitido ou se o tipo não estiver selecionado
                addButtonDisabled={(!this.props.codeLengthValid) || (!this.props.nameLengthValid) || (!this.props.tipoOrigemVenda)}
                attractSubmit={this.props.codeLengthValid && this.props.nameLengthValid && (![null,undefined].includes(this.props.tipoOrigemVenda))}
                handleListar={() => this.props.cadastroSwitchUI(STATE_CADASTRO_FORM, this.props.cadastroDados)}
                handleSubmit={() => this.props.saveOrigemVendaBatch({
                    codePrefix: this.props.codePrefix,
                    codeSuffix: this.props.codeSuffix,
                    namePrefix: this.props.namePrefix,
                    nameSuffix: this.props.nameSuffix,
                    numberFinal: this.props.numberFinal,
                    numberInitial: this.props.numberInitial,
                    numberPadding: this.props.numberPadding,
                    tipoOrigemVenda: this.props.tipoOrigemVenda
                })}
                isShowConfig={false}
                isSubmit={true}
            />

            <WidthAwareDiv>
                <div id='cadastro-setup' className='sub-form'>
                    <InputCustomizado
                        ref={input => this.tipoOrigemVendaList = getReduxWrappedComponent(input)}
                        id='tipo'
                        required={true}
                        inputType='singleSelect'
                        name='tipo'
                        label={getStrings().type}
                        placeholder={getStrings().saleSourceTypePlaceholder()}
                        onChange={() => setTimeout(() => this.props.setValue(((this.tipoOrigemVendaList.inputComponent.getValue() || {}).value || {}).codigo, 'tipoOrigemVenda'), 0)}
                        sort
                    />
                    <div className='flex'>
                        <InputCustomizado
                            className='flex-item'
                            ref={input => { if (input) { this.numberInitial = getReduxWrappedComponent(input) } }}
                            id='numberInitial'
                            required={true}
                            name='numberInitial'
                            type='text'
                            inputType='masked'
                            validacaoDados='numeroInteiro'
                            label={getStrings().initialCode}
                            placeholder={getStrings().initialCodePlaceholder}
                            scale={0}
                            padFractionalZeros={false}
                            // Atualiza a quantidade do produto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                            onInput={() => {
                                if (this.numberInitial.getMaskValue() || (this.numberInitial.getMaskValue() === 0)) {
                                    this.props.setValue(this.numberInitial.getMaskValue(), 'numberInitial');
                                }
                            }}
                            // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera a quantidade do produto para zero.
                            // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                            onBlur={() => {
                                if ((!this.numberInitial.getMaskValue()) && (this.numberInitial.getMaskValue() !== 0)) {
                                    this.props.setValue(0, 'numberInitial');
                                }
                            }}
                        />
                        <InputCustomizado
                            ref={input => { if (input) { this.numberFinal = getReduxWrappedComponent(input) } }}
                            id='numberFinal'
                            required={true}
                            name='numberFinal'
                            type='text'
                            inputType='masked'
                            validacaoDados='numeroInteiro'
                            label={getStrings().finalCode}
                            placeholder={getStrings().finalCodePlaceholder}
                            scale={0}
                            padFractionalZeros={false}
                            // Atualiza a quantidade do produto no estado a cada dígito digitado, a menos que o campo contenha um número inválido.
                            onInput={() => {
                                if (this.numberFinal.getMaskValue() || (this.numberFinal.getMaskValue() === 0)) {
                                    this.props.setValue(this.numberFinal.getMaskValue(), 'numberFinal');
                                }
                            }}
                            // Ao sair do campo, se ele possui um valor inválido ou estiver vazio, altera a quantidade do produto para zero.
                            // Isso não pode ser feito antes, senão atrapalha a digitação do campo.
                            onBlur={() => {
                                if ((!this.numberFinal.getMaskValue()) && (this.numberFinal.getMaskValue() !== 0)) {
                                    this.props.setValue(0, 'numberFinal');
                                }
                            }}
                        />
                    </div>
                    <InputCustomizado
                        ref={input => { if (input) { this.numberPadding = getReduxWrappedComponent(input) } }}
                        id='numberPadding'
                        name='numberPadding'
                        type='text'
                        maxLength='1'
                        label={getStrings().numberPadding}
                        handleInputValidado={() => this.props.setValue(this.numberPadding.inputComponent.value, 'numberPadding')}
                        placeholder={getStrings().numberPaddingPlaceholder}
                    />
                    <div className='flex'>
                        <InputCustomizado
                            ref={input => { if (input) { this.codePrefix = getReduxWrappedComponent(input) } }}
                            id='codePrefix'
                            name='codePrefix'
                            type='text'
                            maxLength='20'
                            label={getStrings().codePrefix}
                            handleInputValidado={() => this.props.setValue(this.codePrefix.inputComponent.value, 'codePrefix')}
                            placeholder={getStrings().codePrefixPlaceholder}
                        />
                        <InputCustomizado
                            ref={input => { if (input) { this.codeSuffix = getReduxWrappedComponent(input) } }}
                            id='codeSuffix'
                            name='codeSuffix'
                            type='text'
                            maxLength='20'
                            label={getStrings().codeSuffix}
                            handleInputValidado={() => this.props.setValue(this.codeSuffix.inputComponent.value, 'codeSuffix')}
                            placeholder={getStrings().codeSuffixPlaceholder}
                        />
                    </div>
                    <div className='flex'>
                        <InputCustomizado
                            ref={input => { if (input) { this.namePrefix = getReduxWrappedComponent(input) } }}
                            id='namePrefix'
                            name='namePrefix'
                            type='text'
                            maxLength='40'
                            label={getStrings().namePrefix}
                            handleInputValidado={() => this.props.setValue(this.namePrefix.inputComponent.value, 'namePrefix')}
                            placeholder={getStrings().namePrefixPlaceholder}
                        />
                        <InputCustomizado
                            ref={input => { if (input) { this.nameSuffix = getReduxWrappedComponent(input) } }}
                            id='nameSuffix'
                            name='nameSuffix'
                            type='text'
                            maxLength='40'
                            label={getStrings().nameSuffix}
                            handleInputValidado={() => this.props.setValue(this.nameSuffix.inputComponent.value, 'nameSuffix')}
                            placeholder={getStrings().nameSuffixPlaceholder}
                        />
                    </div>
                    <InputCustomizado
                        ref={input => { if (input) { this.codePreview = getReduxWrappedComponent(input) } }}
                        id='codePreview'
                        code='codePreview'
                        type='text'
                        label={getStrings().codePreview}
                        placeholder={getStrings().codePreviewPlaceholder}
                        disabled
                    />
                    <InputCustomizado
                        ref={input => { if (input) { this.namePreview = getReduxWrappedComponent(input) } }}
                        id='namePreview'
                        name='namePreview'
                        type='text'
                        label={getStrings().namePreview}
                        placeholder={getStrings().namePreviewPlaceholder}
                        disabled
                    />
                </div>
            </WidthAwareDiv>
            {/* </form> */}
        </div>;
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.cadastroReducer,
    ...state.cadastroSetupReducer,
    ...state.idiomaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    cadastroSwitchUI: (state, cadastroDados, keepLoading, formMounted, operation) =>
        dispatch(cadastroActions.cadastroSwitchUI(state, cadastroDados, keepLoading, formMounted, operation)),
    clearState: () => dispatch(cadastroSetupActions.clearState()),
    saveOrigemVendaBatch: config => dispatch(cadastroSetupActions.saveOrigemVendaBatch(config)),
    setValue: (value, position) => dispatch(cadastroSetupActions.setValue(value, position))
});

/**
* Exporta o último argumento entre parênteses.
*/
export default connect(mapStateToProps, mapDispatchToProps)(CadastroSetup);
