import React from "react"
import { seletorItemType } from "../seletores/types"

export type entradaPropsType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    seletores?: Array<seletorItemType>,
    label?: string,
    obrigatorio?: boolean,
    onChange?: (event: {target: {value: string, name: string, obrigatorio?: boolean}}) => void | undefined,
};

export class EntradaType extends React.Component<entradaPropsType> {};