//@ts-check
import "./BalaoTexto.css"

import React from "react"

/**
 * 
 * @param {import('./types').balaoTextoPropsType} props 
 * @returns {JSX.Element}
 */
function BalaoTexto(props) {
    return <div style={props.style} className={"balao-texto " + (props.className || "")}>
        {props.children}
    </div>;
}

export default BalaoTexto;