import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { roundNumber } from "../../../utils/NumberUtils"

import { NEW_SALE_ITEM_AMOUNT_OPERATION_ADD, NEW_SALE_ITEM_AMOUNT_TYPE_UNIT } from "../../../store/reducers/controleVenda/manutencao/manutencaoVendaReducer"
import { ESTADO_ITEM_VENDA_AGUARDANDO } from "../../../store/reducers/controleVenda/controleVendaReducer"
import * as manutencaoVendaActions from "../../../store/actions/controleVenda/manutencao/manutencaoVendaAction"

import CombinacoesGrupo from "./CombinacoesGrupo"

import "./CombinacoesTela.css";

class CombinacoesTela extends React.Component {

  state = {
    produtosSelecionados: {},
    quantidade: 1,
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false)
  }

  handleClick = (e) => {
    if (!this.node.contains(e.target)) {
      const element = document.getElementById('dialogs')
      if (!element || !element.contains(e.target)) {
        this.handleOutsideClick()
      }
    }
  }

  handleOutsideClick = () => {
    //this.props.fecharTela()
  }

  updateProdutosSelecionados = (key, value) => {
    let produtosSelecionados = this.state.produtosSelecionados

    if (value === null) {
      delete produtosSelecionados[key]
    }
    else {
      produtosSelecionados = {
        ...produtosSelecionados,
        [key]: value
      }
    }

    this.setState({ produtosSelecionados })
  }

  calculaTotais = () => {
    let precoUnitario
    const quantidade = this.state.quantidade
    const precoProdutoCombinado = this.props.grupo.precoProdutoCombinado
    const produtosSelecionados = Object.values(this.state.produtosSelecionados)

    if (precoProdutoCombinado && precoProdutoCombinado === 'MAIS_CARO') {
      precoUnitario = produtosSelecionados.reduce((maiorValor, produto) => produto.valor > maiorValor ? produto.valor : maiorValor, 0)
    }
    else {
      const precoTotal = produtosSelecionados.reduce((totalValor, produto) => totalValor + (produto.valor * produto.quantidade), 0)
      const quantidadeTotal = produtosSelecionados.reduce((totalQuantidade, produto) => totalQuantidade + produto.quantidade, 0)
      precoUnitario = roundNumber((precoTotal / quantidadeTotal), 2)
    }

    precoUnitario = !precoUnitario || Number.isNaN(precoUnitario) ? 0 : precoUnitario

    const valorTotal = roundNumber((precoUnitario * quantidade), 2)

    return {
      quantidade,
      precoUnitario,
      valorTotal,
    }
  }

  botaoAdicionarClick = () => {

    const produtosSelecionados = Object.values(this.state.produtosSelecionados)

    if (!produtosSelecionados?.length) {
      return
    }

    let nomeProduto = `${this.props.grupo.nome} ${this.props.tamanhoSelecionado.tamanho.nome}`
    let codigo = `COMB_${this.props.grupo.codigo}_${this.props.tamanhoSelecionado.tamanho.codigo}`

    if (produtosSelecionados.length === 1) {
      codigo += `_${produtosSelecionados[0].codigo}`
      nomeProduto += ` ${produtosSelecionados[0].nome}`
    }
    else {
      const fracoes = produtosSelecionados.reduce((total, produto) => total + produto.quantidade, 0)
      produtosSelecionados.forEach(produto => {
        codigo += `_${produto.codigo}`
        nomeProduto += ` (${produto.quantidade}/${fracoes} ${produto.nome})`
      })
    }

    const { quantidade, precoUnitario, valorTotal } = this.calculaTotais()

    const newSaleItem = {
      sequence: 0,
      code: codigo,
      itemVenda: {
        estado: ESTADO_ITEM_VENDA_AGUARDANDO,
        livre: false,
        combinado: true,
        observacao: this.observacaoElement.value,
        precoUnitario: precoUnitario,
        produto: {
          codigo: codigo,
          preco: precoUnitario,
          nome: nomeProduto
        },
        nomeProduto: nomeProduto,
        quantidade: quantidade,
        valorTotal: valorTotal
      }
    }

    this.props.addNewSaleItemOrSetAmount(NEW_SALE_ITEM_AMOUNT_OPERATION_ADD, newSaleItem, NEW_SALE_ITEM_AMOUNT_TYPE_UNIT, quantidade, null)

    this.props.fecharTela()
  }

  BarraTitulo = () => {
    return <div className='barra-titulo'>
      <h2>{`${this.props.grupo.nome} ${this.props.tamanhoSelecionado.tamanho.nome}`}</h2>
      <button className='botao-fechar' onClick={this.props.fecharTela}>
        x
      </button>
    </div>
  }

  CampoObservacao = () => {
    return <div className='campo-observacao'>
      <textarea id='observacao' name='observacao' maxLength='300' ref={textarea => this.observacaoElement = textarea}
        placeholder={getStrings().observationMaxSizePlaceholder} style={{ resize: 'none', height: '5.2em' }} />
    </div>
  }

  QuantidadeAdicionar = () => {
    return <div className='quantidade-adicionar'>
      <div className='label-quantidade'>{getStrings().amount}</div>
      <div className='spinner-quantidade'>
        <div style={{ height: '100%', width: 'fit-content', minWidth: '8em' }} className={`input-group${this.props.className ? ` ${this.props.className}` : ''} right`}>
          <button
            className={`quant-button pure-u-6-24 button dark-button font-color decrementa`}
            tabIndex={this.props.tabIndex}
            onClick={() => {
              if (this.state.quantidade > 1) {
                this.setState((state) => ({ quantidade: state.quantidade - 1 }))
              }
            }}
          >
            −
          </button>
          <div className='pure-u-12-24 quantidade' >
            <input className='pure-u-12-24 input-quantidade' value={this.state.quantidade} onChange={
              (event) => {
                let quantidade = parseInt(event?.target?.value || '1')
                quantidade = !quantidade || Number.isNaN(quantidade) ? 1 : quantidade
                this.setState({ quantidade })
              }
            }>
            </input>
          </div>
          <button
            className={`quant-button pure-u-6-24 button dark-button font-color incrementa`}
            tabIndex={this.props.tabIndex}
            onClick={() => this.setState((state) => ({ quantidade: state.quantidade + 1 }))}
          >
            +
          </button>
        </div>
      </div>
    </div>
  }

  BotaoAdicionar = () => {

    const { valorTotal } = this.calculaTotais()

    return <div className={`botao-adicionar ${Object.keys(this.state.produtosSelecionados).length < this.props.tamanhoSelecionado.quantidadeMinimaSabores ? 'desativado' : ''}`}>
      <button className='bar-button-primary-white botao' disabled={!Object.keys(this.state.produtosSelecionados).length} onClick={this.botaoAdicionarClick}>
        {`${getStrings().add} (${getStrings().currencyValue(valorTotal)})`}
      </button>
    </div>
  }

  render() {
    return <div className='combinacoes-tela-modal' ref={node => { this.node = node }} >
      <this.BarraTitulo />
      <div className='conteudo'>
        <CombinacoesGrupo
          tabIndex={this.props.tabIndex}
          newSaleItemList={this.props.newSaleItemList}
          tamanhoSelecionado={this.props.tamanhoSelecionado}
          grupo={this.props.grupo}
          updateProdutosSelecionados={this.updateProdutosSelecionados}
        />
        <this.CampoObservacao />
        <this.QuantidadeAdicionar />
        <this.BotaoAdicionar />
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  ...state.idiomaReducer,
})

const mapDispatchToProps = dispatch => ({
  addNewSaleItemOrSetAmount: (operation, newSaleItem, amountType, amount, observation) => dispatch(manutencaoVendaActions.addNewSaleItemOrSetAmount(operation, newSaleItem, amountType, amount, observation)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CombinacoesTela)