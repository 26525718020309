import React from "react"
import { RouteComponentProps, withRouter, WithRouterProps } from "react-router"
import { connect } from "react-redux"

import { log } from "../../../utils/LogUtils"
import { reduxStateType } from "../../../utils/reduxUtils/reduxStateType"

import * as actionTypes from "../../../store/actions/actionTypes"
import * as cadastroActions from "../../../store/actions/cadastroAction"
import * as cadastroPrintActions from "../../../store/actions/cadastroPrintAction"
import {
  cadastroReducerType,
  STATE_CADASTRO_FORM,
  STATE_CADASTRO_LIST,
  STATE_CADASTRO_PRINT_PREVIEW,
  STATE_CADASTRO_PRINT_SETUP,
  STATE_CADASTRO_SETUP
} from "../../../store/reducers/cadastroReducer"

import * as breadCrumbs from "../../UI/BreadCrumbs/BreadCrumbs"
import I18n from "../../UI/I18n/I18n"
import { dispatchTipo } from "../../../store/actions/acaoTemplate"

export type CadastroHeaderType = {
  handleListar?: () => void,
  style?: React.CSSProperties,
  title?: string,
};

interface CadastroHeaderReduxStateType extends cadastroReducerType {};

interface CadastroHeaderReduxDispatchType {
  cadastroSwitchUI: cadastroActions.cadastroSwitchUIType,
  setValue: cadastroPrintActions.setValueType,
  showPrint: cadastroActions.showPrintType,
};

interface CadastroHeaderTypeWithReducer extends CadastroHeaderType, CadastroHeaderReduxStateType, CadastroHeaderReduxDispatchType {};

type TypeWithRouter = RouteComponentProps<any> & WithRouterProps<any>;

type CadastroHeaderTypeWithRouter = CadastroHeaderTypeWithReducer & TypeWithRouter;

/**
 * Exibe o *header* ou o *bread crumbs* baseado nas variáveis armazenadas no *reducer*.
 */
class CadastroHeader_ extends React.Component<CadastroHeaderTypeWithRouter> {
  /**
   * Retorna o conteúdo do header baseado nas variáveis armazenadas no *reducer*.
   */
  getBreadCrumbs = () => {

    switch (this.props.state) {
      // Tabela de cadastros
      case STATE_CADASTRO_LIST:
        return I18n("registers");
      // Formulário de criação e edição cadastro
      case STATE_CADASTRO_FORM:
        switch (this.props.operation) {
          // Novo cadastro
          case actionTypes.CADASTRO_NEW:
            return breadCrumbs.registers_create(this.props.handleListar);
          // Cadastro existente
          case actionTypes.CADASTRO_EDIT:
            // Busca os dados do cadastro
            let registerName = this.props.cadastroDados || {};
            // Busca o nome, dependendo ser for empresa ou não.
            registerName = registerName.nomeFantasia || registerName.nome || registerName.nomeExibicao;
            return breadCrumbs.registers_edit(this.props.handleListar, registerName);
          default:
            return null;
        }
      // Prévia de impressão de códigos de barras/QR de cadastros.
      case STATE_CADASTRO_PRINT_PREVIEW:
        return breadCrumbs.registers_print_preview(this.props.handleListar, () => this.props.showPrint())
      // Configuração de impressão de códigos de barras/QR de cadastros.
      case STATE_CADASTRO_PRINT_SETUP:
        return breadCrumbs.registers_print(this.props.handleListar);
      // Configuração de cadastros. No momento, só sendo utilizado para gerar origens de venda em massa.
      case STATE_CADASTRO_SETUP:
        return breadCrumbs.registers_create_setup(this.props.handleListar, () => this.props.cadastroSwitchUI(STATE_CADASTRO_FORM, this.props.cadastroDados));
      default:
        return null;
    }
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {
    log("CadastroHeader render", window.location);

    let header = this.props.match.url.replace("/", "") || window.location.pathname.replace("/", "");

    return <div className="header breadCrumbs" style={this.props.style}>
      <h1>{this.props.title || I18n("registerUrlToString", false, [header])}</h1>
      <h2>{this.getBreadCrumbs()}</h2>
    </div>;
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: reduxStateType) {
  const props = {
    ...state.cadastroReducer,
  }

  return props;
};

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch: dispatchTipo) {
  const props: CadastroHeaderReduxDispatchType = {
    cadastroSwitchUI: (state, cadastroDados, keepLoading, formMounted, operation) =>
      dispatch(cadastroActions.cadastroSwitchUI(state, cadastroDados, keepLoading, formMounted, operation)),
    setValue: (value, position) => dispatch(cadastroPrintActions.setValue(value, position)),
    showPrint: () => dispatch(cadastroActions.showPrint()),
  };

  return props;
};

// @ts-ignore
const CadastroHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)(CadastroHeader_)) as React.ElementType<CadastroHeaderType>;

/**
 * Exporta o último argumento entre parênteses.
 */
export default CadastroHeader;
