//@ts-check
import React from "react"

/**
 * 
 * @param {import("./types").LinkPropsType} props 
 * @returns {JSX.Element|null}
 */
function Link({disabled, ...props}) {
  if (disabled) {
    return null;
  }

  return <a {...props} className={"ui-link " + (props.className || "")} onClick={(event) => {
    event.preventDefault();
    props.onClick && props.onClick(event); }}>
    {props.children}
  </a>
}

export default Link;
