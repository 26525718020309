import React from 'react';
import { connect } from 'react-redux';


import {
    ESTADO_VENDA_ABERTO,
    ESTADO_VENDA_AGUARDANDO_ENCERRAMENTO,
    ESTADO_VENDA_CANCELADO,
    ESTADO_VENDA_ENCERRADO,
    ESTADO_ITEM_VENDA_AGUARDANDO,
    ESTADO_ITEM_VENDA_EM_PRODUCAO,
    ESTADO_ITEM_VENDA_PRODUZIDO,
    ESTADO_ITEM_VENDA_CANCELADO,
    ESTADO_ITEM_VENDA_ENVIADO,
    ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA,
    ESTADO_ITEM_VENDA_ENTREGUE
} from '../../store/reducers/controleVenda/controleVendaReducer';
import * as cadastroActions from '../../store/actions/cadastroAction';
import { ASCENDING, DESCENDING, LEFT, RIGHT } from '../../store/actions/relatorioAction';
import * as relatorioActions from '../../store/actions/relatorioAction';

import { getAppEmpresa } from "../../utils/AppUtils"
import { isEmpresaColetor } from "../../utils/CompanyUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../utils/reduxUtils/reduxUtils"
import { listToFragment } from "../../utils/ScreenUtils"
import { cadastroBuildOptionsFromEmpresas, cadastroBuildOptionsFromEstadoItemVenda, cadastroBuildOptionsFromEstadoVenda } from "../../utils/SelectUtils"
import { naturalSort } from "../../utils/SortUtils"
import { formatI18nString } from "../../utils/StringUtils"

import FiltroInputDefault from '../UI/filtroInputDefault/FiltroInputDefault';
import CustomButton from '../UI/Button/CustomButton';
import { DATE_INPUT, TIME_INPUT } from '../UI/dateTimeInputDefault/DateTimeInputDefault';
import HelpParagraph from '../UI/HelpParagraph/HelpParagraph';
import WidthAwareDiv from '../UI/WidthAwareDiv/WidthAwareDiv';

import "../vendas/vendas.css";
import "./RelatorioBox.css";

// Chaves das colunas

export const ITEM_PAGAMENTO_VENDA_DIFERENCA = 'valorTotal-valorCustoTotal';

export const ITEM_PAGAMENTO_VENDA_LIVRE = 'livre';

export const ITEM_PAGAMENTO_VENDA_PORCENTAGEM_DIFERENCA = '%(valorTotal-valorCustoTotal)';

export const ITEM_PAGAMENTO_VENDA_PORCENTAGEM_MARGEM = '%valorTotal';

export const ITEM_PAGAMENTO_VENDA_PRODUTO = 'produtoNome';

export const ITEM_PAGAMENTO_VENDA_QUANTIDADE = 'quantidade';

export const ITEM_PAGAMENTO_VENDA_VALOR_CUSTO_TOTAL = 'valorCustoTotal';

export const ITEM_PAGAMENTO_VENDA_VALOR_TOTAL = 'valorTotal';

export const ITEM_VENDA_ESTADO = 'estado';

export const ITEM_VENDA_HORA = 'dataHora';

export const ITEM_VENDA_ORIGEM = 'venda.origemVenda.nome';

export const ITEM_VENDA_PRODUTO = ITEM_PAGAMENTO_VENDA_PRODUTO;

export const ITEM_VENDA_VENDA = 'venda.nome';

export const PAGAMENTO_VENDA_ORIGEM = ITEM_VENDA_ORIGEM;

export const PAGAMENTO_VENDA_HORA = ITEM_VENDA_HORA;

export const PAGAMENTO_VENDA_VALOR_PRODUTOS = 'valorProdutos';

export const PAGAMENTO_VENDA_VALOR_COMISSAO = 'valorComissao';

export const PAGAMENTO_VENDA_VALOR_TOTAL = ITEM_PAGAMENTO_VENDA_VALOR_TOTAL;

export const PAGAMENTO_VENDA_VENDA = ITEM_VENDA_VENDA;

export const NOME_CLIENTE = 'nomeCliente';

export const HORA_ENTREGA = 'horaEntrega';

export const TELEFONE = 'telefone';

export const ENDERECO = 'endereco';

export const VENDA_ID = "vendaId";

export const OBSERVACAO = "observacao";

/**
 * Desaninha os dados recursivamente para formatar cada linha da tabela.
 */
export const flattenDadosRelatorio = (dados, list, formatter) => {
    // Se for do nível mais inferior
    if (dados.level === 1) {
        // Gera uma linha da tabela para cada dado
        dados.dadosList.forEach(dadosItem => list.push(formatter(dadosItem.dadosMap, dadosItem.level)));
    }
    // Se for dos outros níveis superiores
    else {
        // Chama este método para cada dado da lista
        dados.dadosList.forEach(dadosItem => flattenDadosRelatorio(dadosItem, list, formatter));
    }
    // Gera uma linha da tabela para os dados normais, se for no nível mais inferior, ou para os dados de totais, se for nos níveis superiores
    list.push(formatter(dados.dadosMap, dados.level));
}

/**
 * 
 * @typedef {Object} tableColumnType 
 * @property {string} key
 * @property {string} text
 */

/**
 * 
 * @typedef {Object} RelatorioBoxType 
 * 
 * @property {string} header
 * @property {tableColumnType[]} tableHeader
 * @property {JSX.Element[]} list
 * @property {Record<string, boolean>} enableFilter
 * @property {string[]} helpText
 */

/**
 * Monta a tela de relatórios com título, filtros e tabela.
 * @param {RelatorioBoxType} props 
 * @returns {JSX.Element}
 */
const RelatorioBox = (props) => {
    let filtroEstadoItemVendaComponent;
    let filtroEstadoVendaComponent;
    let filtroEmpresaComponent;
    let filtroOrigemVendaComponent;
    let filtroTipoProdutoComponent;
    let filtroGrupoProdutoComponent;
    let filtroProdutoComponent;
    let filtrousuarioComponent;
    let filtroDataInicialComponent;
    let filtroDataFinalComponent;
    let filtroDataEntregaInicialComponent;
    let filtroDataEntregaFinalComponent;
    let filtroClienteComponent;
    let filtroHoraInicialComponent;
    let filtroHoraFinalComponent;

    const [firstRun, updateFirstRun] = React.useState(true);

    /**
     * Método que define as opções disponíveis no campo "Estado Item Venda".
     * @param {*} cadastro 
     */
    const loadEstadosItemVenda = () => {
        log('RelatorioBox loadEstadosItemVenda');

        if (!props.enableFilter.estadoItemVenda) {
            return;
        }

        let initialOptions = [
            cadastroBuildOptionsFromEstadoItemVenda(false, { codigo: ESTADO_ITEM_VENDA_AGUARDANDO }),
            cadastroBuildOptionsFromEstadoItemVenda(false, { codigo: ESTADO_ITEM_VENDA_EM_PRODUCAO }),
            cadastroBuildOptionsFromEstadoItemVenda(false, { codigo: ESTADO_ITEM_VENDA_PRODUZIDO })
        ];

        // Define as opções disponíveis no campo "Estado Item Venda"
        (((filtroEstadoItemVendaComponent || {}).inputField).inputComponent || {updateOptions: () => {}}).updateOptions(naturalSort(
            initialOptions.concat(cadastroBuildOptionsFromEstadoItemVenda(true,
                [
                    { codigo: ESTADO_ITEM_VENDA_CANCELADO },
                    { codigo: ESTADO_ITEM_VENDA_ENVIADO },
                    { codigo: ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA },
                    { codigo: ESTADO_ITEM_VENDA_ENTREGUE }
                ])),
            'label'));

        (((filtroEstadoItemVendaComponent || {}).inputField).inputComponent || {updateValue: () => {}}).updateValue(initialOptions);
    }

    /**
     * Método que define as opções disponíveis no campo "Estado Venda".
     * @param {*} cadastro 
     */
    const loadEstadosVenda = () => {
        log('RelatorioBox loadEstadosVenda');

        if (!props.enableFilter.estadoVenda) {
            return;
        }

        // Define as opções disponíveis no campo "Estado Item Venda"
        (((filtroEstadoVendaComponent || {}).inputField || {}).inputComponent || {updateOptions: () => {}}).updateOptions(naturalSort([
            cadastroBuildOptionsFromEstadoVenda(false, { codigo: ESTADO_VENDA_ABERTO }),
            cadastroBuildOptionsFromEstadoVenda(false, { codigo: ESTADO_VENDA_AGUARDANDO_ENCERRAMENTO }),
            cadastroBuildOptionsFromEstadoVenda(false, { codigo: ESTADO_VENDA_CANCELADO }),
            cadastroBuildOptionsFromEstadoVenda(false, { codigo: ESTADO_VENDA_ENCERRADO }),
        ], 'label'));
    }

    /**
     * Método que define as opções disponíveis no campo "Empresa".
     * @param {*} cadastro 
     */
    const loadEmpresasUsuario = () => {
        log('RelatorioBox loadEmpresasUsuario');

        if (!props.enableFilter.empresa) {
            return;
        }

        // Define as opções disponíveis no campo "Empresa"
        props.getEmpresasFromUsuario(empresas => {
            // Constroi as opções
            let options = cadastroBuildOptionsFromEmpresas(true, empresas);
            // Atribui as opções ao filtro
            (((filtroEmpresaComponent || {}).inputField || {}).inputComponent || {updateOptions: () => {}}).updateOptions(options);
            // Busca a empresa atual nas opções
            let selectedOption;
            options.forEach(option => {
                if (option.value.codigo === getAppEmpresa()) {
                    selectedOption = option;
                }
            });
            // Seleciona a empresa atual no filtro
            (((filtroEmpresaComponent || {}).inputField || {}).inputComponent || {updateValue: () => {}}).updateValue([selectedOption]);
        });
    }

    /**
     * Método executado APÓS a montagem do componente. Popula os filtros de estado de venda e de item de venda.
     */
    const componentDidMount = () => {
        log('RelatorioBox componentDidMount');
        loadEstadosVenda();
        loadEstadosItemVenda();
        loadEmpresasUsuario();
    }

    // eslint-disable-next-line
    React.useEffect(() => {
        if (firstRun) {
            updateFirstRun(false);
            componentDidMount();
        }

    });

    /**
     * Método que executa a montagem/rederização do componente.
     */
    const render = () => {
        /**
         * Monta o rodapé (totais), se houver.
         */
        let footer = null;
        if (props.tableFooter) {
            footer = <tfoot>
                <tr>
                    {props.tableFooter}
                </tr>
            </tfoot>;
        }

        return <>
            {/* Header do box */}
            <div className='header breadCrumbs'>
                <h1>{props.header}</h1>
                <h2>{getStrings().report}</h2>
            </div>

            <WidthAwareDiv>

                <HelpParagraph children={props.helpText.concat(formatI18nString(getStrings().reportHelp()))} />

                <div className='sub-form' >
                    {props.enableFilter.estadoVenda ? <FiltroInputDefault
                        id="filtroEstadoVenda"
                        disableTimeout
                        ref={input => { if (input) { filtroEstadoVendaComponent = getReduxWrappedComponent(input); } }}
                        inputType='multiSelect'
                        label={getStrings().saleStateFilter()}
                        placeholder={getStrings().saleStateFilterPlaceholder()}
                        sort
                    /> : null}

                    {props.enableFilter.estadoItemVenda ? <FiltroInputDefault
                        id="filtroEstadoItemVenda"
                        disableTimeout
                        ref={input => { if (input) { filtroEstadoItemVendaComponent = getReduxWrappedComponent(input); } }}
                        inputType='multiSelect'
                        label={getStrings().saleItemStateFilter()}
                        placeholder={getStrings().saleItemStateFilterPlaceholder()}
                        sort
                    /> : null}

                    {props.enableFilter.empresa ? <FiltroInputDefault
                        id="filtroEmpresa"
                        disableTimeout
                        ref={input => { if (input) { filtroEmpresaComponent = getReduxWrappedComponent(input); } }}
                        inputType='multiSelect'
                        label={getStrings().companyFilter}
                        placeholder={getStrings().companyPlaceholder}
                    /> : null}

                    {props.enableFilter.origemVenda ? (isEmpresaColetor(props.ramoEmpresa) ?
                        <FiltroInputDefault
                            id="filtroCliente"
                            disableTimeout
                            ref={input => { if (input) { filtroClienteComponent = getReduxWrappedComponent(input); } }}
                            label={getStrings().client}
                            placeholder={getStrings().client}
                        />
                        :
                        <FiltroInputDefault
                            id="filtroOrigemVenda"
                            disableTimeout
                            ref={input => { if (input) { filtroOrigemVendaComponent = getReduxWrappedComponent(input); } }}
                            label={getStrings().saleSourceFilter()}
                            placeholder={getStrings().saleSourceFilterPlaceholder()}
                        />
                    ) : null}

                    {props.enableFilter.tipoProduto ? <FiltroInputDefault
                        id="filtroTipoProduto"
                        disableTimeout
                        ref={input => { if (input) { filtroTipoProdutoComponent = getReduxWrappedComponent(input); } }}
                        label={getStrings().productTypeFilter}
                        placeholder={getStrings().productTypeFilterPlaceholder}
                    /> : null}

                    {props.enableFilter.grupoProduto ? <FiltroInputDefault
                        id="filtroGrupoProduto"
                        disableTimeout
                        ref={input => { if (input) { filtroGrupoProdutoComponent = getReduxWrappedComponent(input); } }}
                        label={getStrings().productGroupFilter}
                        placeholder={getStrings().productGroupFilterPlaceholder}
                    /> : null}

                    {props.enableFilter.produto ? <FiltroInputDefault
                        id="filtroProduto"
                        disableTimeout
                        ref={input => { if (input) { filtroProdutoComponent = getReduxWrappedComponent(input); } }}
                        label={getStrings().productFilter}
                        placeholder={getStrings().productFilterPlaceholder}
                    /> : null}

                    {props.enableFilter.usuario ? <FiltroInputDefault
                        id="filtroUsuario"
                        disableTimeout
                        ref={input => { if (input) { filtrousuarioComponent = getReduxWrappedComponent(input); } }}
                        label={getStrings().userFilter}
                        placeholder={getStrings().userFilterPlaceholder}
                    /> : null}

                    <div className='pure-g' >
                        <div className='pure-u-1-1 pure-u-sm-11-24'>
                            {props.enableFilter.data ? <FiltroInputDefault
                                id="filtroDataInicial"
                                disableTimeout
                                ref={input => { if (input) { filtroDataInicialComponent = getReduxWrappedComponent(input); } }}
                                inputType={DATE_INPUT}
                                label={getStrings().startingDate}
                                placeholder={getStrings().startingDatePlaceholder}
                            /> : null}
                        </div>
                        <div className='espaco-condicional-sm pure-u-sm-2-24' >
                            &nbsp;
                                </div>
                        <div className='pure-u-1-1 pure-u-sm-11-24'>
                            {props.enableFilter.data ? <FiltroInputDefault
                                id="filtroDataFinal"
                                disableTimeout
                                ref={input => { if (input) { filtroDataFinalComponent = getReduxWrappedComponent(input); } }}
                                inputType={DATE_INPUT}
                                label={getStrings().finishingDate}
                                placeholder={getStrings().finishingDatePlaceholder}
                            /> : null}
                        </div>
                    </div>

                    <div className='pure-g' >
                        <div className='pure-u-1-1 pure-u-sm-11-24'>
                            {props.enableFilter.dataEntrega ? <FiltroInputDefault
                                id="filtroDataEntregaInicial"
                                disableTimeout
                                ref={input => { if (input) { filtroDataEntregaInicialComponent = getReduxWrappedComponent(input); } }}
                                inputType={DATE_INPUT}
                                label={getStrings().startingDeliveryDate}
                                placeholder={getStrings().startingDatePlaceholder}
                            /> : null}
                        </div>
                        <div className='espaco-condicional-sm pure-u-sm-2-24' >
                            &nbsp;
                                </div>
                        <div className='pure-u-1-1 pure-u-sm-11-24'>
                            {props.enableFilter.dataEntrega ? <FiltroInputDefault
                                id="filtroDataEntregaFinal"
                                disableTimeout
                                ref={input => { if (input) { filtroDataEntregaFinalComponent = getReduxWrappedComponent(input); } }}
                                inputType={DATE_INPUT}
                                label={getStrings().finishingDeliveryDate}
                                placeholder={getStrings().finishingDatePlaceholder}
                            /> : null}
                        </div>
                    </div>

                    <div className='pure-g'>
                        <div className='pure-u-1-1 pure-u-sm-11-24'>
                            {props.enableFilter.hora ? <FiltroInputDefault
                                id="filtroHoraInicial"
                                disableTimeout
                                ref={input => { if (input) { filtroHoraInicialComponent = getReduxWrappedComponent(input); } }}
                                inputType={TIME_INPUT}
                                label={getStrings().startingTime}
                                placeholder={getStrings().startingTimePlaceholder}
                            /> : null}
                        </div>
                        <div className='espaco-condicional-sm pure-u-sm-2-24' >
                            &nbsp;
                                </div>
                        <div className='pure-u-1-1 pure-u-sm-11-24'>
                            {props.enableFilter.hora ? <FiltroInputDefault
                                id="filtroHoraFinal"
                                disableTimeout
                                ref={input => { if (input) { filtroHoraFinalComponent = getReduxWrappedComponent(input); } }}
                                inputType={TIME_INPUT}
                                label={getStrings().finishingTime}
                                placeholder={getStrings().finishingTimePlaceholder}
                            /> : null}
                        </div>
                    </div>

                    <div className='button-grid'>
                        <CustomButton
                            tabIndex={props.tabIndex}
                            btnType='Success'
                            id='doReportButton'
                            onClick={() => props.getReport({
                                ...props.options,
                                // TODO investigar porque dá 404 ao enviar URL para o Spring via matrix variables (por esse motivo precisou executar encode duas vezes)
                                ...(props.enableFilter.empresa ? { empresa: filtroEmpresaComponent.inputField.inputComponent.getValue().map(option => encodeURIComponent(encodeURIComponent(option.value.codigo))) || undefined } : {}),
                                ...(props.enableFilter.origemVenda ? { origemVenda: ((filtroOrigemVendaComponent || {}).getValue || (() => { }))() || undefined } : {}),
                                ...(props.enableFilter.origemVenda ? { cliente: ((filtroClienteComponent || {}).getValue || (() => { }))() || undefined } : {}),
                                ...(props.enableFilter.tipoProduto ? { tipoProduto: filtroTipoProdutoComponent.getValue() || undefined } : {}),
                                ...(props.enableFilter.grupoProduto ? { grupoProduto: filtroGrupoProdutoComponent.getValue() || undefined } : {}),
                                ...(props.enableFilter.produto ? { produto: filtroProdutoComponent.getValue() || undefined } : {}),
                                ...(props.enableFilter.usuario ? { usuario: filtrousuarioComponent.getValue() || undefined } : {}),
                                ...(props.enableFilter.estadoVenda ? { estadoVenda: filtroEstadoVendaComponent.inputField.inputComponent.getValue().map(option => option.value.codigo), } : {}),
                                ...(props.enableFilter.estadoItemVenda ? { estadoItemVenda: filtroEstadoItemVendaComponent.inputField.inputComponent.getValue().map(option => option.value.codigo) } : {}),
                                ...(props.enableFilter.data ? { dataInicial: filtroDataInicialComponent.getValue() } : {}),
                                ...(props.enableFilter.data ? { dataFinal: filtroDataFinalComponent.getValue() } : {}),
                                ...(props.enableFilter.dataEntrega ? { dataEntregaInicial : filtroDataEntregaInicialComponent.getValue() } : {}),
                                ...(props.enableFilter.dataEntrega ? { dataEntregaFinal : filtroDataEntregaFinalComponent.getValue() } : {}),
                                ...(props.enableFilter.hora ? { horaInicial: filtroHoraInicialComponent.getValue() } : {}),
                                ...(props.enableFilter.hora ? { horaFinal: filtroHoraFinalComponent.getValue() } : {}),
                            })}
                        >{getStrings().generateReport}</CustomButton>
                    </div>
                </div>
            </WidthAwareDiv>

            <div style={{
                overflowX: 'auto',
                overflowY: 'hidden',
                paddingBottom: '20px'
            }}>
                <div className='centerFitContent'>
                    {/* Tabela de dados. A ordem deve ser: header, footer, body. */}
                    <table id= 'relatorio' className='pure-table' width='100%'>
                        {/* Cabeçalho */}
                        <thead>
                            <tr>
                                {/* Adiciona a cada célula do cabeçalho botões para ordenar linhas e colunas */}
                                {listToFragment(props.tableHeader.map(header => <th id={header.key} className='Bold'>
                                    {header.text}
                                    <div className='pure-g tableHeaderControls'>
                                        <button
                                            className='pure-u-1-5' style={{ fontFamily: 'Segoe UI Symbol' }}
                                            onClick={() => props.moveColumn(header.key, LEFT)}
                                            disabled={props.tableHeader.indexOf(header) === 0}
                                            title={getStrings().moveColumnLeft}
                                        >◀</button>
                                        <button
                                            className='pure-u-1-5' style={{ fontFamily: 'Segoe UI Symbol' }}
                                            onClick={() => props.updateRowSortOptions(header.key, ASCENDING)}
                                            disabled={props.rowSortOptions[header.key] && props.rowSortOptions[header.key].direction === ASCENDING}
                                            title={getStrings().orderSucceedColumn}
                                        >▲</button>
                                        <div className='pure-u-1-5'>{props.rowSortOptions[header.key] ? props.rowSortOptions[header.key].index : 0}</div>
                                        <button
                                            className='pure-u-1-5' style={{ fontFamily: 'Segoe UI Symbol' }}
                                            onClick={() => props.updateRowSortOptions(header.key, DESCENDING)}
                                            disabled={props.rowSortOptions[header.key] && props.rowSortOptions[header.key].direction === DESCENDING}
                                            title={getStrings().orderPrecedeColumn}
                                        >▼</button>
                                        <button
                                            className='pure-u-1-5' style={{ fontFamily: 'Segoe UI Symbol' }}
                                            onClick={() => props.moveColumn(header.key, RIGHT)}
                                            disabled={props.tableHeader.indexOf(header) === (props.tableHeader.length - 1)}
                                            title={getStrings().moveColumnRight}
                                        >▶</button>
                                    </div>
                                </th>))}
                            </tr>
                        </thead>

                        {/* Rodapé */}
                        {footer}

                        {/* Linhas de Dados */}
                        <tbody>
                            {listToFragment(props.list)}
                        </tbody>
                    </table>
                </div>
            </div>
        </ >;
    };

    return render();
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    ...state.relatorioReducer,
    ...state.idiomaReducer,
    ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
    tabIndex: state.appReducer.getTabIndex()
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

    getReport: options => dispatch(relatorioActions.getReport(options)),
    getEmpresasFromUsuario: successMethod => dispatch(cadastroActions.getEmpresasFromUsuario(successMethod)),
    moveColumn: (column, direction) => dispatch(relatorioActions.moveColumn(column, direction)),
    updateRowSortOptions: (column, direction) => dispatch(relatorioActions.updateRowSortOptions(column, direction))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(RelatorioBox);
