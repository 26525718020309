// @ts-check
import { global_strings, locale } from "../../../utils/LocaleUtils";
import countryName from "./pt_BR_countryName";

export default {
  country: {
    iso: "BR",
    countryName,
  },
  currency: {
    /**
     * 
     * @param {number} value 
     * @returns {string}
     */
    format: (value) => `R$ ${value.toLocaleString("pt-BR", {minimumFractionDigits:2, maximumFractionDigits: 2})}`,
  },
  dialog: {
    error: "Erro",
    installApp: "Instalar aplicativo?",
    login: "Login",
    notAllowedByThisCompany: global_strings[locale.pt_br].notAllowedByThisCompany,
    warning: "Aviso",
  },
  eror: {
    sendingEmail: "Ocorreu um erro ao enviar o email",
    userNotFound: "Nenhum cadastro localizado com o email informado",
  },
  menu: {
    companyInformation: "Informações da empresa",
    editRegister: "Editar cadastro",
    exit: "Sair",
    login: "Entrar",
    openingHours: "Horários de funcionamento",
    requests: "Pedidos",
    signUp: "Cadastrar",
  },
  /**
   * 
   * @param {number} min 
   * @param {number} max 
   * @returns {string}
   */
  minimumAndMaximumAmount: (min, max) => `Mínimo: ${min}  Máximo: ${max}`,
  phrases: {
    developedBy: "Desenvolvido por",
    /**
     * 
     * @param {string} email 
     * @returns {string}
     */
    emailSentTo: (email) => `Email enviado para: ${email}`,
    goBackToProductsScreen: "Voltar a tela de produtos",
    incorrectLogin: "Email ou senha incorretos",
    incorrectToken: "Código incorreto",
    maximumAmountReached: "Quantidade máxima atingida",
    /**
     * 
     * @param {number} quantity 
     * @returns {string}
     */
    minimumFlavorQuantity: (quantity) => `Escolha pelo menos ${quantity} sabor${quantity > 1 ? "es" : ""}`,
    productPutInTheRequestssList: "Pedido enviado",
    receiveInEstablishiment: "Retirar no estabelecimento",
    receiveInThisAddress: "Receber neste endereço",
    shoppingFailed: "Não foi possível finalizar a compra",
  },
  login: {
    /**
     * 
     * @param {string?} email 
     * @returns {string}
     */
    accountConfirmation: (email = null) => `Informe o código que foi enviado para ${email || " o seu email"}`,
    forgotMyPassword: "Esqueci a senha",
    login: "Entrar",
    loginFailed: "Login falhou",
    notRegistered: "Não sou cadastrado",
    password: "Senha",
    passwordRecovery: "Recuperação de senha",
    submit: "Enviar",
    userId: "E-mail",
    /**
     * 
     * @param {string} nomeUsuario 
     * @returns {string}
     */
    welcomeUser: (nomeUsuario) => `Bem-vindo ${nomeUsuario}`,
  },
  noImageAvailableIcon: "/mobi_icons/imagem-nao-disponivel.svg",
  register: {
    error: {
      defaultMessage: "Não foi possível realizar o cadastro",
    },
    invalid: {
      email: "E-mail inválido",
      securityNumber: "CPF/CNPJ inválido",
    },
    label: {
      adress: "Endereço",
      birthDate: "Data nascimento",
      celphoneNumber: "Número do celular",
      city: "Cidade",
      complement: "Complemento",
      country: "País",
      deliveryDate: "Data da entrega",
      deliveryHour: "Hora da entrega",
      email: "E-mail",
      fullName: "Nome completo",
      hood: "Bairro",
      lastName: "Sobrenome",
      name: "Nome",
      newPassword: "Nova senha",
      number: "Número",
      password: "Senha",
      personalData: "Dados pessoais",
      repeatPassword: "Repita a senha",
      securityNumber: "CPF/CNPJ",
      state: "Estado",
      stateName: "UF",
      street: "Rua",
      takeAwayDate: "Data da retirada",
      takeAwayHour: "Hora da retirada",
      zipCode: "CEP",
    },
    passwordsDontMatch: "Senhas estão diferentes",
    placeholder: {
      adress: "Endereço",
      birthDate: "Data de nascimento",
      celphoneNumber: "Número do celular",
      city: "Cidade",
      complement: "Complemento",
      country: "País",
      email: "Preencha seu e-mail",
      fullName: "Preencha seu nome completo",
      hood: "Bairro",
      lastName: "Preencha seu sobrenome",
      name: "Preencha seu nome",
      newPassword: "Digite a nova senha",
      number: "Número",
      password: "Digite uma senha",
      personalData: "Dados pessoais",
      repeatPassword: "Repita a senha",
      securityNumber: "CPF ou CNPJ",
      state: "Estado",
      stateName: "UF",
      street: "Rua ou logradouro",
      zipCode: "CEP",
    },
    select: {
      female: "Feminino",
      gender: "Gênero",
      male: "Masculino",
      notInformed: "Não informado",
    },
    uninformed: {
      adress: "Endereço não informado",
      birthDate: "Data de nascimento não informada",
      celphoneNumber: "Número do celular não informado",
      city: "Cidade não informada",
      complement: "Complemento não informado",
      country: "País não informado",
      deliveryDate: "Informe a data de entrega do pedido",
      deliveryHour: "Informe a hora de entrega do pedido",
      email: "E-mail não informado",
      fullName: "Nome completo não informado",
      hood: "Bairro não informado",
      lastName: "Sobrenome não informado",
      name: "Nome não informado",
      newPassword: "Nova senha não informada",
      number: "Número não informado",
      password: "Senha não informada",
      personalData: "Dados pessoais não informados",
      repeatPassword: "Senha repetida não informada",
      securityNumber: "CPF/CNPJ não informado",
      state: "Estado não informado",
      stateName: "UF não informada",
      street: "Rua não informada",
      takeAwayDate: "Informe a data de retirada do pedido",
      takeAwayHour: "Informe a hora de retirada do pedido",
      zipCode: "CEP não informado",
    },
  },
  orders: {
    analisys: "Análise",
    approved: "Aprovado",
    cancelled: "Cancelado",
    dateOfRequest: "Data do pedido",
    delivered: "Entregue",
    deliverying: "Entrega",
    deliveryValue: "Valor entrega",
    finished: "Concluído",
    inTransit: "Trânsito",
    item: "item",
    items: "itens",
    itemTotal: "Total do item",
    notApproved: "Não aprovado",
    numberOfRequests: "Número de pedidos",
    numberOfUnitys: "Número de unidades",
    payedValue: "Valor pago",
    product: "Produto",
    quantity: "Qtde.",
    produced: "Produzido",
    order: "Pedido",
    orderNumber: "Número do pedido",
    orders: "Pedidos",
    sent: "Enviado",
    unityPrice: "Preço unitário",
    inProduction: "Em produção",
    orderCanceled: "Pedido cancelado",
    orderPlaced: "Pedido realizado",
    readyToDelivery: "Pronto para a entrega",
    deliveryCompleted: "Entrega finalizada",
    orderCompleted: "Pedido finalizado",
    companyWillContactYou: "A empresa entrará contato com você",
    totalValue: "Valor total",
    waitingPayment: "Aguardando pagamento",
  },
  weeks: {
    DOM: {reduced: "Dom", extended: "Domingo"},
    SEG: {reduced: "Seg", extended: "Segunda"},
    TER: {reduced: "Ter", extended: "Terça"},
    QUA: {reduced: "Qua", extended: "Quarta"},
    QUI: {reduced: "Qui", extended: "Quinta"},
    SEX: {reduced: "Sex", extended: "Sexta"},
    SAB: {reduced: "Sáb", extended: "Sábado"},
    "": {reduced: "", extended: ""},
  },
  words: {
    about: "Sobre",
    adress: {
      adress: "Endereço",
      city: "Cidade",
      complement: "Complemento",
      country: "País",
      hood: "Bairro",
      number: "Número",
      state: "Estado",
      zipCode: "CEP",
    },
    amount: "Quantidade",
    back: "Voltar",
    buy: "Comprar",
    cancel: "Cancelar",
    closed: "Fechado",
    code: "Código",
    contact: "Contato",
    creditCard: "Cartão de crédito",
    delivery: "Entrega",
    deliveryValueByKm: "Valor da entrega por km",
    description: "Descrição",
    email: "Email",
    emptyCart: "Carrinho vazio",
    finishBuying: "Concluir",
    /**
     * 
     * @param {boolean?} plural 
     * @returns 
     */
    flavor: (plural = null) => `Sabor${plural ? "es" : ""}`,
    holiday: "Feriado",
    information: "Informações",
    login: "Entrar",
    money: "Dinheiro",
    nextPage: "Avançar",
    no: "Não",
    numberOfInstallments: "Número de parcelas",
    observation: "Observação",
    observationShort: "Obs.",
    ok: "OK",
    openingHours: "Horários de funcionamento",
    otherInformation: "Outras informações", 
    payment: "Pagamento",
    paymentMethod: "Forma de pagamento",
    phone: "Telefone",
    reason: "Motivo",
    register: "Cadastrar",
    remove: "Remover",
    save: "Salvar",
    schedules: "Horários",
    search: "Buscar",
    soldOut: "Esgotado",
    state: "Estado",
    submit: "Enviar",
    takeAway: "Retirada",
    type: "Tipo", 
    yes: "Sim",
  },
}
