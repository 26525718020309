//@ts-check
import { ClienteHttp } from "../utils";

/**
 * 
 * @param {string} caminhoDoArquivo 
 * @returns 
 */
export default function carregarArquivoLocal(caminhoDoArquivo) {
  return ClienteHttp.obterClienteHttp().get(caminhoDoArquivo);
}
