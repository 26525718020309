import React from 'react';

import './PrincipalTela.css';

import apelidos from '../../../rotas/apelidos';
import { connect } from 'react-redux';
import { atualizarBotaoVoltar } from '../../../nucleo/redux/navegacao/navegacaoActions';
import { mudarEstadoMostrarBotoesFlutuantes } from '../../../nucleo/redux/barraNavegacao/barraNavegacaoActions';
import BarraTopo from '../../barras/BarraTopo/BarraTopo';
import BarraNavegacao from '../../barras/BarraNavegacao/BarraNavegacao';
import BarraLogoEmpresa from '../../barras/BarraLogoEmpresa/BarraLogoEmpresa';
import MenuModal from '../../menus/MenuModal/MenuModal';
import { mudarEstadoMenuModal } from '../../../nucleo/redux/menus/menuModal/menuModalActions';
import SnackbarTemplate from '../../snackbars/SnackbarTemplate/SnackbarTemplate';
import { atualizarUsuario } from '../../../nucleo/redux/usuario/usuarioActions';
import { ClienteHttp, LocalidadeUtils, NavegacaoUtils } from '../../../nucleo/utils/utils';
import { setNomeEmpresa, getNomeEmpresa } from '../../../assets/empresas/configEmpresa';
import BarraGrupoProduto from '../../barras/BarraGrupoProduto/BarraGrupoProduto';
import { atualizarParametrosEmpresa } from '../../../nucleo/redux/parametrosEmpresa/parametrosEmpresaActions';
import { polularEmpresa } from '../../../nucleo/redux/empresa/empresaActions';
import { getURIFromEntity } from '../../../nucleo/utils/URIUtils/URIUtils';
import { mostrarDialog } from '../../../nucleo/redux/dialog/dialogActions';
import { atualizarEstiloMobiApp } from '../../../../../utils/mobiAppUtils/atualizarEstiloMobiApp';
import scrollToGrupo from '../../../nucleo/utils/ScreenUtils/ScreenUtils';
import AvisoDialog from '../../dialogs/AvisoDialog/AvisoDialog';
import { polularFormasPagamento } from '../../../nucleo/redux/pedidos/formasPagamento/formasPagamentoActions';
import { polularBairros } from '../../../nucleo/redux/bairro/bairroActions';
import { popularQuadroHorarios } from '../../../nucleo/redux/quadroHorarios/quadroHorariosActions';
import { estaEmHorarioDeFuncionamento } from '../../../../../utils/QuadroHorariosUtils/quadroHorariosUtils';

class PrincipalTela extends React.Component {
  /**
   * 
   * @type {React.ReactNode} 
   */
  BotaoPrincipal = (_) => null;
  /**
   * 
   * @type {React.ReactNode} 
   */
  elementoFilho = <div className='tela'></div>;
  /**
   * 
   * @type {boolean} 
   */
  habilitarMensagemLojaFechada = false;

  state = {
    sticky: false,
    lojaAberta: true,
  }

  atualizaUsuarioInterval = window.setInterval(
    /**
     * 
     * @param {any} dispatch 
     * @param {() => void | undefined} atualizarUsuario 
     */
    (dispatch, atualizarUsuario) => {
    if (this.props.history.location.pathname !== `/${apelidos.pedidos}` && sessionStorage.getItem('a')) {
      dispatch(atualizarUsuario());
    }
  }, 60000, this.props.dispatch, atualizarUsuario);

  verificaLojaAberta = () => {

    const quadroHorarios = this.props.webAppQuadroHorariosReducer;

    if (Object.keys(quadroHorarios).length) {
      const lojaEstaAberta = estaEmHorarioDeFuncionamento(this.props.webAppQuadroHorariosReducer);

      if (this.state.lojaAberta !== lojaEstaAberta) {
        this.setState({ lojaAberta: lojaEstaAberta });
      }
    }
  }

  abertoFechadoInterval = window.setInterval(() => {
    this.buscaQuadroHorarios(this.props.parametrosEmpresa.idDaEmpresa, true);
  }, 60000);

  atualizarEmpresa = (mobiApp) => {
    if (!mobiApp) {
      return;
    }

    const idDaEmpresa = mobiApp.origemVenda?.empresa?.id;
    const link = `empresas/${idDaEmpresa}/findToApp`;

    ClienteHttp.requisicaoServidor(link, 'get', true, {})
        .then((resposta) => {

          const dados = resposta.data?.content?.empresa
            ? resposta.data.content.empresa
            : resposta.data

          if (dados) {
            /**
             * 
             * @type {import('../../../nucleo/redux/empresa/empresaReducer').empresaReducerTipo}
             */
            const empresa = {
              empresa: {
                id: idDaEmpresa,
                uri: getURIFromEntity(dados),
                cnpj: dados.cnpj,
                razaoSocial: dados.razaoSocial,
                nomeFantasia: dados.nomeFantasia,
              },
              parametrosEmpresa: {
                filtroDeProdutos: dados.parametrosEmpresa.filtroDeProdutos,
                modoCobrancaTeleEntrega: dados.parametrosEmpresa.modoCobrancaTeleEntrega,
                valorTeleEntregaPorKm: dados.parametrosEmpresa.valorTeleEntregaPorKm,
                tipoCadastroUsuario: dados.parametrosEmpresa.tipoCadastroUsuario,
              },
              endereco: {
                bairro: dados.enderecoEmpresa?.bairro,
                cep: dados.enderecoEmpresa?.cep,
                cidade: dados.enderecoEmpresa?.municipio,
                codigoCidade: dados.enderecoEmpresa?.codigoMunicipioIbge,
                complemento: dados.enderecoEmpresa?.complemento,
                estado: dados.enderecoEmpresa?.uf,
                numero: dados.enderecoEmpresa?.numero,
                pais: {
                  codigo: dados.enderecoEmpresa?.pais._links.self.href.replace(ClienteHttp.getApi('paises/'), ''),
                  iso: dados.enderecoEmpresa?.pais.iso,
                  nome: dados.enderecoEmpresa?.pais.nome,
                },
                rua: dados.enderecoEmpresa?.endereco,
              },
              contato: {
                email: dados.contatoEmpresa?.email,
                telefone: dados.contatoEmpresa?.telefone,
              }
            };

            this.props.dispatch(polularEmpresa(empresa));
          }
        })
        .catch(() => {});
  }

  updateHabilitarMensagemLojaFechada = (habilitarMensagemLojaFechada = false) => {
    this.habilitarMensagemLojaFechada = habilitarMensagemLojaFechada
    this.forceUpdate()
  }

  atualizaParametros = (mobiApp) => {
    if (mobiApp) {
      const idDaEmpresa = mobiApp.origemVenda?.empresa?.id;

      const parametrosEmpresa =  {
        idDaEmpresa: idDaEmpresa,
        idOrigemVendaDaEmpresa: mobiApp.origemVenda.id,
        nomeExibicao: mobiApp.nomeExibicao,
        origemVenda: mobiApp.origemVenda,
        valorLinkParametro: mobiApp.valorLinkParametro,
        versaoLogoEmpresa: mobiApp.versaoLogoEmpresa,
      };

      this.updateHabilitarMensagemLojaFechada(mobiApp.habilitarMensagemLojaFechada)
      this.props.dispatch(atualizarParametrosEmpresa(parametrosEmpresa));
      this.atualizarEmpresa(mobiApp);
    }
  }

  /**
   * 
   * @param {string | null} vendaId 
   * @param {string | null} formaPagamentoId 
   */
  salvarTransacao = (vendaId, formaPagamentoId) => {

    vendaId && formaPagamentoId && ClienteHttp.requisicaoServidor('pagseguro/saveTransaction', 'post', true, {params: {noCompany: true}}, {vendaId, formaPagamentoId})
    .then(() => {
      this.props.dispatch(NavegacaoUtils.goTo(apelidos.pedidos));
    })
    .catch((e) => {
      console.error(e);
    });
  }

  buscaFormasPagamento = (idDaEmpresa) => {
    if (idDaEmpresa && Object.keys(this.props.webAppFormasPagamentoReducer).length === 0) {
      ClienteHttp.obterClienteHttp().get(ClienteHttp.getApi('formaPagamentos/findListToApp'), ClienteHttp.getHeaders({ empresaId: idDaEmpresa }))
        .then((resposta) => {
          /**
           * 
           * @type {Array<{ codigo: string, nome: string, usaPagSeguro: boolean }>}
           */
          const dados = ((resposta.data || {}).content || []);

          /**
           * 
           * @type {import('../../../nucleo/redux/pedidos/formasPagamento/formasPagamentoReducer').formasPagamentoReducerTipo}
           */
          let formasPagamento = {};

          if (dados.length > 0) {
            for (const formaPagamento of dados) {
              formasPagamento[getURIFromEntity(formaPagamento)] = { nome: formaPagamento.nome, usaPagSeguro: formaPagamento.usaPagSeguro };
            }

            this.props.dispatch(polularFormasPagamento(formasPagamento));
          }
        })
        .catch(() => {});
    }
  }

  buscaBairros = (idDaEmpresa) => {
    if (idDaEmpresa && Object.keys(this.props.webAppBairroReducer).length === 0) {
      ClienteHttp.obterClienteHttp().get(ClienteHttp.getApi(`bairros/porEmpresa/${idDaEmpresa}`), ClienteHttp.getHeaders())
        .then((resposta) => {
          /**
           * 
           * @type {Array<{ nome: string, valorEntrega: number }>}
           */
          const dados = resposta?.data?.content || [];
          /**
           * 
           * @type {import('../../../nucleo/redux/bairro/bairroReducer').bairroReducerTipo}
           */
          const bairros = {};
          if (dados.length > 0) {
            for (const bairro of dados) {
              bairros[getURIFromEntity(bairro)] = { nome: bairro.nome, valorEntrega: bairro.valorEntrega };
            }
            this.props.dispatch(polularBairros(bairros));
          }
        })
        .catch(() => {});
    }
  }

  /**
   * 
   * @param {any} idDaEmpresa 
   * @param {boolean} atualizar 
   */
  buscaQuadroHorarios = (idDaEmpresa, atualizar) => {
    if (idDaEmpresa && (atualizar || Object.keys(this.props.webAppQuadroHorariosReducer).length === 0)) {
      ClienteHttp.obterClienteHttp().get(ClienteHttp.getApi('quadroHorarios/findByEmpresa'), ClienteHttp.getHeaders({ id: idDaEmpresa }))
        .then((resposta) => {
          /**
           * 
           * @type {import('../../../nucleo/redux/quadroHorarios/quadroHorariosReducer').quadroHorariosReducerTipo}
           */
          const dados = resposta?.data;
          if (dados) {
            this.props.dispatch(popularQuadroHorarios(dados));
          }
        })
        .catch(() => { });
    }
  }

  componentDidMount() {
    this.verificaLojaAberta();

    const params = new URLSearchParams(window.location.search);
    const nomesDiferentes = getNomeEmpresa() !== params.get('empresa');
    const nomeEmpresa = nomesDiferentes && (params.get('empresa') !== null)
      ? params.get('empresa')
      : getNomeEmpresa();
    
    setNomeEmpresa(nomeEmpresa);

    this.salvarTransacao(params.get('vendaId'), params.get('formaPagamentoId'));

    ClienteHttp.obterClienteHttp()
      .get(ClienteHttp.getApi(`mobiApps/porValorLinkParametro`), ClienteHttp.getHeaders({ valorLinkParametro: nomeEmpresa }))
      .then(resposta => {
        const mobiApp = resposta.data;

        if (mobiApp.versaoLogoEmpresa === null) {
          mobiApp.versaoLogoEmpresa = '1';
        }

        if (mobiApp.origemVenda.empresa.parametrosEmpresa.nivelUsuarioSemEmpresa === 'NADA') {
          this.props.dispatch(
            mostrarDialog({
              conteudo: () => <AvisoDialog mensagem={LocalidadeUtils.obterTraducao().dialog.notAllowedByThisCompany} />
            })
          )
          throw new Error('notAllowedByThisCompany')
        }

        atualizarEstiloMobiApp(mobiApp);
        this.atualizaParametros(mobiApp);
        this.buscaQuadroHorarios(mobiApp.origemVenda?.empresa?.id, false);
        this.buscaFormasPagamento(mobiApp.origemVenda?.empresa?.id);
        this.buscaBairros(mobiApp.origemVenda?.empresa?.id);
      })
      .catch(e => {
        console.error(e);
        atualizarEstiloMobiApp();
      });

    const urlManifest = `${window.location.origin}/mobi_app_layout/${nomeEmpresa}/pwa/manifest.json`;
    /**
     * 
     * @type {HTMLLinkElement}
     */
    const linkElement = document.getElementById('manifest-link');

    if (linkElement) {
      linkElement.href = urlManifest;
    }
    else {
      const linkElement = document.createElement('link');
      linkElement.id = 'manifest-link';
      linkElement.href = urlManifest;
      linkElement.rel = 'manifest';
      document.getElementsByTagName('head')[0].appendChild(linkElement);
    }

    const codigoGrupo = params.get('cg');
    if (codigoGrupo) {
      setTimeout(() => {
        scrollToGrupo(codigoGrupo);
      }, 5);
    }

    this.forceUpdate();
  }

  scrollActive = false;

  updateSticky = () => {
    if (!this.scrollActive) {
      this.scrollActive = true

      const _self = this
      const elements = document.getElementsByClassName('conteudo')

      if (elements && elements.length) {
        const el = elements[0]
    
        el.addEventListener('scroll', (evt) => {
          _self.setState({sticky: el.scrollTop > 148})
        })
      }
    }
  }

  /**
   * 
   * @param {import('./types').principalTelaPropsType} prevProps 
   */
  componentDidUpdate(prevProps) {
    const caminhoAtual = this.props.history.location.pathname;
    const disabled = caminhoAtual.replace(new RegExp(`\\${apelidos.app}\\/*`), '') === '';

    if (disabled !== this.props.botaoVoltar.disabled) {
      this.props.atualizarBotaoVoltar({disabled});
    }

    if ((this.props.quantidadeDeTiposDeProdutos > 0) !== this.props.webAppBarraNavegacaoReducer.mostrar) {
      this.props.mudarEstadoMostrarBotoesFlutuantes(this.props.quantidadeDeTiposDeProdutos > 0);
    }

    if (this.props.parametrosEmpresa.idDaEmpresa) {
      this.elementoFilho = this.props.children;
    }

    if (prevProps.webAppQuadroHorariosReducer !== this.props.webAppQuadroHorariosReducer) {
      this.verificaLojaAberta();
    }
  }

  componentWillUnmount() {
    clearInterval(this.atualizaUsuarioInterval);
    clearInterval(this.abertoFechadoInterval);
  }

  render() {
    const location = this.props.history.location;
    /**
     * 
     * @type {string}
     */
    const caminhoAtual = location.pathname;
    /**
     * 
     * @type {typeof React.Component}
     */
    const Dialog = this.props.mostrarDialog
      ? this.props.dialog
      : ((_) => null);

    /**
     * 
     * @type {typeof React.Component}
     */
    const Overlay = this.props.webAppOverlayReducer.componente || ((_) => null);

    return <div key='__principal_tela__' id={`principal-tela`} className={`empresa-mobi-app`} onClick={() => { this.props.mudarEstadoMenuModal({estadoMenu: 'fechado'}); }}>
      <Dialog key='__dialog__' />
      <Overlay />
      <SnackbarTemplate></SnackbarTemplate>
      <BarraTopo sticky={this.state.sticky} parametrosEmpresa={this.props.parametrosEmpresa} />
      {this.props.estadoMenu === 'aberto'
        ? <MenuModal key='__menu_modal__' />
        : null
      }
      {this.state.lojaAberta
        ? null
        : <div id='barra-aberto-fechado'>
            <div>{'Fechado agora'}</div>
          </div>
      }
      <div className='conteudo'>
        {((apelidos.app).replace(location.pathname, '').indexOf('/') === -1)
          ? <>
            <BarraLogoEmpresa
              sticky={this.state.sticky}
              nomeEmpresa={this.props.parametrosEmpresa.valorLinkParametro}
              versaoLogo={this.props.parametrosEmpresa.versaoLogoEmpresa}
            />
            <BarraGrupoProduto />
          </>
          : null
        }
        <div className='conteudo-principal'>
          <div>
            {this.elementoFilho}
          </div>
        </div>
      </div>
      <BarraNavegacao caminhoAtual={caminhoAtual} botaoVoltar={this.props.botaoVoltar} />
    </div>;
  }
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  const props = {
    ...state.webAppNavegacaoReducer,
    //horariosFuncionamento: state.webAppParametrosEmpresaReducer.origemVenda?.empresa?.parametrosEmpresa?.horariosFuncionamento || {diasDaSemana: {}, feriados: {}},
    dialog: state.webAppDialogReducer.dialog,
    estadoMenu: state.webAppMenuModalReducer.estadoMenu,
    mostrarDialog: state.webAppDialogReducer.mostrar,
    parametrosEmpresa: state.webAppParametrosEmpresaReducer,
    quantidadeDeTiposDeProdutos: Object.keys(state.webAppCarrinhoReducer.produtos).length,
    telaMensagens: state.webAppPlaceholderMessageReducer,
    webAppBarraNavegacaoReducer: state.webAppBarraNavegacaoReducer,
    webAppOverlayReducer: state.webAppOverlayReducer,
    webAppFormasPagamentoReducer: state.webAppFormasPagamentoReducer,
    webAppBairroReducer: state.webAppBairroReducer,
    webAppQuadroHorariosReducer: state.webAppQuadroHorariosReducer,
  };

  return props;
};

const mapActionToProps = (dispatch) => ({
  atualizarUsuario: () => dispatch(atualizarUsuario()),
  /**
   * 
   * @param {{disabled: boolean}} botaoVoltar 
   * @returns 
   */
  atualizarBotaoVoltar: (botaoVoltar) => dispatch(atualizarBotaoVoltar(botaoVoltar)),
  dispatch,
  /**
   * 
   * @param {{estadoMenu: import('../../../nucleo/redux/menus/menuModal/menuModalReducer').estadoMenuTipo}} carga 
   * @returns 
   */
  mudarEstadoMenuModal: (carga) => dispatch(mudarEstadoMenuModal(carga)),
  /**
   * 
   * @param {boolean} mostrar 
   * @returns 
   */
  mudarEstadoMostrarBotoesFlutuantes: (mostrar) => dispatch(mudarEstadoMostrarBotoesFlutuantes({mostrar})),
});

export default connect(mapStateToProps, mapActionToProps)(PrincipalTela);
