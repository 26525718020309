import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getReduxWrappedComponent } from "../../../utils/reduxUtils/reduxUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"

import * as pagamentoVendasActions from "../../../store/actions/controleVenda/pagamentoVendasAction"

import InputCustomizado from "../../UI/Input/InputCustomizado"

import "./BuildCellFromQuebraVendaOrigem.css";

/**
 * Item inserido entre os itens de venda para identificar e separar as vendas
 * e exibir totais de cada venda.
 */
class BuildCellFromQuebraVendaOrigem extends React.Component {

  /**
   * Metodo executado após a atualização do componente.
   */
  componentDidUpdate() {
    log("BuildCellFromQuebraVendaOrigem componentDidUpdate")
    if (this.props.new) {
      this.checkboxInput.inputComponent.checked = this.props.checked
    }
  }

  render() {
    log("BuildCellFromQuebraVendaOrigem render")

    let id = getURIFromEntity(this.props.venda) + "&component=checkBox"

    return <div className="table-footer build-cell-from-quebra-venda-origem">
      <div className="table-pagamento table-row row">
        {this.props.new
          ? <div className="table-cell">
            <InputCustomizado
              tabIndex={this.props.tabIndex}
              ref={checkbox => this.checkboxInput = getReduxWrappedComponent(checkbox)}
              id={id}
              type="checkbox"
              name={id}
              handleInputValidado={() => this.props.setNewSalePaymentItemAmount(
                this.props.salePaymentItemKeyList,
                this.props.checked ? -1 : +1,
                true)}
            />
          </div>
          : <div style={{ backgroundColor: "transparent" }}>{/* Padding branco */}</div>}
        <div className="table-cell quebra TextAlign" >
          {getStrings().saleSourceAndSaleTemplate((this.props.origemVenda || {}).nome || "", (this.props.venda || {}).nome || "")}
        </div>
      </div>
    </div>
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  tabIndex: state.appReducer.getTabIndex()
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  setNewSalePaymentItemAmount: (salePaymentItemKeyList, sign, all) =>
    dispatch(pagamentoVendasActions.setNewSalePaymentItemAmount(salePaymentItemKeyList, sign, all))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BuildCellFromQuebraVendaOrigem)