import React from 'react'

import "./Botao.css";

import { connect } from 'react-redux';

type propsPadrao = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

function Botao(props: propsPadrao) {
  return <button {...props} className={'ui-botao-interno ' + (props.className + '')} />;
}

type tipoProps = {
  tabIndex?: number,
}

const mapStateToProps = (estado: any) => ({
  tabIndex: estado.appReducer.getTabIndex(),
} as tipoProps);

const mapDispatchToProps = (despacho: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Botao);
