import React from "react"

import { connect } from "react-redux"
import * as actions from "../../store/actions/producaoAction"
import * as appActions from "../../store/actions/appAction"

import { ClienteHttp } from "../../apps/promo_app_frontend/nucleo/utils/utils"
import { getAppUsuario } from "../../utils/AppUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { getMapaApi } from "../../utils/MapUtils"
import { getReduxWrappedComponent } from "../../utils/reduxUtils/reduxUtils"
import { updateActiveMenuItem } from "../../utils/ScreenUtils"
import SoundManager from "../../assets/sound/SoundManager"
import { formatI18nString } from "../../utils/StringUtils"
import { getIdFromEntity, getIdFromURI, getURIFromEntity } from "../../utils/URIUtils"
import { getEndereco } from "../../utils/EnderecoUtils/EnderecoUtils"

import {
    CODIGO_ORDENACAO_ESTADO,
    TELA_PRONTOS,
    TELA_EM_PRODUCAO,
    PARAMETRO_BUSCA_ORDENACAO,
    PARAMETRO_BUSCA_FILTRO } from "../../store/reducers/producaoReducer"
    import { ESTADO_ITEM_VENDA_ENTREGUE, ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA } from "../../store/reducers/controleVenda/controleVendaReducer"

import BarraAcoesProducao from "./barraAcao/BarraAcoesProducao"
import GridProducao from "./grid/GridProducao"
import HelpParagraph from "../UI/HelpParagraph/HelpParagraph"
import WidthAwareDiv from "../UI/WidthAwareDiv/WidthAwareDiv"
import DialogItemVenda from "./dialog/DialogItemVenda"
import InputCustomizado from "../UI/Input/InputCustomizado"
import ProducaoFiltro from "./filtro/ProducaoFiltro"
import ProducaoOrdenacao from "./filtro/ProducaoOrdenacao"
import GridPedidosProducao from "./grid/GridPedidosProducao"
import DialogVenda from "./dialog/DialogVenda"
import { Button } from "../UI/Button/Button"

import "../vendas/vendas.css";
import "./ProducaoBox.css";

class ProducaoBox extends React.Component {
    state = {
        atualizacaoNotificarNovosItensPendente: false
        , modoSelecao: false
        , selecionados: []
        , estadoSelecionarTodos: 0
        , filtrarIsOpen: false
        , ordenarIsOpen: false
    }

    tempoAmareloComponent
    tempoVermelhoComponent
    agruparProdutosVendaComponent
    quantidadeEntregasComponent
    valorTelesComponent
    mostrarEncerradosComponent
    enderecos = []
    mapaApi = null

    /**
     * Método executado APÓS a montagem/renderização do componente.
     * Marca o componente como montado.
     */
    componentDidMount() {
        log("ProducaoBox componentDidMount")

        updateActiveMenuItem("menuItemProducao", "menuItemVenda")

        const agruparProdutosVenda = localStorage.getItem('agruparProdutosVenda') === 'true'

        this.props.setAgruparProdutosVenda(agruparProdutosVenda)

        this.props.setValue(true, "visible")

        this.props.atualizarTelaAtual(this.props.showProduced || this.props.somenteEntregador ? TELA_PRONTOS : TELA_EM_PRODUCAO)

        if (this.props.somenteEntregador) {
            this.props.setValue(true, "isEntregador")
            this.props.setValue(true, "showProduced")

            this.props.updateParametrosBusca(PARAMETRO_BUSCA_ORDENACAO, CODIGO_ORDENACAO_ESTADO, "ordenacao")
            this.props.updateParametrosBusca(PARAMETRO_BUSCA_FILTRO, getAppUsuario(), "idEntregador")
        }

        this.componentDidUpdate()
    }

    /**
     * Método executado APÓS a montagem/renderização do componente.
     */
    componentDidUpdate(prevProps) {

        // Se passou a estar visível, carrega os itens.
        if (((!prevProps || !prevProps.visible) && this.props.visible) 
            || (prevProps && prevProps.agruparProdutosVenda !== this.props.agruparProdutosVenda)
            || (prevProps && prevProps.mostrarEncerrados !== this.props.mostrarEncerrados)) {
            this.props.loadItensVendaAbertos()
        }

        if (prevProps && prevProps.showProduced !== this.props.showProduced) {
            this.setState({estadoSelecionarTodos: 0 })
        }

        if (this.props.somNovoItem) {
            SoundManager("bellRing")
            this.props.setValue(false, "somNovoItem")
        }

        this.loadTempoAmarelo()
        this.loadTempoVermelho()
        this.loadAgruparProdutosVenda()
        this.loadQuantidadeEntregas()
        this.loadValorTeles()
        this.loadMostrarEncerrados()
        //this.notificarNovosItens()
    }

    /**
     * Método executado ANTES de "DESMONTAR" o componente.
     * Marca o componente como NÃO montado.
     */
    componentWillUnmount() {
        log("ProducaoBox componentWillUnmount")

        this.props.setValue(false, "visible")
    }

    loadTempoAmarelo = () => {
        if (this.tempoAmareloComponent) {
            this.tempoAmareloComponent.inputComponent.value = this.props.tempoAmarelo
        }
    }

    loadTempoVermelho = () => {
        if (this.tempoVermelhoComponent) {
            this.tempoVermelhoComponent.inputComponent.value = this.props.tempoVermelho
        }
    }

    loadAgruparProdutosVenda = () => {
        if (this.agruparProdutosVendaComponent) {
            this.agruparProdutosVendaComponent.inputComponent.checked = this.props.agruparProdutosVenda
        }
    }

    loadQuantidadeEntregas = () => {
        if (this.quantidadeEntregasComponent) {
            this.quantidadeEntregasComponent.inputComponent.value = this.props.quantidadeEntregas
        }
    }

    loadValorTeles = () => {
        if (this.valorTelesComponent) {
            this.valorTelesComponent.inputComponent.value = this.props.valorTeles
        }
    }

    loadMostrarEncerrados = () => {
        if (this.mostrarEncerradosComponent) {
            this.mostrarEncerradosComponent.inputComponent.checked = this.props.mostrarEncerrados
        }
    }

    notificarNovosItens = () => {
        if (!this.state.atualizacaoNotificarNovosItensPendente) {
            const vendaList = this.props.itemProducaoList.map((itemProducao) => itemProducao.itemVenda.venda)
            let vendas = {}

            vendaList.forEach((venda) => {
                vendas[venda._links.self.href] = venda
            })

            let deveNotificar = false
            let vendaIds = []

            for (const vendaId in vendas) {
                const venda = vendas[vendaId]
                const vendaIdNumber = window.parseInt(vendaId.replace(ClienteHttp.getApi("vendas/"), ""))

                if (venda.notificarNovoItem) {
                    deveNotificar = true
                    vendaIds.push(vendaIdNumber)
                }
            }

            if (deveNotificar) {
                this.setState({atualizacaoNotificarNovosItensPendente: true})

                ClienteHttp.requisicaoServidor("vendas/update", "post", true, undefined, {
                    vendaList: vendaIds
                    , notificarNovoItem: false
                })
                    .then(() => {
                        SoundManager("bellRing")
                    })
                    .finally(() => {
                        this.setState({atualizacaoNotificarNovosItensPendente: true})
                    })
            }
        }
    }

    getVendasSelecionadas = () => this.props.vendaList.filter(
        (item) => this.state.selecionados.includes(getIdFromURI(getURIFromEntity(item))))

    getItensVendaSelecionados = () => this.props.itemVendaList.filter(
        (item) => this.state.selecionados.includes(getIdFromURI(getURIFromEntity(item))))

    getItensProducaoSelecionados = () => this.props.itemProducaoList.filter(
            (itemProducao) => this.state.selecionados.includes(getIdFromURI(getURIFromEntity(itemProducao))))

    handleTempoAmarelo = () => {
        if (this.tempoAmareloComponent.inputComponent.value) {
            this.props.setValue(this.tempoAmareloComponent.inputComponent.value, "tempoAmarelo")
        }
    }

    handleTempoVermelho = () => {
        if (this.tempoVermelhoComponent.inputComponent.value) {
            this.props.setValue(this.tempoVermelhoComponent.inputComponent.value, "tempoVermelho")
        }
    }

    handleAgruparProdutosVenda = () => {
        this.setState({selecionados: []})
        this.props.switchAgruparProdutosVenda()
    }

    handleMostrarEncerrados = () => {
        this.props.switchMostrarEncerrados()
    }

    selecionarTodos = () => {
        if (this.state.estadoSelecionarTodos) {
            this.setState({ selecionados: [], estadoSelecionarTodos: 0 })
        }
        else {
            let selecionados = []
            if (this.props.showProduced) {
                this.props.vendaList.forEach(venda => selecionados.push(getIdFromURI(getURIFromEntity(venda))))
            }
            else if (this.props.agruparProdutosVenda) {
                this.props.itemVendaList.forEach(itemVenda => selecionados.push(getIdFromURI(getURIFromEntity(itemVenda))))
            }
            else {
                this.props.itemProducaoList.forEach(itemProducao => selecionados.push(getIdFromURI(getURIFromEntity(itemProducao))))
            }
            this.setState({ selecionados, estadoSelecionarTodos: 1 })
        }
    }

    atualizarSelecao = (id) => {
        const selecionados = this.state.selecionados

        if (selecionados.includes(id)) {
            selecionados.splice(selecionados.indexOf(id), 1)
        }
        else {
            selecionados.push(id)
        }

        const quantidadeItens = this.props.showProduced ? this.props.vendaList.length : this.props.agruparProdutosVenda ? this.props.itemVendaList.length : this.props.itemProducaoList.length
        const estadoSelecionarTodos = selecionados.length === quantidadeItens ? 1 : 0

        this.setState({selecionados, estadoSelecionarTodos})
    }

    buildHeader = () => {
        if (!this.props.isEntregador && !this.props.showProduced) {
            return <div className="producao-header">
                <div className="temposAviso">
                    <div className="tempoAmarelo">
                        <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.tempoAmareloComponent = getReduxWrappedComponent(input)} id="tempoAmarelo" type="text" name="tempoAmarelo" label={getStrings().timeYellow} handleInputValidado={this.handleTempoAmarelo} />
                    </div>
                    <div className="tempoVermelho">
                        <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.tempoVermelhoComponent = getReduxWrappedComponent(input)} id="tempoVermelho" type="text" name="tempoVermelho" label={getStrings().timeRed} handleInputValidado={this.handleTempoVermelho} />
                    </div>
                </div>
                <div className="agruparProdutosVenda">
                    <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.agruparProdutosVendaComponent = getReduxWrappedComponent(input)} id="agruparProdutosVenda" type="checkbox" name="agruparProdutosVenda" label={getStrings().groupProductsBySale} handleInputValidado={this.handleAgruparProdutosVenda} />
                </div>
            </div>
        }
        else if (this.props.isEntregador && this.props.showProduced) {
            return <div className="producao-header">
            <div className="resumoEntregador">
                <div className="quantidadeEntregas">
                    <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.quantidadeEntregasComponent = getReduxWrappedComponent(input)} id="quantidadeEntregas" type="text" name="quantidadeEntregas" label={getStrings().deliveryAmount} />
                </div>
                <div className="valorTeles">
                    <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.valorTelesComponent = getReduxWrappedComponent(input)} id="valorTeles" type="text" name="valorTeles" label={getStrings().deliveriesValue} />
                </div>
            </div>
            <div className="mostrarEncerrados">
                <InputCustomizado tabIndex={this.props.tabIndex} ref={input => this.mostrarEncerradosComponent = getReduxWrappedComponent(input)} id="mostrarEncerrados" type="checkbox" name="mostrarEncerrados" label={getStrings().showClosedOrders} handleInputValidado={this.handleMostrarEncerrados} />
            </div>
        </div>
        }
    }

    MapaElement = ({vendaList = [], map, directions, position, mostrarRota}) => {
        console.log(this.enderecos)
        const origem = position || this.enderecos.slice(0, 1)[0]

        if (!position) {
            this.enderecos = this.enderecos.slice(1)
        }

        const enderecos = this.enderecos

        if (vendaList.length > 0) {
            mostrarRota(map, directions, enderecos, {isDestinoOrigem: false, origem})
        }

        return <>
            <div key="__mapa__" id="mapa" style={{height: "60vh"}}></div>
            <Button className="color-secondary text-color-white" key="__entregue__" onClick={() => {
                const origem = this.enderecos.slice(0, 1)[0]
                this.enderecos = this.enderecos.slice(1)
                const enderecos = this.enderecos

                mostrarRota(map, directions, enderecos, {isDestinoOrigem: false, origem: origem})

                const vendas = vendaList.filter((venda) => {
                    const endereco = getEndereco(venda)
                    const cep = origem
                        .replace(/-/g, "")

                    return cep
                        .includes(endereco.cep)
                })

                vendas.forEach((venda) => {
                    const vendaIds = [getIdFromEntity(venda)]
                    
                    this.props.dispatch(
                        actions.updateItemVenda({
                            estado: ESTADO_ITEM_VENDA_ENTREGUE,
                            vendaList: vendaIds,
                        }, ClienteHttp.getApi("vendas/update"))
                    )
                })

                if (!enderecos.length) {
                    this.mapaApi = null
                    this.props.dispatch(appActions.appDialogHide())
                }
            }}>
                {getStrings().delivered}
            </Button>
        </>
    }

    mostrarRotaCallback = (venda) => {
        const self = this
        const vendasSelecionadas = (self.getVendasSelecionadas() || []).length > 0
            ? self.getVendasSelecionadas()
            : venda
                ? [venda]
                : []
        const vendaList = vendasSelecionadas.filter(item => {
            return item.estadoItensVenda === ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA || item.estado === ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA
        })
        if (vendaList.length > 0) {
            (async () => {
                const Component = vendaList.length === 0
                    ? (() => <div>{`${getStrings().noSaleSourceSeSelectedForDeliveryMessage}`}</div>)
                    : self.MapaElement

                self.props.dispatch(appActions.appDialogShow(<Component />, "", null, null, () => {
                    self.endereco = {
                        location: "",
                        index: -1,
                        get next() {
                            return (enderecos) => {
                                self.index = self.index + 1
                                self.location = enderecos[self.index]
                            }
                        }
                    }
                }))

                const mapaElement = document.getElementById("mapa")

                if (!self.mapaApi || mapaElement?.innerHTML === "") {
                    self.mapaApi = await getMapaApi({idDoElemento: "mapa"}).init()
                }

                const {map, directions, position, mostrarRota, ordernarRotasDistancia} = await self.mapaApi

                if (vendaList.length) {
                    const enderecos = vendaList.map((venda) => {
                        const enderecoCompleto = getEndereco(venda)
                        const { endereco, numero, bairro, municipio, uf, cep } = enderecoCompleto

                        return `${endereco}, ${numero} - ${bairro}, ${municipio} - ${uf}, ${cep}`
                    })

                    const ends = await ordernarRotasDistancia([position, ...enderecos], enderecos)

                    const enderecosDestino = ends.map((endereco) => {
                        return endereco.to
                    })

                    self.enderecos = enderecosDestino
        
                    self.props.dispatch(appActions.appDialogShow(<self.MapaElement {...{vendaList, map, directions, position, mostrarRota}} />, "", null, null, () => { this.mapaApi = null }))
                }
            })()
        }
    }

    /**
     * Método que executa a montagem/rederização do componente.
     */
    render() {
        const self = this
        const vendasSelecionadas = self.getVendasSelecionadas() || []
        return <>
            
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {/* Header do box */}
                <div className="header breadCrumbs">
                    <h1>{getStrings().production}</h1>
                    <h2>{this.props.showProduced ? getStrings().finishedItemsState : getStrings().producingItemsState}</h2>
                </div>
                {this.buildHeader()}
            </div>

            <WidthAwareDiv>
                <HelpParagraph children={getStrings().productionHelp.concat([getStrings().navigationBarHelp]).map(string => formatI18nString(string))} />
            </WidthAwareDiv>

            <BarraAcoesProducao
                somenteEntregador={this.props.somenteEntregador}
                page={{ number: 0, size: this.props[this.props.telaAtual].page.size }}
                pageSize={this.props[this.props.telaAtual].page.size}
                onNavigate={this.props.loadItensVendaAbertos}
                updatePageSize={this.props.updatePageSize}
                selecionarMetodo={() => {
                    this.setState({selecionados: [], modoSelecao: !this.state.modoSelecao, estadoSelecionarTodos: 0})
                }}
                isVendasParaEntregar={vendasSelecionadas.length > 0 && vendasSelecionadas.filter((item) => {
                    return item.estadoItensVenda !== ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA || !getEndereco(item)
                }).length === 0}
                modoSelecao={this.state.modoSelecao}
                selecionarTodos={this.selecionarTodos}
                estadoSelecionarTodos={this.state.estadoSelecionarTodos}
                abrirDialogDaSelecao={() => {
                    this.props.appDialogShow(
                        this.props.showProduced
                        ? <DialogVenda
                            vendasList={this.getVendasSelecionadas()}
                            isEntregador={this.props.isEntregador}
                            onDialogHide={() => this.setState({modoSelecao: false, estadoSelecionarTodos: 0})}
                            mostrarRotaCallback={() => this.mostrarRotaCallback()}
                        />
                        : <DialogItemVenda
                            itemVendasList={this.getItensVendaSelecionados()}
                            itemProducaoList={this.props.agruparProdutosVenda ? undefined : this.getItensProducaoSelecionados()}
                            onDialogHide={() => this.setState({modoSelecao: false, estadoSelecionarTodos: 0})}
                        />
                        , null
                        , {
                            maxWidth: "400px"
                            , maxHeight: "250px"
                            , width: "75%"
                            //, height: "240px"
                            , top: "12%"
                            , left: "12%"
                            , marginTop: "0px"
                            , marginLeft: "0px"
                        }
                    )
                }}
                imprimir={() => {
                    if (this.props.showProduced) {
                        this.getVendasSelecionadas().forEach(venda => this.props.setPrinted(getURIFromEntity(venda.itemVendaList[0]), false, true))
                    }
                    else if (this.props.agruparProdutosVenda) {
                        this.getItensVendaSelecionados().forEach(itemVenda => this.props.setPrinted(getURIFromEntity(itemVenda), false, false))
                    }
                    else {
                        this.getItensProducaoSelecionados().forEach(itemProducao => this.props.setPrinted(getURIFromEntity(itemProducao.itemVenda), false, false))
                    }
                }}
                mostrarRotaCallback={() => this.mostrarRotaCallback()}
                filtrarIsOpen={this.state.filtrarIsOpen}
                ordenarIsOpen={this.state.ordenarIsOpen}
                ordenarMetodo={() => {
                    this.setState({ordenarIsOpen: !this.state.ordenarIsOpen})
                }}
                filtrarMetodo={() => {
                    this.setState({filtrarIsOpen: !this.state.filtrarIsOpen})
                }}
            />

            {this.state.ordenarIsOpen
                ?
                <ProducaoOrdenacao />
                : null
            }

            {this.state.filtrarIsOpen
                ? <ProducaoFiltro />
                : null
            }

            {this.props.showProduced
                ? <GridPedidosProducao
                    vendalist={this.props.vendaList}
                    modoSelecao={this.state.modoSelecao}
                    selecionados={this.state.selecionados}
                    atualizarSelecao={(id) => this.atualizarSelecao(id)}
                    mostrarRotaCallback={this.mostrarRotaCallback}
                />
                : <GridProducao
                    vendalist={this.props.vendaList}
                    modoSelecao={this.state.modoSelecao}
                    selecionados={this.state.selecionados}
                    atualizarSelecao={(id) => this.atualizarSelecao(id)}
                />
            }
        </>
    }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

    ...state.producaoReducer,
    ...state.idiomaReducer,
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles, onClosedClicked) =>
        dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles, onClosedClicked)),
    appDialogHide: () => dispatch(appActions.appDialogHide()),
    dispatch,
    loadItensVendaAbertos: link => dispatch(actions.loadItensVendaAbertos(link)),
    setValue: (value, position) => dispatch(actions.setValue(value, position)),
    setPrinted: (itemVenda, printed, printOtherItems) => dispatch(actions.setPrinted(itemVenda, printed, printOtherItems)),
    switchAgruparProdutosVenda: () => dispatch(actions.switchAgruparProdutosVenda()),
    setAgruparProdutosVenda: (agruparProdutosVenda) => dispatch(actions.setAgruparProdutosVenda(agruparProdutosVenda)),
    switchMostrarEncerrados: () => dispatch(actions.switchMostrarEncerrados()),
    updateParametrosBusca: (tipoParametro, valor, posicao) => dispatch(actions.updateParametrosBusca(tipoParametro, valor, posicao)),
    atualizarTelaAtual: (telaAtual) => dispatch(actions.atualizarTelaAtual(telaAtual))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ProducaoBox)
