import React from 'react';

import "./Spinner.css";

/**
 * Div que exibe um spinner normal.
 * @param {*} id opcional, identifica o spinner para poder ser exibido e ocultado
 */
export const loadSpinner = (id) => (
    <div className='Loader' id={id}/>
)

/**
 * Div que exibe um spinner pequeno para campos de entrada.
 * @param {*} id opcional, identifica o spinner para poder ser exibido e ocultado
 */
export const loadSpinnerInput = (id) => (
    <div className='LoaderInput' id={id}/>
)

/**
 * Div que exibe um spinner que bloqueia toda a área de conteúdo.
 * @param {*} id opcional, identifica o spinner para poder ser exibido e ocultado
 */
export const loadSpinnerModal = (id) => (
    <div className='Loader' id={id}/>
)

/**
 * Exibe um spinner que já está montado na tela.
 * @param {*} id identificador do spinner a ser exibido
 */
export const showSpinner = (id) => {
    const spinner = document.getElementById(id);
    if (spinner) {
        spinner.style.visibility = 'visible';
    }
}

/**
 * Oculta um spinner que já está montado na tela.
 * @param {*} id identificador do spinner a ser ocultado
 */
export const hideSpinner = (id) => {
    const spinner = document.getElementById(id);
    if (spinner) {
        spinner.style.visibility = 'hidden';
    }
}

export default loadSpinner;
