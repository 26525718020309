//@ts-check
import React from 'react';
import { LocalidadeUtils, NavegacaoUtils } from '../../../nucleo/utils/utils';
import { BalaoTexto, Botao } from '../../../nucleo/nucleo';
import apelidos from '../../../rotas/apelidos';
import config from '../../../configuracoes/config';
import { connect } from 'react-redux';
import { mostrarDialog } from '../../../nucleo/redux/dialog/dialogActions';
import { deslogarUsuario } from '../../../nucleo/redux/usuario/usuarioActions';
import { mudarEstadoMenuModal } from '../../../nucleo/redux/menus/menuModal/menuModalActions';
import LoginDialog from '../../dialogs/LoginDialog/LoginDialog';
import InformacaoEmpresaDialog from '../../dialogs/InformacaoEmpresaDialog/InformacaoEmpresaDialog';

import './MenuModal.css';

/**
 * 
 * @param {{children: any, mudarEstadoMenuModal: (carga: {estadoMenu: import('../../../nucleo/redux/menus/menuModal/menuModalReducer').estadoMenuTipo}) => any, onClick: (event: any) => any}} props 
 * @returns 
 */
function BotaoMenu(props) {
  return <Botao style={{
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row wrap',
    //width: '100%',
    height: '3em',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'var(--color-secundaria)',
    color: 'var(--color-secundaria-texto)',
    border: 'none',
    padding: 0,
    paddingLeft: '40px'
  }} onClick={(event) => { props.onClick(event); props.mudarEstadoMenuModal({estadoMenu: 'fechado'}); }}>{props.children}</Botao>
}

/**
 * 
 * @param {import('./types').menuModalPropsType} props 
 * @returns 
 */
const BotoesDeslogados = (props) => {
  if (props.webAppUsuarioReducer.verificaUsuarioLogado()) {
    return null;
  }

  const Componente = () => <LoginDialog fecharHabilitado />;

  return <>
    <BotaoMenu mudarEstadoMenuModal={props.mudarEstadoMenuModal} onClick={() => { props.mostrarDialog(Componente) }}>{LocalidadeUtils.obterTraducao().menu.login}</BotaoMenu>
    <BotaoMenu mudarEstadoMenuModal={props.mudarEstadoMenuModal} onClick={() => { props.dispatch(NavegacaoUtils.goTo(apelidos.cadastro)); }}>{LocalidadeUtils.obterTraducao().menu.signUp}</BotaoMenu>
    <BotaoMenu mudarEstadoMenuModal={props.mudarEstadoMenuModal} onClick={() => { props.dispatch(mostrarDialog({conteudo: () => <InformacaoEmpresaDialog />})); }}>{LocalidadeUtils.obterTraducao().menu.companyInformation}</BotaoMenu>
  </>;
};

/**
 * 
 * @param {import('./types').menuModalPropsType} props 
 * @returns 
 */
const BotoesLogados = (props) => {
  if (!props.webAppUsuarioReducer.verificaUsuarioLogado()) {
    return null;
  }

  return <>
    <BotaoMenu mudarEstadoMenuModal={props.mudarEstadoMenuModal} onClick={() => { props.dispatch(NavegacaoUtils.goTo(apelidos.pedidos)); }}>{LocalidadeUtils.obterTraducao().menu.requests}</BotaoMenu>
    <BotaoMenu mudarEstadoMenuModal={props.mudarEstadoMenuModal} onClick={() => { props.dispatch(NavegacaoUtils.goTo(apelidos.editar, {editar: true})); }}>{LocalidadeUtils.obterTraducao().menu.editRegister}</BotaoMenu>
    <BotaoMenu mudarEstadoMenuModal={props.mudarEstadoMenuModal} onClick={() => { props.dispatch(mostrarDialog({conteudo: () => <InformacaoEmpresaDialog />})); }}>{LocalidadeUtils.obterTraducao().menu.companyInformation}</BotaoMenu>
    <BotaoMenu mudarEstadoMenuModal={props.mudarEstadoMenuModal} onClick={() => { props.sair(); }}>{LocalidadeUtils.obterTraducao().menu.exit}</BotaoMenu>
  </>;
};

class MenuModal extends React.Component {
  render() {
    return <BalaoTexto className='menu-modal'>
      {/* @ts-ignore */}
      {this.props.webAppUsuarioReducer.verificaUsuarioLogado() ? <BotoesLogados {...this.props} /> : <BotoesDeslogados {...this.props} />
      }
      {config.modoDesenvolvedor
        ? <BotaoMenu mudarEstadoMenuModal={this.props.mudarEstadoMenuModal} key='__desenvolvedor_tela__' onClick={() => {}}>Dev</BotaoMenu>
        : null
      }
    </BalaoTexto>;
  }
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  webAppUsuarioReducer: state.webAppUsuarioReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  /**
   * 
   * @param {{estadoMenu: import('../../../nucleo/redux/menus/menuModal/menuModalReducer').estadoMenuTipo}} carga 
   * @returns 
   */
  mudarEstadoMenuModal: (carga) => dispatch(mudarEstadoMenuModal(carga)),
  /**
   * 
   * @param {React.ElementType} Dialog 
   * @returns 
   */
  mostrarDialog: (Dialog) => dispatch(mostrarDialog({conteudo: () => <Dialog />})),
  sair: () => dispatch(deslogarUsuario()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuModal);
