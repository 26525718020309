import "./GridGrupoProduto.css"

import React from "react"
import moment from "moment"
import { connect } from "react-redux"
import { getStrings } from "../../../utils/LocaleUtils"
import { log } from "../../../utils/LogUtils"
import { getURIFromEntity } from "../../../utils/URIUtils"
import {
	GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED
	, GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_OPEN
	, GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_CODE_NUMERICAL
	, GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_NAME_ALPHABETICAL
} from "../../../store/reducers/controleVenda/manutencao/manutencaoVendaReducer"
import * as gridGrupoProdutoActions from "../../../store/actions/controleVenda/manutencao/gridGrupoProdutoAction"
import BuildCellFromGrupoProduto from "./BuildCellFromGrupoProduto"
import BuildCellFromProduto from "./BuildCellFromProduto"
import GridDisplaySelect from "./GridDisplaySelect"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import { DATE_INPUT, TIME_INPUT } from "../../UI/dateTimeInputDefault/DateTimeInputDefault"
import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"


function pesquisarProduto(produto, entradaBuscaReducer) {
	const nome = produto.nome.toLowerCase();
	const codigo = produto.codigo.toLowerCase();
	const codigoImpresso = (produto.codigoImpresso || "").toLowerCase();
	const texto = entradaBuscaReducer.texto.toLowerCase();
	const filtrarProduto = texto === "" || (nome.includes(texto) || codigo.includes(texto) || codigoImpresso.includes(texto));

	return filtrarProduto;
}

/**
 * Classe que monta a tela que exibe os grupos de produtos com os seus produtos.
 */
class GridGrupoProduto extends React.Component {

	state = {
		valorItensSelecionados: 0,
		telaModal: null,
	}

	componentDidUpdate() {

		if (this.state.telaModal) {
			document.body.style.overflow = "hidden";
		}
		else {
			document.body.style.overflow = "unset";
		}

		const valorItensSelecionados = this.props.controleVendaReducer.sentSaleItemTotal + this.props.controleVendaReducer.newSaleItemTotal;

		if (valorItensSelecionados !== this.state.valorItensSelecionados) {
			if (this.props.atualizarEstado) {
				this.props.atualizarEstado({ valorItensSelecionados });
			}
			this.setState({ valorItensSelecionados });
		}
	}

	atualizarParametroTempos = () => {
		const { tempoProducao, tempoEntrega } = this.props.buscarEstado().cadastroComanda.entregaVenda;
		ClienteHttp.requisicaoServidor("usuarios/updateParametros", "post", true, null, { tempoProducao, tempoEntrega });
	}

	abreTelaModal = (telaModal) => {
		this.setState({ telaModal });
	}

	fechaTelaModal = () => {
		this.setState({ telaModal: null });
	}

	Bar = (props) => {

		if (!props.buscarEstado || ["Balcão", "Mesa", "Cartão", "Painel"].includes(props.tipoOrigem)) {
			return <GridDisplaySelect getDisplaySelector={(ref) => props.setDisplaySelector(ref)} />;
		}

		const {
			dataHoraEntrega,
			tempoProducao,
			tempoEntrega,
			horaEntrega
		} = props.buscarEstado().cadastroComanda.entregaVenda;

		if (moment.isMoment(dataHoraEntrega) && dataHoraEntrega.format("YYYY-MM-DD") !== moment().add(tempoEntrega || "0", "minute").format("YYYY-MM-DD")) {

			console.log(dataHoraEntrega.format("YYYY-MM-DD"));
			console.log(moment().add(tempoEntrega || "0", "minute").format("YYYY-MM-DD"));

			const novoEstado = {
				cadastroComanda: {
					...props.buscarEstado().cadastroComanda,
					entregaVenda: {
						...props.buscarEstado().cadastroComanda.entregaVenda,
						dataHoraEntrega: moment().add(tempoEntrega || "0", "minute")
					}
				}
			};

			props.atualizarEstado(novoEstado);
		}

		const rowStyle = { display: "flex", flexFlow: "row wrap", padding: "auto", flex: "1 1 auto", justifyItems: "center", justifyContent: "center", margin: "5px 0 15px 0" };

		return <div style={rowStyle}>
			<GridDisplaySelect ignorarWidthAwareDiv={true} getDisplaySelector={(ref) => props.setDisplaySelector(ref)} topClassName={"telaPedido"} />
			<InputCustomizado
				newApi
				topClassName={"topClassNameStyleTempoPedido"}
				value={tempoProducao}
				onChange={(evt) => {
					const tempoProducao = parseInt(evt.target.value || "0");

					const novoEstado = {
						cadastroComanda: {
							...props.buscarEstado().cadastroComanda,
							entregaVenda: {
								...props.buscarEstado().cadastroComanda.entregaVenda,
								tempoProducao
							}
						}
					};

					props.atualizarEstado(novoEstado);
				}}
				onBlur={() => this.atualizarParametroTempos()}
				inputClassName={"tempo-cadastro-pedido left"}
				label={getStrings().productionTime}
				type={"text"}
				inputType={"number"}
				validacaoDados={"numeroInteiro"}
				max={999999}
			/>
			<InputCustomizado
				newApi
				topClassName={"topClassNameStyleTempoPedido"}
				value={tempoEntrega}
				onChange={(evt) => {
					const tempoEntrega = parseInt(evt.target.value || "0");
					const horaEntrega = moment().add(evt.target.value || "0", "minute");
					const dataHoraEntrega = horaEntrega;

					const novoEstado = {
						cadastroComanda: {
							...props.buscarEstado().cadastroComanda,
							entregaVenda: {
								...props.buscarEstado().cadastroComanda.entregaVenda,
								tempoEntrega,
								horaEntrega,
								dataHoraEntrega
							}
						}
					};

					props.atualizarEstado(novoEstado);
				}}
				onBlur={() => this.atualizarParametroTempos()}
				inputClassName={"tempo-cadastro-pedido right"}
				label={getStrings().deliveryTime}
				type={"text"}
				inputType={"number"}
				validacaoDados={"numeroInteiro"}
				max={999999}
			/>
			<InputCustomizado
				newApi
				topClassName={"topClassNameStyleTempoPedido"}
				value={dataHoraEntrega}
				onChange={(dataHoraEntrega) => {
					const diaAtualMoment = moment().seconds(0).milliseconds(0);
					const dataHoraEntregaMoment = moment(dataHoraEntrega);
					const horaEntregaMoment = moment(props.buscarEstado().cadastroComanda.entregaVenda.horaEntrega);
					const YYYY = dataHoraEntregaMoment.year();
					const MM = dataHoraEntregaMoment.month() + 1;
					const DD = dataHoraEntregaMoment.date();
					const HH = horaEntregaMoment.hours();
					const mm = horaEntregaMoment.minutes();
					const diaEntregaMoment = moment(`${YYYY}-${MM}-${DD} ${HH}:${mm}`, "YYYY-MM-DD HH:mm");

					if (diaEntregaMoment.isSameOrAfter(diaAtualMoment)) {
						const tempoEntrega = diaEntregaMoment.diff(diaAtualMoment, "minutes");

						const novoEstado = {
							cadastroComanda: {
								...props.buscarEstado().cadastroComanda,
								entregaVenda: {
									...props.buscarEstado().cadastroComanda.entregaVenda,
									tempoEntrega,
									dataHoraEntrega
								}
							}
						};

						props.atualizarEstado(novoEstado);
					}
				}}
				type={"text"}
				inputType={DATE_INPUT}
				inputClassName={"tempo-cadastro-pedido left"}
				label={getStrings().deliveryDate}
			/>
			<InputCustomizado
				newApi
				topClassName={"topClassNameStyleTempoPedido"}
				value={horaEntrega}
				onChange={(value) => {

					let horaEntregaMoment = null;

					if (typeof value === "string") {
						let valueTemp = value.replace(/\D/g, "");
						const valueArray = valueTemp.split("");
						if (valueArray.length > 3) {
							valueTemp = valueArray[0] + valueArray[1] + ":" + valueArray[2] + valueArray[3];
							horaEntregaMoment = moment(valueTemp, "HH:mm");
						}
					}
					else {
						horaEntregaMoment = moment(value);
					}

					const estado = props.buscarEstado();
					const dataHoraEntregaMoment = moment(estado.cadastroComanda.entregaVenda.dataHoraEntrega);

					const horaAtualMoment = moment().seconds(0).milliseconds(0);
					const YYYY = dataHoraEntregaMoment.year();
					const MM = dataHoraEntregaMoment.month() + 1;
					const DD = dataHoraEntregaMoment.date();
					const mm = horaEntregaMoment ? horaEntregaMoment.minutes() : "00";
					const HH = horaEntregaMoment ? horaEntregaMoment.hours() : "00";
					const diaEntregaMoment = moment(`${YYYY}-${MM}-${DD} ${HH}:${mm}`, "YYYY-MM-DD HH:mm");

					if (horaEntregaMoment && horaEntregaMoment.isValid() && diaEntregaMoment.isSameOrAfter(horaAtualMoment)) {
						const novoEstado = {
							cadastroComanda: {
								...estado.cadastroComanda,
								entregaVenda: {
									...estado.cadastroComanda.entregaVenda,
									tempoEntrega: diaEntregaMoment.diff(horaAtualMoment, "minutes"),
									horaEntrega: horaEntregaMoment,
								}
							}
						};

						props.atualizarEstado(novoEstado);
					}
				}}
				onBlur={(value) => {
					if (typeof value === "string") {
						const estado = props.buscarEstado();
						const horaAtualMoment = moment().seconds(0).milliseconds(0);
						const novoEstado = {
							cadastroComanda: {
								...estado.cadastroComanda,
								entregaVenda: {
									...estado.cadastroComanda.entregaVenda,
									tempoEntrega: 0,
									horaEntrega: horaAtualMoment,
								}
							}
						};
						props.atualizarEstado(novoEstado);
					}
				}}
				inputClassName={"tempo-cadastro-pedido right"}
				label={getStrings().deliveryHour}
				type={"date"}
				inputType={TIME_INPUT}
			/>
			<InputCustomizado
				newApi
				disabled
				type={"text"}
				inputType={"text"}
				topClassName={"topClassNameStyleTempoPedido"}
				inputClassName={"tempo-cadastro-pedido right"}
				label={getStrings().totalValue}
				value={this.state.valorItensSelecionados.toFixed(2).replace(".", ",")}
				onChange={(evt) => { this.props.atualizarEstado({ valorItensSelecionados: evt.target.value }); }}
			/>
		</div>
	}

	/**
	 * Método que executa a montagem/rederização do componente.
	 */
	render() {

		log("GridGrupoProduto render");

		// Monta as celulas que irão compor a grid
		let grupoProdutoGrid;

		switch (this.props.gridGrupoProdutoDisplay) {
			// Caso não for para exibir os grupos de produto
			case GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_CODE_NUMERICAL:
			case GRID_GRUPO_PRODUTO_DISPLAY_PRODUCT_NAME_ALPHABETICAL:
				// Mapeia os novos itens de venda para os seus produtos
				grupoProdutoGrid = (this.props.newSaleItemList || []).filter((newSaleItem) => pesquisarProduto(newSaleItem.itemVenda.produto, this.props.entradaBuscaReducer)).map(newSaleItem => <div
					className="grupo-produto pure-u-1 pure-u-sm-1-2 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4"
					key={`${getURIFromEntity(newSaleItem.itemVenda.produto)}?s=${newSaleItem.sequence || 0}`}
				>
					{<div className="card">
						<BuildCellFromProduto
							itemVenda={newSaleItem.itemVenda}
							newSaleItem={newSaleItem}
							produto={newSaleItem.itemVenda.produto}
						/>
					</div>}
				</div>);
				break;
			// Caso for para exibir os grupos de produto
			case GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_OPEN:
			case GRID_GRUPO_PRODUTO_DISPLAY_GROUP_PRODUCT_CLOSED:
			default:
				grupoProdutoGrid = this.props.grupoProdutoList.map((grupoProduto, index) =>
					<BuildCellFromGrupoProduto
						index={index}
						displaySelector={this.displaySelector}
						tabIndex={this.props.tabIndex}
						key={getURIFromEntity(grupoProduto)}
						grupoProduto={grupoProduto}
						abreTelaModal={this.abreTelaModal}
						fechaTelaModal={this.fechaTelaModal}
						newSaleItemList={(this.props.newSaleItemList || [])
							.filter(newSaleItem => !newSaleItem.itemVenda.combinado && (newSaleItem.itemVenda.produto.grupoProdutoList || { some: () => { } })
								.some(saleItemGrupoProduto => getURIFromEntity(grupoProduto) === getURIFromEntity(saleItemGrupoProduto))
								&& pesquisarProduto(newSaleItem.itemVenda.produto, this.props.entradaBuscaReducer))}
					/>
				);
		}

		return <>
			<this.Bar tipoOrigem={this.props.tipoOrigem} buscarEstado={this.props.buscarEstado} atualizarEstado={this.props.atualizarEstado} setDisplaySelector={(ref) => this.displaySelector = ref} />
			{this.state.telaModal
				? <div className="grid-grupo-produto-modal">
					{this.state.telaModal}
				</div>
				: null
			}
			<div className="pure-g">
				{grupoProdutoGrid}
			</div>
		</>;
	}
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
	entradaBuscaReducer: state.entradaBuscaReducer,
	tabIndex: state.appReducer.getTabIndex(),
	...state.idiomaReducer,
	gridGrupoProdutoDisplay: state.manutencaoVendaReducer.gridGrupoProdutoDisplay,
	grupoProdutoList: state.controleVendaReducer.grupoProdutoList,
	newSaleItemList: state.controleVendaReducer.newSaleItemList,
	controleVendaReducer: state.controleVendaReducer,
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
	updateGridGrupoProdutoDisplay: gridGrupoProdutoDisplay => dispatch(gridGrupoProdutoActions.updateGridGrupoProdutoDisplay(gridGrupoProdutoDisplay))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(GridGrupoProduto);
