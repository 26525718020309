import "./Form.css"

import React from "react"

/**
 * 
 * @param {import("./types").formPropsType} props 
 * @returns {JSX.Element}
 */
function Form(props) {
  return <form {...props} className={"ui-form " + (props.className || "")} />;
}

export default Form;
