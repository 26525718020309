//@ts-check

/**
 * 
 * @param {import('./types').seletorMultiploPropsType} props 
 * @returns {JSX.Element|null}
 */
function SeletorMultiplo(props) {
    return null;
}

export default SeletorMultiplo;