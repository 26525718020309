// import _BarraMenu from './barras/BarraMenu/BarraMenu';
import _Botao from './botoes/Botao/Botao';
// import _BotaoMenu from './botoes/BotaoMenu/BotaoMenu';
import _BotaoFlutuante from './botoes/BotaoFlutuante/BotaoFlutuante';
import _Imagem from './imagens/Imagem/Imagem';
import _icones from '../../assets/icones/Icones';
import _Texto from './texto/Texto/Texto';

// export const BarraMenu = _BarraMenu;
export const Botao = _Botao;
// export const BotaoMenu = _BotaoMenu;
export const BotaoFlutuante = _BotaoFlutuante;
export const icones = _icones;
export const Imagem = _Imagem;
export const Texto = _Texto;
