//@ts-check
import React from 'react';
import { connect } from 'react-redux';
import { LocalidadeUtils } from '../../../nucleo/utils/utils';
import DialogTemplate from '../DialogTemplate';

/**
 * 
 * @param {import('./types').mostrarMensagemContatoEmpresaDialogPropsType} props 
 * @returns 
 */
function MostrarMensagemContatoEmpresaDialog({dispatch}) {
    return <DialogTemplate fecharHabilitado titulo={LocalidadeUtils.obterTraducao().dialog.warning}>
        {LocalidadeUtils.obterTraducao().orders.companyWillContactYou}
    </DialogTemplate>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} _ 
 * @returns 
 */
const mapStateToProps = (_) => ({});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    const actions = {
        dispatch,
    }

    return actions;
};

export default connect(mapStateToProps, mapDispatchToProps)(MostrarMensagemContatoEmpresaDialog);