import React from 'react';
import { connect } from 'react-redux';
import * as appActions from '../../../store/actions/appAction';

import "./CardPedidoProducao.css";

import * as AuthUtils from "../../../utils/AuthUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { formatNumber } from "../../../utils/NumberUtils"
import { formataTelefone } from "../../../utils/TelefoneUtils"
import { formatHourMinuteFromDate, formatDayMonthYear } from "../../../utils/TimeUtils"
import { getIdFromURI } from "../../../utils/URIUtils"

import { MdCheck } from 'react-icons/md';
import CardPedidoItemProducao from './CardPedidoItemProducao';
import DialogVenda from '../dialog/DialogVenda';

import { stateToColor } from '../../producao/grid/GridProducao';

import PropTypes from 'prop-types';

/**
 * Função que monta a célula a partir do item de venda.
 */
class CardPedidoProducao extends React.Component {
    static propTypes = {
        venda: PropTypes.any.isRequired,
    };

    /**
     * Se o cargo possui permissão para alterar o estado do item de venda.
     */
    editItemAllowed = () => AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_PRODUCAO_ALTERACAO, AuthUtils.PERMISSAO_VENDA_ENTREGA]);

        /**
     * Método executado ao clicar sobre a célula.
     */
    handleClick = (evt) => {
        evt.type === 'click' && evt.preventDefault();

        if (!this.editItemAllowed()) {
            return;
        }
        this.props.appDialogShow(
            <DialogVenda
                venda={this.props.venda}
                isEntregador={this.props.isEntregador}
                mostrarRotaCallback={this.props.mostrarRotaCallback}
            />,
            null,
            {
                maxWidth: '400px',
                maxHeight: '250px',
                width: '75%',
                //height: '240px',
                top: '12%',
                left: '12%',
                marginTop: '0px',
                marginLeft: '0px',
            }
        );
    }

    mapKeys = {
        'Enter': this.handleClick,
        ' ': (evt) => { evt.preventDefault(); return this.handleClick(evt); },
    };

    keyHandler = (evt) => {
        try {
            this.mapKeys[evt.key](evt);
        } catch (err) { }
    };

    clickHandler = (event) => {

        if (event.target.id === 'botao-observacao-item' || event.target.firstElementChild?.id === 'botao-observacao-item') {
            return;
        }

        if (!this.editItemAllowed()) {
            return;
        }

        if (this.props.modoSelecao) {
            this.props.atualizarSelecao(getIdFromURI(this.props.vendaId));
            return;
        }

        return this.handleClick(event);
    }

    getClienteNome = (venda = {}) => {
        if (venda.clientePreCadastrado) {
            return venda.clientePreCadastrado.nome;
        }
        else if (venda.clienteIntegracao) {
            return `${venda.clienteIntegracao.nome}`;
        }
        else if (venda.usuarioCliente) {
            return `${venda.usuarioCliente.nome} ${venda.usuarioCliente.sobrenome}`;
        }
        else if (venda.cliente) {
            return `${venda.cliente.nome} ${venda.cliente.sobrenome}`;
        }
        return '';
    }

    getClienteTelefone = (venda = {}) => {
        if (venda.clientePreCadastrado) {
            return venda.clientePreCadastrado.telefone;
        }
        else if (venda.usuarioCliente) {
            return venda.usuarioCliente.dadosAdicionais.telefone;
        }
        else if (venda.cliente) {
            return '';
        }
        return '';
    }

    getClienteEndereco = (venda = {}) => {
        if (venda.clientePreCadastrado) {
            return this.getEndereco(venda.clientePreCadastrado.enderecoUsuario);
        }
        else if (venda.clienteIntegracao) {
            return this.getEndereco(venda.clienteIntegracao.endereco);
        }
        else if (venda.usuarioCliente) {
            return this.getEndereco(venda.usuarioCliente.dadosAdicionais);
        }
        else if (venda.cliente) {
            return null;
        }
        return null;
    }

    getEndereco = (enderecoUsuario) => {
        let endereco = enderecoUsuario.endereco + ', ' + enderecoUsuario.numero;

        if (enderecoUsuario.complemento) {
            endereco += ', ' + enderecoUsuario.complemento;
        }
        if (enderecoUsuario.pontoReferencia) {
            endereco += ', ' + enderecoUsuario.pontoReferencia;
        }
        if (enderecoUsuario.bairro) {
            endereco += ' - ' + enderecoUsuario.bairro;
        }
        if (enderecoUsuario.municipio) {
            const municipio = enderecoUsuario.municipio.split(' ').map(s => s[0] && (/\p{L}/gu).test(s[0]) && s[0] === s[0].toUpperCase() ? s[0] : '').join('');
            if (municipio) {
                endereco += ` (${municipio})`;
            }
        }

        return endereco;
    }

    getFormaPagamento = () => {
        if (this.props.venda.entregaVenda?.formaPagamento) {
            return this.props.venda.entregaVenda.formaPagamento.nome;
        }
        else {
            return this.props.venda.pagamentoVendaList ? this.props.venda.pagamentoVendaList.filter(pagamento => pagamento.formaPagamento).map(pagamento => pagamento.formaPagamento.nome).join(' - ') : '';
        }
    }

    render() {
        if (this.props.venda.estadoItensVenda === 'ESPERANDO_ACEITAR') {
            return null;
        }
        
        const estado = getStrings().saleItemStateEnumToString(this.props.venda.estadoItensVenda);
        const itemId = getIdFromURI(this.props.vendaId);
        const observacao = this.props.venda.entregaVenda?.observacao ? this.props.venda.entregaVenda.observacao + '\n' : '';
        const codigoPedidoIntegrado = this.props.venda.codigoPedidoIntegrado;

        return <div id={'card-pedido-producao'} className={(this.props.sideDrawerShow ? 'pure-u-sideDrawerShow ' : '') + 'pure-u-1 pure-u-sm-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-3 pure-u-xxl-1-3'}>

            <ul id='tabs'>
                <li className={'tab-item'}>
                    <div>
                        <svg viewBox={"0 0 80 60"} preserveAspectRatio={"none"}>
                            <use href="#tabshape">
                                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                            </use>
                        </svg>
                        <span>{`${formatDayMonthYear(new Date(this.props.venda.dataHoraEnvio))} - ${this.props.venda.origemVenda.nome} - ${codigoPedidoIntegrado ? codigoPedidoIntegrado + ' - ' : ''}${itemId.toString().padStart(9, '0')}`}</span>
                    </div>
                </li>
            </ul>

            <div tabIndex={this.props.tabIndex} className={`${this.editItemAllowed() ? 'clickable ' : ''} card estado-producao with-footer card-container${isAncientChromeMobile ? ' ancient-chrome-mobile' : ''}`} onClick={this.clickHandler} onKeyDown={this.keyHandler} onTouchStart={this.touchStartHandler} onTouchEnd={this.touchEndHandler}>

                <span className='uri-container' children={itemId} />

                <div className='card-content production'>
                    <div className='linha no-wrap'>
                        <div className='linha'>
                            <div className='nome-cliente'>
                                {this.getClienteNome(this.props.venda)}
                            </div>
                            <div className='telefone'>
                                <a href={`tel:${this.getClienteTelefone(this.props.venda)}`}>{formataTelefone(this.getClienteTelefone(this.props.venda))}</a>
                            </div>
                        </div>
                        {this.props.modoSelecao
                            ? <div key='__selecao_check__' className='selecao'>
                                {this.props.selecionados.includes(itemId)
                                    ? <MdCheck key='__selecao_check_icon__' />
                                    : null
                                }
                            </div>
                            : null
                        }
                    </div>
                    <div className='linha'>
                        <div className='endereco'>
                            {this.getClienteEndereco(this.props.venda)}
                        </div>
                    </div>
                    <div className='linha'>
                        <div className='origem-forma-pagamento'>
                            {this.getFormaPagamento()}
                        </div>
                        <div className='horarios'>
                            {`HA: ${formatHourMinuteFromDate(new Date(this.props.venda.dataHoraEnvio))} - 
                            DE: ${formatDayMonthYear(new Date(this.props.venda.entregaVenda?.dataHoraEntrega || this.props.venda.dataHoraEnvio), true)}
                            HE: ${formatHourMinuteFromDate(new Date(this.props.venda.entregaVenda?.dataHoraEntrega || this.props.venda.dataHoraEnvio), true)}`
                            }
                        </div>
                    </div>
                </div>

                <div className='produtos'>
                    <CardPedidoItemProducao dispatch={this.props.dispatch} itemList={this.props.venda.itemVendaList} estadoItensVenda={this.props.venda.estadoItensVenda}/>
                </div>

                <div className='totais'>
                    <div className='linha'>
                        <div className='tele'>
                            {getStrings().deliveryLabel}:
                        </div>
                        <div className='tele-valor'>
                            {(this.props.venda.entregaVenda || {}).valorTeleEntrega ? `${formatNumber(this.props.venda.entregaVenda.valorTeleEntrega, 2)}` : formatNumber(0.00, 2)}
                        </div>
                        <div className='total-itens'>
                            {getStrings().totalItems}:
                        </div>
                        <div className='total-itens-valor'>
                            {formatNumber(this.props.venda.valorItens, 2)}
                        </div>
                    </div>
                    <div className='linha'>
                        <div className='cupom'>
                            {getStrings().coupon}:
                        </div>
                        <div className='cupom-valor'>
                            {(this.props.venda.entregaVenda || {}).valorCupom ? `- ${formatNumber(this.props.venda.entregaVenda.valorCupom, 2)}` : formatNumber(0.00, 2)}
                        </div>
                        <div className='total-pedido'>
                            {getStrings().totalValue}:
                        </div>
                        <div className='total-pedido-valor'>
                            {formatNumber(this.props.venda.valorTotal, 2)}
                        </div>
                    </div>
                    <div className='linha'>
                        <div style={{ width: '20%', marginLeft: '1em' }}></div>
                        <div style={{ width: '25%', marginRight: '1em' }}></div>
                        <div className='troco'>
                            {getStrings().moneyChange}:
                        </div>
                        <div className='troco-valor'>
                            {(this.props.venda.entregaVenda || {}).valorPago ? `${formatNumber(this.props.venda.entregaVenda.valorPago - this.props.venda.valorTotal, 2)}` : formatNumber(0.00, 2)}
                        </div>
                    </div>
                </div>

                <div className='rodape'>
                    <div className='observacao-pedido'>
                        {observacao && `Obs: ${observacao}`}
                    </div>
                    <div className={`estado-pedido ${stateToColor(this.props.venda.estadoItensVenda)}${isAncientChromeMobile ? ' row' : ''}`}>
                        <div className={`estado Uppercase ${estado}`}>
                            {estado}
                        </div>
                    </div>
                </div>

            </div>
        </div>;
    }
};

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
    sideDrawerShow: state.appReducer.sideDrawerShow,
    ...state.idiomaReducer
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    appDialogShow: (dialogContent, dialogTitle, dialogStyles, dialogTitleStyles) =>
        dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles)),
    dispatch,
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CardPedidoProducao);
