//@ts-check
import "./CadastroTela.css"

import React from "react"
import { Texto } from "../../../../nucleo/nucleo"
import { connect } from "react-redux"
import { atualizarCadastro, cadastrarUsuario, editarCadastroAction } from "../../../../nucleo/redux/cadastro/cadastroUsuario/cadastroUsuarioActions"
import { definirBotaoPrincipal } from "../../../../nucleo/redux/botoes/botaoPrincipal/botaoPrincipalActions"
import { mostrarDialog } from "../../../../nucleo/redux/dialog/dialogActions"
import CadastroUsuarioFinalTela from "../CadastroUsuarioFinalTela/CadastroUsuarioFinalTela"
import CadastroEnderecoTela from "../CadastroEnderecoTela/CadastroEnderecoTela"
import { definirBotaoVoltar } from "../../../../nucleo/redux/botoes/botaoVoltar/botaoVoltarActions"
import obterTraducao from "../../../../nucleo/utils/LocalidadeUtils/obterTraducao"
import Icones from "../../../../assets/icones/Icones"
import AvisoDialog from "../../../dialogs/AvisoDialog/AvisoDialog"
import { LocalidadeUtils, NavegacaoUtils } from "../../../../nucleo/utils/utils"
import emailIsValid from "../../../../nucleo/utils/EmailUtils/emailIsValid"
import { TIPO_CADASTRO_USUARIO_COMPLETO } from "../../../../../../store/reducers/empresaSelectorReducer"
import { validaCPFOuCNPJ } from "../../../../nucleo/utils/IdentificadorUtils/IdentificadorUtils"

const paginas = [
  CadastroUsuarioFinalTela,
  CadastroEnderecoTela
];

class CadastroTela extends React.Component {
  /**
   * 
   * @type {{ paginaAtual: number }}
   */
  state = {
    paginaAtual: 0,
  }

  /**
   * 
   * @param {string} name 
   * @param {string} value 
   * @param {'usuario' | 'endereco' | undefined} cadastroKey 
   * @returns 
   */
  cadastroFunction = (name, value, cadastroKey = undefined) => {
    if (Object.keys(cadastroKey ? this.props.cadastro[cadastroKey] : this.props.cadastro).includes(name)) {
      return {[name]: value};
    }

    return {};
  };
  
  /**
   * 
   * @param {{target: {value: string, name: string}}} evt 
   */
  changeHandler = ({target}) => {
    const {value, name} = target;

    this.props.atualizarCadastroUsuario({
      ...this.props.cadastro,
      usuario: {
        ...this.props.cadastro.usuario,
        ...this.cadastroFunction(name, value, 'usuario'),
      },
      endereco: {
        ...this.props.cadastro.endereco,
        ...this.cadastroFunction(name, value, 'endereco'),
      },
      ...this.cadastroFunction(name, value),
    });
  }

  validarCadastroDadosPessoais = () => {
    let mensagem = '';
    const editar = this.props.location?.state?.editar;
    const isCadastroCompleto = this.props.parametrosEmpresa.tipoCadastroUsuario === TIPO_CADASTRO_USUARIO_COMPLETO;

    if (!this.props.cadastro.usuario.nome) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.name;
    }
    else if (!this.props.cadastro.usuario.sobrenome) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.lastName;
    }
    else if (!this.props.cadastro.usuario.email) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.email;
    }
    else if (!editar && !emailIsValid(this.props.cadastro.usuario.email)) {
      mensagem = LocalidadeUtils.obterTraducao().register.invalid.email;
    }
    else if (!editar && !this.props.cadastro.senha) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.password;
    }
    // else if (!editar && !this.props.cadastro.senhaRepetida) {
    //   mensagem = LocalidadeUtils.obterTraducao().register.uninformed.repeatPassword;
    // }
    // else if (!editar && this.props.cadastro.senha !== this.props.cadastro.senhaRepetida) {
    //   mensagem = LocalidadeUtils.obterTraducao().register.passwordsDontMatch;
    // }
    else if (isCadastroCompleto && !this.props.cadastro.usuario.cpf) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.securityNumber;
    }
    else if (isCadastroCompleto && !validaCPFOuCNPJ(this.props.cadastro.usuario.cpf)) {
      mensagem = LocalidadeUtils.obterTraducao().register.invalid.securityNumber;
    }
    else if (editar && !(this.props.cadastro.usuario.cpf?.length > 0)) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.securityNumber;
    }
    else if (!this.props.cadastro.usuario.celular) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.celphoneNumber;
    }
    else if (isCadastroCompleto && !this.props.cadastro.usuario.dataDeNascimento) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.birthDate;
    }

    if (mensagem) {
      this.props.dispatch(mostrarDialog({conteudo: () => <AvisoDialog mensagem={mensagem} />}));
      return false;
    }
    return true;
  }

  validarCadastroEndereco = () => {
    let mensagem = '';

    if (!this.props.endereco.endereco.pais.codigo) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.country;
    }
    else if (!this.props.endereco.endereco.cep) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.zipCode;
    }
    else if (!this.props.endereco.endereco.estado) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.stateName;
    }
    else if (!this.props.endereco.endereco.cidade) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.city;
    }
    else if (!this.props.endereco.endereco.bairro) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.hood;
    }
    else if (!this.props.endereco.endereco.rua) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.street;
    }
    else if (!this.props.endereco.endereco.numero) {
      mensagem = LocalidadeUtils.obterTraducao().register.uninformed.number;
    }

    if (mensagem) {
      this.props.dispatch(mostrarDialog({conteudo: () => <AvisoDialog mensagem={mensagem} />}));
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.props.dispatch(definirBotaoVoltar({botaoVoltarCustomizado: undefined})); 
    this.props.definirBotaoPrincipal({nomeCustomizado: ''});
  }

  componentDidMount() {
    const AvancarIcone = Icones.mobi.Avancar;

    this.props.definirBotaoPrincipal({
      executarAcao: () => {
        if (this.state.paginaAtual < (paginas.length - 1)) {
          if (this.validarCadastroDadosPessoais()) {
            this.setState({ paginaAtual: this.state.paginaAtual + 1 });
          }
        }
        else {
          if (this.validarCadastroEndereco()) {
            const caminho = this.props.location?.state?.caminho;
            this.props.location?.state?.editar ? this.props.editarCadastroAction({caminho}) : this.props.cadastrarUsuario({caminho});
          }
        }
      },
      nomeCustomizado: this.state.paginaAtual < 1 ? <><Texto>{obterTraducao().words.nextPage}</Texto><AvancarIcone /></> : null,
    });

    this.props.dispatch(definirBotaoVoltar({
      botaoVoltarCustomizado: () => {
        if (this.state.paginaAtual > 0) {
          this.setState({paginaAtual: this.state.paginaAtual - 1});
        }
        else {
          this.props.dispatch(NavegacaoUtils.goBack());
        }
      }
    }));

    if (this.props.location?.state?.completarCadastro) {
      this.props.dispatch(mostrarDialog({conteudo: () => <AvisoDialog mensagem={'Complete o cadastro para finalizar o pedido'} />}));
    }
  }

  componentDidUpdate() {
    if ((!this.props.webAppBotaoPrincipalReducer.nomeCustomizado && this.state.paginaAtual < 1) || (!!this.props.webAppBotaoPrincipalReducer.nomeCustomizado && this.state.paginaAtual > 0)) {
      const AvancarIcone = Icones.mobi.Avancar;

      this.props.definirBotaoPrincipal({
        nomeCustomizado: this.state.paginaAtual < 1 ? <><Texto>{obterTraducao().words.nextPage}</Texto><AvancarIcone /></> : null,
      })
    }
  }

  render() {
    const Pagina = paginas[this.state.paginaAtual];

    return <div key='tela' className='tela' id='cadastro-tela'>
      <Pagina editar={((this.props.location || {}).state || {}).editar} onChange={this.changeHandler} />
    </div>;
  }
}

/**
 * 
 * @param {import('../../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  cadastro: state.webAppCadastroUsuarioReducer,
  endereco: state.webAppCadastroEnderecoReducer,
  webAppBotaoPrincipalReducer: state.webAppBotaoPrincipalReducer,
  parametrosEmpresa: state.webAppEmpresaReducer.parametrosEmpresa,
});

/**
 * 
 * @param {import('../../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   * 
   * @param {import('../../../../nucleo/redux/cadastro/cadastroUsuario/cadastroUsuarioReducer').cadastroUsuarioReducerTipo} cadastroUsuario 
   * @returns 
   */
  atualizarCadastroUsuario: (cadastroUsuario) => dispatch(atualizarCadastro(cadastroUsuario)),
  /**
   * 
   * @param {{caminho?: string}} carga 
   * @returns 
   */
  cadastrarUsuario: (carga) => dispatch(cadastrarUsuario(carga)),
  /**
   * 
   * @param {import('../../../../nucleo/redux/botoes/botaoPrincipal/botaoPrincipalReducer').botaoPrincipalReducerTipo} carga 
   * @returns 
   */
  definirBotaoPrincipal: (carga) => dispatch(definirBotaoPrincipal(carga)),
  dispatch,
  /**
   * 
   * @param {{caminho?: string}} carga 
   * @returns 
   */
  editarCadastroAction: (carga) => dispatch(editarCadastroAction(carga)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CadastroTela);
