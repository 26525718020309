import React from 'react';
import { connect } from 'react-redux';

import { isNonEmptyArray } from "../../utils/ComparatorsUtils"
import { getStrings } from "../../utils/LocaleUtils"
import { log } from "../../utils/LogUtils"
import { isEdge } from "../../utils/NavigatorUtils"
import { updateActiveMenuItem } from "../../utils/ScreenUtils"
import { getAuthStorage } from "../../utils/StorageUtils/SessionStorageUtils"
import { getIdFromURI, getURIFromEntity } from "../../utils/URIUtils"

import EmpresaSelectorItem from './EmpresaSelectorItem/EmpresaSelectorItem';
import HelpParagraph from '../UI/HelpParagraph/HelpParagraph';
import WidthAwareDiv from '../UI/WidthAwareDiv/WidthAwareDiv';

import * as actions from '../../store/actions/empresaSelectorAction';
import { appSnackbarMessage, appSpinnerHide } from '../../store/actions/appAction';
import { setPageSize, getPageSize } from '../../utils/StorageUtils/LocalStorageUtils';

/* 
 * Necessário para o Edge, pelo menos até sair o próximo update
 * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8993198/
 */
var URLSearchParams = require('url-search-params');

/**
 * Componente para seleção de Empresa. Utilizado quando o usuário fizer login, para selecionar a empresa que irá utilizar. 
 */
class EmpresaSelector extends React.Component {

    componentDidMount() {
        log('EmpresaSelector componentDidMount');

        updateActiveMenuItem('menuItemEmpresaSelector');

        setPageSize(JSON.parse(sessionStorage.getItem("a")).tamanhoPadraoPaginacao, getPageSize());

        // Armazena a querystring
        const querystring = new URLSearchParams(this.props.location.search);
        // Busca o Cargo Padrão da querystring, caso houver
        const cargoPadrao = querystring.get('cp');

        if (cargoPadrao) {
            this.empresaSelectedHandler(cargoPadrao);
            return;
        }
        // Chama a ação que busca a lista de Cargos atribuídos ao Usuário logado, populando a lista
        this.props.getCargoList();

        const showWelcomeMessage = JSON.parse(sessionStorage.getItem('showWelcomeMessage'));
        const nomeUsuario = getAuthStorage().nomeUsuario.nome;

        if (showWelcomeMessage) {
            this.props.dispatch(appSnackbarMessage(getStrings().helloUserTemplate(nomeUsuario)));
            sessionStorage.setItem('showWelcomeMessage', null);
        }

        if (isEdge) {
            setTimeout(() => {
                this.props.dispatch(appSpinnerHide('getCargoList'));
            }, 250);
        }
    }

    empresaSelectedHandler = cargo => {
        log('EmpresaSelector empresaSelectedHandler', cargo);
        // Chama a ação que atribui o Cargo ao Estado e ao Storage do Navegador. 
        this.props.empresaSelectorCargoSelecionado(cargo, this.props.history);
    }

    render() {
        log('EmpresaSelector render');
        let itens = null;
        let restrictArea = this.props.cargoList.length < 3;

        if (this.props.cargoList.length === 1)
            this.empresaSelectedHandler(getIdFromURI(getURIFromEntity(this.props.cargoList[0])));

        if (isNonEmptyArray(this.props.cargoList)) {

            itens = this.props.cargoList.map(item =>
                <EmpresaSelectorItem
                    key={item._links.self.href}
                    cargo={item}
                    clickHandler={() => this.empresaSelectedHandler(getIdFromURI(getURIFromEntity(item)))}
                    restrictArea={restrictArea}
                />
            )
        }

        let itensInside = null;
        let itensOutside = itens;
        if (restrictArea) {
            itensInside = itensOutside
            itensOutside = null;
        }

        return <div id='cadastro-box' >
            {/* Header do box */}
            <div className='header breadCrumbs'>
                <h1>{getStrings().companySelection}</h1>
            </div>

            {/* Conteúdo do box */}
            <div id='conteudo-box' >
                <WidthAwareDiv>

                    <HelpParagraph>
                        {[getStrings().companySelectionHelp]}
                    </HelpParagraph>

                    {itensInside}
                </WidthAwareDiv>
                {itensOutside}
            </div>
        </div>;
    }
}

const mapStateToProps = state => ({

    cargoList: state.empresaSelectorReducer.list,
    nomeUsuario: state.nomeUsuario,
    ...state.idiomaReducer,
});

const mapDispatchToProps = dispatch => ({
    dispatch: (app) => dispatch(app),
    getCargoList: () => dispatch(actions.getCargoList()),
    empresaSelectorCargoSelecionado: (cargo, history) => dispatch(actions.empresaSelectorCargoSelecionado(cargo, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmpresaSelector);
