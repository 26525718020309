//@ts-check
import "./InformacaoEmpresaDialog.css"

import React from "react"
import { connect } from "react-redux"
import { Botao } from "../../../nucleo/nucleo"
import { Icones } from "../../../assets/icones/Icones"
import { LocalidadeUtils } from "../../../nucleo/utils/utils"
import { fecharDialog } from "../../../nucleo/redux/dialog/dialogActions"
import { MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO, MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA } from "../../../../../store/reducers/empresaSelectorReducer"
import { horarioFuncionamentoListToDiasDaSemanaHorarios } from "../../../../../utils/QuadroHorariosUtils/quadroHorariosUtils"

/**
 * 
 * @param {{empresaReducer: import('../../../nucleo/redux/empresa/empresaReducer').empresaReducerTipo}} props
 * @returns 
 */
function Sobre(props) {
  const {empresa, endereco, contato} = props.empresaReducer;

  return <>
    <div>
      <div className='sobre-titulo'>{LocalidadeUtils.obterTraducao().words.adress.adress}</div>
      <div className='sobre-dados'>{`${endereco.rua}, ${endereco.numero} - ${endereco.bairro}`}</div>
      <div className='sobre-dados'>{`${endereco.cidade} - ${endereco.estado}`}</div>
      <div className='sobre-dados'>{`CEP: ${endereco.cep}`}</div>
    </div>
    <div>
      <div className='sobre-titulo'>{LocalidadeUtils.obterTraducao().words.contact}</div>
      <div className='sobre-dados'>
        {`${LocalidadeUtils.obterTraducao().words.phone}:`}
        <a href={`tel:${contato.telefone}`}>{contato.telefone}</a>
      </div>
      <div className='sobre-dados'>
        {`${LocalidadeUtils.obterTraducao().words.email}:`}<a href={`mailto:${contato.email}`}>{contato.email}</a>
      </div>
    </div>
    <div>
      <div className='sobre-titulo'>{LocalidadeUtils.obterTraducao().words.otherInformation}</div>
      <div className='sobre-dados'>{`CNPJ: ${empresa.cnpj}`}</div>
    </div>
  </>;
}

/**
 * 
 * @param {{quadroHorarios: import('../../../nucleo/redux/quadroHorarios/quadroHorariosReducer').quadroHorariosReducerTipo}} props 
 * @returns 
 */
function HorarioFuncionamento(props) {
  const horarioFuncionamentoList = props.quadroHorarios.horarioFuncionamentoList;
  const diaDaSemanaHorarios = horarioFuncionamentoListToDiasDaSemanaHorarios(horarioFuncionamentoList);

  const feriadoList = props.quadroHorarios.feriadoList || [];

  return <>
    <div className='horarios-funcionamento'>
      {
        Object.entries(diaDaSemanaHorarios).map(diaDaSemana => {
          /**
           * 
           * @type {import('../../../../../utils/QuadroHorariosUtils/quadroHorariosTypes').diaDaSemanaTipo}
           */
          // @ts-ignore
          const dia = diaDaSemana[0];
          const horarios = diaDaSemana[1];
          return horarios?.length
            ? <HorarioFuncionamentoItem key={`__horario_funcionamento_${dia}__`} dia={dia} horarios={horarios} />
            : null;
        })
      }
    </div>
    {
      feriadoList.length
        ? <div className='titulo'>{`${LocalidadeUtils.obterTraducao().words.holiday}s`}</div>
        : null
    }
    <div className='horarios-funcionamento'>
      {
        feriadoList.map(feriado => {
          return <FeriadoItem key={`__horario_funcionamento_${feriado.dia}-${feriado.mes}__`} feriado={feriado} />
        })
      }
    </div>
  </>;
}

/**
 * 
 * @param {{ dia: import('../../../../../utils/QuadroHorariosUtils/quadroHorariosTypes').diaDaSemanaTipo, horarios: import('../../../../../utils/QuadroHorariosUtils/quadroHorariosTypes').horarioTipo[] }} props 
 * @returns 
 */
function HorarioFuncionamentoItem(props) {
  const {dia, horarios} = props;
  const maxIndex = horarios.length - 1;

  return <>
    <div className='horario-funcionamento-item'>
      <div className='horario-funcionamento-item-dia'>
        <div className='dia-semana'>
          {`${LocalidadeUtils.obterTraducao().weeks[dia].reduced}:`}
        </div>
        <div className='horarios'>
          {
            Object.values(horarios)
              .sort((a, b) => {
                return a.horaInicial > b.horaInicial ? 1 : a.horaInicial < b.horaInicial ? -1 : 0;
              })
              .map((horario, index) => {
                return <div key={`__${dia}_horarios-item_${index}__`} className='horarios-item'>
                  {`${horario.horaInicial} - ${horario.horaFinal}${index < maxIndex ? ' e' : ''}`}
                </div>
              })
          }
        </div>
      </div>
    </div>
  </>
}

/**
 * 
 * @param {{feriado: import('../../../../../utils/QuadroHorariosUtils/quadroHorariosTypes').feriadoTipo}} props 
 * @returns 
 */
function FeriadoItem(props) {
  const feriado = props.feriado;
  const maxIndex = feriado.horarioFeriadoList.length - 1;

  return <>
    <div className='horario-funcionamento-item'>
      <div className='horario-funcionamento-item-dia'>
        <div className='dia-mes'>
          {`${feriado.dia.toString().padStart(2 ,'0')}/${(feriado.mes + 1).toString().padStart(2 ,'0')}:`}
        </div>
        <div className='horarios' style={{marginTop: '2px'}}>
          {
            feriado.horarioFeriadoList?.length
              ? Object.values(feriado.horarioFeriadoList)
                .sort((a, b) => {
                  return a.horaInicial > b.horaInicial ? 1 : a.horaInicial < b.horaInicial ? -1 : 0;
                })
                .map((horario, index) => {
                  return <div key={`__${feriado.dia}-${feriado.mes}_horarios-item_${index}__`} className='horarios-item'>
                    {`${horario.horaInicial} - ${horario.horaFinal}${index < maxIndex ? ' e' : ''}`}
                  </div>
                })
              : <div className='horarios-item'>{LocalidadeUtils.obterTraducao().words.closed.toUpperCase()}</div>
          }
        </div>
      </div>
    </div>
  </>
}

/**
 * 
 * @param {{empresaReducer: import('../../../nucleo/redux/empresa/empresaReducer').empresaReducerTipo, bairroReducer: import('../../../nucleo/redux/bairro/types').bairroReducerTipo}} props 
 * @returns 
 */
function Entrega(props) {
  const {modoCobrancaTeleEntrega, valorTeleEntregaPorKm} = props.empresaReducer.parametrosEmpresa;

  if (modoCobrancaTeleEntrega === MODO_COBRANCA_TELE_ENTREGA_POR_BAIRRO) {
    return <>
      <div className='bairros'>
        {
          Object.values(props.bairroReducer).map((bairro, index) => {
            return <div key={`__bairro-item_${index}__`} className='bairro-item'>
              <div>{bairro.nome}</div>
              <div>{LocalidadeUtils.obterTraducao().currency.format(bairro.valorEntrega)}</div>
            </div>
          })
        }
      </div>
    </>
  }
  else if (modoCobrancaTeleEntrega === MODO_COBRANCA_TELE_ENTREGA_POR_DISTANCIA) {
    return <>
      <div className='bairros'>
        <div className='bairro-item'>
          <div>{LocalidadeUtils.obterTraducao().words.deliveryValueByKm}</div>
          <div>{LocalidadeUtils.obterTraducao().currency.format(valorTeleEntregaPorKm)}</div>
        </div>
      </div>
    </>
  }
  else {
    return null;
  }
}

/**
 * 
 * @param {{formasPagamentoReducer: import('../../../nucleo/redux/pedidos/formasPagamento/formasPagamentoReducer').formasPagamentoReducerTipo}} props 
 * @returns 
 */
function FormasPagamento(props) {
  const formasPagamento = Object.values(props.formasPagamentoReducer);

  return <>
    <div className='formas-pagamento'>
      {
        formasPagamento.map((formaPagamento, index) => {
          return <div key={`__forma-pagamento-item_${index}__`}>{formaPagamento.nome}</div>
        })
      }
    </div>
  </>;
}

class InformacaoEmpresaDialog extends React.Component {
  /**
   * 
   * @type {{ aba: 'sobre' | 'horario' | 'entrega' | 'pagamento' }}
   */
  state = { aba: 'sobre' };

  showAnimation = false;

  componentDidMount() {
    this.showAnimation = true;
  }

  Botoes = () => {
    const { modoCobrancaTeleEntrega } = this.props.empresaReducer.parametrosEmpresa;
    return <>
      <Botao
        className={`${this.state.aba === 'sobre' ? 'selecionado' : ''}`}
        onClick={() => this.setState({ aba: 'sobre' })}>
        {LocalidadeUtils.obterTraducao().words.about}
      </Botao>
      <Botao
        className={`${this.state.aba === 'horario' ? 'selecionado' : ''}`}
        onClick={() => this.setState({ aba: 'horario' })}>
        {LocalidadeUtils.obterTraducao().words.schedules}
      </Botao>
      {modoCobrancaTeleEntrega
        ? <Botao
            className={`${this.state.aba === 'entrega' ? 'selecionado' : ''}`}
            onClick={() => this.setState({ aba: 'entrega' })}>
            {LocalidadeUtils.obterTraducao().words.delivery}
          </Botao>
        : null
      }
      <Botao
        className={`${this.state.aba === 'pagamento' ? 'selecionado' : ''}`}
        onClick={() => this.setState({ aba: 'pagamento' })}>
        {LocalidadeUtils.obterTraducao().words.payment}
      </Botao>
    </>
  }

  Conteudo = () => {
    const animationClass = this.showAnimation ? 'animation' : '';
   
    switch (this.state.aba) {
      case 'sobre':
        return <>
          <div key='__aba-sobre__0' className={`aba-sobre ${animationClass}`}>
            {/* @ts-ignore */}
            <Sobre {...this.props}/>
          </div>
        </>
      case 'horario':
        return <>
          <div key='__aba-horarios__1' className={`aba-horarios ${animationClass}`}>
            {/* @ts-ignore */}
            <HorarioFuncionamento {...this.props}/>
          </div>
        </>
      case 'entrega':
        return <>
          <div key='__aba-entrega__2' className={`aba-entrega ${animationClass}`}>
            {/* @ts-ignore */}
            <Entrega {...this.props}/>
          </div>
        </>
      case 'pagamento':
        return <>
          <div key='__aba-pagamento__3' className={`aba-pagamento ${animationClass}`}>
            {/* @ts-ignore */}
            <FormasPagamento {...this.props}/>
          </div>
        </>;
      default:
        return null;
    }
  }

  render() {
    const IconeFechar = Icones.md.MdClose;

    return <>
      <div id='informacao-empresa-dialog'>
        <div className='dialog-frame'>
          <div className='barra-titulo'>
            <div>{LocalidadeUtils.obterTraducao().words.information}</div>
            <Botao onClick={() => this.props.fecharDialog()}>
              <IconeFechar/>
            </Botao>
          </div>
          <div className='abas'>
            <div className='abas-botoes'>
              <this.Botoes/>
            </div>
          </div>
          <div className='conteudo'>
            <this.Conteudo/>
          </div>
          <div className='rodape'>
            <p>{`${LocalidadeUtils.obterTraducao().phrases.developedBy} `}<a target='_blank' rel='noopener noreferrer' href='https://www.ampix.com.br'>Ampix Software</a></p>
          </div>
        </div>
      </div>
    </>
  }
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  quadroHorarios: state.webAppQuadroHorariosReducer,
  formasPagamentoReducer: state.webAppFormasPagamentoReducer,
  bairroReducer: state.webAppBairroReducer,
  empresaReducer: state.webAppEmpresaReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fecharDialog: () => dispatch(fecharDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformacaoEmpresaDialog);