import "./CardPedido.css"

import React from "react"
import { connect } from "react-redux"
import { stateToColor } from "../../producao/grid/GridProducao"
import { ClienteHttp } from "../../../apps/promo_app_frontend/nucleo/utils/utils"
import * as AuthUtils from "../../../utils/AuthUtils"
import { formataIdentificador } from "../../../utils/IndentificadorUtils"
import { getStrings } from "../../../utils/LocaleUtils"
import { isAncientChromeMobile } from "../../../utils/NavigatorUtils"
import { formatNumber  } from "../../../utils/NumberUtils"
import { formataTelefone } from "../../../utils/TelefoneUtils"
import { formatHourMinuteFromDate, formatDayMonthYear } from "../../../utils/TimeUtils"
import { getIdFromEntity, getIdFromURI } from "../../../utils/URIUtils"
import * as appActions from "../../../store/actions/appAction"
import { Botao } from "../../../apps/promo_app_frontend/nucleo/nucleo"
import { MdCheck } from "react-icons/md"
import CardPedidoItem from "./CardPedidoItem"
import { pedidosTelaEstadoTipo } from "./PedidosTela"
import { ESTADO_ITEM_VENDA_AGUARDANDO, ESTADO_ITEM_NAO_ACEITO, ESTADO_ITEM_VENDA_CANCELADO, ESTADO_ITEM_VENDA_ENCERRADO } from "../../../store/reducers/controleVenda/controleVendaReducer"
import DialogCancelSaleSaleItem from "../../vendas/dialog/DialogCancelSaleSaleItem"
import ReceberPedidosTela from "./ReceberPedidosTela"
import InputCustomizado from "../../UI/Input/InputCustomizado"
import { stringParseFloat } from "../../../utils/StringUtils"
import getEnderecoEmTexto from "../../../utils/EnderecoUtils/getEnderecoEmTexto"

export type CardPedidoTipo = {
  atualizarEstado: (novoEstado: any) => void | undefined
  , atualizarSelecao?: (id: number | null) => void | undefined
  , buscarEstado: () => pedidosTelaEstadoTipo
  , dispatch: (carga: any) => any
  , modoSelecao: boolean
  , permissoesCargo: string[]
  , selecionados: Array<any>
  , sideDrawerShow: boolean
  , tabIndex?: number
  , venda: any
  , vendaId: number | null
  , vendaURI: string
  , botoesVenda?: boolean
  , appDialogShow: (dialogContent: any, dialogTitle: any, dialogStyles: any, dialogTitleStyles: any, onCloseClicked: any, onOverlayClicked: any, dialogContentStyles: any) => any
};

class CardPedido extends React.Component<CardPedidoTipo> {
  static props = { atualizarSelecao: (id: any) => { } };

  state = {
    valorCupom: this.props.venda.entregaVenda?.valorCupom ? `${formatNumber(this.props.venda.entregaVenda.valorCupom, 2)}` : formatNumber(0.00, 2),
    valorTeleEntrega: this.props.venda.entregaVenda?.valorTeleEntrega ? `${formatNumber(this.props.venda.entregaVenda.valorTeleEntrega, 2)}` : formatNumber(0.00, 2),
  }

  /**
   * Se o cargo possui permissão para alterar o estado do item de venda.
   */
  editItemAllowed = () => AuthUtils.verificaPermissaoAcesso([AuthUtils.PERMISSAO_PRODUCAO_ALTERACAO, AuthUtils.PERMISSAO_VENDA_ENTREGA]);

  /**
   * Método executado ao clicar sobre a célula.
   */
  handleClick = (evt: any) => {
    evt.type === 'click' && evt.preventDefault();

    if (!this.editItemAllowed()) {
      return;
    }

  }

  /**
   * 
   * @type {React.DOMAttributes<HTMLDivElement>.onKeyDown}
   */
  keyHandler = (evt: {key: string, preventDefault: () => void}) => {
    switch(evt.key) {
      case 'Enter': 
        this.handleClick(evt);
        break;
      case ' ':
        evt.preventDefault();
        this.handleClick(evt);
        break;
      default:
        break;
    }
  };

  clickHandler = (event: any) => {
    if (!this.editItemAllowed()) {
      return;
    }

    this.props.atualizarSelecao && this.props.atualizarSelecao(getIdFromURI(this.props.vendaURI));

    if (this.props.modoSelecao) {
      return;
    }

    return this.handleClick(event);
  }

  getClienteNome = (venda: any = {}) => {
    if (venda.clientePreCadastrado) {
      return venda.clientePreCadastrado.nome;
    }
    else if (venda.clienteIntegracao) {
      return `${venda.clienteIntegracao.nome}`;
    }
    else if (venda.usuarioCliente) {
      return `${venda.usuarioCliente.nome} ${venda.usuarioCliente.sobrenome}`;
    }
    else if (venda.cliente) {
      return `${venda.cliente.nome} ${venda.cliente.sobrenome}`;
    }
    return '';
  }

  getClienteTelefone = (venda: any = {}) => {
    if (venda.clientePreCadastrado) {
      return venda.clientePreCadastrado.telefone;
    }
    else if (venda.usuarioCliente) {
      return venda.usuarioCliente.dadosAdicionais.telefone;
    }
    else if (venda.cliente) {
      return '';
    }
    return '';
  }

  getFormaPagamento = () => {
    if (this.props.venda.entregaVenda?.formaPagamento) {
      return this.props.venda.entregaVenda.formaPagamento.nome;
    }
    else {
      return this.props.venda.pagamentoVendaList ? this.props.venda.pagamentoVendaList.filter((pagamento: any) => pagamento.formaPagamento).map((pagamento: any) => pagamento.formaPagamento.nome).join(' - ') : '';
    }
  }

  aceitaPedido = () => {
    this.modificandoEstadoPedido(this.props.venda, ESTADO_ITEM_VENDA_AGUARDANDO, null);
  }

  rejeitaPedido = () => {
    this.props.appDialogShow(
      <DialogCancelSaleSaleItem
        tipoOrigemVenda={this.props.venda.origemVenda.tipoOrigemVenda}
        placeholder={getStrings().rejectReason}
        handleConfirm={cancelReason => {
          this.props.appDialogShow(
            <ReceberPedidosTela
              estado={this.props.buscarEstado()}
              atualizarEstado={this.props.atualizarEstado}
              buscarEstado={this.props.buscarEstado}
            />,
            getStrings().receiveOrdersScreenTitle(this.props.venda.origemVenda.tipoOrigemVenda),
            undefined,
            undefined,
            () => { },
            () => { },
            {
              width: '100%',
              overflow: 'unset'
            }
          )
          this.modificandoEstadoPedido(this.props.venda, ESTADO_ITEM_NAO_ACEITO, cancelReason);
        }}
      />,
      undefined,
      {
        overflow: 'unset'
      },
      undefined,
      () => { },
      () => { },
      {
        overflow: 'unset'
      }
    );
  }

  modificandoEstadoPedido = (pedido: any, estado: string, motivo: string | null) => {
    const vendaList = [getIdFromEntity(pedido)];

    this.props.dispatch(appActions.appSpinnerShow('updateVendas'));

    const valorCupom = this.state.valorCupom;
    const valorTeleEntrega = this.state.valorTeleEntrega;

    ClienteHttp.requisicaoServidor('vendas/update', 'post', true, undefined, { estado, vendaList, motivo, valorCupom, valorTeleEntrega })
      .then(() => {
        //this.props.dispatch(appActions.appDialogHide());
        //this.props.dispatch(loadItensVendaAbertos());
      })
      .finally(() => {
        this.props.dispatch(appActions.appSpinnerHide('updateVendas'));
      });
  }

  render() {
    if ((this.props.venda.estadoItensVenda === 'ESPERANDO_ACEITAR') && !this.props.botoesVenda) {
      return <></>;
    }

    const estado = getStrings().saleItemStateEnumToString(this.props.venda.estadoItensVenda);
    const itemId = getIdFromURI(this.props.vendaURI);
    const observacao = this.props.venda.entregaVenda?.observacao ? this.props.venda.entregaVenda.observacao + '\n' : '';
    const codigoPedidoIntegrado = this.props.venda.codigoPedidoIntegrado;

    let valorItens;
    let valorTotal;

    if (this.props.venda.valorItens) {
      valorItens = this.props.venda.valorItens;
      valorTotal = this.props.venda.valorTotal;
    }
    else {
      valorItens = this.props.venda.itemVendaList.map((item: any) => item.valorTotal).reduce((total: any, valorItem: any) => total + valorItem);
      valorTotal = this.props.venda.valorTotal + valorItens;
    }

    const esperandoAceitar = this.props.venda.estadoItensVenda === 'ESPERANDO_ACEITAR';

    const classe = (this.props.sideDrawerShow ? 'pure-u-sideDrawerShow ' : '') + (esperandoAceitar ? '' : 'pure-u-1 pure-u-sm-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-3 pure-u-xxl-1-3');

    return <div id={'card-pedido'} className={classe}>

      <ul id='tabs'>
        <li className={'tab-item'}>
          <div>
            <svg viewBox={"0 0 80 60"} preserveAspectRatio={"none"}>
              <use href="#tabshape">
                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
              </use>
            </svg>
            <span>{`${formatDayMonthYear(new Date(this.props.venda.dataHoraEnvio), false)} - ${this.props.venda.origemVenda.nome} - ${codigoPedidoIntegrado ? codigoPedidoIntegrado + ' - ' : ''}${itemId ? itemId.toString().padStart(9, '0') : ''}`}</span>
          </div>
        </li>
      </ul>

      <div tabIndex={this.props.tabIndex} style={this.props.selecionados.includes(getIdFromURI(this.props.vendaURI)) ? { background: 'var(--color-primary)' } : undefined} className={`${this.editItemAllowed() && !esperandoAceitar ? 'clickable ' : ''} card estado-producao with-footer card-container${isAncientChromeMobile ? ' ancient-chrome-mobile' : ''}`} onClick={this.clickHandler} onKeyDown={this.keyHandler}>

        <span className='uri-container' children={itemId} />

        <div className='card-content production'>
          <div className='linha no-wrap'>
            <div className='linha'>
              <div className='nome-cliente'>
                {this.getClienteNome(this.props.venda)}
              </div>
              <div className='telefone'>
                <a href={`tel:${this.getClienteTelefone(this.props.venda)}`}>{formataTelefone(this.getClienteTelefone(this.props.venda))}</a>
              </div>
            </div>
            {this.props.modoSelecao
              ? <div key='__selecao_check__' className='selecao'>
                {this.props.selecionados.includes(itemId)
                  ? <MdCheck key='__selecao_check_icon__' />
                  : null
                }
              </div>
              : null
            }
          </div>
          {this.props.venda.clientePreCadastrado?.cpf
            ? <div className='linha'>
              <div className='cpf'>
                {`Incluir CPF na nota fiscal ${formataIdentificador(this.props.venda.clientePreCadastrado?.cpf)}`}
              </div>
            </div>
            : null
          }
          <div className='linha'>
            <div className='endereco'>
              {getEnderecoEmTexto(this.props.venda)}
            </div>
          </div>
          <div className='linha'>
            <div className='origem-forma-pagamento'>
              {this.getFormaPagamento()}
            </div>
            <div className='horarios'>
              {`HA: ${formatHourMinuteFromDate(new Date(this.props.venda.dataHoraEnvio))} - 
                            DE: ${formatDayMonthYear(new Date(this.props.venda.entregaVenda?.dataHoraEntrega || this.props.venda.dataHoraEnvio), true)}
                            HE: ${formatHourMinuteFromDate(new Date(this.props.venda.entregaVenda?.dataHoraEntrega || this.props.venda.dataHoraEnvio))}`
              }
            </div>
          </div>
          {this.props.venda.descricaoPagamento
            ? <div className='linha'>
              <div className='descricao-pagamento'>
                {this.props.venda.descricaoPagamento}
              </div>
            </div>
            : null
          }
        </div>

        <div className='produtos'>
          <CardPedidoItem dispatch={this.props.dispatch} itemList={this.props.venda.itemVendaList} estadoItensVenda={this.props.venda.estadoItensVenda} />
        </div>

        <div className='totais'>
          <div className='linha'>
            <div className='tele'>
              {getStrings().deliveryLabel}:
            </div>
            <div className='tele-valor'>
              {esperandoAceitar
                ? <InputCustomizado
                  newApi
                  inputClassName='tele-valor-input'
                  value={this.state.valorTeleEntrega}
                  type={'text'}
                  inputType={'number'}
                  validacaoDados={'numeroDecimal'}
                  max={999999.99}
                  scale={2}
                  onChange={(evt) => this.setState({ valorTeleEntrega: evt.target.value })}
                  onBlur={(evt) => this.setState({ valorTeleEntrega: evt.target.value })}
                  onClick={(evt) => {
                    this.state.valorTeleEntrega === '0,00' && evt.target.select();
                  }}
                />
                : this.props.venda.entregaVenda?.valorTeleEntrega ? `${formatNumber(this.props.venda.entregaVenda.valorTeleEntrega, 2)}` : formatNumber(0.00, 2)}
            </div>
            <div className='total-itens'>
              {getStrings().totalItems}:
            </div>
            <div className='total-itens-valor'>
              {formatNumber(valorItens, 2)}
            </div>
          </div>
          <div className='linha'>
            <div className='cupom'>
              {getStrings().coupon}:
            </div>
            <div className='cupom-valor'>
              {esperandoAceitar
                ? <InputCustomizado
                  newApi
                  inputClassName='cupom-valor-input'
                  value={this.state.valorCupom}
                  type={'text'}
                  inputType={'number'}
                  validacaoDados={'numeroDecimal'}
                  max={999999.99}
                  scale={2}
                  onChange={(evt) => this.setState({ valorCupom: evt.target.value })}
                  onBlur={(evt) => this.setState({ valorCupom: evt.target.value })}
                  onClick={(evt) => {
                    this.state.valorCupom === '0,00' && evt.target.select();
                  }}
                />
                : this.props.venda.entregaVenda?.valorCupom ? `- ${formatNumber(this.props.venda.entregaVenda.valorCupom, 2)}` : formatNumber(0.00, 2)}
            </div>
            <div className='total-pedido'>
              {getStrings().totalValue}:
            </div>
            <div className='total-pedido-valor'>
              {esperandoAceitar
                ? formatNumber((this.props.venda.valorItens + stringParseFloat(this.state.valorTeleEntrega) - stringParseFloat(this.state.valorCupom)), 2)
                : formatNumber(valorTotal, 2)
              }
            </div>
          </div>
          {esperandoAceitar
            ? null
            : <div className='linha'>
              <div style={{ width: '20%', marginLeft: '1em' }}></div>
              <div style={{ width: '25%', marginRight: '1em' }}></div>
              <div className='troco'>
                {getStrings().moneyChange}:
              </div>
              <div className='troco-valor'>
                {this.props.venda.entregaVenda?.valorPago ? `${formatNumber(this.props.venda.entregaVenda.valorPago - valorTotal, 2)}` : formatNumber(0.00, 2)}
              </div>
            </div>
          }
        </div>

        <div className='rodape'>
          {this.props.botoesVenda ?
            <div className='linha' style={{ display: 'flex', flexFlow: 'row nowrap', flex: '1 1 auto', justifyContent: 'center', alignItems: 'center', marginTop: '0.5em' }}>
              <Botao className='botao-aceitar' onClick={this.aceitaPedido}>{getStrings().accept}</Botao>
              <Botao className='botao-rejeitar' onClick={this.rejeitaPedido}>{getStrings().reject}</Botao>
            </div>
            : <Botao
              disabled={!this.props.permissoesCargo.includes('vendaEnvio') || this.props.venda.estadoItensVenda === ESTADO_ITEM_VENDA_CANCELADO || this.props.venda.estadoItensVenda === ESTADO_ITEM_VENDA_ENCERRADO}
              className='botao-white-n-blue'
              style={{ alignSelf: 'flex-end', marginLeft: '0.2em' }}
              onClick={() => {
                this.props.dispatch(appActions.appSpinnerShow('editarPedidoVenda'));

                ClienteHttp.requisicaoServidor(`vendas/${this.props.vendaId}`, 'get', true)
                  .then((resposta: any) => {
                    const novoEstado = {
                      editar: {
                        ...(this.props.venda || {})
                        , ...resposta.data
                      }
                      , editandoPedido: true
                    };

                    this.props.atualizarEstado(novoEstado);
                  })
                  .finally(() => {
                    this.props.dispatch(appActions.appSpinnerHide('editarPedidoVenda'));
                  });
              }}>
              {getStrings().edit}
            </Botao>
          }
          {esperandoAceitar
            ? null
            : <div className='observacao-pedido'>
              {observacao && `Obs: ${observacao}`}
            </div>
          }
          {esperandoAceitar
            ? null
            : <div className={`estado-pedido ${stateToColor(this.props.venda.estadoItensVenda)}${isAncientChromeMobile ? ' row' : ''}`}>
              <div className={`estado Uppercase ${estado}`}>
                {estado}
              </div>
            </div>
          }
        </div>
      </div>
    </div>;
  }
};

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = (state: any) => ({
  sideDrawerShow: state.appReducer.sideDrawerShow,
  locale: state.idiomaReducer
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = (dispatch: (carga: any) => any) => ({
  appDialogShow: (dialogContent: any, dialogTitle: any, dialogStyles: any, dialogTitleStyles: any, onCloseClicked: any, onOverlayClicked: any, dialogContentStyles: any) =>
    dispatch(appActions.appDialogShow(dialogContent, dialogTitle, dialogStyles, dialogTitleStyles, onCloseClicked, onOverlayClicked, dialogContentStyles)),
  dispatch,
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(CardPedido);
