import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getStrings } from "../../../utils/LocaleUtils";
import { removePageSize } from "../../../utils/StorageUtils/LocalStorageUtils";
import { updateActiveMenuItem } from "../../../utils/ScreenUtils";
import { formatI18nString } from "../../../utils/StringUtils";

import * as appActions from "../../../store/actions/appAction";
import { idiomaReducerType } from "../../../store/reducers/idiomaReducer";

import HelpParagraph from "../../UI/HelpParagraph/HelpParagraph";
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv";
import { getTour } from "../../UI/ModalSystem/TourSystem";
import Icones from "../../../assets/icones/Icones";
// import PresentationSlide from "./PresentationSlide";

import "./HomeExterna.css";

export interface HomeExternaType {
}

interface HomeExternaTypeWithReducer extends HomeExternaType, idiomaReducerType {
	checkFirstUse: () => void
	tabIndex: number
	startTour: (tour: any) => void
}

/**
 * Home Page externa da aplicação. Apresentada quando o Usuário não estiver autenticado, apresentando as opções.
 */
class HomeExterna extends React.Component<HomeExternaTypeWithReducer> {
	state = {
		showWelcomeMessage: false, // Para caso de tela muito pequena esconde parte da mensagem
	};

	// noButton = isMobile;
	// imagePath = (complement: any) => `https://ampix.mobi/static/media/presentation${complement || ""}`;

	componentDidMount() {
		updateActiveMenuItem("menuItemHome");

		removePageSize();

		// Checa se é primeiro uso do usuário para oferecer tour pelo site.
		this.props.checkFirstUse();
	}

	render() {
		const MdArrowDownward = Icones.md.MdArrowDownward;
		const MdArrowUpward = Icones.md.MdArrowUpward;

		return <WidthAwareDiv>
			<div className='tela-start tela-home-externa'>
				<div style={this.state.showWelcomeMessage ? { overflow: 'visible', height: 'auto' } : undefined} className='welcome-message'>
					{getStrings().externalHomeHelp().slice(0, 3).map((string, index) =>
						<p key={'externalHomeIntro' + index} className='intro'>{formatI18nString(string)}</p>)}
				</div>
				<button className='arrow-button show-welcome-message-button' onClick={() => {
					const showWelcomeMessage = !this.state.showWelcomeMessage;
					this.setState({ showWelcomeMessage });
				}} children={this.state.showWelcomeMessage ? <MdArrowUpward /> : <MdArrowDownward />} />

				<HelpParagraph children={getStrings().externalHomeHelp().slice(3).map(string => formatI18nString(string))
					.concat([<button id="startLoginTour" onClick={() => this.props.startTour(getTour('website'))}>
						<span>{formatI18nString(getStrings().tourIcon)}</span><span>{getStrings().tourWebsite}</span></button>,
					<button id="startCadastroTour" onClick={() => {
						// if (isIOS) {
						// 	window.scroll({ top: window.innerHeight });
						// 	this.props.startTour(getTour('cadastro'));
						// }
						// else {
						// 	let rootElement = document.querySelector('#root');
						// 	let defaultWidthValue = rootElement.style.width;
						// 	rootElement.style.width = '1px';
						// 	setTimeout(() => {
						// 		this.props.startTour(getTour('cadastro'));
						// 		rootElement.style.width = defaultWidthValue;
						// 	}, 25);
						// }

						window.scroll({ top: window.innerHeight });
						this.props.startTour(getTour('cadastro'));
					}}>
						<span>{formatI18nString(getStrings().tourIcon)}</span><span>{getStrings().tourCadastro}</span></button>,
					<button id="startUsuarioTour" onClick={() => this.props.startTour(getTour('usuario'))}>
						<span>{formatI18nString(getStrings().tourIcon)}</span><span>{getStrings().tourUser}</span></button>,
					])} />

				<div className={`button-grid`}>
					<div className='row-sigin-signup'>
						<Link className='button blue-button login' tabIndex={this.props.tabIndex} to='/login'>{getStrings().signIn}</Link>
						<Link className='button blue-button sign-up' tabIndex={this.props.tabIndex} to='/signUp'>{getStrings().signUp}</Link>
					</div>
					<Link className='button blue-button reader' tabIndex={this.props.tabIndex} to='/controleVendas'>{getStrings().codeReader}</Link>
				</div>
			</div>
		</WidthAwareDiv>;
	}
};

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
function mapStateToProps(state: any) {
	const props = {
		...state.idiomaReducer,
		tabIndex: state.appReducer.getTabIndex()
	};

	return props;
}

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch: any) {
	const props = {
		checkFirstUse: () => dispatch(appActions.checkFirstUse()),
		startTour: (tour: any) => dispatch(appActions.appStartTour(tour))
	};

	return props;
}

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(HomeExterna);
