//@ts-check
import "./RedefinicaoSenhaTela.css";

import React from 'react';
import { Entrada, Botao } from '../../../../nucleo/nucleo';
import { ClienteHttp, NavegacaoUtils, LocalidadeUtils } from '../../../../nucleo/utils/utils';
import apelidos from '../../../../rotas/apelidos';
import { connect } from 'react-redux';
import { mostrarOverlay, esconderOverlay } from '../../../../nucleo/redux/overlay/overlayActions';
import CarregamentoOverlay from '../../../overlay/CarregamentoOverlay/CarregamentoOverlay';
import { getEmpresaApp } from '../../../../nucleo/utils/StorageUtils/StorageUtils';


class RedefinicaoSenhaTela extends React.Component {
    /**
     * 
     * @type {{valido: boolean, novaSenha: string, confirmacaoSenha: string}}
     */
    state = {
        valido: false,
        novaSenha: '',
        confirmacaoSenha: '',
    }

    /**
     * 
     * @param {{target: {value: string, name: string}}} event 
     */
    onChange = (event) => {
        const {name, value} = event.target;
        
        
        this.setState({[name]: value});
    }

    entradas = () => {
        const query = new URLSearchParams(window.location.search);
        const id = query.get('id');
        const token = query.get('token');

        return <div>
            <Entrada type="password" placeholder={LocalidadeUtils.obterTraducao().register.label.newPassword} onChange={this.onChange} name={'novaSenha'} value={this.state.novaSenha} />
            <Entrada type="password" placeholder={LocalidadeUtils.obterTraducao().register.label.repeatPassword} onChange={this.onChange} name={'confirmacaoSenha'} value={this.state.confirmacaoSenha} />
            <Botao children={LocalidadeUtils.obterTraducao().words.submit} onClick={() => {
                const {novaSenha, confirmacaoSenha} = this.state;

                if (novaSenha === confirmacaoSenha) {
                    const passwordResetToken = {
                        token: token,
                        usuario: {
                            id,
                            login: {
                                senha: novaSenha,
                                confirmacaoSenha,
                            }
                        }
                    };

                    this.props.dispatch(mostrarOverlay({componente: 
                        /**
                         * 
                         * @param {any} props 
                         * @returns 
                         */
                        (props) => <CarregamentoOverlay {...props} />}));

                    ClienteHttp.obterClienteHttp().post(
                        ClienteHttp.getApi('user/changePassword'),
                        passwordResetToken,
                    ).then(() => {
                        this.props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`));
                    })
                    .finally(() => {
                        this.props.dispatch(esconderOverlay());
                    });
                }
            }} />
        </div>;
    }

    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const id = query.get('id');
        const token = query.get('token');

        ClienteHttp.obterClienteHttp().get(
            ClienteHttp.getApi('user/validaTokenRedefinirSenha'),
            {params: {id, token}},
        ).then((_) => {
            this.setState({valido: true});
        });
    }

    render() {
        return  <div key='tela' className='tela' id='redefinicao-senha-tela'>
            {this.state.valido
                ? this.entradas()
                : null
            }
        </div>
    }
}

/**
 * 
 * @param {import("../../../../nucleo/redux/types").webAppReducersTipo} _ 
 * @returns 
 */
const mapStateToProps = (_) => ({

});

/**
 * 
 * @param {import("../../../../../../store/actions/acaoTemplate").dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(RedefinicaoSenhaTela);
