//@ts-check
export * from "../../../../../utils/StorageUtils/LocalStorageUtils"
export * from "../../../../../utils/StorageUtils/SessionStorageUtils"

/**
 * 
 * @param {import("../../redux/carrinho/types").carrinhoReducerTipo} produtos 
 */
 export function salvarCarrinho(produtos) {
    localStorage.setItem("carrinho", JSON.stringify(produtos));
}

/**
 * 
 * @return {import("../../redux/carrinho/types").carrinhoReducerTipo}}
 */
export function getCarrinhoVazio() {
    return {
        grupoProdutoTamanhoId: NaN,
        produtos: {},
        unidadeAtual: '',
        unidade: {sabores: {}, quantidadeMaximaSabores: 0, nome: '', saboresRestantes: 0, quantidade: 1}
    };
}

/**
 * 
 * @returns {import("../../redux/carrinho/types").carrinhoReducerTipo}
 */
export function carregarCarrinho() {
    const carrinhoString = localStorage.getItem("carrinho");

    if (carrinhoString) {
        return JSON.parse(carrinhoString);
    }

    return getCarrinhoVazio();
}