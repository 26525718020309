//@ts-check
import criarReducer from "../factory/criarReducer";
import tipoDeAcoes from "./carrinhoActions";
import { ProdutoUtils } from "../../utils/utils";
import { carregarCarrinho, getCarrinhoVazio, salvarCarrinho } from "../../utils/StorageUtils/StorageUtils";

/**
 * 
 * @type {import("./types").carrinhoUnidadeTipo}
 */
const unidadeInicial = {sabores: {}, quantidadeMaximaSabores: 0, nome: '', saboresRestantes: 0, quantidade: 1};

/**
 * 
 * @type {import("./types").carrinhoReducerTipo}
 */
const estadoInicial = carregarCarrinho();

/**
 * 
 * @type {import("../../../../../store/actions/acaoTemplate").acoesNomeadasTipo<import("./types").carrinhoReducerTipo>}
 */
const acoesNomeadas = {
  [tipoDeAcoes.REMOVER_UNIDADE]: (_, estadoAtual = estadoInicial) => {
    const novoEstado = {
      ...estadoAtual,
      unidade: unidadeInicial,
    };

    salvarCarrinho(novoEstado);
    return novoEstado;
  },
  [tipoDeAcoes.ATUALIZAR_QUANTIDADE_UNIDADE]: ({quantidade}, estadoAtual = estadoInicial) => {
    const novoEstado = {
      ...estadoAtual,
      unidade: {
        ...estadoAtual.unidade,
        quantidade: quantidade > 0 && quantidade <= ProdutoUtils.obterQuantidadeEmEstoque()
          ? quantidade
          : estadoAtual.unidade.quantidade,
      }
    };

    salvarCarrinho(novoEstado);
    return novoEstado;
  },
  [tipoDeAcoes.ATUALIZAR_UNIDADE]: ({nome, sabor, quantidadeMaximaSabores, grupoProdutoTamanhoId}, estadoAtual = estadoInicial) => {
    const sabores = {
      ...estadoAtual.unidade.sabores,
      [sabor.id]: sabor,
    }
    const unidade = {
      ...estadoAtual.unidade,
      nome,
      quantidadeMaximaSabores,
      sabores,
      saboresRestantes: (() => {
        const quantidades = [
          0,
          ...Object.values(sabores)
            .map(sabor => sabor.quantidade),
        ];
        const quantidade = quantidades
          .reduce((soma, valorAtual) => soma + valorAtual);
        
        return quantidadeMaximaSabores - quantidade;
      })(),
    };
    const novoEstado = {
      ...estadoAtual,
      grupoProdutoTamanhoId: isNaN(grupoProdutoTamanhoId)
        ? estadoAtual.grupoProdutoTamanhoId
        : grupoProdutoTamanhoId,
      unidadeAtual: nome,
      unidade,
    };

    salvarCarrinho(novoEstado);
    return novoEstado;
  },
  [tipoDeAcoes.ATUALIZAR_PRODUTO]: ({id, observacao, produto, quantidade, valorTotal}, estadoAtual = estadoInicial) => {
    const promocao = produto.promocao || {precoPromo: 0};
    const carrinhoProduto = {
      ...produto, 
      quantidade,
      observacao,
      valorTotal: valorTotal === undefined
        ? (
          promocao.precoPromo > 0
            ? promocao.precoPromo
            : produto.preco
        ) * quantidade
        : valorTotal,
    };
    
    const produtos = quantidade <= produto.quantidadeEmEstoque && quantidade > 0
    ? {
      ...estadoAtual.produtos,
      [id]: carrinhoProduto, 
    }
    : {
      ...estadoAtual.produtos
    };

    if (quantidade <= 0) {
      delete produtos[id];
    }

    const novoEstado = {
      ...estadoAtual,
      produtos,
    }
 
    salvarCarrinho(novoEstado);
    return novoEstado;
  }
  , [tipoDeAcoes.LIMPAR_CARRINHO]: () => {
    /**
     * 
     * @type {import("./types").carrinhoReducerTipo}
     */
    const novoEstado = getCarrinhoVazio();

    salvarCarrinho(novoEstado);
    return novoEstado;
  }
  , [tipoDeAcoes.REMOVER_PRODUTO]: ({id}, estadoAtual = estadoInicial) => {
    const produtos = {...estadoAtual.produtos};

    delete produtos[id];

    const novoEstado = {
      ...estadoAtual,
      produtos,
    }

    salvarCarrinho(novoEstado);
    return novoEstado;
  }
};

/**
 * 
 * @param {import("./types").carrinhoReducerTipo} estado
 * @param {import("../factory/criarReducer").acaoTipo} acao
 */
export default (estado = estadoInicial, acao) =>
  criarReducer(estado, acao, acoesNomeadas);
