//@ts-check
import { bairroReducerActionTypes } from "./bairroReducer"
import criarAction from "../factory/criarAction"

/**
 * 
 * @param {import("./types").bairroReducerTipo} carga
 * @returns {(dispatch: import("../../../../../store/actions/acaoTemplate").dispatchTipo) => void}
 */
export const polularBairros = (carga) => function(dispatch) {
  dispatch(criarAction(carga, bairroReducerActionTypes.POPULAR_BAIRROS));
}
