//@ts-check
import React from 'react';
import { connect } from 'react-redux';

import "./SnackbarTemplate.css";

/**
 * 
 * @param {import('./types').snackBarPropsType} props 
 * @returns {JSX.Element}
 */
function SnackbarTemplate(props) {
  return <div key='snackbar_template' style={props.ativo ? undefined : {display: 'none'}} className='snackbar-template'>
    {props.frase}
  </div>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  ...state.webAppSnackbarReducer,
});

/**
 * 
 * @param {*} _ 
 * @returns 
 */
const mapDispatchToProps = (_) => ({});


export default connect(mapStateToProps, mapDispatchToProps)(SnackbarTemplate);
