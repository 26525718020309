import React from 'react';

import Icones from '../../../assets/icones/Icones';

import "../../../assets/css/components/Button.css";

class DelButton extends React.Component {
    render() {
        const GoTrashcan = Icones.go.GoTrashcan;

        return <button onClick={this.props.onClick} className={`delete-button fit-content width`} style={{height: '2.25em', paddingLeft: '.75em', paddingRight: '.75em'}}>
            <GoTrashcan />
        </button>;
    }
}

export default DelButton;
