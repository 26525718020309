//@ts-check
import React from 'react';
import { Entrada, Form } from '../../../../nucleo/nucleo';

import "./CadastroUsuarioFinalTela.css";

import { LocalidadeUtils } from '../../../../nucleo/utils/utils';

import { connect } from 'react-redux';
import { atualizarCadastro, editarCadastro } from '../../../../nucleo/redux/cadastro/cadastroUsuario/cadastroUsuarioActions';
import { TIPO_CADASTRO_USUARIO_COMPLETO } from '../../../../../../store/reducers/empresaSelectorReducer';

class CadastroUsuarioFinalTela extends React.Component {
  firstRunUpdate = false;

  componentDidMount() {
    window.scrollTo({top: 0});

    if (this.props.editar) {
      this.props.editarCadastro();
    }
  }

  /**
   * 
   * @param {import('./types').cadastroUsuarioFinalTela} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.cadastroInicial.usuario.nome && this.props.cadastroInicial.usuario.nome) {
      this.props.atualizarCadastro(this.props.cadastroInicial.usuario);
    }
  }

  render() {
    const isCadastroCompleto = this.props.parametrosEmpresa.tipoCadastroUsuario === TIPO_CADASTRO_USUARIO_COMPLETO;

    return <div id='cadastro-usuario-final-tela' className='tela'>
      <div className='barra'>
        {LocalidadeUtils.obterTraducao().register.label.personalData}
      </div>
      <Form id='conteudo'>
        <div className='campo-duplo'>
          <div className='left'>
            <Entrada obrigatorio onChange={this.props.onChange} name='nome' value={this.props.cadastro.usuario.nome} type='text' maxLength={40} label={LocalidadeUtils.obterTraducao().register.label.name} placeholder={LocalidadeUtils.obterTraducao().register.placeholder.name} />
          </div>
          <div className='right'>
            <Entrada obrigatorio onChange={this.props.onChange} name='sobrenome' value={this.props.cadastro.usuario.sobrenome} maxLength={40} type='text' label={LocalidadeUtils.obterTraducao().register.label.lastName} placeholder={LocalidadeUtils.obterTraducao().register.placeholder.lastName} />
          </div>
        </div>
        <Entrada obrigatorio onChange={this.props.onChange} name='celular' value={this.props.cadastro.usuario.celular} maxLength={15} type='digits' label={LocalidadeUtils.obterTraducao().register.label.celphoneNumber} placeholder={LocalidadeUtils.obterTraducao().register.placeholder.celphoneNumber} />
        {this.props.editar || isCadastroCompleto
          ? <div className='campo-duplo'>
              <div className='left'>
                <Entrada obrigatorio={isCadastroCompleto} onChange={this.props.onChange} name='cpf' value={this.props.cadastro.usuario.cpf} maxLength={14} type='digits' label={LocalidadeUtils.obterTraducao().register.label.securityNumber} placeholder={LocalidadeUtils.obterTraducao().register.placeholder.securityNumber} />
              </div>
              <div className='right'>
                <Entrada obrigatorio={isCadastroCompleto} onChange={this.props.onChange} name='dataDeNascimento' value={this.props.cadastro.usuario.dataDeNascimento} type='date' label={LocalidadeUtils.obterTraducao().register.label.birthDate} placeholder={LocalidadeUtils.obterTraducao().register.placeholder.birthDate} />
              </div>
            </div>
          : null
        }
        {this.props.editar 
          ? null
          : <>
            <Entrada obrigatorio onChange={this.props.onChange} name='email' value={this.props.cadastro.usuario.email} type='email' maxLength={250} label={LocalidadeUtils.obterTraducao().register.label.email} placeholder={LocalidadeUtils.obterTraducao().register.placeholder.email} />
            <Entrada obrigatorio onChange={this.props.onChange} name='senha' value={this.props.cadastro.senha} type='password' maxLength={100} label={LocalidadeUtils.obterTraducao().register.label.password} placeholder={LocalidadeUtils.obterTraducao().register.placeholder.password} />
          </>
        }
      </Form>
    </div>;
  }
}

/**
 * 
 * @param {import('../../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  cadastroInicial: state.webAppUsuarioReducer,
  cadastro: state.webAppCadastroUsuarioReducer,
  parametrosEmpresa: state.webAppEmpresaReducer.parametrosEmpresa,
});

/**
 * 
 * @param {import('../../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   * 
   * @param {any} usuario 
   * @returns 
   */
  atualizarCadastro: (usuario) => dispatch(atualizarCadastro({usuario})),
  dispatch,
  editarCadastro: () => dispatch(editarCadastro()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CadastroUsuarioFinalTela);
