//@ts-check
import axios from "axios"
import { setAuthStorage as setAuthStorage_  } from "../StorageUtils/StorageUtils"
import { getApi as getApi_, urlDatabase as urlDatabase_, getUrl  } from "../SecureConnectionUtils/SecureConnectionUtils"
import { getHeaders as getHeaders_  } from "../HeadersUtils/HeadersUtils"

export const urlDatabase = urlDatabase_;

export const getApi = getApi_;

/**
 * 
 * @param {any} object 
 * @returns 
 */
export const setAuthStorage = (object = undefined) => setAuthStorage_(object);

/**
 * 
 * @param {any} params 
 * @param {any} headers 
 * @param {any} otherConfigs 
 * @returns 
 */
export const getHeaders = (params = undefined, headers = undefined, otherConfigs = undefined) => getHeaders_(params, headers, otherConfigs);

export function obterClienteHttp () {
  return axios;
}

/**
 * 
 * @param {string} endpoint 
 * @param {"get"|"post"|"delete"} metodo 
 * @param {boolean} temAutenticacao 
 * @param {any} configuracoes 
 * @param {any} body 
 * @returns 
 */
export const requisicaoServidor = (endpoint, metodo, temAutenticacao, configuracoes = null, body = null) => {
  const configs = temAutenticacao
    ? getHeaders(undefined, undefined, configuracoes)
    : configuracoes;

  switch(metodo) {
    case "get":
    case "delete":
      return obterClienteHttp()[metodo](getApi(endpoint), configs);
    case "post":
      return obterClienteHttp().post(getApi(endpoint), body, configs);
    default:
      throw new Error("method not implemented");
  }
}

export const obterUrl = getUrl;
