import moment, { Moment } from "moment"
import React, { useEffect, useState }  from "react"

import { getStrings } from "../../../utils/LocaleUtils"
import { DialogFeriadosEdicaoTipo, feriadoTipo, horarioFeriadoTipo, selectOptionTipo } from "../../../utils/QuadroHorariosUtils/quadroHorariosTypes"
import { timeInputOnBlur, timeInputOnChange } from "../../../utils/QuadroHorariosUtils/quadroHorariosUtils"
import { MONTH_DAY_INPUT, TIME_INPUT } from "../../UI/dateTimeInputDefault/DateTimeInputDefault"
import InputCustomizado from "../../UI/Input/InputCustomizado"

import "./DialogFeriadosEdicao.css"

const horarioFeriadoOptionAberto = {label: '', value: true};
const horarioFeriadoOptionFechado = {label: '', value: false};

export function DialogFeriadosEdicao({ cadastroDados, salvarDados, esconderDialog, feriadoSelecionado }: DialogFeriadosEdicaoTipo) {

  const [nomeFeriado, setNomeFeriado] = useState<string>('');
  const [dataFeriado, setDataFeriado] = useState<Moment | string | undefined>();
  const [horarioFeriadoOption, setHorarioFeriadoOption] = useState<selectOptionTipo>(horarioFeriadoOptionFechado);
  const [horaInicial, setHoraInicial] = useState<Moment | undefined>();
  const [horaFinal, setHoraFinal] = useState<Moment | undefined>();
  const [aviso, setAviso] = useState('');

  horarioFeriadoOptionAberto.label = getStrings().open;
  horarioFeriadoOptionFechado.label = getStrings().closed;

  useEffect(() => {
    if (feriadoSelecionado) {
      setNomeFeriado(feriadoSelecionado.nome);
      setDataFeriado(moment({day: feriadoSelecionado.dia, month: feriadoSelecionado.mes, year: feriadoSelecionado.ano}));

      if (feriadoSelecionado.horarioFeriadoList?.length) {
        setHorarioFeriadoOption(horarioFeriadoOptionAberto);
        setHoraInicial(moment(feriadoSelecionado.horarioFeriadoList[0].horaInicial, 'HH:mm'));
        setHoraFinal(moment(feriadoSelecionado.horarioFeriadoList[0].horaFinal, 'HH:mm'));
      }
      else {
        setHorarioFeriadoOption(horarioFeriadoOptionFechado);
        setHoraInicial(undefined);
        setHoraFinal(undefined);
      }
    }
    else {
      limparCampos();
    }
    setAviso('');
  }, [feriadoSelecionado]);

  function fecharTela() {
    limparCampos();
    esconderDialog();
  }

  function limparCampos() {
    setNomeFeriado('');
    setDataFeriado(undefined);
    setHorarioFeriadoOption(horarioFeriadoOptionFechado);
    setHoraInicial(undefined);
    setHoraFinal(undefined);
    setAviso('');
  }

  function validaDados(): feriadoTipo | null {
    if (!nomeFeriado || !nomeFeriado.length) {
      setAviso(getStrings().holidayNameNotInformed)
      return null;
    }
    if (!dataFeriado) {
      setAviso(getStrings().holidayDateNotInformed)
      return null;
    }

    const dataMoment = typeof dataFeriado === 'string' ? moment(dataFeriado, 'DD/MM/YYYY') : dataFeriado;

    if (!dataMoment.isValid()) {
      setAviso(getStrings().invalidDate)
      return null;
    }
    if (horarioFeriadoOption.value) {
      if (!horaInicial) {
        setAviso(getStrings().startTimeNotInformed);
        return null;
      }
      if (!horaFinal) {
        setAviso(getStrings().endTimeNotInformed);
        return null;
      }
      if (horaInicial.isSameOrAfter(horaFinal)) {
        setAviso(getStrings().endTimeSameOrBeforeStartTime);
        return null;
      }
    }
    if (!feriadoSelecionado && cadastroDados?.feriadoList?.some(feriado => feriado.dia === dataMoment.date() && feriado.mes === dataMoment.month())) {
      setAviso(getStrings().holidayAlreadyExists);
      return null;
    }

    let horarioFeriadoList: horarioFeriadoTipo[] = [];

    if (horarioFeriadoOption.value) {
      const horaInicialString = (horaInicial || moment.utc(Date.now())).format('HH:mm');
      const horaFinalString = (horaFinal || moment.utc(Date.now())).format('HH:mm');
      horarioFeriadoList.push({ horaInicial: horaInicialString, horaFinal: horaFinalString });
    }

    return {
      dia: dataMoment.date(),
      mes: dataMoment.month(),
      nome: nomeFeriado,
      horarioFeriadoList: horarioFeriadoList,
    }
  }

  return <div id="dialog-feriados-edicao">
    <div className="dialog-feriados-edicao-conteudo">
      <div className="feriado-informacao">
        <InputCustomizado
          newApi
          type="text"
          inputType="text"
          maxLength={50}
          value={nomeFeriado}
          label={getStrings().name}
          placeholder={getStrings().holidayNamePlaceholder}
          onChange={event => setNomeFeriado(event?.target?.value)}
        />
        <InputCustomizado
          newApi
          type="date"
          inputType={MONTH_DAY_INPUT}
          value={dataFeriado}
          label={getStrings().date}
          placeholder={getStrings().holidayDatePlaceholder}
          onChange={value => {
            if (typeof value === 'string') {
              let valueString = value.replace(/\D/g, '');

              valueString = valueString.split('').reduce((acc, cur, idx) => {
                return idx === 2 || idx === 4 ? (acc + '/' + cur) : (acc + cur);
              }, '');

              if (valueString.length > 5) {
                const valueMoment = moment(valueString, 'DD/MM/YYYY');
                setDataFeriado(valueMoment);
              }
              else if (valueString.length > 0) {
                setDataFeriado(valueString);
              }
              else {
                setDataFeriado(dataFeriado !== undefined ? undefined : '');
              }
            }
            else {
              setDataFeriado(value)
            }
          }}
          onClear={() => setDataFeriado(undefined)}
          onBlur={value => {
            if (typeof value === 'string') {
              const valueString = value.replace(/\D/g, '');
              const valueMoment = valueString.length > 9 ? moment(valueString, 'DD/MM/YYYY') : moment();
              setDataFeriado(valueMoment);
            }
          }}
        />
        <InputCustomizado
          newApi
          id="horarioFeriado"
          inputType="singleSelect"
          clearable={false}
          searchable={false}
          label={getStrings().schedule}
          value={horarioFeriadoOption}
          options={[horarioFeriadoOptionAberto, horarioFeriadoOptionFechado]}
          onChange={(value: selectOptionTipo) => value && setHorarioFeriadoOption(value)}
        />
      </div>
      {horarioFeriadoOption.value
        ? <div className="feriado-horarios">
          <div className="feriado-horarios__horario">
            <span>{getStrings().from}</span>
            <InputCustomizado
              newApi
              type="date"
              inputType={TIME_INPUT}
              value={horaInicial}
              placeholder={getStrings().timeStart}
              onChange={value => timeInputOnChange(value, setHoraInicial)}
              onBlur={value => timeInputOnBlur(value, horaInicial, setHoraInicial)}
              onClear={() => setHoraInicial(undefined)}
            />
          </div>
          <div className="feriado-horarios__horario">
            <span>{getStrings().to}</span>
            <InputCustomizado
              newApi
              type="date"
              inputType={TIME_INPUT}
              value={horaFinal}
              placeholder={getStrings().timeEnd}
              onChange={value => timeInputOnChange(value, setHoraFinal)}
              onBlur={value => timeInputOnBlur(value, horaFinal, setHoraFinal)}
              onClear={() => setHoraFinal(undefined)}
            />
          </div>
        </div>
        : null
      }
      {aviso
        ? <div className="aviso">{aviso}</div>
        : null
      }
      <div className="botoes">
        <button
          className="button-primary"
          style={{ marginLeft: '2em' }}
          onClick={() => fecharTela()}>
          {getStrings().cancel}
        </button>
        <button
          className="button-primary"
          onClick={() => {         
            const novoFeriado = validaDados();

            if (!novoFeriado) {
              return;
            }

            let dadosSalvar;

            if (cadastroDados) {
              let feriadoList;

              if (cadastroDados.feriadoList?.length) {
                if (feriadoSelecionado) {
                  feriadoList = cadastroDados.feriadoList.map(feriado => {
                    if (feriado.dia === feriadoSelecionado.dia
                      && feriado.mes === feriadoSelecionado.mes
                      && feriado.ano === feriadoSelecionado.ano) {
                      return novoFeriado;
                    }
                    return feriado;
                  });
                }
                else {
                  feriadoList = [...cadastroDados.feriadoList, novoFeriado];
                }
              }
              else {
                feriadoList = [novoFeriado];
              }

              dadosSalvar = {
                ...cadastroDados,
                feriadoList: feriadoList,
              }
            }
            else {
              dadosSalvar = {
                feriadoList: [novoFeriado],
                forcarEmpresaFechada: false,
                horarioFuncionamentoList: [],
              }
            }

            salvarDados(dadosSalvar);
            fecharTela();
          }}
        >
          {feriadoSelecionado ? getStrings().change : getStrings().save}
        </button>
      </div>
    </div>
  </div>
}