//@ts-check
import "./CarrinhoTela.css"

import React from "react"
import { Texto } from "../../../nucleo/nucleo"
import { LocalidadeUtils, NavegacaoUtils, OrdenacaoUtils } from "../../../nucleo/utils/utils"
import { connect } from "react-redux"
import { definirBotaoPrincipal } from "../../../nucleo/redux/botoes/botaoPrincipal/botaoPrincipalActions"
import CarrinhoCard from "../../cards/CarrinhoCard/CarrinhoCard"
import apelidos from "../../../rotas/apelidos"
import LoginDialog from "../../dialogs/LoginDialog/LoginDialog"
import { mostrarDialog, fecharDialog } from "../../../nucleo/redux/dialog/dialogActions"
import { TIPO_CADASTRO_USUARIO_COMPLETO } from "../../../../../store/reducers/empresaSelectorReducer"
import { getEmpresaApp } from "../../../nucleo/utils/StorageUtils/StorageUtils"
import { validaCPFOuCNPJ } from "../../../nucleo/utils/IdentificadorUtils/IdentificadorUtils"
import { definirBotaoVoltar } from "../../../nucleo/redux/botoes/botaoVoltar/botaoVoltarActions"

class CarrinhoTela extends React.Component {
  componentDidMount() {
    this.props.definirBotaoPrincipal({
      executarAcao: () => {
        const usuarioReducer = this.props.webAppUsuarioReducer;
        if (usuarioReducer.verificaUsuarioLogado()) {
          if (this.props.parametrosEmpresa.tipoCadastroUsuario === TIPO_CADASTRO_USUARIO_COMPLETO && !validaCPFOuCNPJ(usuarioReducer.usuario.cpf)) {
            this.props.dispatch(NavegacaoUtils.goTo(apelidos.editar, { editar: true, completarCadastro: true }));
          }
          else {
            this.props.dispatch(NavegacaoUtils.goTo(apelidos.compra));
          }
        }
        else {
          this.props.mostrarDialog(() => <LoginDialog caminho={apelidos.compra} fecharHabilitado />);
        }
      },
    });

    this.props.dispatch(definirBotaoVoltar({
      botaoVoltarCustomizado: () => { this.props.dispatch(NavegacaoUtils.goTo(`${apelidos.app}?empresa=${getEmpresaApp()}`)) }
    }));
  }

  componentWillUnmount() {
    this.props.dispatch(fecharDialog());
  }

  render() {
    const produtosCarrinho = this.props.webAppCarrinhoReducer.produtos;
    /**
     * 
     * @type {Array<import('../../../nucleo/redux/carrinho/types').carrinhoProdutoTipo & {id: string}>}
     */
    const produtosCarrinhoLista = OrdenacaoUtils
      .ordenarArrayDeObjetosPorStringItem(Object.values(produtosCarrinho), 'nome');

    return <div key='tela' className='tela' id='carrinho-tela'>
      {
        produtosCarrinhoLista.length > 0
      ? <>
        <div key='__carrinho_conteudo__' id='carrinho-conteudo'>
          <div style={{marginTop: '1em'}} />
          {produtosCarrinhoLista
            .map((produto) => {
              return <CarrinhoCard {...produto} />;
            })
          }
        </div>
        <div id='carrinho-rodape'>
            <div id='carrinho-preco-total'>
              <Texto className='campo'>
                {`${LocalidadeUtils.obterTraducao().orders.totalValue}:`}
              </Texto>
              <Texto className='valor'>
                {LocalidadeUtils.obterTraducao().currency.format([0, ...produtosCarrinhoLista
                .map(
                  /**
                   * 
                   * @param {import('../../../nucleo/redux/carrinho/types').carrinhoProdutoTipo} produto 
                   * @returns {number} valorTotal
                   */
                  (produto) => produto.valorTotal)]
                .reduce((precoA, precoB) => precoA + precoB))
                }
            </Texto>
            </div>
          </div>
        </>
        : <Texto key='__carrinho_conteudo__' id='carrinho-vazio'>{LocalidadeUtils.obterTraducao().words.emptyCart}</Texto>
      }
    </div>;
  }
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  webAppGrupoProdutosReducer: state.webAppGrupoProdutosReducer,
  webAppCarrinhoReducer: state.webAppCarrinhoReducer,
  webAppProdutosReducer: state.webAppProdutosReducer,
  webAppUsuarioReducer: state.webAppUsuarioReducer,
  webAppCadastroUsuarioReducer: state.webAppCadastroUsuarioReducer,
  parametrosEmpresa: state.webAppEmpresaReducer.parametrosEmpresa,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   * 
   * @param {import('../../../nucleo/redux/botoes/botaoPrincipal/botaoPrincipalReducer').botaoPrincipalReducerTipo} carga 
   * @returns 
   */
  definirBotaoPrincipal: (carga) => dispatch(definirBotaoPrincipal(carga)),
  dispatch,
  /**
   * 
   * @param {React.ElementType} Dialog 
   * @returns 
   */
  mostrarDialog: (Dialog) => dispatch(mostrarDialog({conteudo: () => <Dialog />})),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarrinhoTela);
