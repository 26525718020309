import React from "react";
import { connect } from "react-redux";

import { getStrings } from "../../../utils/LocaleUtils"

import {
  ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA
  , ESTADO_ITEM_VENDA_EM_PRODUCAO
  , ESTADO_ITEM_VENDA_AGUARDANDO
  , ESTADO_ITEM_VENDA_PRODUZIDO
  , ESTADO_ITEM_VENDA_ENTREGUE
  , ESTADO_ITEM_VENDA_ENCERRADO
  , ESTADO_ITEM_VENDA_CANCELADO
  , ESTADO_ITEM_VENDA_ENVIADO
} from "../../../store/reducers/controleVenda/controleVendaReducer"
import * as controleVendaActions from "../../../store/actions/controleVenda/controleVendaAction"

import BuildCellFromOrigemRaw from "./BuildCellFromOrigemRaw"
import { stateToColor } from "../../producao/grid/GridProducao"

import "../vendas.css";

class BuildCellFromPedido extends React.Component {

  render() {

    let venda = (this.props || {}).venda || {}

    let itemVendaList = venda.itemVendaList || []

    let saleState = ""

    // Define o estado da venda em função do estado dos itens,
    // levando em consideração a prioridade de cada estado.
    if (itemVendaList.some(itemVenda => itemVenda.estado === ESTADO_ITEM_VENDA_ENVIADO)) {
      saleState = ESTADO_ITEM_VENDA_ENVIADO
    }
    else if (itemVendaList.some(itemVenda => itemVenda.estado === ESTADO_ITEM_VENDA_AGUARDANDO)) {
      saleState = ESTADO_ITEM_VENDA_AGUARDANDO
    }
    else if (itemVendaList.some(itemVenda => itemVenda.estado === ESTADO_ITEM_VENDA_EM_PRODUCAO)) {
      saleState = ESTADO_ITEM_VENDA_EM_PRODUCAO
    }
    else if (itemVendaList.some(itemVenda => itemVenda.estado === ESTADO_ITEM_VENDA_PRODUZIDO)) {
      saleState = ESTADO_ITEM_VENDA_PRODUZIDO
    }
    else if (itemVendaList.some(itemVenda => itemVenda.estado === ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA)) {
      saleState = ESTADO_ITEM_VENDA_SAIU_PARA_ENTREGA
    }
    else if (itemVendaList.some(itemVenda => itemVenda.estado === ESTADO_ITEM_VENDA_ENTREGUE)) {
      saleState = ESTADO_ITEM_VENDA_ENTREGUE
    }
    else if (itemVendaList.some(itemVenda => itemVenda.estado === ESTADO_ITEM_VENDA_ENCERRADO)) {
      saleState = ESTADO_ITEM_VENDA_ENCERRADO
    }
    else if (itemVendaList.some(itemVenda => itemVenda.estado === ESTADO_ITEM_VENDA_CANCELADO)) {
      saleState = ESTADO_ITEM_VENDA_CANCELADO
    }

    return <BuildCellFromOrigemRaw
      accordionTitle={getStrings().saleItemStateEnumToString(saleState)}
      className={this.props.className}
      codigo={(venda.origemVenda || {}).nome || ""}
      footerColor={stateToColor(saleState)}
      handleClick={() => { }}
      isOrigemLivre={false}
      nome={venda.nome || ""}
      nomeVendas={venda.itemSummary || ""}
      tabIndex={this.props.tabIndex}
      totalOnly
      valorGeral={(venda.pagamentoVendaList || []).reduce((previousSum, currentItem) => previousSum + currentItem.valorTotal, 0)}
    />
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({
  ...state.idiomaReducer,
  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
  tabIndex: state.appReducer.getTabIndex(),
})

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  exibeGridVendaFromOrigem: origemVenda => dispatch(controleVendaActions.exibeGridVendaFromOrigem(origemVenda)),
  reserveSaleSource: origemVenda => dispatch(controleVendaActions.reserveSaleSource(origemVenda)),
  setupNewVenda: origemVenda => dispatch(controleVendaActions.setupNewVenda(origemVenda))
})

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(BuildCellFromPedido)
