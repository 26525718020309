import React from "react"
import { connect } from "react-redux"

import { getStrings } from "../../../utils/LocaleUtils"
import { goBack } from "../../../utils/NavigatorUtils"
import { reduxDispatch } from "../../../utils/reduxUtils/reduxDispatch"
import { getURIFromEntity } from "../../../utils/URIUtils"
import { getApi } from "../../../utils/SecureConnectionUtils"
import { calculaMinutos, diasDaSemana, horarioFuncionamentoListToDiasDaSemanaHorarios, mesclaHorarios } from "../../../utils/QuadroHorariosUtils/quadroHorariosUtils"
import { diaDaSemanaHorarioTipo, diaDaSemanaTipo, horarioFuncionamentoTipo, horarioTipo, QuadroHorariosFormTipo } from "../../../utils/QuadroHorariosUtils/quadroHorariosTypes"

import { dispatchTipo } from "../../../store/actions/acaoTemplate"
import { appDialogHide, appDialogShow } from "../../../store/actions/appAction"
import { cadastroSaveUpdate } from "../../../store/actions/cadastroAction"

import Icones from "../../../assets/icones/Icones"
import WidthAwareDiv from "../../UI/WidthAwareDiv/WidthAwareDiv"
import BarraAcoesButton from "../../UI/Toolbar/BarraAcoesButton"
import { genBreadCrumbs } from "../../UI/BreadCrumbs/genBreadCrumbs"
import DialogQuadroHorariosEdicao from "../dialog/DialogQuadroHorariosEdicao"

import "./QuadroHorariosForm.css";

const editarDialog = (props: QuadroHorariosFormTipo, diaDaSemanaSelecionado?: diaDaSemanaTipo, horarioSelecionado?: horarioTipo) => {
  reduxDispatch((dispatch: dispatchTipo) => {
    dispatch(
      appDialogShow(
        <DialogQuadroHorariosEdicao
          esconderDialog={() => dispatch(appDialogHide())}
          salvarAlteracoes={(horariosSalvar?: diaDaSemanaHorarioTipo, horariosExcluir?: diaDaSemanaHorarioTipo) => salvarAlteracoes(props, horariosSalvar, horariosExcluir)}
          diaDaSemanaSelecionado={diaDaSemanaSelecionado}
          horarioSelecionado={horarioSelecionado}
        />,
        horarioSelecionado ? getStrings().editTime : getStrings().newTime,
        { overflow: 'unset' },
        undefined,
        () => { },
        () => { },
        { overflow: 'unset' }
      ));
  });
}

const salvarAlteracoes = (props: QuadroHorariosFormTipo, horariosSalvar: diaDaSemanaHorarioTipo = {}, horariosExcluir: diaDaSemanaHorarioTipo = {}) => {

  const diaDaSemanaHorarios = horarioFuncionamentoListToDiasDaSemanaHorarios(props.cadastroDados?.horarioFuncionamentoList);

  const novoHorarioFuncionamentoList: horarioFuncionamentoTipo[] = [];

  (Object.keys(diaDaSemanaHorarios) as diaDaSemanaTipo[]).forEach(diaDaSemana => {

    let horariosOriginais = diaDaSemanaHorarios[diaDaSemana];

    const horarioExcluir = horariosExcluir[diaDaSemana];
    if (horarioExcluir) {
      horariosOriginais = horariosOriginais.filter(horario => {
        return horario.horaInicial !== horarioExcluir.horaInicial && horario.horaFinal !== horarioExcluir.horaFinal;
      });
    }

    const horarioSalvar = horariosSalvar[diaDaSemana];
    if (horarioSalvar) {
      horariosOriginais.push(horarioSalvar);
    }

    const horariosAjustados = mesclaHorarios(horariosOriginais);

    novoHorarioFuncionamentoList.push(...horariosAjustados.map(horario => ({ diaDaSemana: diaDaSemana, ...horario })));
  });

  const dadosSalvar = { ...props.cadastroDados, horarioFuncionamentoList: novoHorarioFuncionamentoList };

  reduxDispatch(cadastroSaveUpdate(dadosSalvar, getURIFromEntity(dadosSalvar, true), getApi('quadroHorarios'), null, {}));
}

const BarraQuadroHorario = (props: QuadroHorariosFormTipo) => {
  const AiOutlineCalendar = Icones.ai.AiOutlineCalendar;
  const IoMdLock = Icones.io.IoMdLock;
  const IoMdUnlock = Icones.io.IoMdUnlock;

  return <div id="toolbar" className="toolbar">
    <div className="toolbar-content">
      <WidthAwareDiv>
        <div className="bar-container" >
          <BarraAcoesButton
            id="back-button"
            buttonGridClassName="bar-item"
            key="back"
            isPrimary={true}
            commandMethod={() => goBack()}
            label={"\u003c"}
            title={getStrings().back}
          />
          <BarraAcoesButton
            id="lock-button"
            buttonGridClassName="bar-item"
            style={props.cadastroDados?.forcarEmpresaFechada ? {background: 'rgb(225,20,20)', color: 'rgb(255,255,255)'} : {}}
            key="lock"
            isPrimary={false}
            commandMethod={() => props.perguntaForcarEmpresaFechada(props.cadastroDados?.forcarEmpresaFechada)}
            label={props.cadastroDados?.forcarEmpresaFechada ? <IoMdLock /> : <IoMdUnlock />}
            title={props.cadastroDados?.forcarEmpresaFechada ? getStrings().cancelForceCloseStore : getStrings().forceCloseStore}
          />
          <BarraAcoesButton
            id="holidays-button"
            buttonGridClassName="bar-item"
            key="holidays"
            isPrimary={false}
            commandMethod={() => props.updateExibirFeriados(true)}
            label={<AiOutlineCalendar />}
            title={getStrings().holiday("s")}
          />
          <BarraAcoesButton
            id="new-item-button"
            buttonGridClassName="bar-item"
            key="add"
            isPrimary={true}
            commandMethod={() => editarDialog(props)}
            label={"\u002B"}
            title={getStrings().newTime}
            attractSubmit={false}
            isDisabled={false}
          />
        </div>
      </WidthAwareDiv>
    </div>
  </div>
}

const ALTURA_TABELA_EM_PIXELS = 600; //12 x 50px

const QUANTIDADE_TOTAL_MINUTOS = 1440; //60m x 24h

class QuadroHorariosForm extends React.Component<QuadroHorariosFormTipo> {

  geraColunaHoras = () => {

    const listaHoras: JSX.Element[] = [];
    let top = 15;

    for (let i = 0; i <= 24; i += 2) {
      listaHoras.push(<span key={`__horas_hora_${i}__`} className="quadro-horarios-horas__hora" style = {{top: `${top}px`}}>{`${i}h`}</span>);
      top += 50;
    }

    return <div className="quadro-horarios-horas">
      {listaHoras}
    </div>
  }

  geraQuadroHorarios = () => {
    const diaDaSemanaHorarios = horarioFuncionamentoListToDiasDaSemanaHorarios(this.props.cadastroDados?.horarioFuncionamentoList);

    // altura da tabela em pixels dividido pela quantidade de minutos em 24h
    const pixelsPorMinuto = ALTURA_TABELA_EM_PIXELS / QUANTIDADE_TOTAL_MINUTOS;

    const linhas: JSX.Element[] = [];
    let top = 50;

    for (let i = 0; i <= 24; i++) {
      linhas.push(<div key={`__grade_linha_${i}__`} className="dia-semana-horarios-grade__linha" style={{top: `${top}px`}}></div>);
      top += 50;
    }

    const dayOfWeekLabelSize = window.screen.width < 800 ? 'reduced' : 'normal';

    return diasDaSemana.map((diaDaSemana, index) => {
      return <div key={index} className="quadro-horarios-dias__dia-semana">
        <div className="dia-semana-cabecalho">{getStrings().daysOfWeekLabel[diaDaSemana][dayOfWeekLabelSize]}</div>
        <div className="dia-semana-horarios">
          <div className="dia-semana-horarios-intervalos">
            {diaDaSemanaHorarios[diaDaSemana].map(horario => {
              const minutosHoraInicial = calculaMinutos(horario.horaInicial);
              const minutosHoraFinal = calculaMinutos(horario.horaFinal);

              const top = (minutosHoraInicial * pixelsPorMinuto) + (minutosHoraInicial > 0 ? 2 : 0);
              const height = (minutosHoraFinal * pixelsPorMinuto) - top + 2;

              return <button
                key={`${diaDaSemana}_${horario.horaInicial}-${horario.horaFinal}`}
                className="dia-semana-horarios__intervalo"
                type="button"
                style={{ top: top.toFixed(2) + 'px', height: height.toFixed(2) + 'px' }}
                onClick={() => editarDialog(this.props, diaDaSemana, horario)}
              >
                <span className="dia-semana-horarios__intervalo__horas">{`${horario.horaInicial} - ${horario.horaFinal}`}</span>
              </button>
            })}
          </div>
          <button
            className={`dia-semana-horarios-grade ${diaDaSemana === 'SEG' ? 'primeiro-dia-semana' : diaDaSemana === 'DOM' ? 'ultimo-dia-semana' : ''}`}
            type="button"
            onClick={() => editarDialog(this.props, diaDaSemana)}
          >
            {linhas}
          </button>
        </div>
      </div>
    });
  }

  render() {
    return <>
      {genBreadCrumbs(getStrings().timesheet, getStrings().registers)}
      <BarraQuadroHorario {...this.props}/>
      <div id="quadro-horarios-form">
          <div className="quadro-horarios-container">
            <div className="quadro-horarios">
              <div className="quadro-horarios-horas-container" style={{ height: '625px' }}>
                {this.geraColunaHoras()}
              </div>
              <div className="quadro-horarios-dias-container">
                {this.geraQuadroHorarios()}
              </div>
          </div>
        </div>
      </div>
    </>;
  }

}

const mapStateToProps = (state: any) => ({
  locale: state.idiomaReducer.locale,
  cadastroDados: state.cadastroReducer.cadastroDados,
});

export default connect(mapStateToProps)(QuadroHorariosForm);
