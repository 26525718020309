//@ts-check
import React from "react"
import { Roteador, Rota } from "../nucleo/nucleo"
import GrupoProdutoTela from "../componentes/telas/GrupoProdutoTela/GrupoProdutoTela"
import CarrinhoTela from "../componentes/telas/CarrinhoTela/CarrinhoTela"
import CadastroTela from "../componentes/telas/cadastro/CadastroTela/CadastroTela"
import CompraTela from "../componentes/telas/CompraTela/CompraTela"
import PedidosTela from "../componentes/telas/usuario/PedidoTela/PedidosTela"
import { connect } from "react-redux"
import apelidos from "./apelidos"
import { NavegacaoUtils } from "../nucleo/utils/utils"
import RedefinicaoSenhaTela from "../componentes/telas/usuario/RedefinicaoSenhaTela/RedefinicaoSenhaTela"
import ProdutosComTamanhoTela from "../componentes/telas/ProdutosComTamanhoTela/ProdutosComTamanhoTela"

/**
 * 
 * @param {import('./types').rotasPropsType} props 
 * @returns 
 */
function RotasDeslogado(props) {
  if (props.verificaUsuarioLogado()) {
    return null;
  }

  return [
    <Rota key='__rota_cadastro__' exact path={apelidos.cadastro} render={(props) => <CadastroTela { ...props} />} />,
    <Rota key='__rota_redefinir_senha__' exact path={apelidos.redefinirSenha} render={(props) => <RedefinicaoSenhaTela { ...props } />} />,
  ];
}

/**
 * 
 * @param {import('./types').rotasPropsType} props 
 * @returns 
 */
function RotasLogado(props) {
  if (!props.verificaUsuarioLogado()) {
    return null;
  }

  return [
    <Rota key='__rota_pedidos__' exact path={apelidos.pedidos} render={(props) => <PedidosTela { ...props } />} />,
    <Rota key='__rota_editar__' exact render={(props) => <CadastroTela { ...props } />} />,
  ];
}

/**
 * 
 * @param {import('./types').rotasPropsType} propsRotas 
 * @returns 
 */
function Rotas(propsRotas) {
  return <Rota path={apelidos.app} render={(props) =>
      <Roteador>
          <Rota exact path={apelidos.app} render={(props) => <GrupoProdutoTela { ...props } />} />
          <Rota exact path={apelidos.carrinho} render={(props) => <CarrinhoTela { ...props } />} />
          <Rota exact path={apelidos.compra} render={(props) => <CompraTela { ...props } />} />
          {/*@ts-ignore*/}
          <Rota exact path={apelidos.produtosComTamanho} render={(props) => <ProdutosComTamanhoTela { ...props } />} />
          {RotasLogado(propsRotas)}
          {RotasDeslogado(propsRotas)}
          <Rota exact path={'*'} render={(props) => { NavegacaoUtils.goTo(apelidos.app); return <div key='tela' className='tela'></div>; }} />
      </Roteador>
    }
    />;
}

/**
 * 
 * @param {import('../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  verificaUsuarioLogado: state.webAppUsuarioReducer.verificaUsuarioLogado,
});

export default connect(mapStateToProps)(Rotas);
