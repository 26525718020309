//@ts-check

/**
 * 
 * @param {string} iso 
 * @returns {string}
 */
export default function (iso) {
  switch (iso) {
    case "AD": return "Andorra";
    case "AE": return "Emirados Árabes";
    case "AF": return "Afeganistão";
    case "AG": return "Antígua e Barbuda";
    case "AI": return "Anguilla";
    case "AL": return "Albânia";
    case "AM": return "Armênia";
    case "AO": return "Angola";
    case "AQ": return "Antártida";
    case "AR": return "Argentina";
    case "AS": return "Samoa Americana";
    case "AT": return "Áustria";
    case "AU": return "Austrália";
    case "AW": return "Aruba";
    case "AX": return "Ilhas de Aland";
    case "AZ": return "Azerbaijão";
    case "BA": return "Bósnia e Herzegovina";
    case "BB": return "Barbados";
    case "BD": return "Bangladesh";
    case "BE": return "Bélgica";
    case "BF": return "Burkina Faso";
    case "BG": return "Bulgária";
    case "BH": return "Barém";
    case "BI": return "Burundi";
    case "BJ": return "Benim";
    case "BL": return "São Bartolomeu";
    case "BM": return "Bermudas";
    case "BN": return "Brunei";
    case "BO": return "Bolívia";
    case "BQ": return "Países Baixos Caribenhos";
    case "BR": return "Brasil";
    case "BS": return "Bahamas";
    case "BT": return "Butão";
    case "BV": return "Ilha Bouvet";
    case "BW": return "Botsuana";
    case "BY": return "Bielorrússia";
    case "BZ": return "Belize";
    case "CA": return "Canadá";
    case "CC": return "Ilhas Cocos (Keeling)";
    case "CD": return "República Democrática do Congo";
    case "CF": return "República Centro-Africana";
    case "CG": return "República do Congo";
    case "CH": return "Suíça";
    case "CI": return "Costa do Marfim";
    case "CK": return "Ilhas Cook";
    case "CL": return "Chile";
    case "CM": return "Camarões";
    case "CN": return "China";
    case "CO": return "Colômbia";
    case "CR": return "Costa Rica";
    case "CU": return "Cuba";
    case "CV": return "Cabo Verde";
    case "CW": return "Curaçao";
    case "CX": return "Ilha do Natal";
    case "CY": return "Chipre";
    case "CZ": return "República Tcheca";
    case "DE": return "Alemanha";
    case "DJ": return "Djibouti";
    case "DK": return "Dinamarca";
    case "DM": return "Dominica";
    case "DO": return "República Dominicana";
    case "DZ": return "Argélia";
    case "EC": return "Equador";
    case "EE": return "Estônia";
    case "EG": return "Egito";
    case "EH": return "Saara Ocidental";
    case "ER": return "Eritreia";
    case "ES": return "Espanha";
    case "ET": return "Etiópia";
    case "FI": return "Finlândia";
    case "FJ": return "Fiji";
    case "FK": return "Ilhas Malvinas";
    case "FM": return "Estados Federados da Micronésia";
    case "FO": return "Ilhas Feroé";
    case "FR": return "França";
    case "GA": return "Gabão";
    case "GB": return "Reino Unido";
    case "GD": return "Granada";
    case "GE": return "Geórgia";
    case "GF": return "Guiana Francesa";
    case "GG": return "Guernsey";
    case "GH": return "Gana";
    case "GI": return "Gibraltar";
    case "GL": return "Groelândia";
    case "GM": return "Gâmbia";
    case "GN": return "Guiné";
    case "GP": return "Guadalupe";
    case "GQ": return "Guiné Equatorial";
    case "GR": return "Grécia";
    case "GS": return "Ilhas Geórgia do Sul e Sandwich do Sul";
    case "GT": return "Guatemala";
    case "GU": return "Guam";
    case "GW": return "Guiné-Bissau";
    case "GY": return "Guiana";
    case "HK": return "Hong Kong";
    case "HM": return "Ilha Heard e Ilhas McDonald";
    case "HN": return "Honduras";
    case "HR": return "Croácia";
    case "HT": return "Haiti";
    case "HU": return "Hungria";
    case "ID": return "Indonésia";
    case "IE": return "Irlanda";
    case "IL": return "Israel";
    case "IM": return "Ilha de Man";
    case "IN": return "Índia";
    case "IO": return "Território Britânico do Oceano Índico";
    case "IQ": return "Iraque";
    case "IR": return "Irão";
    case "IS": return "Islândia";
    case "IT": return "Itália";
    case "JE": return "Jersey";
    case "JM": return "Jamaica";
    case "JO": return "Jordânia";
    case "JP": return "Japão";
    case "KE": return "Quênia";
    case "KG": return "Quirguistão";
    case "KH": return "Camboja";
    case "KI": return "Kiribati";
    case "KM": return "Comores";
    case "KN": return "São Cristóvão e Névis";
    case "KP": return "Coreia do Norte";
    case "KR": return "Coreia do Sul";
    case "KW": return "Kuwait";
    case "KY": return "Ilhas Cayman";
    case "KZ": return "Cazaquistão";
    case "LA": return "Laos";
    case "LB": return "Líbano";
    case "LC": return "Santa Lúcia";
    case "LI": return "Liechtenstein";
    case "LK": return "Sri Lanka";
    case "LR": return "Libéria";
    case "LS": return "Lesoto";
    case "LT": return "Lituânia";
    case "LU": return "Luxemburgo";
    case "LV": return "Letônia";
    case "LY": return "Líbia";
    case "MA": return "Marrocos";
    case "MC": return "Mônaco";
    case "MD": return "Moldávia";
    case "ME": return "Montenegro";
    case "MF": return "São Martinho";
    case "MG": return "Madagáscar";
    case "MH": return "Ilhas Marshall";
    case "MK": return "Macedônia";
    case "ML": return "Mali";
    case "MM": return "Mianmar";
    case "MN": return "Mongólia";
    case "MO": return "Macau";
    case "MP": return "Marianas Setentrionais";
    case "MQ": return "Martinica";
    case "MR": return "Mauritânia";
    case "MS": return "Montserrat";
    case "MT": return "Malta";
    case "MU": return "Maurícia";
    case "MV": return "Maldivas";
    case "MW": return "Malaui";
    case "MX": return "México";
    case "MY": return "Malásia";
    case "MZ": return "Moçambique";
    case "NA": return "Namíbia";
    case "NC": return "Nova Caledônia";
    case "NE": return "Níger";
    case "NF": return "Ilha Norfolk";
    case "NG": return "Nigéria";
    case "NI": return "Nicarágua";
    case "NL": return "Países Baixos";
    case "NO": return "Noruega";
    case "NP": return "Nepal";
    case "NR": return "Nauru";
    case "NU": return "Niuê";
    case "NZ": return "Nova Zelândia";
    case "OM": return "Omã";
    case "PA": return "Panamá";
    case "PE": return "Peru";
    case "PF": return "Polinésia Francesa";
    case "PG": return "Papua-Nova Guiné";
    case "PH": return "Filipinas";
    case "PK": return "Paquistão";
    case "PL": return "Polônia";
    case "PM": return "Saint-Pierre e Miquelon";
    case "PN": return "Pitcairn";
    case "PR": return "Porto Rico";
    case "PS": return "Palestina";
    case "PT": return "Portugal";
    case "PW": return "Palau";
    case "PY": return "Paraguai";
    case "QA": return "Catar";
    case "RE": return "Reunião";
    case "RO": return "Romênia";
    case "RS": return "Sérvia";
    case "RU": return "Rússia";
    case "RW": return "Ruanda";
    case "SA": return "Arábia Saudita";
    case "SB": return "Ilhas Salomão";
    case "SC": return "Seicheles";
    case "SD": return "Sudão";
    case "SE": return "Suécia";
    case "SG": return "Singapura";
    case "SH": return "Santa Helena, Ascensão e Tristão da Cunha";
    case "SI": return "Eslovênia";
    case "SJ": return "Svalbard e Jan Mayen";
    case "SK": return "Eslováquia";
    case "SL": return "Serra Leoa";
    case "SM": return "San Marino";
    case "SN": return "Senegal";
    case "SO": return "Somália";
    case "SR": return "Suriname";
    case "SS": return "South Sudan";
    case "ST": return "São Tomé e Príncipe";
    case "SV": return "El Salvador";
    case "SX": return "São Martinho";
    case "SY": return "Síria";
    case "SZ": return "Suazilândia";
    case "TC": return "Ilhas Turcas e Caicos";
    case "TD": return "Chade";
    case "TF": return "Terras Austrais e Antárticas Francesas";
    case "TG": return "Togo";
    case "TH": return "Tailândia";
    case "TJ": return "Tajiquistão";
    case "TK": return "Toquelau";
    case "TL": return "Timor-Leste";
    case "TM": return "Turcomenistão";
    case "TN": return "Tunísia";
    case "TO": return "Tonga";
    case "TR": return "Turquia";
    case "TT": return "Trinidad e Tobago";
    case "TV": return "Tuvalu";
    case "TW": return "Taiwan";
    case "TZ": return "Tanzânia";
    case "UA": return "Ucrânia";
    case "UG": return "Uganda";
    case "UM": return "Ilhas Menores Distantes dos Estados Unidos";
    case "US": return "Estados Unidos";
    case "UY": return "Uruguai";
    case "UZ": return "Uzbequistão";
    case "VA": return "Vaticano";
    case "VC": return "São Vicente e Granadinas";
    case "VE": return "Venezuela";
    case "VG": return "Ilhas Virgens Britânicas";
    case "VI": return "Ilhas Virgens Americanas";
    case "VN": return "Vietnã";
    case "VU": return "Vanuatu";
    case "WF": return "Wallis e Futuna";
    case "WS": return "Samoa";
    case "YE": return "Iêmen";
    case "YT": return "Maiote";
    case "ZA": return "África do Sul";
    case "ZM": return "Zâmbia";
    case "ZW": return "Zimbábue";
    default: return iso;
  };
};