//@ts-check
import React from 'react';
import { Botao } from '../../../nucleo/nucleo';
import { connect } from 'react-redux';
import Icones from '../../../assets/icones/Icones';

import './BotaoCarrinho.css';

/**
 * 
 * @param {import('./types').botaoPrincipalPropsType} props 
 * @returns 
 */
function BotaoCarrinho(props) {
  const IconeCarrinho = Icones.mobi.Carrinho;

  return <Botao className={'botao-carrinho row ' + (props.className || '')} disabled={(props.disabled) || (props.quantidadeDeProdutos <= 0)} onClick={props.webAppBotaoPrincipalReducer.executarAcao}>
    {props.quantidadeDeProdutos > 0
      ? <div key='__quantidade_de_produtos_carrinho__' className='quantidade'>{props.quantidadeDeProdutos}</div>
      : null
    }
      <IconeCarrinho style={{width: '1.7em'}} />
  </Botao>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  const props = {
    webAppBotaoPrincipalReducer: state.webAppBotaoPrincipalReducer,
    quantidadeDeProdutos: Object.keys(state.webAppCarrinhoReducer.produtos).length 
  };

  return props;
};

const mapActionToProps = () => ({});

export default connect(mapStateToProps, mapActionToProps)(BotaoCarrinho);
