import React from "react";
import { dispatchTipo } from "../../../../../store/actions/acaoTemplate";
import { carrinhoProdutoTipo, carrinhoUnidadeTipo } from "../../../nucleo/redux/carrinho/types";
import { grupoProdutoReducerTipo } from "../../../nucleo/redux/produtos/grupoProdutos/grupoProdutosReducer";
import { grupoProdutoTamanhoTipo } from "../../../nucleo/redux/produtos/grupoProdutoTamanhos/grupoProdutoTamanhoReducer";

export type produtosComTamanhoTelaTipo = {
    dispatch: dispatchTipo,
    grupoProduto: grupoProdutoReducerTipo,
    grupoProdutoTamanho?: grupoProdutoTamanhoTipo,
    nomeUnidade: string,
    sabores: Array<carrinhoProdutoTipo>,
    unidade: carrinhoUnidadeTipo,
    busca: string
};

export class ProdutosComTamanhoTelaType<S = {}, SS = {}> extends React.Component<produtosComTamanhoTelaTipo, S, SS> {}