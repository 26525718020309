//@ts-check
import "./BarraGrupoProduto.css"

import React from "react"
import { Botao } from "../../../nucleo/nucleo"
import { connect } from "react-redux"
import scrollToGrupo from "../../../nucleo/utils/ScreenUtils/ScreenUtils"

/**
 * 
 * @param {import('./types').barraGrupoProdutoTipo} props 
 * @returns {JSX.Element}
 */
function BarraGrupoProduto(props) {
    const grupoProdutos = props.grupoProdutosReducer.grupos;

    return <div id='barra-grupo-produto' style={props.style}>
        <div id='barra-grupo-alinhado-ao-centro'>
            <div id='barra-grupo-produto-conteudo'>
            {Object.keys(grupoProdutos)
                .map((codigo) => <Botao
                key={`__botao_grupo_produto__${codigo}`}
                children={grupoProdutos[codigo].nome}
                onClick={() => scrollToGrupo(codigo)}
                />)
            }
            </div>
        </div>
    </div>;
}

/**
 * 
 * @param {import('../../../nucleo/redux/types').webAppReducersTipo} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
  grupoProdutosReducer: state.webAppGrupoProdutosReducer,
});

/**
 * 
 * @param {import('../../../../../store/actions/acaoTemplate').dispatchTipo} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BarraGrupoProduto);
