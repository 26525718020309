import React from "react"
import { connect } from "react-redux"

import { loadEmpresaId } from "../../utils/CompanyUtils"
import { log } from "../../utils/LogUtils"
import { getSaleSourceNoCompany } from "../../utils/StorageUtils/LocalStorageUtils"
import { getSaleSourceCode, setSaleSourceCode } from "../../utils/StorageUtils/SessionStorageUtils"
import { updateActiveMenuItem } from "../../utils/ScreenUtils"

import * as controleVendaActions from "../../store/actions/controleVenda/controleVendaAction"
import { RamoEmpresa } from "../../store/actions/empresaSelectorAction"
import {
  SELECTED_SALE_SOURCE_STATE_LIST
  , STATE_CONTROLE_VENDA_BAR_QR_CODE_TO_NEW_SALE
  , STATE_CONTROLE_VENDA_CLIENT_LAST_SALES
  , STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL
  , STATE_CONTROLE_VENDA_GET_SALE_FROM_QR_CODE
  , STATE_CONTROLE_VENDA_GRID_ORIGENS
  , STATE_CONTROLE_VENDA_GRID_VENDAS
  , STATE_CONTROLE_VENDA_LIST_SALES_FROM_CALL
  , STATE_CONTROLE_VENDA_MANUTENCAO_VENDA
  , STATE_CONTROLE_VENDA_NEW_SALE_FROM_BAR_QR_CODE
  , STATE_CONTROLE_VENDA_NEW_SALE_FROM_CALL
  , STATE_CONTROLE_VENDA_NOVA_VENDA
  , STATE_CONTROLE_VENDA_PAGAR_ORIGENS
  , STATE_CONTROLE_VENDA_PAGAR_VENDAS
} from "../../store/reducers/controleVenda/controleVendaReducer"

import CodeReader from "../CodeReader/CodeReader"
import ControleVendaHeader from "./ControleVendaHeader"
import GridOrigemVenda from "./grid/GridOrigemVenda"
import GridVendaFromCliente from "./grid/GridVendaFromCliente"
import GridVendaFromOrigem from "./grid/GridVendaFromOrigem"
import ManutencaoVenda from "./manutencao/ManutencaoVenda"
import TelaPagamentoVendas from "./pagamento/TelaPagamentoVendas"

import "./vendas.css";

/* 
 * Necessário para o Edge, pelo menos até sair o próximo updates
 * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8993198/
 */
const URLSearchParams = require("url-search-params")

class ControleVendaBox extends React.Component {

  /**
   * Retorna a tela a ser exibida de acordo com o estado armazenado no *reducer*.
   */
  getConteudoControleVendaBox() {

    log('ControleVendaBox getConteudoControleVendaBox');

    switch (this.props.state) {
      case STATE_CONTROLE_VENDA_GRID_ORIGENS:
        return this.props.isCargo ? <GridOrigemVenda /> : null;
      case STATE_CONTROLE_VENDA_GRID_VENDAS:
        return this.props.isCargo ? <GridVendaFromOrigem /> : null;
      case STATE_CONTROLE_VENDA_CLIENT_LAST_SALES:
        return ((!this.props.isCargo) && this.props.isAuthenticated) ? <GridVendaFromCliente /> : null;
      case STATE_CONTROLE_VENDA_MANUTENCAO_VENDA:
      case STATE_CONTROLE_VENDA_NOVA_VENDA:
        return <ManutencaoVenda
          history={this.props.history}
        />;
      case STATE_CONTROLE_VENDA_PAGAR_VENDAS:
      case STATE_CONTROLE_VENDA_PAGAR_ORIGENS:
        return <TelaPagamentoVendas />;
      default:
        return null;
    }
  }

  /**
   * Inicia uma nova venda ou exibe as vendas de uma origem cujo código impresso tenha sido lido.
   */
  treatCall = () => {
    // Se está iniciando vendas a partir de chamadas feitas pelos códigos impressos em origens de venda
    if (this.props.fromCallState === STATE_CONTROLE_VENDA_NEW_SALE_FROM_CALL) {
      this.props.continueNewVenda(this.props.newVendaData);
      this.props.updateUnread(this.props.newVendaData.origemVenda);
      this.props.resetFromCall();
      return true;
    }
    // e a origem não é livre
    else if (this.props.fromCallState === STATE_CONTROLE_VENDA_LIST_SALES_FROM_CALL) {
      this.props.exibeGridVendaFromOrigem(this.props.origemVendaFromCall);
      if (this.props.state === STATE_CONTROLE_VENDA_GRID_VENDAS) {
        this.props.loadVendaListAbertaFromOrigemVenda();
      }
      this.props.updateUnread(this.props.origemVendaFromCall);
      this.props.resetFromCall();
      return true;
    }
    // Ou se estiver abrindo uma venda que teve um ou mais pagamentos cancelados
    else if ([STATE_CONTROLE_VENDA_GET_SALE_FROM_CANCEL, STATE_CONTROLE_VENDA_GET_SALE_FROM_QR_CODE].includes(this.props.fromCallState)) {
      // Trata a venda
      this.props.exibeManutencaoVendaSearch(this.props.vendaURI, this.props.fromCallState === STATE_CONTROLE_VENDA_GET_SALE_FROM_QR_CODE);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Método executado APÓS montar o componente.
   */
  componentDidMount() {

    log('ControleVendaBox componentDidMount');

    updateActiveMenuItem('menuItemControleVenda', 'menuItemVenda');

    this.props.setValue(true, 'visible');

    // Verifica se foi passada uma origem de venda pelo URL
    const query = new URLSearchParams(this.props.location.search);
    let code = query.get('code');
    let order = query.get('order');

    // Se for um usuário sem vínculo com empresa
    if (!this.props.isCargo) {
      // Verifica se foi feita uma venda por um usuário recém registrado
      let saleSourceNoCompany = getSaleSourceNoCompany();

      let saleSourceCode = code ? code : getSaleSourceCode();

      if (saleSourceCode) {
        loadEmpresaId(parseInt(saleSourceCode));
        setSaleSourceCode(saleSourceCode);
        if (!saleSourceNoCompany) {
          code = saleSourceCode;
        }
      }
      else if (saleSourceNoCompany && saleSourceNoCompany.origemVendaURI) {
        let uriArray = saleSourceNoCompany.origemVendaURI.split('origemVendas/');
        if (uriArray.length > 1) {
          loadEmpresaId(parseInt(uriArray[1]));
          setSaleSourceCode(uriArray[1]);
        }
      }

      // Se foi passada uma origem de venda pelo URL, inicia ou continua uma venda nesta origem.
      if (code) {
        this.props.setupOrContinueSaleNoCompanyFromURL(code, order);
      }
      // se foi feito um pedido antes do usuário estar registrado e o usuário está logado, carrega esse pedido.
      else if (saleSourceNoCompany && this.props.isAuthenticated) {
        this.props.continueSaleNoCompany(saleSourceNoCompany.origemVendaURI, saleSourceNoCompany.numeroPessoas);
      }
      // Senão, exibe o leitor de código QR para iniciar ou continuar uma venda.
      else {
        this.props.setupSaleNoCompany();
      }
    }
    // Não carrega as origens se estiver usando o leitor de código de barras/QR
    else if (!this.treatCall()) {
      // Se for empresa do ramo Painel, exibe a manutenção de venda imediatamente.
      if (this.props.ramoEmpresa === RamoEmpresa.PAINEL) {
        this.props.exibeManutencaoVendaPainel();
      }
      // Se não, exibe as origens em uso normalmente.
      else if (this.props.state !== STATE_CONTROLE_VENDA_BAR_QR_CODE_TO_NEW_SALE) {
        this.props.exibeGridOrigemVendaEmUso();
      }
    }
  }

  /**
   * Método executado APÓS a montagem/renderização do componente.
   */
  componentDidUpdate(previousProps) {

    log('ControleVendaBox componentDidUpdate');

    // Carrega as origens de venda se foi exibido o dialog para iniciar nova venda
    // a partir de leitura de código de barras ou QR mas foi cancelado
    if ((!this.treatCall()) && (this.props.state === STATE_CONTROLE_VENDA_NEW_SALE_FROM_BAR_QR_CODE) && (!this.props.dialogShow)) {
      this.props.exibeGridOrigemVendaEmUso();
    }
    // Se estava em uma das telas que envolvem ter uma origem de venda selecionada
    // e vai para outra tela que não, marca as origens de venda como não sendo usadas por este usuário.
    if (this.props.isCargo
      && SELECTED_SALE_SOURCE_STATE_LIST.some(state => previousProps.state === state)
      && SELECTED_SALE_SOURCE_STATE_LIST.every(state => this.props.state !== state)) {
      this.props.clearUsuarioAtivo();
    }
  }

  /**
   * Método executado ANTES de "DESMONTAR" o componente.
   * Limpa a tela armazenada no *reducer* para não exibir notificação ao voltar para esta tela.
   */
  componentWillUnmount() {

    log('ControleVendaBox componentWillUnmount');

    this.props.setValue(false, 'visible');

    this.props.setValue(0, 'clientSaleAmount');

    this.props.resetState();

    if (this.props.isCargo) {
      // Marca as origens de venda como não sendo usadas por este usuário ao sair das vendas.
      this.props.clearUsuarioAtivo();
    }
  }

  /**
   * Método que executa a montagem/rederização do componente.
   */
  render() {

    log('ControleVendaBox render');

    switch (this.props.state) {
      // Exibe o leitor de código de barras ou QR no lugar do cabeçalho e do conteúdo de vendas
      case STATE_CONTROLE_VENDA_BAR_QR_CODE_TO_NEW_SALE:
        return <CodeReader />;
      // Exibe nada enquanto dialog de iniciar nova venda a partir de uma leitura de código está aberto
      case STATE_CONTROLE_VENDA_NEW_SALE_FROM_BAR_QR_CODE:
        return null;
      // Exibe uma das telas de vendas
      default: return <div id='cadastro-box' >

        {/* Header do box */}
        <ControleVendaHeader
          history={this.props.history}
        />

        {/* Conteúdo do box */}
        <div id='conteudo-box' >
          {/* valor inicial é null */}
          {this.getConteudoControleVendaBox()}
        </div>

      </div>;
    }
  }
}

/**
 * Passa as propriedades do estado global para o estado local.
 * @param {*} state 
 */
const mapStateToProps = state => ({

  dialogShow: state.appReducer.dialogShow,
  fromCallState: state.controleVendaReducer.fromCallState,
  isAuthenticated: !!state.authReducer.token,
  isCargo: !!state.empresaSelectorReducer.cargo,
  newVendaData: state.controleVendaReducer.newVendaData,
  origemVendaFromCall: state.controleVendaReducer.origemVendaFromCall,
  ramoEmpresa: state.empresaSelectorReducer.ramoEmpresa,
  state: state.controleVendaReducer.state,
  vendaURI: state.controleVendaReducer.vendaURI
});

/**
 * Mapeia as ações.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({

  clearUsuarioAtivo: () => dispatch(controleVendaActions.clearUsuarioAtivo()),
  continueNewVenda: newVendaData => dispatch(controleVendaActions.continueNewVenda(newVendaData)),
  continueSaleNoCompany: (origemVendaURI, numeroPessoas) => dispatch(controleVendaActions.continueSaleNoCompany(origemVendaURI, numeroPessoas)),
  exibeGridOrigemVendaEmUso: () => dispatch(controleVendaActions.exibeGridOrigemVendaEmUso()),
  exibeGridVendaFromOrigem: origemVenda => dispatch(controleVendaActions.exibeGridVendaFromOrigem(origemVenda)),
  exibeManutencaoVendaPainel: () => dispatch(controleVendaActions.exibeManutencaoVendaPainel()),
  exibeManutencaoVendaSearch: (vendaURI, tryAgain) => dispatch(controleVendaActions.exibeManutencaoVendaSearch(vendaURI, tryAgain)),
  loadVendaListAbertaFromOrigemVenda: () => dispatch(controleVendaActions.loadVendaListAbertaFromOrigemVenda()),
  resetFromCall: () => dispatch(controleVendaActions.resetFromCall()),
  resetState: () => dispatch(controleVendaActions.resetState()),
  setValue: (value, position) => dispatch(controleVendaActions.setValue(value, position)),
  setupOrContinueSaleNoCompanyFromURL: (origemVendaURI, order) => dispatch(controleVendaActions.setupOrContinueSaleNoCompanyFromURL(origemVendaURI, order)),
  setupSaleNoCompany: () => dispatch(controleVendaActions.setupSaleNoCompany()),
  updateUnread: origemVenda => dispatch(controleVendaActions.updateUnread(origemVenda))
});

/**
 * Exporta o último argumento entre parênteses.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ControleVendaBox);
